import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import React from "react";
import "./favorites_tools.scss";
import { Subscription } from "rxjs";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { FavoritesToolsLoaded, FavoritesToolsState } from "blocs/favorites_tools_bloc/favorites_tools_state";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ToolItem from "./components/tool_item";
import EmptyImageSrc from "assets/images/empty.svg";
import FavoriteTool from "models/favorite_tool";

interface Props extends RouteComponentProps<any>, WithTranslation {}

enum SortBy {
	DATE,
	REF,
}

type State = {
	rawToolsList: Array<FavoriteTool>;
	tools: Array<FavoriteTool>;
	sortby: SortBy;
	searchQuery: string;
};

class FavoritesToolsScreen extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;
	// history = useHistory();

	// static contextTypes = {
	//     contextType: BlocsContext,
	//     router: () => true, // replace with PropTypes.object if you use them
	//   }
	// declare context: React.ContextType<typeof BlocsContext>

	private favoritesToolsBlocSubscription: Subscription = Subscription.EMPTY;
	private toolfinderSelectionBlocSubscription: Subscription = Subscription.EMPTY;

	constructor(props: Props) {
		super(props);
		this.state = {
			rawToolsList: [],
			tools: [],
			sortby: SortBy.DATE,
			searchQuery: "",
		};
	}

	componentDidMount(): void {
		this.favoritesToolsBlocSubscription = this.context.favoritesToolsBloc.listen(this.favoritesToolsBlocAction);
		this.favoritesToolsBlocAction(this.context.favoritesToolsBloc.state);
	}

	componentWillUnmount(): void {
		this.favoritesToolsBlocSubscription.unsubscribe();
		this.toolfinderSelectionBlocSubscription.unsubscribe();
	}

	favoritesToolsBlocAction = (state: FavoritesToolsState) => {
		if (state instanceof FavoritesToolsLoaded) {
			this.setState({
				rawToolsList: state.favorites,
				tools: this.sortAndFilterTools(state.favorites),
			});
		}
	};

	sortAndFilterTools(_rawToolsList: Array<FavoriteTool>): Array<FavoriteTool> {
		var _tools: Array<FavoriteTool> = _rawToolsList;
		// Filters
		if (this.state.searchQuery && this.state.searchQuery !== "") {
			_tools = _tools.filter((favorite: FavoriteTool) => favorite.articleCode.toLowerCase().search(this.state.searchQuery.toLowerCase()) !== -1);
		}

		// Sort
		switch (this.state.sortby) {
			case SortBy.DATE:
				// _tools.sort((a, b) =>
				//     b.getAffinity(coating: _toolfinderSelection.coating, tool: _toolfinderSelection.tool).compareTo(a.getAffinity(coating: _toolfinderSelection.coating, tool: _toolfinderSelection.tool)));
				_tools.sort((a: FavoriteTool, b: FavoriteTool) => b.createdAt.diff(a.createdAt));

				break;
			case SortBy.REF:
				_tools.sort((a: FavoriteTool, b: FavoriteTool) => a.articleCode.localeCompare(b.articleCode));

				break;
		}

		return _tools;
	}

	setQueryString(query: string) {
		this.setState(
			{
				searchQuery: query,
				// tools: this.sortAndFilterTools(this.state.rawToolsList)
			},
			() => {
				this.setState({
					// searchQuery: event.currentTarget.value,
					tools: this.sortAndFilterTools(this.state.rawToolsList),
				});
			}
		);
	}

	setSortBy(sortBy: SortBy) {
		this.setState({ sortby: sortBy }, () => {
			this.setState({
				tools: this.sortAndFilterTools(this.state.rawToolsList),
			});
		});
	}

	renderSortsAndFilters() {
		return (
			<div className="container">
				<div className="sort-and-filter d-flex py-3">
					<div className="me-5">
						<h2>{this.props.t("screen.favorites_tools.title")}</h2>
						<div className="tools-found">{this.state.tools.length} {this.props.t("screen.favorites_tools.favorite_found")}</div>
					</div>

					<div className="me-5">
						<div className="filterTitle">{this.props.t("general.search")}</div>
						<div className="d-flex">
							<div className="custom-search-field">
								<span className="icon icon-search"></span>
								<input
									type="text"
									placeholder={this.props.t("screen.favorites_tools.search")}
									value={this.state.searchQuery}
									onChange={(event) => this.setQueryString(event.currentTarget.value)}
								/>
							</div>
							<button onClick={(event) => this.setQueryString("")} type="button" className="btnResetSearch btn btn-primary text-white px-4">
								{this.props.t("general.reset")}
							</button>
						</div>
					</div>
					<div className="vertical-separator"></div>
					<div className="mx-4">
						<div className="filterTitle">{this.props.t("general.sortby")}</div>
						<div className="d-flex">
							<button
								type="button"
								onClick={(event) => this.setSortBy(SortBy.DATE)}
								className={"filter" + (this.state.sortby === SortBy.DATE ? " active" : "")}
							>
								<span className="icon icon-sort"></span>
								{this.props.t("general.sortby_date")}
							</button>
							<button
								type="button"
								onClick={(event) => this.setSortBy(SortBy.REF)}
								className={"filter" + (this.state.sortby === SortBy.REF ? " active" : "")}
							>
								<span className="icon icon-sort"></span>
								{this.props.t("general.sortby_ref")}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<BaseScreen>
				<div className="w-100 step-tool d-flex flex-column align-items-center">
					<div className="border-bottom my-4 w-100 mx-auto">{this.renderSortsAndFilters()}</div>
					<div className="w-100 mx-auto">
						<div className="container align-items-center mx-auto">
							{this.state.tools.length === 0 && (
								<div className="d-flex justify-content-center my-5 empty-state">
									<img src={EmptyImageSrc} className="emptyImage" alt="logo" />
									<h5>{this.props.t("screen.favorites_tools.no_favorite")}.</h5>
									<p>{this.props.t("screen.favorites_tools.no_favorite_description")}</p>
									<Link to="/select-tool">
										<button type="button" className={"emptySeeAll"}>
											{this.props.t("screen.favorites_tools.show_list")} →
										</button>
									</Link>
								</div>
							)}
							{this.state.tools.length !== 0 && (
								<div className="row">
									{this.state.tools.map((favorite: FavoriteTool) => {
										return (
											<ToolItem
												key={favorite.articleCode}
												favorite={favorite}
												// onClick={() => this.setState({ selectedTool: tool })}
												onClick={() => {}}
											/>
										);
									})}
								</div>
							)}
						</div>
					</div>
				</div>
			</BaseScreen>
		);
	}
}

export default withTranslation("common")(withRouter(FavoritesToolsScreen));
