import { Bloc, Transition } from "@felangel/bloc";
import AssRefUsi from "models/ass_ref_usi";
import AssRefUsiRepository from "repositories/ass_ref_usi_repository";
import { AssRefUsisEvent, AssRefUsisLoad } from "./ass_ref_usi_event";
import { AssRefUsisState, AssRefUsisLoaded, AssRefUsisLoading, AssRefUsisInitial, AssRefUsisFailure } from "./ass_ref_usi_state";


export default class AssRefUsisBloc extends Bloc<AssRefUsisEvent, AssRefUsisState> {
	assRefUsiRepository: AssRefUsiRepository;
	assRefUsis: Array<AssRefUsi> = [];

	constructor(assRefUsiRepository: AssRefUsiRepository) {
		super(new AssRefUsisInitial());
		this.assRefUsiRepository = assRefUsiRepository;
	}

	async *mapEventToState(event: AssRefUsisEvent) {
		if (event instanceof AssRefUsisLoad) {
			yield* this.mapLoadAssRefUsisToState(event);
		}
	}

	async *mapLoadAssRefUsisToState(event: AssRefUsisLoad) {
		try {
			yield new AssRefUsisLoading();
			var assRefUsisList: Array<AssRefUsi> = await this.assRefUsiRepository.getData();
			this.assRefUsis = assRefUsisList;

			yield new AssRefUsisLoaded(this.assRefUsis);
		} catch (e) {
			if (typeof e === "string") {
				yield new AssRefUsisFailure(e);
			} else {
				// yield new AssRefUsisFailure(e.toString());
				yield new AssRefUsisFailure("Unknow error");
			}
		}
	}

	// Called whenever an `event` is added.
	onEvent(event: AssRefUsisEvent): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
	}

	// Called whenever a state change is about to occur.
	onTransition(transition: Transition<any, any>): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
	}

	onError(error: any): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
	}
}
