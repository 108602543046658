import Article from "./article";
import Coating from "./coating";
import Machine from "./machine";
import Machining from "./machining";
import Material from "./material";
import Reference from "./reference";
// import Tool from "./tool";

export default class ToolfinderSelection {
	material: Material;
	materialLock: boolean;

	machining: Machining;
	machiningLock: boolean;

  reference: Reference;
  article: Article;
	toolLock: boolean;

	coating: Coating;
	coatingLock: boolean;

	machine: Machine;
	machineLock: boolean;

	constructor(
		{ material, materialLock, machining, machiningLock, reference, article, toolLock, coating, coatingLock, machine, machineLock }: any /*NamedParameters*/
	) {
		this.material = material ?? null;
		this.materialLock = materialLock;
		this.machining = machining;
		this.machiningLock = machiningLock;
		this.reference = reference;
		this.article = article;
		this.toolLock = toolLock;
		this.coating = coating;
		this.coatingLock = coatingLock;
		this.machine = machine;
		this.machineLock = machineLock;
	}

	// name() {
	//     return "his firstname is: " + this.firstname;
	// }

	public copyWith({
		material,
		materialLock,
		machining,
		machiningLock,
		reference,
		article,
		toolLock,
		coating,
		coatingLock,
		machine,
		machineLock,
	}: any): ToolfinderSelection {
		return new ToolfinderSelection({
			material: material ?? this.material,
			materialLock: materialLock ?? this.materialLock,
			machining: machining ?? this.machining,
			machiningLock: machiningLock ?? this.machiningLock,
			reference: reference ?? this.reference,
			article: article ?? this.article,
			toolLock: toolLock ?? this.toolLock,
			coating: coating ?? this.coating,
			coatingLock: coatingLock ?? this.coatingLock,
			machine: machine ?? this.machine,
			machineLock: machineLock ?? this.machineLock,
		});
	}
}
