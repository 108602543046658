import Message from "models/message";

export abstract class MessagesState {}

export class MessagesInitial extends MessagesState {}

export class MessagesLoading extends MessagesState {}

export class MessagesLoaded extends MessagesState {
	messages: Array<Message>;
	constructor(messages: Array<Message>) {
		super();
		this.messages = messages;
	}

	public toString = (): string => {
		return `MessagesLoaded ${this.messages.length} messages`;
	};
}

export class MessagesFailure extends MessagesState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `MessagesLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
