import Machine from "models/machine";
import Config from "config/config";
import axios from "axios";

export default class MachineRepository {
	async getData(): Promise<Array<Machine>> {
		var data: any = await axios.get(Config.base_data_url + "/getJson?file=IND_MACHINE", {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "X-Requested-With",
			},
		});
		return await this.dataToMachine(data.data);
	}

	dataToMachine(data: any): Array<Machine> {
		var machines: Array<Machine> = [];
		for (var i = 0; i < data.length; i++) {
			if (data[i]["MACHINE_STATUT"] == true) machines.push(Machine.fromJson(data[i]));
		}
		return machines;
	}
}
