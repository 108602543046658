import toast from "react-hot-toast";

export default class GeneralHelper {
	static warningToast(text: String) {
		toast.error(text as any, {
			position: "top-right",
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="35"
					height="35"
					fill="currentColor"
					className="bi bi-exclamation-circle-fill"
					viewBox="0 0 16 16"
				>
					<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
				</svg>
			),
			duration: 3000,
		});
	}

  static successToast(text: String) {
		toast.error(text as any, {
			position: "top-right",
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="35"
					height="35"
					fill="currentColor"
					className="bi bi-check-circle-fill"
					viewBox="0 0 16 16"
				>
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
				</svg>
			),
			duration: 3000,
		});
	}

	static parseDoubleOrNull(value: any): number {
		if (value == null) return undefined as any;
		if (typeof value === 'string' || value instanceof String) return value != undefined ? parseFloat(value as string) : (undefined as any);
		if (typeof value === 'number' || value instanceof Number) return value != undefined ? value : (undefined as any);
		return undefined as any;
	}

	static parseIntOrNull(value: any): number {
		if (value == null) return undefined as any;

		if (typeof value === 'string' || value instanceof String) return value != undefined ? parseInt(value as string) : (undefined as any);
		if (typeof value === 'number' || value instanceof Number) return value != undefined ? value.toFixed(0) : (undefined as any);
		return undefined as any;
	}

	static avoidNullOrEmptyString(text: string): string {
		if (text != null && text != undefined && text.trim() != "") {
			return text;
		}
		return undefined as any;
	}

  static roundNumber(number: number, decimalCount: number): string {
    // Utilisez .toFixed() pour arrondir le nombre
    const fixedNumber = number.toFixed(decimalCount);
  
    // Convertissez le résultat en une chaîne de caractères
    // const nombreChaine = nombreArrondi.toString();
  
    // Supprimez les zéros inutiles à la fin en utilisant une expression régulière
    const nombreFormate = fixedNumber.replace(/\.?0+$/, '');
  
    return nombreFormate;
  }

  // function to remove html from string
  static removeHtml(text: string): string {
    return text.replace(/<[^>]*>?/gm, '');
  }

  // function to truncate text to x characters
  static truncateText(text: string, length: number): string {
    return text.length > length ? text.substring(0, length) + "..." : text;
  }
}
