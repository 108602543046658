import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import React from "react";
import "./step_coating.scss";
import { Subscription } from "rxjs";
import { CoatingsLoaded, CoatingsState } from "blocs/coatings_bloc/coatings_state";
import Coating from "models/coating";
import { withRouter, RouteComponentProps } from "react-router-dom";
import CoatingItem from "./components/coating_item";
import BackButton from "components/back_button";
import { ToolfinderSetSelections } from "../blocs/toolfiinder_selections_bloc/toolfiinder_selections_event";
import ToolfinderSelection from "models/toolfinder_selection";
import { ToolfinderSelectionsState, ToolfinderSelectionsUpdated } from "../blocs/toolfiinder_selections_bloc/toolfiinder_selections_state";
import { WithTranslation, withTranslation } from "react-i18next";
import StepsResume from "../components/steps_resume";
import diameterMinIcon from "assets/icons/diameter_min.svg";
import diameterMaxIcon from "assets/icons/diameter_max.svg";
import CustomCoatingFilterDropdown from "components/custom_coating_filter_dropdown";
import Article from "models/article";
import CalculHelper from "helpers/calcul_helper";

interface Props extends RouteComponentProps<any>, WithTranslation {}

export enum CoatingSortBy {
  AFFINITY,
  NAME,
  D1MIN,
  D1MAX,
}

type State = {
  rawCoatingsList: Array<Coating>;
  // coatings: Array<Coating>;
  allCoatingsSortedForTop10: Array<Coating>;
  top10Coatings: Array<Coating>;
  othersCoatings: Array<Coating>;
  selectedCoating: Coating;
  toolfinderSelection: ToolfinderSelection;
  sortNumber: number;
  availableSortbyArray: Array<CoatingSortBy>;
  sortbyArray: Array<CoatingSortBy>;
  // sortby: CoatingSortBy;
  searchQuery: string;
  showOthersCoatings: boolean;
};

class StepCoatingScreen extends React.Component<Props, State> {
  static contextType = BlocsContext;
  context!: React.ContextType<typeof BlocsContext>;
  // history = useHistory();

  // static contextTypes = {
  //     contextType: BlocsContext,
  //     router: () => true, // replace with PropTypes.object if you use them
  //   }
  // declare context: React.ContextType<typeof BlocsContext>

  private coatingsBlocSubscription: Subscription = Subscription.EMPTY;
  private toolfinderSelectionBlocSubscription: Subscription = Subscription.EMPTY;

  constructor(props: Props) {
    super(props);

    var defaultSortbyArray = [CoatingSortBy.NAME];

    this.state = {
      rawCoatingsList: [],
      // coatings: [],
      allCoatingsSortedForTop10: [],
      top10Coatings: [],
      othersCoatings: [],
      selectedCoating: null as any,
      toolfinderSelection: null as any,
      sortNumber: 1,
      availableSortbyArray: [CoatingSortBy.NAME, CoatingSortBy.D1MIN, CoatingSortBy.D1MAX],
      sortbyArray: defaultSortbyArray,
      // sortby: CoatingSortBy.AFFINITY,
      searchQuery: "",
      showOthersCoatings: false,
    };
  }

  componentDidMount(): void {
    this.toolfinderSelectionBlocSubscription = this.context.toolfinderSelectionBloc.listen(this.toolfinderSelectionBlocAction);
    this.toolfinderSelectionBlocAction(this.context.toolfinderSelectionBloc.state);
    if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated && this.context.toolfinderSelectionBloc.state.toolfinderSelection.article != null) {
      // var coatingsTop10Sorted: Array<Coating> = this.initialSortAndFilter(this.state.rawCoatingsList);
      this.setState({
        // sortbyArray: [CoatingSortBy.AFFINITY],
        availableSortbyArray: [CoatingSortBy.AFFINITY, CoatingSortBy.NAME, CoatingSortBy.D1MIN, CoatingSortBy.D1MAX],
      });
      this.setCoatingSortBy(CoatingSortBy.AFFINITY, 0);
    } else {
      this.setCoatingSortBy(CoatingSortBy.NAME, 0);
    }

    this.coatingsBlocSubscription = this.context.coatingsBloc.listen(this.coatingsBlocAction);
    this.coatingsBlocAction(this.context.coatingsBloc.state);
  }

  componentWillUnmount(): void {
    this.coatingsBlocSubscription.unsubscribe();
    this.toolfinderSelectionBlocSubscription.unsubscribe();
  }

  coatingsBlocAction = (state: CoatingsState) => {
    if (state instanceof CoatingsLoaded) {
      this.setState(
        {
          rawCoatingsList: state.coatings,
        },
        () => {
          this.splitCoatingsBySection();
        }
      );
    }
  };

  splitCoatingsBySection() {
    var coatingsTop10Sorted: Array<Coating> = this.initialSortAndFilter(this.state.rawCoatingsList);
    var top10 = this.filterCoatingByCompatible(coatingsTop10Sorted);
    top10 = top10.length > 10 ? top10.splice(0, 10) : top10;
    var other = coatingsTop10Sorted.filter((coating: Coating) => !top10.includes(coating));

    // sort
    coatingsTop10Sorted = this.sortAndFilterCoatingsWithUserFilter(coatingsTop10Sorted);
    top10 = this.sortAndFilterCoatingsWithUserFilter(top10);
    other = this.sortAndFilterCoatingsWithUserFilter(other);
    // console.log(top10);
    // Debug
    // var top10 = coatingsTop10Sorted;

    this.setState({
      allCoatingsSortedForTop10: coatingsTop10Sorted,
      top10Coatings: top10,
      othersCoatings: other,
      // coatings: this.sortAndFilterCoatings(state.coatings),
    });
  }

  customCoatingSort = (a: Coating, b: Coating): number => {
    var returnValue: number | undefined = undefined;
    this.state.sortbyArray.forEach((sortby: CoatingSortBy) => {
      if (returnValue != undefined) return;

      if (sortby == CoatingSortBy.NAME) {
        var publicNameCompare = a.publicName[this.props.i18n.language].localeCompare(b.publicName[this.props.i18n.language]);
        if (publicNameCompare != 0) return (returnValue = publicNameCompare);
      }
      if (sortby == CoatingSortBy.AFFINITY) {
        if (a.affinity > b.affinity) return (returnValue = -1);
        if (a.affinity < b.affinity) return (returnValue = 1);
      }
      if (sortby == CoatingSortBy.D1MIN) {
        if (a.dCibleMin == undefined) return (returnValue = 1);
        if (b.dCibleMin == undefined) return (returnValue = -1);
        if (a.dCibleMin < b.dCibleMin) return (returnValue = -1);
        if (a.dCibleMin > b.dCibleMin) return (returnValue = 1);
      }
      if (sortby == CoatingSortBy.D1MAX) {
        if (a.dCibleMax == undefined) return (returnValue = 1);
        if (b.dCibleMax == undefined) return (returnValue = -1);
        if (a.dCibleMax > b.dCibleMax) return (returnValue = -1);
        if (a.dCibleMax < b.dCibleMax) return (returnValue = 1);
      }
    });
    return returnValue ?? 0;
    // console.log("sort");
  };

  //   getTop10Coatings(_rawCoatingsList: Array<Coating>): Array<Coating> {
  //  var selectedArticle: Article | undefined = undefined;

  //     if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated && this.context.toolfinderSelectionBloc.state.toolfinderSelection.article != null) {
  //       selectedArticle = this.context.toolfinderSelectionBloc.state.toolfinderSelection.article;
  //     }

  //     if(selectedArticle == undefined) return [];
  //     // return CalculHelper.getCoatingsWithDefaultSort(_rawCoatingsList, selectedArticle!);

  //     var coatings = CalculHelper.getCoatingsWithDefaultSort(_rawCoatingsList, selectedArticle!);
  //     return coatings.slice(0, 10);
  //   }

  // sortAndFilterCoatingsForTop10(_rawCoatingsList: Array<Coating>): Array<Coating> {
  //   // var selectedArticle: Article | undefined = undefined;

  //   // if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated && this.context.toolfinderSelectionBloc.state.toolfinderSelection.article != null) {
  //   //   selectedArticle = this.context.toolfinderSelectionBloc.state.toolfinderSelection.article;
  //   // }

  //   // if(selectedArticle == undefined) return _rawCoatingsList;
  //   // // return CalculHelper.getCoatingsWithDefaultSort(_rawCoatingsList, selectedArticle!);

  //   // var _coatings: Array<Coating> = Object.assign([], _rawCoatingsList);

  //   // _coatings.sort((a: Coating, b: Coating) => {

  //   //   var aCompatible: boolean = CalculHelper.isCoatingCompatibleWithArticle(a, selectedArticle!);
  //   //   var bCompatible: boolean = CalculHelper.isCoatingCompatibleWithArticle(b, selectedArticle!);

  //   //   if(aCompatible && !bCompatible) return -1;
  //   //   if(!aCompatible && bCompatible) return 1;

  //   //   return a.affinity - b.affinity;
  //   // });

  //   // return _coatings;

  //   var selectedArticle: Article | undefined = undefined;

  //   if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated && this.context.toolfinderSelectionBloc.state.toolfinderSelection.article != null) {
  //     selectedArticle = this.context.toolfinderSelectionBloc.state.toolfinderSelection.article;
  //   }

  //   if (selectedArticle == undefined) return _rawCoatingsList;

  //   return CalculHelper.getCoatingsWithDefaultSort(_rawCoatingsList, selectedArticle!);
  // }

  initialSortAndFilter(_rawCoatingsList: Array<Coating>): Array<Coating> {
    var _coatings: Array<Coating> = Object.assign([], _rawCoatingsList);
    var selectedArticle: Article | undefined = undefined;

    if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated && this.context.toolfinderSelectionBloc.state.toolfinderSelection.article != null) {
      selectedArticle = this.context.toolfinderSelectionBloc.state.toolfinderSelection.article;
    }

    if (selectedArticle == undefined) return _coatings;

    return CalculHelper.getCoatingsWithDefaultSort(_coatings, selectedArticle!);
  }

  filterCoatingByCompatible(_rawCoatingsList: Array<Coating>): Array<Coating> {
    var _coatings: Array<Coating> = Object.assign([], _rawCoatingsList);
    var selectedArticle: Article | undefined = undefined;

    if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated && this.context.toolfinderSelectionBloc.state.toolfinderSelection.article != null) {
      selectedArticle = this.context.toolfinderSelectionBloc.state.toolfinderSelection.article;
    }

    if (selectedArticle == undefined) return _coatings;

    return _coatings.filter((coating: Coating) => {
      return CalculHelper.isCoatingCompatibleWithArticle(coating, selectedArticle!);
    });
  }

  sortAndFilterCoatingsWithUserFilter(_rawCoatingsList: Array<Coating>): Array<Coating> {
    var _coatings: Array<Coating> = Object.assign([], _rawCoatingsList);
    // Filters
    if (this.state.searchQuery && this.state.searchQuery !== "") {
      _coatings = _coatings.filter((coating: Coating) => {
        var searchQuery1 = coating.publicName[this.props.i18n.language].toLowerCase().search(this.state.searchQuery.toLowerCase()) !== -1;
        var searchQuery2 = coating.code.toLowerCase().search(this.state.searchQuery.toLowerCase()) !== -1;

        return searchQuery1 || searchQuery2;
      });
    }

    _coatings.sort(this.customCoatingSort);

    return _coatings;
  }

  toolfinderSelectionBlocAction = (state: ToolfinderSelectionsState) => {
    if (state instanceof ToolfinderSelectionsUpdated) {
      this.setState({
        toolfinderSelection: state.toolfinderSelection,
        selectedCoating: state.toolfinderSelection.coating ? state.toolfinderSelection.coating : this.state.selectedCoating,
      });
    }
  };

  renderActionButtons() {
    return (
      <div>
        <button
          className="btn btn-dark custom-btn text-white mx-2"
          onClick={(e) => {
            this.props.history.push("/dashboard");
          }}
        >
          <span className="icon icon-arrow-left2 icon-left"></span>
          {this.props.t("screen.step_coating.cancel_back_button")}
        </button>
        <button
          className="btn btn-primary text-white custom-btn mx-2"
          onClick={(e) => {
            if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated) {
              this.context.toolfinderSelectionBloc.add(new ToolfinderSetSelections(this.state.toolfinderSelection.copyWith({ coating: this.state.selectedCoating })));
            }
            this.props.history.push("/dashboard");
          }}
        >
          <span className="icon icon-save icon-left"></span>
          {this.props.t("screen.step_coating.save_button")}
        </button>
      </div>
    );
  }

  setQueryString(query: string) {
    this.setState(
      {
        searchQuery: query,
        // coatings: this.sortAndFilterCoatings(this.state.rawCoatingsList)
      },
      () => {
        this.splitCoatingsBySection();
      }
    );
  }

  setCoatingSortBy(sortBy: CoatingSortBy, index: number) {
    var newSortbyArray = this.state.sortbyArray;
    if (sortBy == undefined) {
      newSortbyArray.splice(index, 1);
    } else {
      newSortbyArray[index] = sortBy;
    }
    this.setState(
      {
        sortbyArray: newSortbyArray,
        sortNumber: newSortbyArray.length,
      },
      () => {
        this.splitCoatingsBySection();
      }
    );
  }

  renderSortsAndFilters() {
    return (
      <div className="container">
        <div className="sort-and-filter d-flex py-3">
          <div className="me-5">
            <div className="filterTitle">{this.props.t("general.search")}</div>
            <div className="d-flex">
              <div className="custom-search-field">
                <span className="icon icon-search"></span>
                <input type="text" placeholder={this.props.t("screen.step_coating.search")} value={this.state.searchQuery} onChange={(event) => this.setQueryString(event.currentTarget.value)} />
              </div>
              <button onClick={(event) => this.setQueryString("")} type="button" className="btnResetSearch btn btn-primary text-white px-4">
                {this.props.t("general.reset")}
              </button>
            </div>
          </div>
          <div className="vertical-separator"></div>
          <div className="mx-4">
            <div className="filterTitle">{this.props.t("general.sortby")}</div>
            <div className="d-flex flex-wrap flex-md-nowrap gap-3">
              {Array(this.state.sortNumber)
                .fill(null)
                .map((_, index) => (
                  <CustomCoatingFilterDropdown
                    number={index + 1}
                    activeSort={this.state.sortbyArray[index]}
                    activeSorts={this.state.sortbyArray}
                    sorts={this.state.availableSortbyArray}
                    onSelectSort={(sort: CoatingSortBy) => this.setCoatingSortBy(sort, index)}
                  />
                ))}

              {this.state.sortNumber < this.state.availableSortbyArray.length && !this.state.sortbyArray.includes(null as any) && this.state.sortbyArray.length == this.state.sortNumber && (
                <button
                  className="btn-add-sort"
                  onClick={() => {
                    this.setState({
                      sortNumber: this.state.sortNumber + 1,
                    });
                  }}
                >
                  + {this.props.t("screen.step_coating.addSort")}
                </button>
              )}

              {/* 
              <button
                type="button"
                onClick={(event) => this.setCoatingSortBy(CoatingSortBy.AFFINITY)}
                className={"filter" + (this.state.sortbyArray.includes(CoatingSortBy.AFFINITY) ? " active" : "")}
              >
                <span className="icon icon-d-cube"></span>
                {this.props.t("general.sortby_affinity")}
              </button>
              <button type="button" onClick={(event) => this.setCoatingSortBy(CoatingSortBy.NAME)} className={"filter" + (this.state.sortbyArray.includes(CoatingSortBy.NAME) ? " active" : "")}>
                <span className="icon icon-sort"></span>
                {this.props.t("general.sortby_name")}
              </button>
              <button type="button" onClick={(event) => this.setCoatingSortBy(CoatingSortBy.D1MIN)} className={"filter" + (this.state.sortbyArray.includes(CoatingSortBy.D1MIN) ? " active" : "")}>

                <svg className="icon diameter-icon" id="Layer_1" enable-background="new 0 0 511.897 511.897" height="512" viewBox="0 0 511.897 511.897" width="512" xmlns="http://www.w3.org/2000/svg">
                  <path d="m255.95 511.699c-226.766.39-341.647-276.965-181.017-437.021 95.289-99.3 266.747-99.317 362.036.004 160.623 160.069 45.737 437.422-181.019 437.017zm0-472c-119.103 0-216 96.897-216 216 10.859 286.16 421.184 286.082 432-.003 0-119.1-96.898-215.997-216-215.997zm-34 282c-17.482.185-26.778-21.699-14.142-34.142 0 0 80-80 80-80 19.395-18.032 46.344 8.827 28.285 28.284 0 0-80 80-80 80-3.906 3.905-9.025 5.858-14.143 5.858zm176-85v-54c0-33.084-26.916-60-60-60h-55c-11.046 0-20 8.954-20 20s8.954 20 20 20h55c11.028 0 20 8.972 20 20v54c.956 26.461 39.015 26.528 40 0zm-140 143c0-11.046-8.954-20-20-20h-55c-11.028 0-20-8.972-20-20v-55c0-11.046-8.954-20-20-20s-20 8.954-20 20v55c0 33.084 26.916 60 60 60h55c11.046 0 20-8.954 20-20z" />
                </svg>
                {this.props.t("general.sortby_d1min")}
              </button>
              <button type="button" onClick={(event) => this.setCoatingSortBy(CoatingSortBy.D1MAX)} className={"filter" + (this.state.sortbyArray.includes(CoatingSortBy.D1MAX) ? " active" : "")}>

                <svg className="icon diameter-icon" id="Layer_1" enable-background="new 0 0 511.897 511.897" height="512" viewBox="0 0 511.897 511.897" width="512" xmlns="http://www.w3.org/2000/svg">
                  <path d="m255.95 511.699c-226.766.39-341.647-276.965-181.017-437.021 95.289-99.3 266.747-99.317 362.036.004 160.623 160.069 45.737 437.422-181.019 437.017zm0-472c-119.103 0-216 96.897-216 216 10.859 286.16 421.184 286.082 432-.003 0-119.1-96.898-215.997-216-215.997zm-34 282c-17.482.185-26.778-21.699-14.142-34.142 0 0 80-80 80-80 19.395-18.032 46.344 8.827 28.285 28.284 0 0-80 80-80 80-3.906 3.905-9.025 5.858-14.143 5.858zm176-85v-54c0-33.084-26.916-60-60-60h-55c-11.046 0-20 8.954-20 20s8.954 20 20 20h55c11.028 0 20 8.972 20 20v54c.956 26.461 39.015 26.528 40 0zm-140 143c0-11.046-8.954-20-20-20h-55c-11.028 0-20-8.972-20-20v-55c0-11.046-8.954-20-20-20s-20 8.954-20 20v55c0 33.084 26.916 60 60 60h55c11.046 0 20-8.954 20-20z" />
                </svg>

                {this.props.t("general.sortby_d1max")}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    // var bestCoatings: Array<Coating> = this.state.coatings.slice(0, 10);
    // var otherCoatings: Array<Coating> = this.state.coatings.slice(10, this.state.coatings.length);
    return (
      <BaseScreen actionButtons={this.renderActionButtons()}>
        <div className="w-100 step-coating d-flex flex-column align-items-center">
          <StepsResume />
          <div className="border-bottom mb-4 mt-2 w-100 mx-auto">
            {/* <div className="container">
							<BackButton onClick={this.props.history.goBack} text={this.props.t("general.back_to_dashboard")} />
						</div> */}
            {this.renderSortsAndFilters()}
          </div>
          <div className="w-100 mx-auto">
            <div className="container align-items-center mx-auto">
              <div className="coatings-found">
                {this.state.allCoatingsSortedForTop10.length} {this.props.t("screen.step_coating.coatings_found")}
              </div>

              {!this.state.availableSortbyArray.includes(CoatingSortBy.AFFINITY) && this.state.allCoatingsSortedForTop10.length > 0 && (
                <div className="d-flex flex-wrap">
                  {this.state.allCoatingsSortedForTop10.map((coating: Coating) => {
                    return (
                      <CoatingItem
                        key={coating.code}
                        coating={coating}
                        selected={this.state.selectedCoating && this.state.selectedCoating.code === coating.code}
                        onClick={() => {
                          this.setState({ selectedCoating: coating });
                          if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated) {
                            this.context.toolfinderSelectionBloc.add(new ToolfinderSetSelections(this.state.toolfinderSelection.copyWith({ coating: coating, coatingLock: true })));
                          }
                          this.props.history.push("/dashboard");
                        }}
                      />
                    );
                  })}
                </div>
              )}

              {this.state.availableSortbyArray.includes(CoatingSortBy.AFFINITY) && this.state.top10Coatings.length > 0 && (
                <>
                  <h3 className="mt-3 mb-3">{this.props.t("screen.step_coating.bestCoatings")}</h3>
                  {/* {this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated && this.context.toolfinderSelectionBloc.state.toolfinderSelection.article != null && (
                    <p>Diamètre de l'outil séléctionné : {this.context.toolfinderSelectionBloc.state.toolfinderSelection.article.dimD1}</p>
                  )} */}
                  <div className="d-flex flex-wrap">
                    {this.state.top10Coatings.map((coating: Coating) => {
                      return (
                        <CoatingItem
                          key={coating.code}
                          coating={coating}
                          selected={this.state.selectedCoating && this.state.selectedCoating.code === coating.code}
                          onClick={() => {
                            this.setState({ selectedCoating: coating });
                            if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated) {
                              this.context.toolfinderSelectionBloc.add(new ToolfinderSetSelections(this.state.toolfinderSelection.copyWith({ coating: coating, coatingLock: true })));
                            }
                            this.props.history.push("/dashboard");
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              )}

              {this.state.availableSortbyArray.includes(CoatingSortBy.AFFINITY) && this.state.showOthersCoatings == false && (
                <div className="d-flex justify-content-start">
                  <button
                    className="btn btn-primary text-white custom-btn mt-5"
                    onClick={(e) => {
                      this.setState({ showOthersCoatings: true });
                    }}
                  >
                    {this.props.t("screen.step_coating.show_others_coatings")}
                  </button>
                </div>
              )}
              {this.state.availableSortbyArray.includes(CoatingSortBy.AFFINITY) && this.state.showOthersCoatings && this.state.othersCoatings.length > 0 && (
                <>
                  <h3 className="mt-5 mb-3">{this.props.t("screen.step_coating.otherCoatings")}</h3>
                  <div className="d-flex flex-wrap">
                    {this.state.othersCoatings.map((coating: Coating) => {
                      return (
                        <CoatingItem
                          key={coating.code}
                          coating={coating}
                          selected={this.state.selectedCoating && this.state.selectedCoating.code === coating.code}
                          onClick={() => {
                            this.setState({ selectedCoating: coating });
                            if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated) {
                              this.context.toolfinderSelectionBloc.add(new ToolfinderSetSelections(this.state.toolfinderSelection.copyWith({ coating: coating, coatingLock: true })));
                            }
                            this.props.history.push("/dashboard");
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
}

export default withTranslation("common")(withRouter(StepCoatingScreen));
