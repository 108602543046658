import CartArticle from "models/cart_article";
import Article from "../../models/article";

export abstract class CartEvent { }

export class CartInit extends CartEvent {
  public toString = (): string => `CartInit`;
}

export class CartAddArticle extends CartEvent {
  article: Article;
  qty: number;
  rev: string|undefined;
  constructor(article: Article, qty: number = 1, rev: string|undefined = undefined) {
    super();
    this.article = article;
    this.qty = qty;
    this.rev = rev;
  }

  public toString = (): string => `CartAddArticle ` + this.article.id;
}

export class CartEditArticleQuantity extends CartEvent {
  article: Article;
  qty: number;
  rev: string|undefined;
  constructor(article: Article, qty: number, rev: string|undefined = undefined) {
    super();
    this.article = article;
    this.qty = qty;
    this.rev = rev;
  }

  public toString = (): string => `CartAddArticle ` + this.article.id;
}

export class CartRemoveArticle extends CartEvent {
  article: Article;
  rev: string|undefined;

  constructor(article: Article, rev: string|undefined = undefined) {
    super();
    this.article = article;
    this.rev = rev;
  }

  public toString = (): string => `CartRemoveArticle ` + this.article.id;
}

export class CartUpdateAll extends CartEvent {
  articles: Array<CartArticle>;
  constructor(articles: Array<CartArticle>) {
    super();
    this.articles = articles;
  }

  public toString = (): string => `CartUpdateAll ` + this.articles.length;
}

export class CartClear extends CartEvent {
  public toString = (): string => `CartClear`;
}


export class CartCheckArticles extends CartEvent {
  public toString = (): string => `CartCheckArticles`;
}
