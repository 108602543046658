import { BlocsContext } from "contexts/blocs_context";
import React from "react";
import "./steps_resume.scss";

import { withRouter, RouteComponentProps, matchPath, Link } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends RouteComponentProps<any>, WithTranslation {}

type State = {};

class StepsResume extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;

	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	isRouteActive(route: string) {
		return !!matchPath(this.props.location.pathname, { path: route, exact: true, strict: false });
	}

	render() {
		return (
			<div className="steps-resume row">
				<div className="container">
					<Link replace={true} to="/select-material" className={"step-item " + (this.isRouteActive("/select-material") ? "selected" : "")}>
						<span className="icon icon-d-cube"></span>

						{this.props.t("screen.steps_resume.step1")}
					</Link>
					<Link replace={true} to="/select-machining" className={"step-item " + (this.isRouteActive("/select-machining") ? "selected" : "")}>
						<span className="icon icon-drill"></span>
						{this.props.t("screen.steps_resume.step2")}
					</Link>
					<Link replace={true} to="/select-tool" className={"step-item " + (this.isRouteActive("/select-tool") ? "selected" : "")}>
						<span className="icon icon-drill-bit"></span>
						{this.props.t("screen.steps_resume.step3")}
					</Link>
					<Link replace={true} to="/select-coating" className={"step-item " + (this.isRouteActive("/select-coating") ? "selected" : "")}>
						<span className="icon icon-drill"></span>
						{this.props.t("screen.steps_resume.step4")}
					</Link>
					<Link replace={true} to="/select-machine" className={"step-item " + (this.isRouteActive("/select-machine") ? "selected" : "")}>
						<span className="icon icon-laser-cutting-machine"></span>
						{this.props.t("screen.steps_resume.step5")}
					</Link>
				</div>
			</div>
		);
	}
}

export default withTranslation("common")(withRouter(StepsResume));
