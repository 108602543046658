// import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import DashboardScreen from "screens/toolfinder/dashboard/dashboard";
import StepCoatingScreen from "screens/toolfinder/step_coating/step_coating";
import StepMachineScreen from "screens/toolfinder/step_machine/step_machine";
import StepMachiningScreen from "screens/toolfinder/step_machining/step_machining";
import StepMaterialScreen from "screens/toolfinder/step_material/step_material";
import StepToolScreen from "screens/toolfinder/step_tool/step_tool";

import FavoritesToolsScreen from "screens/favorites_tools/favorites_tools";
import FavoritesMaterialsScreen from "screens/favorites_materials/favorites_materials";
import HelpInfoScreen from "screens/help_info/help_info";
import AboutScreen from "screens/about/about";
import PrivacyPolicyScreen from "screens/privacy_policy/privacy_policy";
import LoginScreen from "screens/login/login";
import CartScreen from "screens/cart/cart";

// import BlogView from "./views/Blog/Blog";

// import NotFound from "./views/NotFound/NotFound";

const Router = (props: any) => {
	return (
		<Switch>
			{/* <Route exact path={"/"} isLoggedIn={ props.loggedIn } render={() => (<Redirect to="/blog" />)} />
            <Route path="/index" isLoggedIn={ props.loggedIn } render={() => (<Redirect to="/blog" />)} /> */}

			<Route exact path={"/"} render={() => <DashboardScreen />} />
			<Route exact path={"/dashboard"} render={() => <Redirect to="/" />} />

			<Route exact path={"/login"} render={() => <LoginScreen />} />
			<Route exact path={"/cart"} render={() => <CartScreen />} />


			<Route exact path={"/favorites-tools"} render={() => <FavoritesToolsScreen />} />
			<Route exact path={"/favorites-materials"} render={() => <FavoritesMaterialsScreen />} />

			<Route exact path={"/help-info"} render={() => <HelpInfoScreen />} />
			<Route exact path={"/about"} render={() => <AboutScreen />} />

			<Route exact path={"/privacy-policy"} render={() => <PrivacyPolicyScreen />} />

			<Route exact path={"/select-material"} render={() => <StepMaterialScreen />} />
			<Route exact path={"/select-machining"} render={() => <StepMachiningScreen />} />
			<Route exact path={"/select-tool"} render={() => <StepToolScreen />} />
			<Route exact path={"/select-coating"} render={() => <StepCoatingScreen />} />
			<Route exact path={"/select-machine"} render={() => <StepMachineScreen />} />

			{/* 
            <Route path={"/blog"} isLoggedIn={ props.loggedIn } render={() => (<BlogView name={"The Fetish Blog"} />)} />
            <Route path={"/news"} isLoggedIn={ props.loggedIn } render={() => (<NewsView name={"News"} />)} />
            <Route path={"/new/:id"} isLoggedIn={ props.loggedIn } render={() => (<NewView name={"New"} />)} />
            <Route path={"/channels"} isLoggedIn={ props.loggedIn } render={() => (<ChannelsView name={"Channels"} />)} />
            <Route path={"/video"} isLoggedIn={ props.loggedIn } render={() => (<SingleChannelView name={"Video"} />)} />
            <Route path={"/map"} isLoggedIn={ props.loggedIn } render={() => (<MapView name={"Fetish Map"} />)} />
            <Route path={"/messages"} isLoggedIn={ props.loggedIn } render={() => (<Chat name={"Messages"} />)}/>
            <Route path={"/conversation/:id"} isLoggedIn={ props.loggedIn } render={() => (<Conversation name={"Conversation"} />)}/>
            <Route path={"/conversation"} isLoggedIn={ props.loggedIn } render={() => (<Conversation name={"Conversation"} />)}/>

            <Route path={"/myprofile"} isLoggedIn={ props.loggedIn } render={() => (<MyProfileView name={"My Profile"} />)} />
            <Route path={"/share"} isLoggedIn={ props.loggedIn } render={() => (<ShareView name={"Invite friends"} />)} />
            <Route path={"/gallery/:id"} isLoggedIn={ props.loggedIn } render={(props) => (<GalleryView name={"Gallery"} { ...props } />)} />

            <Route path={"/about"} isLoggedIn={ props.loggedIn } render={() => (<AboutView name={"About"} />)} />
            <Route path={"/add-home-screen"} isLoggedIn={ props.loggedIn } render={() => (<AddToHomeScreen name={"Add to home screen"} />)} />
            <Route path={"/help"} isLoggedIn={ props.loggedIn } render={() => (<HelpView name={"Help"} />)} />
            <Route path={"/privacy"} isLoggedIn={ props.loggedIn } render={() => (<PrivacyView name={"Privacy policy"} />)}/>
            <Route path={"/terms"} isLoggedIn={ props.loggedIn } render={() => (<TermsView name={"Terms of services"} />)}/>
            <Route path={"/contact"} isLoggedIn={ props.loggedIn } render={() => (<ContactView name={"Contact"} />)}/>
            <Route path={"/feedback"} isLoggedIn={ props.loggedIn } render={() => (<FeedbackView name={"Feedback"} />)}/>
            <Route path={"/search"} isLoggedIn={ props.loggedIn } render={() => (<SearchView name={"Search"} />)}/>
            <Route path={"/blocked-users"} isLoggedIn={ props.loggedIn } render={() => (<BlockedUsersView name={"Blocked users"} />)}/>

            <Route path={"/profile/:id"} isLoggedIn={ props.loggedIn } render={(props) => (<ProfileView name={"Profile"} { ...props } />)} />
            <Route path={"/settings"} isLoggedIn={ props.loggedIn } render={() => (<SettingsView name={"Settings"} />)} />
            <Route path={"/goldprofile"} isLoggedIn={ props.loggedIn } render={() => (<GoldProfileView name={"Become gold now"} />)} />

            <Route path={"/posts/:id"} isLoggedIn={ props.loggedIn } render={(props) => (<UserPosts name={"User posts"} { ...props } />)} /> */}

			{/*Page Not Found*/}
			{/* <Route isLoggedIn={ props.loggedIn } component={NotFound} /> */}
		</Switch>
	);
};

// const Route = ({ isLoggedIn, ...props }) =>
//   isLoggedIn
//     ? <Route { ...props } />
//     : <Redirect to="/login" />

export default Router;
