import FavoriteTool from "models/favorite_tool";

export abstract class FavoritesToolsState {}

export class FavoritesToolsInitial extends FavoritesToolsState {}

export class FavoritesToolsLoading extends FavoritesToolsState {}

export class FavoritesToolsLoaded extends FavoritesToolsState {
	favorites: Array<FavoriteTool>;
	constructor(favorites: Array<FavoriteTool>) {
		super();
		this.favorites = favorites;
	}

	public toString = (): string => {
		return `FavoritesToolsLoaded ${this.favorites.length} coatings`;
	};
}

export class FavoritesToolsFailure extends FavoritesToolsState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `FavoritesToolsLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
