import { ArticleCoating } from "constants/constants";
import Article from "models/article";

export abstract class ArticlesCoatingsSelectionState {}

export class ArticlesCoatingsSelectionInitial extends ArticlesCoatingsSelectionState {}

export class ArticlesCoatingsSelectionLoading extends ArticlesCoatingsSelectionState {}

export class ArticlesCoatingsSelectionLoaded extends ArticlesCoatingsSelectionState {
	articlesCoatingsSelection: Array<ArticleCoating>;
	constructor(articlesCoatingsSelection: Array<ArticleCoating>) {
		super();
		this.articlesCoatingsSelection = articlesCoatingsSelection;
	}

	public toString = (): string => {
		return `ArticlesCoatingsSelectionLoaded ${this.articlesCoatingsSelection.length} ArticleCoating`;
	};
}

export class ArticlesCoatingsSelectionFailure extends ArticlesCoatingsSelectionState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `ArticlesCoatingsSelectionFailure { errorMessage: ${this.errorMessage} }`;
	};
}
