import React, { useState, useContext, useEffect, useRef, MouseEventHandler } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import "./custom_coating_filter_dropdown.scss";
import Coating from "models/coating";
import checkIcon from "assets/icons/check.svg";
import Article from "models/article";
import Reference from "models/reference";
import { BlocsContext } from "contexts/blocs_context";
import { ToolfinderSelectionsUpdated } from "screens/toolfinder/blocs/toolfiinder_selections_bloc/toolfiinder_selections_state";
import ShoppingCartIcon from "assets/icons/shopping-cart.svg";
import { BlocBuilder } from "@felangel/react-bloc";
import { CartState, CartUpdated } from "blocs/cart_bloc/cart_state";
import CartArticle from "models/cart_article";
import { CoatingSortBy } from "screens/toolfinder/step_coating/step_coating";

interface Props extends WithTranslation {
  number: number;
  sorts: Array<CoatingSortBy>;
  activeSort: CoatingSortBy;
  activeSorts: Array<CoatingSortBy>;
  onSelectSort: Function;
}

const CustomCoatingFilterDropdown: React.FC<Props> = ({ t, number, sorts, activeSort, activeSorts, onSelectSort }) => {
  const blocsContext = useContext(BlocsContext);

  const [open, setOpen] = useState(false);
  // const [selected, setSelected] = useState<Coating|undefined>(undefined);

  let btnRef = useRef<HTMLButtonElement>(null);
  let menuRef = useRef<HTMLDivElement>(null);

  const onMouseDownhandler = (e: any) => {
    if (!menuRef?.current?.contains(e.target) && !btnRef?.current?.contains(e.target)) {
      setOpen(false);
    }
  };
  const onWheelHandler = (e: any) => {
    if (!menuRef?.current?.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onMouseDownhandler);
    document.addEventListener("wheel", onWheelHandler);

    return () => {
      document.removeEventListener("mousedown", onMouseDownhandler);
      document.removeEventListener("wheel", onWheelHandler);
    };
  });

  useEffect(() => {
    if (open) {
      // var btnRefPosition = btnRef.current?.getBoundingClientRect();
      // var wrapperTop = document.getElementsByClassName("articles-box")[0].getBoundingClientRect().top;
      // var btnHeight = btnRef.current?.clientHeight ?? 0;
      // var popupPosition = (btnRefPosition?.top ?? 0) - wrapperTop + btnHeight;

      // menuRef.current?.style.setProperty("top", `${popupPosition}px`);
      // menuRef.current?.style.setProperty("display", `flex`);
      // menuRef.current?.style.setProperty("width", `${btnRef.current?.clientWidth}px`);
      menuRef.current?.style.setProperty("display", "flex");
      menuRef.current?.style.setProperty("margin-top", "46px");
    } else {
      menuRef.current?.style.setProperty("display", `none`);
    }
  }, [open]);

  const toggleList = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(!open);
  };

  const selectItem = (item: CoatingSortBy) => {
    onSelectSort(item);
    setOpen(false);
  };

  const getSortName = (sort: CoatingSortBy) => {
    switch (sort) {
      case CoatingSortBy.AFFINITY:
        return t("general.sortby_affinity");
      case CoatingSortBy.NAME:
        return t("general.sortby_name");
      case CoatingSortBy.D1MIN:
        return t("general.sortby_d1min");
      case CoatingSortBy.D1MAX:
        return t("general.sortby_d1max");
    }
  };

  const getSortIcon = (sort: CoatingSortBy) => {
    switch (sort) {
      case CoatingSortBy.AFFINITY:
        return <span className="icon icon-d-cube d-flex align-items-center" style={{ fontSize: 20, marginBottom: 2 }}></span>;
      case CoatingSortBy.NAME:
        return <span className="icon icon-sort d-flex align-items-center" style={{ fontSize: 16, marginBottom: 1 }}></span>;
      case CoatingSortBy.D1MIN:
        return (
          <svg className="icon diameter-icon" id="Layer_1" enable-background="new 0 0 511.897 511.897" height="20" viewBox="0 0 511.897 511.897" width="20" xmlns="http://www.w3.org/2000/svg">
            <path d="m255.95 511.699c-226.766.39-341.647-276.965-181.017-437.021 95.289-99.3 266.747-99.317 362.036.004 160.623 160.069 45.737 437.422-181.019 437.017zm0-472c-119.103 0-216 96.897-216 216 10.859 286.16 421.184 286.082 432-.003 0-119.1-96.898-215.997-216-215.997zm-34 282c-17.482.185-26.778-21.699-14.142-34.142 0 0 80-80 80-80 19.395-18.032 46.344 8.827 28.285 28.284 0 0-80 80-80 80-3.906 3.905-9.025 5.858-14.143 5.858zm176-85v-54c0-33.084-26.916-60-60-60h-55c-11.046 0-20 8.954-20 20s8.954 20 20 20h55c11.028 0 20 8.972 20 20v54c.956 26.461 39.015 26.528 40 0zm-140 143c0-11.046-8.954-20-20-20h-55c-11.028 0-20-8.972-20-20v-55c0-11.046-8.954-20-20-20s-20 8.954-20 20v55c0 33.084 26.916 60 60 60h55c11.046 0 20-8.954 20-20z" />
          </svg>
        );
      case CoatingSortBy.D1MAX:
        return (
          <svg className="icon diameter-icon" id="Layer_1" enable-background="new 0 0 511.897 511.897" height="20" viewBox="0 0 511.897 511.897" width="20" xmlns="http://www.w3.org/2000/svg">
            <path d="m255.95 511.699c-226.766.39-341.647-276.965-181.017-437.021 95.289-99.3 266.747-99.317 362.036.004 160.623 160.069 45.737 437.422-181.019 437.017zm0-472c-119.103 0-216 96.897-216 216 10.859 286.16 421.184 286.082 432-.003 0-119.1-96.898-215.997-216-215.997zm-34 282c-17.482.185-26.778-21.699-14.142-34.142 0 0 80-80 80-80 19.395-18.032 46.344 8.827 28.285 28.284 0 0-80 80-80 80-3.906 3.905-9.025 5.858-14.143 5.858zm176-85v-54c0-33.084-26.916-60-60-60h-55c-11.046 0-20 8.954-20 20s8.954 20 20 20h55c11.028 0 20 8.972 20 20v54c.956 26.461 39.015 26.528 40 0zm-140 143c0-11.046-8.954-20-20-20h-55c-11.028 0-20-8.972-20-20v-55c0-11.046-8.954-20-20-20s-20 8.954-20 20v55c0 33.084 26.916 60 60 60h55c11.046 0 20-8.954 20-20z" />
          </svg>
        );
    }
  };

  const buildCoatingItem = (coatingSortBy: CoatingSortBy | undefined) => {
    // var active: boolean = coatingSortBy == activeSort;
    var active: boolean = activeSorts.find((sort: CoatingSortBy) => sort == coatingSortBy) != undefined;
    return (
      <button
        type="button"
        className={["dd-list-item", active ? "selected" : ""].join(" ")}
        key={coatingSortBy}
        onClick={() => {
          if (active) return;
          selectItem(coatingSortBy!);
        }}
      >
        {coatingSortBy != undefined ? getSortName(coatingSortBy) : t("general.sortChoice")}
        {active && <div className="active-label">{t("general.active")}</div>}
      </button>
    );
  };

  return (
    <div className={["custom-coating-filter-dropdown dd-wrapper", activeSort != undefined ? "active" : ""].join(" ")}>
      <button type="button" className="dd-header" onClick={toggleList} ref={btnRef}>
        <div className={["dd-header-title", activeSort == undefined ? "unselected" : "", "d-flex align-items-center"].join(" ")}>
          <div style={{ display: 'inline', marginRight: 10}}>{getSortIcon(activeSort)}</div> {number}. {activeSort != undefined ? getSortName(activeSort) : t("general.sortChoice")}
        </div>
        {/* {open
          ? <FontAwesome name="angle-up" size="2x" />
          : <FontAwesome name="angle-down" size="2x" />} */}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="open-menu-icon">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
      </button>
      <div role="list" className="dd-list" ref={menuRef}>
        {open && (
          <>
            <div className="dd-section-title">- {t("general.sortChoice")} -</div>

            {sorts.map((coating: CoatingSortBy) => buildCoatingItem(coating))}

            {activeSort != undefined && (
              <>
                <div className="divider"></div>
                <button
                  type="button"
                  className="delete-button"
                  onClick={() => {
                    selectItem(undefined!);
                  }}
                >
                  {t("general.deleteSort")}
                </button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation("common")(CustomCoatingFilterDropdown);
