import Material from "models/material";

export abstract class MaterialsState {}

export class MaterialsInitial extends MaterialsState {}

export class MaterialsLoading extends MaterialsState {}

export class MaterialsLoaded extends MaterialsState {
	materials: Array<Material>;
	constructor(materials: Array<Material>) {
		super();
		this.materials = materials;
	}

	public toString = (): string => {
		return `MaterialsLoaded ${this.materials.length} materials`;
	};
}

export class MaterialsFailure extends MaterialsState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `MaterialsLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
