import AuthenticationData from "../../models/authentication_data";

export abstract class AuthenticationEvent {}

export class AuthenticationAppLoaded extends AuthenticationEvent {
    public toString = (): string => `AuthenticationAppLoaded`;
}

export class AuthenticationLoggedIn extends AuthenticationEvent {
    authenticationData: AuthenticationData;
    fromRegistration : boolean;

    constructor(authenticationData: AuthenticationData, fromRegistration: boolean = false) {
        super();
        this.authenticationData = authenticationData;
        this.fromRegistration = fromRegistration;
    }

    public toString = (): string => `AuthenticationLoggedIn`;
}

export class AuthenticationLoggedOut extends AuthenticationEvent {
    public toString = (): string => `AuthenticationLoggedOut`;
}
