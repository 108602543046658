import BaseScreen from "layouts/base";
import React, { Fragment } from "react";
import "./starter_loading.scss";
import { WithTranslation, withTranslation, WithTranslationProps } from "react-i18next";
import logo from "assets/images/logo-louis-belet.svg";
import CoatingsBloc from "blocs/coatings_bloc/coatings_bloc";
import { CoatingsLoaded, CoatingsState } from "blocs/coatings_bloc/coatings_state";
import MachinesBloc from "blocs/machines_bloc/machines_bloc";
import { MachinesLoaded, MachinesState } from "blocs/machines_bloc/machines_state";
import MaterialsBloc from "blocs/materials_bloc/materials_bloc";
import { MaterialsLoaded, MaterialsState } from "blocs/materials_bloc/materials_state";
import ReferencesBloc from "blocs/references_bloc/references_bloc";
import { ReferencesLoaded, ReferencesState } from "blocs/references_bloc/references_state";
import ArticlesBloc from "blocs/articles_bloc/articles_bloc";
import { ArticlesLoaded, ArticlesState } from "blocs/articles_bloc/articles_state";
import { BlocsContext } from "contexts/blocs_context";
import { Subscription } from "rxjs";
import DataService from "services/data_service";
import { CoatingsLoad } from "blocs/coatings_bloc/coatings_event";
import { MachinesLoad } from "blocs/machines_bloc/machines_event";
import { MaterialsLoad } from "blocs/materials_bloc/materials_event";
import { ReferencesLoad } from "blocs/references_bloc/references_event";
import { ArticlesLoad } from "blocs/articles_bloc/articles_event";
import { AssRefMatsLoaded, AssRefMatsState } from "blocs/ass_ref_mat_bloc/ass_ref_mat_state";
import { AssRefUsisLoaded, AssRefUsisState } from "blocs/ass_ref_usi_bloc/ass_ref_usi_state";
import { AssRevMatsLoaded, AssRevMatsState } from "blocs/ass_rev_mat_bloc/ass_rev_mat_state";
import { MachiningsLoaded, MachiningsState } from "blocs/machining_bloc/machining_state";
import { MachiningGroupsLoaded, MachiningGroupsState } from "blocs/machining_group_bloc/machining_group_state";
import { AssRefMatsLoad } from "blocs/ass_ref_mat_bloc/ass_ref_mat_event";
import { AssRefUsisLoad } from "blocs/ass_ref_usi_bloc/ass_ref_usi_event";
import { AssRevMatsLoad } from "blocs/ass_rev_mat_bloc/ass_rev_mat_event";
import { MachiningsLoad } from "blocs/machining_bloc/machining_event";
import { MachiningGroupsLoad } from "blocs/machining_group_bloc/machining_group_event";
import { FavoritesToolsInit } from "blocs/favorites_tools_bloc/favorites_tools_event";
import { FavoritesMaterialsInit } from "blocs/favorites_materials_bloc/favorites_materials_event";
import { AuthenticationAppLoaded } from "blocs/authentication_bloc/authentication_event";
import Article from "models/article";
import { ToolfinderSetSelections } from "screens/toolfinder/blocs/toolfiinder_selections_bloc/toolfiinder_selections_event";
import Reference from "models/reference";
import { CartInit } from "blocs/cart_bloc/cart_event";
import { ArticlesCoatingsSelectionLoad } from "blocs/articles_coatings_selection_bloc/articles_coatings_selection_event";
import { MessagesLoad } from "blocs/messages_bloc/messages_event";
import { MessagesLoaded, MessagesState } from "blocs/messages_bloc/messages_state";

interface Props extends WithTranslation {}

type State = {
  isLoading: boolean;
  dataReady: boolean;
};

class StarterLoadingScreen extends React.Component<Props, State> {
  // static contextType = BlocsContext;
  // context!: React.ContextType<typeof BlocsContext>

  // _coatingsBloc: CoatingsBloc;
  _coatingsState: CoatingsState = undefined as any;
  // _machinesBloc: MachinesBloc;
  _machinesState: MachinesState = undefined as any;
  // _materialsBloc: MaterialsBloc;
  _materialsState: MaterialsState = undefined as any;
  // _referencesBloc: ReferencesBloc;
  _referencesState: ReferencesState = undefined as any;
  // _articlesBloc: ArticlesBloc;
  _articlesState: ArticlesState = undefined as any;

  // _assRefMatBloc: AssRefMatBloc;
  _assRefMatState: AssRefMatsState = undefined as any;
  // _assRefUsiBloc: AssRefUsiBloc;
  _assRefUsiState: AssRefUsisState = undefined as any;
  // _assRevMatBloc: AssRevMatBloc;
  _assRevMatState: AssRevMatsState = undefined as any;
  // _machiningBloc: MachiningBloc;
  _machiningState: MachiningsState = undefined as any;
  // _machiningGroupBloc: MachiningGroupBloc;
  _machiningGroupState: MachiningGroupsState = undefined as any;

  _messagesState: MessagesState = undefined as any;

  static contextType = BlocsContext;
  context!: React.ContextType<typeof BlocsContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      dataReady: false,
    };
  }
  componentDidMount() {
    this.context.coatingsBloc.listen((state: MaterialsState) => {
      this._coatingsState = state;
      this.checkLoadingFinished();
    });
    this.context.machinesBloc.listen((state: MachinesState) => {
      this._machinesState = state;
      this.checkLoadingFinished();
    });
    this.context.materialsBloc.listen((state: MaterialsState) => {
      this._materialsState = state;
      this.checkLoadingFinished();
    });
    this.context.referencesBloc.listen((state: ReferencesState) => {
      this._referencesState = state;
      this.checkLoadingFinished();
    });
    this.context.articlesBloc.listen((state: ArticlesState) => {
      this._articlesState = state;
      this.checkLoadingFinished();
    });

    this.context.assRefMatBloc.listen((state: AssRefMatsState) => {
      this._assRefMatState = state;
      this.checkLoadingFinished();
    });

    this.context.assRefUsiBloc.listen((state: AssRefUsisState) => {
      this._assRefUsiState = state;
      this.checkLoadingFinished();
    });

    this.context.assRevMatBloc.listen((state: AssRevMatsState) => {
      this._assRevMatState = state;
      this.checkLoadingFinished();
    });

    this.context.machiningBloc.listen((state: MachiningsState) => {
      this._machiningState = state;
      this.checkLoadingFinished();
    });
    this.context.machiningGroupBloc.listen((state: MachiningGroupsState) => {
      this._machiningGroupState = state;
      this.checkLoadingFinished();
    });

    this.context.messagesBloc.listen((state: MessagesState) => {
      this._messagesState = state;
      this.checkLoadingFinished();
    });

    this.startDataLoading();
  }

  async startDataLoading() {
    // var dataService: DataService = new DataService();
    // await dataService.init();
    this.setState({
      dataReady: true,
    });
    this.context.coatingsBloc.add(new CoatingsLoad());
    this.context.machinesBloc.add(new MachinesLoad());
    this.context.materialsBloc.add(new MaterialsLoad());
    this.context.referencesBloc.add(new ReferencesLoad());
    this.context.articlesBloc.add(new ArticlesLoad());

    this.context.assRefMatBloc.add(new AssRefMatsLoad());
    this.context.assRefUsiBloc.add(new AssRefUsisLoad());
    this.context.assRevMatBloc.add(new AssRevMatsLoad());
    this.context.machiningBloc.add(new MachiningsLoad());
    this.context.machiningGroupBloc.add(new MachiningGroupsLoad());
    this.context.articlesCoatingsSelectionBloc.add(new ArticlesCoatingsSelectionLoad());

    this.context.favoritesToolsBloc.add(new FavoritesToolsInit());
    this.context.favoritesMaterialsBloc.add(new FavoritesMaterialsInit());

    this.context.authenticationBloc.add(new AuthenticationAppLoaded());
    this.context.cartBloc.add(new CartInit());

    this.context.messagesBloc.add(new MessagesLoad());

    this.checkLoadingFinished();
  }

  checkLoadingFinished() {
    if (
      this._coatingsState instanceof CoatingsLoaded &&
      this._machinesState instanceof MachinesLoaded &&
      this._materialsState instanceof MaterialsLoaded &&
      this._referencesState instanceof ReferencesLoaded &&
      this._articlesState instanceof ArticlesLoaded &&
      this._assRefMatState instanceof AssRefMatsLoaded &&
      this._assRefUsiState instanceof AssRefUsisLoaded &&
      this._assRevMatState instanceof AssRevMatsLoaded &&
      this._machiningState instanceof MachiningsLoaded &&
      this._machiningGroupState instanceof MachiningGroupsLoaded &&
      //       this._messagesState instanceof MessagesLoaded &&
      this.state.dataReady == true
    ) {
      const queryParameters = new URLSearchParams(window.location.search);
      const goodid = queryParameters.get("goodid");

      var article = this._articlesState.articles.find((art: Article) => art.id == parseInt(goodid ?? ""));

      if (article != null) {
        var reference = this._referencesState.references.find((ref: Reference) => ref.id == article?.refId);

        this.context.toolfinderSelectionBloc.add(new ArticlesLoad());

        this.context.toolfinderSelectionBloc.add(
          new ToolfinderSetSelections(
            this.context.toolfinderSelectionBloc.toolfinderSelection.copyWith({
              reference: reference,
              article: article,
              toolLock: true,
            })
          )
        );
      }

      this.setState({
        isLoading: false,
      });
      // Navigator.pushReplacementNamed(context, ROUTES.DASHBOARD);
    }
  }

  renderContent() {
    return (
      <div style={{ height: "100vh" }}>
        <div className="d-flex flex-column justify-content-around align-items-center" style={{ height: "100%" }}>
          <img src={logo} className="App-logo-loading" alt="logo" />
          <div className="d-flex flex-column justify-content-around align-items-center">
            <div className="spinner-border mb-3">
              <span className="sr-only"></span>
            </div>
            <span>{this.props.t("screen.starter_loading.loading")}</span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.state.isLoading == true && this.renderContent()}
        {this.state.isLoading == false && this.props.children}
      </Fragment>
    );
  }
}

export default withTranslation("common")(StarterLoadingScreen);
