import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import config from "../config/config";

export default class AuthenticationService {
	static async login(username: string, password: string) {
		var data: AxiosResponse = await axios.post(
			config.SKYLIGHT_API + "/login",
			{
				username: username,
				password: password,
			},
			{
				headers: { 
          "Accept": "application/json",
        },
			}
		);

		return data;

		// if (data.status == 200 && data.data.status_code == 200) {
		//     return true;
		// }
		// return false;

		// .catch(function (error: any) {
		//     console.log(error);
		// })
		// .then((response) => {
		//   console.log(response);
		//     // console.log(Product.fromJson(response.data.data));
		//     // this.setState({
		//     //     product: Product.fromJson(response.data.data),
		//     // });
		// });
	}

	static async getMySelft(accessToken: string) {
		var data: AxiosResponse = await axios.get(
      config.SKYLIGHT_API + "/getInfoUser", 
      {
        headers: {
          Accept: "application/json",
        },
        params: { 
          token: accessToken 
        }
      } as AxiosRequestConfig
    );

		return data;
	}

	static storageSetToken(userToken: string | null) {
		// sessionStorage.setItem("token", JSON.stringify(userToken));
		if (userToken == null || userToken == undefined) {
			localStorage.removeItem("token");
			return;
		}
		localStorage.setItem("token", userToken as string);
	}

	static storageGetToken(): string {
		const tokenString: string = localStorage.getItem("token") as string;
		// const userToken = JSON.parse(tokenString);
		const userToken = tokenString;
		return userToken;
	}
}
