import { Bloc, Transition } from "@felangel/bloc";
import { FavoritesMaterialsInit, FavoritesMaterialsEvent, FavoritesMaterialsLoad } from "./favorites_materials_event";
import { FavoritesMaterialsState, FavoritesMaterialsLoaded, FavoritesMaterialsLoading } from "./favorites_materials_state";
import { FavoritesMaterialsAdd, FavoritesMaterialsClear, FavoritesMaterialsRemove } from "./favorites_materials_event";
import FavoriteMaterial from "models/favorite_material";


export default class FavoritesMaterialsBloc extends Bloc<FavoritesMaterialsEvent, FavoritesMaterialsState> {
	favorites: Array<FavoriteMaterial> = [];

	constructor() {
		super(new FavoritesMaterialsLoaded([]));
	}

	async *mapEventToState(event: FavoritesMaterialsEvent) {
		if (event instanceof FavoritesMaterialsInit) {
			yield* this.mapInitFavoritesMaterialsToState(event as FavoritesMaterialsInit);
		} else if (event instanceof FavoritesMaterialsLoad) {
			yield* this.mapLoadFavoritesMaterialsToState(event as FavoritesMaterialsLoad);
		} else if (event instanceof FavoritesMaterialsAdd) {
			yield* this.mapFavoritesMaterialsAddToState(event as FavoritesMaterialsAdd);
		} else if (event instanceof FavoritesMaterialsRemove) {
			yield* this.mapFavoritesMaterialsRemoveToState(event as FavoritesMaterialsRemove);
		} else if (event instanceof FavoritesMaterialsClear) {
			yield* this.mapFavoritesMaterialsClearToState(event as FavoritesMaterialsClear);
		}
	}

	async *mapInitFavoritesMaterialsToState(event: FavoritesMaterialsInit) {
		this.restoreDataFromLocalStorage();
		yield new FavoritesMaterialsLoaded(this.favorites);
	}

	async *mapLoadFavoritesMaterialsToState(event: FavoritesMaterialsLoad) {
		yield new FavoritesMaterialsLoading();
	}

	async *mapFavoritesMaterialsAddToState(event: FavoritesMaterialsAdd) {
		var alreadyHasFavorite: boolean = false;

		this.favorites.forEach((material: FavoriteMaterial) => {
			if (material.material.id === event.favorite.material.id) {
				alreadyHasFavorite = true;
			}
		});

		if (alreadyHasFavorite) {
			return;
		}

		this.favorites.push(event.favorite);
		this.saveDataToLocalStorage();
		yield new FavoritesMaterialsLoaded(this.favorites);
	}

	async *mapFavoritesMaterialsRemoveToState(event: FavoritesMaterialsRemove) {
		this.favorites.splice(this.favorites.map((favorite: FavoriteMaterial) => favorite.material.id).indexOf(event.favorite.material.id), 1);
		this.saveDataToLocalStorage();
		yield new FavoritesMaterialsLoaded(this.favorites);
	}

	async *mapFavoritesMaterialsClearToState(event: FavoritesMaterialsClear) {
		this.favorites = [];
		this.saveDataToLocalStorage();
		yield new FavoritesMaterialsLoaded(this.favorites);
	}

	// Called whenever an `event` is added.
	onEvent(event: FavoritesMaterialsEvent): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
	}

	// Called whenever a state change is about to occur.
	onTransition(transition: Transition<any, any>): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
	}

	onError(error: any): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
	}

	restoreDataFromLocalStorage() {
		var favoritesMaterialsRaw: string = localStorage.getItem("favorites_materials") as string;
		var favoritesMaterials: Array<FavoriteMaterial> = FavoriteMaterial.favoritesFromJson(JSON.parse(favoritesMaterialsRaw));
		this.favorites = favoritesMaterials;
	}
	saveDataToLocalStorage() {
		localStorage.setItem("favorites_materials", JSON.stringify(FavoriteMaterial.favoritesToJson(this.favorites)));
	}
}
