import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import React from "react";
import "./step_material.scss";
import { Subscription } from "rxjs";
import { MaterialsLoaded, MaterialsState } from "blocs/materials_bloc/materials_state";
import Material from "models/material";
import { withRouter, RouteComponentProps } from "react-router-dom";
import MaterialItem from "./components/material_item";
import BackButton from "components/back_button";
import { ToolfinderSetSelections } from "../blocs/toolfiinder_selections_bloc/toolfiinder_selections_event";
import ToolfinderSelection from "models/toolfinder_selection";
import { ToolfinderSelectionsState, ToolfinderSelectionsUpdated } from "../blocs/toolfiinder_selections_bloc/toolfiinder_selections_state";
import { FavoritesMaterialsLoaded, FavoritesMaterialsState } from "blocs/favorites_materials_bloc/favorites_materials_state";
import StepsResume from "../components/steps_resume";
import { WithTranslation, withTranslation } from "react-i18next";
import FavoriteMaterial from "models/favorite_material";

interface Props extends RouteComponentProps<any>, WithTranslation {}

enum SortBy {
	AFFINITY,
	NAME,
}

type State = {
	rawMaterialsList: Array<Material>;
	materials: Array<Material>;
	selectedMaterial: Material;
	toolfinderSelection: ToolfinderSelection;
	sortby: SortBy;
	searchQuery: string;
	favoritesOnly: boolean;
	favoritesMaterials: Array<FavoriteMaterial>;
};

class StepMaterialScreen extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;
	// history = useHistory();

	// static contextTypes = {
	//     contextType: BlocsContext,
	//     router: () => true, // replace with PropTypes.object if you use them
	//   }
	// declare context: React.ContextType<typeof BlocsContext>

	private materialsBlocSubscription: Subscription = Subscription.EMPTY;
	private toolfinderSelectionBlocSubscription: Subscription = Subscription.EMPTY;
	private favoritesMaterialsBlocSubscription: Subscription = Subscription.EMPTY;

	constructor(props: Props) {
		super(props);
		this.state = {
			rawMaterialsList: [],
			materials: [],
			favoritesMaterials: [],
			selectedMaterial: null as any,
			toolfinderSelection: null as any,
			sortby: SortBy.AFFINITY,
			searchQuery: "",
			favoritesOnly: false,
		};
	}

	componentDidMount(): void {
		this.materialsBlocSubscription = this.context.materialsBloc.listen(this.materialsBlocAction);
		this.materialsBlocAction(this.context.materialsBloc.state);

		this.toolfinderSelectionBlocSubscription = this.context.toolfinderSelectionBloc.listen(this.toolfinderSelectionBlocAction);
		this.toolfinderSelectionBlocAction(this.context.toolfinderSelectionBloc.state);

		this.favoritesMaterialsBlocSubscription = this.context.favoritesMaterialsBloc.listen(this.favoritesMaterialsBlocAction);
		this.favoritesMaterialsBlocAction(this.context.favoritesMaterialsBloc.state);
	}

	componentWillUnmount(): void {
		this.materialsBlocSubscription.unsubscribe();
		this.toolfinderSelectionBlocSubscription.unsubscribe();
	}

	materialsBlocAction = (state: MaterialsState) => {
		if (state instanceof MaterialsLoaded) {
			this.setState({
				rawMaterialsList: state.materials,
				materials: this.sortAndFilterMaterials(state.materials),
			});
		}
	};

	favoritesMaterialsBlocAction = (state: FavoritesMaterialsState) => {
		if (state instanceof FavoritesMaterialsLoaded) {
			this.setState(
				{
					favoritesMaterials: state.favorites,
				},
				() => {
					// var materials: Array<Material> = this.state.materials.map((material: Material) => {
					//     material.
					//     return material;
					// })

					this.setState({
						materials: this.sortAndFilterMaterials(this.state.materials),
					});
				}
			);
		}
	};

	sortAndFilterMaterials(_rawMaterialsList: Array<Material>): Array<Material> {
		var _materials: Array<Material> = _rawMaterialsList;
		// Filters
		if (this.state.searchQuery && this.state.searchQuery !== "") {
			_materials = _materials.filter((material: Material) => material.code.toLowerCase().search(this.state.searchQuery.toLowerCase()) !== -1);
		}

		if (this.state.favoritesOnly === true) {
			_materials = _materials.filter((material: Material) => {
				var isFavorite: boolean = false;

				this.state.favoritesMaterials.forEach((favoriteMaterial: FavoriteMaterial) => {
					if (favoriteMaterial.material.id === material.id) {
						isFavorite = true;
					}
				});
				return isFavorite;
			});
			//   List<MaterialModel.Material> favorites = BlocProvider.of<FavoriteMaterialsBloc>(context).favorites;
			//   _materials = _materials.where((MaterialModel.Material material) => favorites.contains(material)).toList();
		}

		// Auto sort by name by default
		_materials.sort((a: Material, b: Material) => a.code.localeCompare(b.code));

		// Sort
		switch (this.state.sortby) {
			case SortBy.AFFINITY:
				// _materials.sort((a, b) =>
				//     b.getAffinity(coating: _toolfinderSelection.coating, tool: _toolfinderSelection.tool).compareTo(a.getAffinity(coating: _toolfinderSelection.coating, tool: _toolfinderSelection.tool)));
				_materials.sort((a: Material, b: Material) => b.affinity - a.affinity);

				break;
			// case SortBy.NAME:
			// 	_materials.sort((a: Material, b: Material) => a.name.localeCompare(b.name));

			// 	break;
		}

		return _materials;
	}

	toolfinderSelectionBlocAction = (state: ToolfinderSelectionsState) => {
		if (state instanceof ToolfinderSelectionsUpdated) {
			this.setState({
				toolfinderSelection: state.toolfinderSelection,
				selectedMaterial: state.toolfinderSelection.material ? state.toolfinderSelection.material : this.state.selectedMaterial,
			});
		}
	};

	renderActionButtons() {
		return (
			<div>
				<button
					className="btn btn-dark custom-btn text-white mx-2"
					onClick={(e) => {
						this.props.history.push("/dashboard");
					}}
				>
					<span className="icon icon-arrow-left2 icon-left"></span>
					{this.props.t("screen.step_material.cancel_back_button")}
				</button>
				<button
					className="btn btn-primary text-white custom-btn mx-2"
					onClick={(e) => {
						if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated) {
							this.context.toolfinderSelectionBloc.add(
								new ToolfinderSetSelections(this.state.toolfinderSelection.copyWith({ material: this.state.selectedMaterial }))
							);
						}
						this.props.history.push("/dashboard");
					}}
				>
					<span className="icon icon-save icon-left"></span>
					{this.props.t("screen.step_material.save_button")}
				</button>
			</div>
		);
	}

	setQueryString(query: string) {
		this.setState(
			{
				searchQuery: query,
				// materials: this.sortAndFilterMaterials(this.state.rawMaterialsList)
			},
			() => {
				this.setState({
					// searchQuery: event.currentTarget.value,
					materials: this.sortAndFilterMaterials(this.state.rawMaterialsList),
				});
			}
		);
	}

	setSortBy(sortBy: SortBy) {
		this.setState({ sortby: sortBy }, () => {
			this.setState({
				materials: this.sortAndFilterMaterials(this.state.rawMaterialsList),
			});
		});
	}

	setFavoritesOnly(favoritesOnly: boolean) {
		this.setState({ favoritesOnly: favoritesOnly }, () => {
			this.setState({
				materials: this.sortAndFilterMaterials(this.state.rawMaterialsList),
			});
		});
	}

	renderSortsAndFilters() {
		return (
			<div className="container">
				<div className="sort-and-filter d-flex py-3">
					<div className="me-5">
						<div className="filterTitle">{this.props.t("general.search")}</div>
						<div className="d-flex">
							<div className="custom-search-field">
								<span className="icon icon-search"></span>
								<input
									type="text"
									placeholder={this.props.t("screen.step_material.search")}
									value={this.state.searchQuery}
									onChange={(event) => this.setQueryString(event.currentTarget.value)}
								/>
							</div>
							<button onClick={(event) => this.setQueryString("")} type="button" className="btnResetSearch btn btn-primary text-white px-4">
								{this.props.t("general.reset")}
							</button>
						</div>
					</div>
					<div className="vertical-separator"></div>
					<div className="mx-4">
						<div className="filterTitle">{this.props.t("general.sortby")}</div>
						<div className="d-flex">
							<button
								type="button"
								onClick={(event) => this.setSortBy(SortBy.AFFINITY)}
								className={"filter" + (this.state.sortby === SortBy.AFFINITY ? " active" : "")}
							>
								<span className="icon icon-d-cube"></span>
								{this.props.t("general.sortby_affinity")}
							</button>
							<button
								type="button"
								onClick={(event) => this.setSortBy(SortBy.NAME)}
								className={"filter" + (this.state.sortby === SortBy.NAME ? " active" : "")}
							>
								<span className="icon icon-sort"></span>
								{this.props.t("general.sortby_name")}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<BaseScreen actionButtons={this.renderActionButtons()}>
				<div className="w-100 step-material d-flex flex-column align-items-center">
					<StepsResume />
					<div className="border-bottom mb-4 mt-2 w-100 mx-auto">
						{/* <div className="container">
							<BackButton onClick={this.props.history.goBack} text={this.props.t("general.back_to_dashboard")} />
						</div> */}
						{this.renderSortsAndFilters()}
					</div>
					<div className="w-100 mx-auto">
						<div className="container align-items-center mx-auto">
							<label className="cl-switch">
								<input type="checkbox" checked={this.state.favoritesOnly} onChange={(event) => this.setFavoritesOnly(event.currentTarget.checked)} />
								<span className="switcher"></span>
								<span className="label">{this.props.t("general.favorites_only")}</span>
							</label>

							<div className="materials-found mt-4">
								{this.state.materials.length} {this.props.t("screen.step_material.materials_found")}
							</div>
							<div className="d-flex flex-wrap materialItemsWrapper">
								{this.state.materials.map((material: Material) => {
									return (
										<MaterialItem
											key={material.id}
											material={material}
											selected={this.state.selectedMaterial && this.state.selectedMaterial.id === material.id}
											onClick={() => {
												this.setState({ selectedMaterial: material });
												if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated) {
													this.context.toolfinderSelectionBloc.add(
														new ToolfinderSetSelections(this.state.toolfinderSelection.copyWith({ material: material, materialLock: true, }))
													);
												}
												this.props.history.push("/dashboard");
											}}
										/>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</BaseScreen>
		);
	}
}

export default withTranslation("common")(withRouter(StepMaterialScreen));
