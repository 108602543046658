import { Bloc, Transition } from "@felangel/bloc";
import Machine from "models/machine";
import MachineRepository from "repositories/machine_repository";
import { MachinesEvent, MachinesLoad } from "./machines_event";
import { MachinesState, MachinesLoaded, MachinesLoading, MachinesInitial, MachinesFailure } from "./machines_state";


export default class MachinesBloc extends Bloc<MachinesEvent, MachinesState> {
  machineRepository: MachineRepository;
  machines: Array<Machine> = [];

  constructor(machineRepository: MachineRepository) {
    super(new MachinesInitial());
    this.machineRepository = machineRepository;
  }

  async *mapEventToState(event: MachinesEvent) {
    if (event instanceof MachinesLoad) {
      yield* this.mapLoadMachinesToState(event);
    }
  }

  async *mapLoadMachinesToState(event: MachinesLoad) {
    try {
      yield new MachinesLoading();
      var machinesList: Array<Machine> = await this.machineRepository.getData();
      this.machines = machinesList;

      yield new MachinesLoaded(this.machines);
    } catch (e) {
      if (typeof e === "string") {
        yield new MachinesFailure(e);
      } else {
        // yield new MachinesFailure(e.toString());
        yield new MachinesFailure("Unknow error");
      }
    }
  }

  // Called whenever an `event` is added.
  onEvent(event: MachinesEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
  }

  // Called whenever a state change is about to occur.
  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
  }
}
