import BaseScreen from "layouts/base";
import React from "react";
import "./help_info.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends RouteComponentProps<any>, WithTranslation {}

type State = {};

class HelpInfoScreen extends React.Component<Props, State> {
	// static contextType = BlocsContext;
	// context!: React.ContextType<typeof BlocsContext>

	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	renderTitle() {
		return (
			<div className="container">
				<div className="sort-and-filter d-flex py-3">
					<div className="me-5">
						<h2>{this.props.t("screen.help_info.title")}</h2>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<BaseScreen>
				<div className="w-100 help-info d-flex flex-column align-items-center">
					<div className="border-bottom my-4 w-100 mx-auto">{this.renderTitle()}</div>
					<div className="w-100 mx-auto">
						<div className="container align-items-center mx-auto">{/* <p>Bla bla blablabla blabla</p> */}</div>
					</div>
				</div>
			</BaseScreen>
		);
	}
}

export default withTranslation("common")(withRouter(HelpInfoScreen));
