import config from "config/config";
import GeneralHelper from "helpers/general_helper";

// interface NamedParameters {
// 	id: number;
// 	code: string;
// 	type: string;
// 	constructorName: string;
// 	coef: number;
// 	createdAt: Date;
// 	longDescription: Record<string, string>;
// 	shortDescription: Record<string, string>;
// 	NMax: number;
// }

export default class MachiningGroup {
	// USI_GRP_ID
	id: number;
	// USIGRP_CODE
	code: string;

	// DESC_USI_GRP_XX
	groupDescription: Record<string, string>;
	// LONG_DESC_XX
	longDescription: Record<string, string>;
	// SHORT_DESC_XX
	shortDescription: Record<string, string>;

	affinity: number;

	constructor({ id, code, groupDescription, longDescription, shortDescription, affinity }: any) {
		this.id = id;
		this.code = code;
		this.groupDescription = groupDescription;
		this.longDescription = longDescription;
		this.shortDescription = shortDescription;
		this.affinity = affinity;
	}

	static areIdentical(a: MachiningGroup, b: MachiningGroup): boolean {
		if (!a && !b) return true;
		if (!a || !b) return false;
		return a.id === b.id;
	}

	static fromJson(map: Record<string, any>): MachiningGroup {
		return new MachiningGroup({
			id: GeneralHelper.parseIntOrNull(map["USI_GRP_ID"]),
			code: map["USIGRP_CODE"],
			groupDescription: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["DESC_USI_GRP_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["DESC_USI_GRP_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["DESC_USI_GRP_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["DESC_USI_GRP_IT"]),
			},
			longDescription: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESCR_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESCR_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESCR_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESCR_IT"]),
			},
			shortDescription: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESCR_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESCR_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESCR_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESCR_IT"]),
			},
			// affinity: map[''],
		});
	}

	getLongDescription(lang: string) {
		return this.longDescription[lang] != null && this.longDescription[lang] != ""
			? this.longDescription[lang]
			: this.longDescription[config["FALLBACK_LOCALE"]] ?? "";
	}

	getShortDescription(lang: string) {
		return this.shortDescription[lang] != null && this.shortDescription[lang] != ""
			? this.shortDescription[lang]
			: this.shortDescription[config["FALLBACK_LOCALE"]] ?? "";
	}
}
