import BaseScreen from "layouts/base";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import Modal from "react-modal";
import "./export_modal.scss";
import ReactPDF, { pdf, Page, Text, View, Document, StyleSheet, usePDF } from "@react-pdf/renderer";
import FileSaver, { saveAs } from "file-saver";
import { Subscription } from "rxjs";
import { CutParamsState, CutParamsUpdated } from "screens/toolfinder/blocs/cut_params_bloc/cut_params_state";
import ToolfinderCutParams from "models/toolfinder_cut_params";
import {
	ToolfinderSelectionsState,
	ToolfinderSelectionsUpdated,
} from "screens/toolfinder/blocs/toolfiinder_selections_bloc/toolfiinder_selections_state";
import { CutParamsSetToolfinderSelection } from "screens/toolfinder/blocs/cut_params_bloc/cut_params_event";
import { BlocsContext } from "contexts/blocs_context";
import { CutParam, CUT_PARAM_TYPE, FRAISAGE_FIELDS, PERCAGE_FIELDS } from "constants/constants";
import toast, { Toaster } from "react-hot-toast";
import html2canvas from "html2canvas";
import { exportComponentAsPNG } from "react-component-export-image";
import ToolfinderSelection from "models/toolfinder_selection";

import matiereImage from "assets/export_images/matiere.png";
import usinageImage from "assets/export_images/usinage.png";
import refOutilsImage from "assets/export_images/ref_outil.png";
import revetementImage from "assets/export_images/revetement.png";
import machineImage from "assets/export_images/machine.png";
import logoLBImage from "assets/export_images/logo-louis-belet.png";
import moment from "moment";

interface Props extends RouteComponentProps<any>, WithTranslation {
	modalOpened: boolean;
	closeModal: Function;
}

type State = {
	modalOpened: boolean;
	toolfinderCutParams: ToolfinderCutParams;
	toolfinderSelection: ToolfinderSelection;
};

class ComponentToPrint extends React.Component {
	render() {
		return <div>Hello World</div>;
	}
}

const pdfExportStyles = StyleSheet.create({
	page: {
		flexDirection: "column",
		backgroundColor: "#fff",
		// justifyContent: 'center',
		alignItems: "center",
		fontSize: 11,
		padding: 50,
	},
	title: {
		marginBottom: 20,
		fontSize: 16,
		fontWeight: "bold",
	},
	mainContainer: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: "#f9f9f9",
		width: 500,
		borderRadius: 3,
		paddingVertical: 30,
		paddingHorizontal: 50,
	},
	toolfinderSelectionContainer: {
		// display: "flex",
		// flexDirection: "row",
		paddingTop: 20,
		paddingBottom: 20,
	},
	toolfinderSelectionItem: {
		backgroundColor: "#ffffff",
		paddingVertical: 15,
		paddingHorizontal: 25,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
		marginLeft: 10,
		marginBottom: 10,
		// textAlign: "center",
	},
	toolfinderSelectionFakeItem: {
		paddingVertical: 10,
		paddingHorizontal: 25,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		flex: 1,
		marginLeft: 10,
		marginBottom: 10,
		// textAlign: "center",
	},
	toolfinderSelectionItemTitle: {
		marginVertical: 3,
		fontWeight: 700,
    marginTop:10,
	},
	row: {
		display: "flex",
		flexDirection: "row",
	},
	col: {
		display: "flex",
		flexDirection: "column",
	},
	cutParamsContainer: {},
	table: {
		display: "flex",
		flexDirection: "column",
	},
	tr: {
		display: "flex",
		flexDirection: "row",
	},
	tdPrefix: {
		paddingHorizontal: 5,
		paddingVertical: 3,
		width: 30,
	},
	tdValue: {
		paddingHorizontal: 5,
		paddingVertical: 3,
		width: 80,
		textAlign: "right",
		fontWeight: "bold",
	},
	tdSuffix: {
		paddingHorizontal: 5,
		paddingVertical: 3,
		width: 50,
	},
});

const imageExportStyles = StyleSheet.create({
	page: {
		flexDirection: "column",
		backgroundColor: "#fff",
		// justifyContent: 'center',
		alignItems: "center",
		fontSize: 11,
		padding: 40,
	},
	title: {
		marginBottom: 20,
		fontSize: 12,
	},
	cutParamsContainer: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: "#eeeeee",
		width: 300,
		borderRadius: 10,
		padding: 20,
	},
});

class ExportModal extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;

	private cutParamsBlocSubscription: Subscription = Subscription.EMPTY;
	private toolfinderSelectionBlocSubscription: Subscription = Subscription.EMPTY;

	componentRef: any;

	constructor(props: Props) {
		super(props);
		this.state = {
			modalOpened: this.props.modalOpened,
			toolfinderCutParams: new ToolfinderCutParams({}),
			toolfinderSelection: undefined as any,
		};

		this.componentRef = React.createRef();
	}

	componentDidMount(): void {
		this.cutParamsBlocSubscription = this.context.cutParamsBloc.listen(this.cutParamsBlocAction);
		this.cutParamsBlocAction(this.context.cutParamsBloc.state);

		this.toolfinderSelectionBlocSubscription = this.context.toolfinderSelectionBloc.listen(this.toolfinderSelectionBlocAction);
		this.toolfinderSelectionBlocAction(this.context.toolfinderSelectionBloc.state);
	}

	componentWillUnmount(): void {
		this.cutParamsBlocSubscription.unsubscribe();
		this.toolfinderSelectionBlocSubscription.unsubscribe();
	}

	cutParamsBlocAction = (state: CutParamsState) => {
		if (state instanceof CutParamsUpdated) {
			this.setState({
				toolfinderCutParams: state.toolfinderCutParams,
			});
		}
	};

	toolfinderSelectionBlocAction = (state: CutParamsState) => {
		if (state instanceof ToolfinderSelectionsUpdated) {
			this.setState({
				toolfinderSelection: state.toolfinderSelection,
			});
		}
	};

	componentWillReceiveProps(nextProps: any) {
		// You don't have to do this check first, but it can help prevent an unneeded render
		if (nextProps.modalOpened !== this.props.modalOpened) {
			this.setState({ modalOpened: nextProps.modalOpened });
		}
	}

	openModal = () => {
		this.setState({
			modalOpened: true,
		});
	};

	getValue = (key: CUT_PARAM_TYPE) => {
		var toolfinderCutParams: ToolfinderCutParams = this.state.toolfinderCutParams;
		var _value;
		var _locked;
		switch (key) {
			case CUT_PARAM_TYPE.FRAISAGE_Vc:
				_value = toolfinderCutParams.FRAISAGE_Vc;
				_locked = toolfinderCutParams.FRAISAGE_Vc_Locked;
				break;
			case CUT_PARAM_TYPE.FRAISAGE_n:
				_value = toolfinderCutParams.FRAISAGE_n;
				_locked = toolfinderCutParams.FRAISAGE_n_Locked;
				break;
			case CUT_PARAM_TYPE.FRAISAGE_d1:
				_value = toolfinderCutParams.FRAISAGE_d1;
				_locked = toolfinderCutParams.FRAISAGE_d1_Locked;
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Z:
				_value = toolfinderCutParams.FRAISAGE_Z;
				_locked = toolfinderCutParams.FRAISAGE_Z_Locked;
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Fz:
				_value = toolfinderCutParams.FRAISAGE_Fz;
				_locked = toolfinderCutParams.FRAISAGE_Fz_Locked;
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Vf:
				_value = toolfinderCutParams.FRAISAGE_Vf;
				_locked = toolfinderCutParams.FRAISAGE_Vf_Locked;
				break;
			case CUT_PARAM_TYPE.FRAISAGE_ap:
				_value = toolfinderCutParams.FRAISAGE_ap;
				_locked = toolfinderCutParams.FRAISAGE_ap_Locked;
				break;
			case CUT_PARAM_TYPE.FRAISAGE_ae:
				_value = toolfinderCutParams.FRAISAGE_ae;
				_locked = toolfinderCutParams.FRAISAGE_ae_Locked;
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Q:
				_value = toolfinderCutParams.FRAISAGE_Q;
				_locked = toolfinderCutParams.FRAISAGE_Q_Locked;
				break;
			case CUT_PARAM_TYPE.FRAISAGE_hm:
				_value = toolfinderCutParams.FRAISAGE_hm;
				_locked = toolfinderCutParams.FRAISAGE_hm_Locked;
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Emax:
				_value = toolfinderCutParams.FRAISAGE_Emax;
				_locked = toolfinderCutParams.FRAISAGE_Emax_Locked;
				break;
			case CUT_PARAM_TYPE.PERCAGE_Vc:
				_value = toolfinderCutParams.PERCAGE_Vc;
				_locked = toolfinderCutParams.PERCAGE_Vc_Locked;
				break;
			case CUT_PARAM_TYPE.PERCAGE_n:
				_value = toolfinderCutParams.PERCAGE_n;
				_locked = toolfinderCutParams.PERCAGE_n_Locked;
				break;
			case CUT_PARAM_TYPE.PERCAGE_d1:
				_value = toolfinderCutParams.PERCAGE_d1;
				_locked = toolfinderCutParams.PERCAGE_d1_Locked;
				break;
			case CUT_PARAM_TYPE.PERCAGE_Z:
				_value = toolfinderCutParams.PERCAGE_Z;
				_locked = toolfinderCutParams.PERCAGE_Z_Locked;
				break;
			case CUT_PARAM_TYPE.PERCAGE_Q:
				_value = toolfinderCutParams.PERCAGE_Q;
				_locked = toolfinderCutParams.PERCAGE_Q_Locked;
				break;
			case CUT_PARAM_TYPE.PERCAGE_F:
				_value = toolfinderCutParams.PERCAGE_F;
				_locked = toolfinderCutParams.PERCAGE_F_Locked;
				break;
			case CUT_PARAM_TYPE.PERCAGE_Vf:
				_value = toolfinderCutParams.PERCAGE_Vf;
				_locked = toolfinderCutParams.PERCAGE_Vf_Locked;
				break;
		}
		return _value;
	};

	getKeyFromCutParam(cutParamType: CUT_PARAM_TYPE) {
		switch (cutParamType) {
			case CUT_PARAM_TYPE.FRAISAGE_Vc:
				return "FRAISAGE_Vc";
				break;
			case CUT_PARAM_TYPE.FRAISAGE_n:
				return "FRAISAGE_n";
				break;
			case CUT_PARAM_TYPE.FRAISAGE_d1:
				return "FRAISAGE_d1";
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Z:
				return "FRAISAGE_Z";
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Fz:
				return "FRAISAGE_Fz";
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Vf:
				return "FRAISAGE_Vf";
				break;
			case CUT_PARAM_TYPE.FRAISAGE_ap:
				return "FRAISAGE_ap";
				break;
			case CUT_PARAM_TYPE.FRAISAGE_ae:
				return "FRAISAGE_ae";
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Q:
				return "FRAISAGE_Q";
				break;
			case CUT_PARAM_TYPE.FRAISAGE_hm:
				return "FRAISAGE_hm";
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Emax:
				return "FRAISAGE_Emax";
				break;
			case CUT_PARAM_TYPE.PERCAGE_Vc:
				return "PERCAGE_Vc";
				break;
			case CUT_PARAM_TYPE.PERCAGE_n:
				return "PERCAGE_n";
				break;
			case CUT_PARAM_TYPE.PERCAGE_d1:
				return "PERCAGE_d1";
				break;
			case CUT_PARAM_TYPE.PERCAGE_Z:
				return "PERCAGE_Z";
				break;
			case CUT_PARAM_TYPE.PERCAGE_Q:
				return "PERCAGE_Q";
				break;
			case CUT_PARAM_TYPE.PERCAGE_F:
				return "PERCAGE_F";
				break;
			case CUT_PARAM_TYPE.PERCAGE_Vf:
				return "PERCAGE_Vf";
				break;
		}
	}

	exportToPdf = async () => {
		var fields: Map<CUT_PARAM_TYPE, CutParam> = FRAISAGE_FIELDS;

		if (this.state.toolfinderSelection.machining?.usiGrpId == 100000) {
			fields = FRAISAGE_FIELDS;
		} else if (this.state.toolfinderSelection.machining?.usiGrpId == 100001) {
			fields = PERCAGE_FIELDS;
		} else {
			fields = FRAISAGE_FIELDS;
		}

		var linesNumberTopColumn1: number = Math.ceil(fields.size / 2);
		var linesNumberTopColumn2: number = fields.size - linesNumberTopColumn1;
		var topLeftColumn: Array<any> = [];
		var topRightColumn: Array<any> = [];
		var index = 0;
		fields.forEach((value: CutParam, key: CUT_PARAM_TYPE) => {
			if (index < linesNumberTopColumn1) {
				topLeftColumn.push(
					<View style={pdfExportStyles.tr}>
						<Text style={pdfExportStyles.tdPrefix}>{value.title.replaceAll("₁", "1")}</Text>
						<Text style={pdfExportStyles.tdValue}>
							{this.state.toolfinderCutParams[this.getKeyFromCutParam(key)] != undefined
								? this.state.toolfinderCutParams[this.getKeyFromCutParam(key)].toFixed(value.decimalLength ?? 0)
								: "n/a"}
						</Text>
						<Text style={pdfExportStyles.tdSuffix}>{value.suffix}</Text>
					</View>
				);
			} else if (index >= linesNumberTopColumn2) {
				topRightColumn.push(
					<View style={pdfExportStyles.tr}>
						<Text style={pdfExportStyles.tdPrefix}>{value.title.replaceAll("₁", "1")}</Text>
						<Text style={pdfExportStyles.tdValue}>
							{this.state.toolfinderCutParams[this.getKeyFromCutParam(key)] != undefined
								? this.state.toolfinderCutParams[this.getKeyFromCutParam(key)].toFixed(value.decimalLength ?? 0)
								: "n/a"}
						</Text>
						<Text style={pdfExportStyles.tdSuffix}>{value.suffix}</Text>
					</View>
				);
			}
			index++;
		});

		var blob: Blob = await pdf(
			<Document>
				<Page size="A4" style={pdfExportStyles.page}>
					<Text style={pdfExportStyles.title}>{this.props.t("screen.dashboard.export_functions.title")}</Text>

					<View style={pdfExportStyles.mainContainer}>
						<View style={pdfExportStyles.toolfinderSelectionContainer}>
							<Text style={{ fontWeight: 600, fontSize: 18, marginBottom: 10 }}>{this.props.t("screen.dashboard.selections_title")}</Text>

							<View style={pdfExportStyles.row}>
								<div style={pdfExportStyles.toolfinderSelectionItem}>
									<ReactPDF.Image src={matiereImage} style={{ height: 35 }} />

									<Text style={pdfExportStyles.toolfinderSelectionItemTitle}>{this.props.t("screen.dashboard.selection_param_material")}</Text>
									<Text>{this.state.toolfinderSelection.material == null ? "-" : this.state.toolfinderSelection.material.code} </Text>
								</div>

								<div style={pdfExportStyles.toolfinderSelectionItem}>
									<ReactPDF.Image src={usinageImage} style={{ height: 35 }} />
									<Text style={pdfExportStyles.toolfinderSelectionItemTitle}>{this.props.t("screen.dashboard.selection_param_machining")}</Text>
									<Text>
										{this.state.toolfinderSelection.machining == null
											? "-"
											: this.state.toolfinderSelection.machining.getLongDescription(this.props.i18n.language) ?? ""}
									</Text>
								</div>

								<div style={pdfExportStyles.toolfinderSelectionItem}>
									<ReactPDF.Image src={refOutilsImage} style={{ height: 35 }} />
									<Text style={pdfExportStyles.toolfinderSelectionItemTitle}>{this.props.t("screen.dashboard.selection_param_tool")}</Text>
									<Text>{this.state.toolfinderSelection.article == null ? "-" : this.state.toolfinderSelection.article.code}</Text>
								</div>
							</View>

							<View style={pdfExportStyles.row}>
								<div style={pdfExportStyles.toolfinderSelectionItem}>
									<ReactPDF.Image src={revetementImage} style={{ height: 35}} />
									<Text style={pdfExportStyles.toolfinderSelectionItemTitle}>{this.props.t("screen.dashboard.selection_param_coating")}</Text>
									<Text>
										{this.state.toolfinderSelection.coating == null
											? "-"
											: this.state.toolfinderSelection.coating.getPublicName(this.props.i18n.language) +
											  " (" +
											  this.state.toolfinderSelection.coating.code +
											  ")"}
									</Text>
								</div>

								<div style={pdfExportStyles.toolfinderSelectionItem}>
									<ReactPDF.Image src={machineImage} style={{ height: 35 }} />
									<Text style={pdfExportStyles.toolfinderSelectionItemTitle}>{this.props.t("screen.dashboard.selection_param_machine")}</Text>
									<Text>
										{this.state.toolfinderSelection.machine == null
											? "-"
											: this.state.toolfinderSelection.machine.getShortDescription(this.props.i18n.language)}
									</Text>
								</div>

								<div style={pdfExportStyles.toolfinderSelectionFakeItem}></div>
							</View>
						</View>
						<View style={pdfExportStyles.cutParamsContainer}>
							<Text style={{ fontWeight: 600, fontSize: 18, marginBottom: 10 }}>{this.props.t("screen.dashboard.cut_params_title")}</Text>

							<View style={pdfExportStyles.row}>
								<View style={pdfExportStyles.table}>{topLeftColumn}</View>
								<View style={{ width: 30 }}></View>
								<View style={pdfExportStyles.table}>{topRightColumn}</View>
							</View>
						</View>
					</View>

					<Text style={{ marginBottom: 5, marginTop: 15 }}>
						{this.props.t("screen.dashboard.export_functions.pdf.export_at")}{" "}
						{moment().format("DD.MM.YYYY") + " " + this.props.t("screen.dashboard.export_functions.pdf.at") + " " + moment().format("H[h]mm")}
					</Text>
					<ReactPDF.Link style={{ color: "#099ba5" }} src="https://www.toolfinder.louisbelet.ch/">
						www.toolfinder.louisbelet.ch
					</ReactPDF.Link>

					<ReactPDF.Image src={logoLBImage} style={{ width: 100, marginTop: 40 }} />
				</Page>
			</Document>
		).toBlob();

		saveAs(blob, "export.pdf");

		toast.success("Données exportées avec succès", { position: "top-right" });
		this.props.closeModal();
	};

	exportToImage = () => {
		exportComponentAsPNG(this.componentRef, { fileName: "export.png" });
		toast.success("Données exportées avec succès", { position: "top-right" });

		this.props.closeModal();
	};

	exportToEmail = () => {
		var subject: String = "Exportation des données du Toolfinder";
		var body: String = this.buildText();
		body = body.replaceAll("\n", "%0D%0A");
		window.open("mailto:?subject=" + subject + "&body=" + body, "_blank");

		toast.success("Données exportées avec succès", { position: "top-right" });

		this.props.closeModal();
	};

	exportToClipboard = () => {
		var subject: String = "Exportation des données du Toolfinder";
		var body: String = this.buildText();

		navigator.clipboard.writeText(subject + "\n\n" + body);
		toast.success("Copié dans le presse papier", { position: "top-right" });

		this.props.closeModal();
	};

	buildText(): string {
		var body: string = "";
		body += this.props.t("screen.dashboard.export_functions.text.selection") + "\n\n";
		body +=
			this.props.t("screen.dashboard.selection_param_material") +
			" : " +
			(this.state.toolfinderSelection.material == null ? "-" : this.state.toolfinderSelection.material.code);
		body += "\n";
		body +=
			this.props.t("screen.dashboard.selection_param_machining") +
			" : " +
			(this.state.toolfinderSelection.machining == null
				? "-"
				: this.state.toolfinderSelection.machining.getLongDescription(this.props.i18n.language) ?? "");
		body += "\n";

		body +=
			this.props.t("screen.dashboard.selection_param_tool") +
			" : " +
			(this.state.toolfinderSelection.article == null ? "-" : this.state.toolfinderSelection.article.code);
		body += "\n";
		body +=
			this.props.t("screen.dashboard.selection_param_coating") +
			" : " +
			(this.state.toolfinderSelection.coating == null
				? "-"
				: this.state.toolfinderSelection.coating.getPublicName(this.props.i18n.language) + " (" + this.state.toolfinderSelection.coating.code + ")");
		body += "\n";

		body +=
			this.props.t("screen.dashboard.selection_param_machine") +
			" : " +
			(this.state.toolfinderSelection.machine == null ? "-" : this.state.toolfinderSelection.machine.getShortDescription(this.props.i18n.language));
		body += "\n";
		body += "\n";

		body += this.props.t("screen.dashboard.export_functions.text.cut_params") + "\n";

    var fields: Map<CUT_PARAM_TYPE, CutParam> = FRAISAGE_FIELDS;

		if (this.state.toolfinderSelection.machining?.usiGrpId == 100000) {
			fields = FRAISAGE_FIELDS;
		} else if (this.state.toolfinderSelection.machining?.usiGrpId == 100001) {
			fields = PERCAGE_FIELDS;
		} else {
			fields = FRAISAGE_FIELDS;
		}

		fields.forEach((value, key) => {
			body =
				body +
				"\n" +
				value.title +
				" : " +
				(this.getValue(key) != null ? this.getValue(key).toFixed(value.decimalLength) + " " + value.suffix : "n/a");
		});

		return body;
	}

	renderImageExport() {
		return (
			<div style={{ opacity: 0, position: "absolute", left: "-100%" }}>
				<div ref={this.componentRef} style={imageExportStyles.page}>
					<Text style={imageExportStyles.title}>{this.props.t("screen.dashboard.export_functions.title")}</Text>

					<View style={imageExportStyles.cutParamsContainer}>
						<Text>{this.props.t("screen.dashboard.cut_params_title")}</Text>
						<Text>
							Vc: {this.state.toolfinderCutParams.FRAISAGE_Vc}{" "}
							{this.state.toolfinderCutParams.FRAISAGE_Vc != null ? FRAISAGE_FIELDS.get(CUT_PARAM_TYPE.FRAISAGE_Vc)?.suffix : ""}
						</Text>
						<Text>
							n: {this.state.toolfinderCutParams.FRAISAGE_n}{" "}
							{this.state.toolfinderCutParams.FRAISAGE_n != null ? FRAISAGE_FIELDS.get(CUT_PARAM_TYPE.FRAISAGE_n)?.suffix : ""}
						</Text>
						<Text>
							D: {this.state.toolfinderCutParams.FRAISAGE_d1}{" "}
							{this.state.toolfinderCutParams.FRAISAGE_d1 != null ? FRAISAGE_FIELDS.get(CUT_PARAM_TYPE.FRAISAGE_d1)?.suffix : ""}
						</Text>
						<Text>
							Z: {this.state.toolfinderCutParams.FRAISAGE_Z}{" "}
							{this.state.toolfinderCutParams.FRAISAGE_Z != null ? FRAISAGE_FIELDS.get(CUT_PARAM_TYPE.FRAISAGE_Z)?.suffix : ""}
						</Text>
						<Text>
							Fz: 1.0 {this.state.toolfinderCutParams.FRAISAGE_Fz}{" "}
							{this.state.toolfinderCutParams.FRAISAGE_Fz != null ? FRAISAGE_FIELDS.get(CUT_PARAM_TYPE.FRAISAGE_Fz)?.suffix : ""}
						</Text>
						<Text>
							Vf: {this.state.toolfinderCutParams.FRAISAGE_Vf}{" "}
							{this.state.toolfinderCutParams.FRAISAGE_Vf != null ? FRAISAGE_FIELDS.get(CUT_PARAM_TYPE.FRAISAGE_Vf)?.suffix : ""}
						</Text>
						<Text>
							ap: {this.state.toolfinderCutParams.FRAISAGE_ap}{" "}
							{this.state.toolfinderCutParams.FRAISAGE_ap != null ? FRAISAGE_FIELDS.get(CUT_PARAM_TYPE.FRAISAGE_ap)?.suffix : ""}
						</Text>
						<Text>
							ae: {this.state.toolfinderCutParams.FRAISAGE_ae}{" "}
							{this.state.toolfinderCutParams.FRAISAGE_ae != null ? FRAISAGE_FIELDS.get(CUT_PARAM_TYPE.FRAISAGE_ae)?.suffix : ""}
						</Text>
						<Text>
							Q: {this.state.toolfinderCutParams.FRAISAGE_Q}{" "}
							{this.state.toolfinderCutParams.FRAISAGE_Q != null ? FRAISAGE_FIELDS.get(CUT_PARAM_TYPE.FRAISAGE_Q)?.suffix : ""}
						</Text>
						{/* <Text>
							Rm: {this.state.toolfinderCutParams.Rm}{" "}
							{this.state.toolfinderCutParams.Rm != null ? FRAISAGE_FIELDS.get(CUT_PARAM_TYPE.Rm)?.suffix : ""}
						</Text> */}
						<Text>
							hm: {this.state.toolfinderCutParams.FRAISAGE_hm}{" "}
							{this.state.toolfinderCutParams.FRAISAGE_hm != null ? FRAISAGE_FIELDS.get(CUT_PARAM_TYPE.FRAISAGE_hm)?.suffix : ""}
						</Text>
						<Text>
							Emax: {this.state.toolfinderCutParams.FRAISAGE_Emax}{" "}
							{this.state.toolfinderCutParams.FRAISAGE_Emax != null ? FRAISAGE_FIELDS.get(CUT_PARAM_TYPE.FRAISAGE_Emax)?.suffix : ""}
						</Text>
					</View>
				</div>
			</div>
		);
	}

	render() {
		return (
			<Modal
				id="export_modal"
				isOpen={this.state.modalOpened}
				// onAfterOpen={afterOpenModal}
				onRequestClose={() => this.props.closeModal()}
				style={{
					content: {
						top: "50%",
						left: "50%",
						right: "auto",
						bottom: "auto",
						marginRight: "-50%",
						transform: "translate(-50%, -50%)",
						width: 400,
						padding: 35,
						borderRadius: 10,
					},
					overlay: {
						backgroundColor: "rgba(0,0,0,0.7)",
					},
				}}
				contentLabel={this.props.t("screen.dashboard.export_button")}
			>
				{/* {this.renderImageExport()} */}
				<div className="d-flex flex-row mb-3">
					<span className="icon icon-export me-3 text-primary"></span>
					<span className="d-inline title">{this.props.t("screen.dashboard.export_button")}</span>
				</div>

				<div className="">{this.props.t("screen.dashboard.export_functions.message")}</div>

				<div className="export-buttons">
					<button className="export-button" onClick={this.exportToPdf}>
						<div className="icon-container">
							<span className="icon icon-pdf"></span>
						</div>

						<span className="featureExport">{this.props.t("screen.dashboard.export_functions.pdfExport")}</span>
					</button>

					{/* <button className="export-button" onClick={this.exportToImage}>
						<div className="icon-container">
							<span className="icon icon-image-gallery"></span>
						</div>

						<span className="featureExport">Exporter en fichier image</span>
					</button> */}

					<button className="export-button" onClick={this.exportToEmail}>
						<div className="icon-container">
							<span className="icon icon-envelope"></span>
						</div>

						<span className="featureExport">{this.props.t("screen.dashboard.export_functions.emailExport")}</span>
					</button>

					<button className="export-button" onClick={this.exportToClipboard}>
						<div className="icon-container">
							<span className="icon icon-export"></span>
						</div>

						<span className="featureExport">{this.props.t("screen.dashboard.export_functions.clipBoardExport")}</span>
					</button>
				</div>

				<button className="btn btn-dark text-white export-button mt-4 px-4" onClick={(event) => this.props.closeModal()}>
					<span className="icon icon-arrow-left2 me-3"></span>
					{this.props.t("general.cancel")}
				</button>
			</Modal>
		);
	}
}

export default withTranslation("common")(withRouter(ExportModal));
