import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import React from "react";
import "./step_machine.scss";
import { Subscription } from "rxjs";
import { MachinesLoaded, MachinesState } from "blocs/machines_bloc/machines_state";
import Machine from "models/machine";
import { withRouter, RouteComponentProps } from "react-router-dom";
import MachineItem from "./components/machine_item";
import BackButton from "components/back_button";
import { ToolfinderSetSelections } from "../blocs/toolfiinder_selections_bloc/toolfiinder_selections_event";
import ToolfinderSelection from "models/toolfinder_selection";
import { ToolfinderSelectionsState, ToolfinderSelectionsUpdated } from "../blocs/toolfiinder_selections_bloc/toolfiinder_selections_state";
import { WithTranslation, withTranslation } from "react-i18next";
import StepsResume from "../components/steps_resume";

interface Props extends RouteComponentProps<any>, WithTranslation {}

type State = {
	rawMachinesList: Array<Machine>;
	machines: Array<Machine>;
	selectedMachine: Machine;
	toolfinderSelection: ToolfinderSelection;
	searchQuery: string;
};

class StepMachineScreen extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;
	// history = useHistory();

	// static contextTypes = {
	//     contextType: BlocsContext,
	//     router: () => true, // replace with PropTypes.object if you use them
	//   }
	// declare context: React.ContextType<typeof BlocsContext>

	private machinesBlocSubscription: Subscription = Subscription.EMPTY;
	private toolfinderSelectionBlocSubscription: Subscription = Subscription.EMPTY;

	constructor(props: Props) {
		super(props);
		this.state = {
			rawMachinesList: [],
			machines: [],
			selectedMachine: null as any,
			toolfinderSelection: null as any,
			searchQuery: "",
		};
	}

	componentDidMount(): void {
		this.machinesBlocSubscription = this.context.machinesBloc.listen(this.machinesBlocAction);
		this.machinesBlocAction(this.context.machinesBloc.state);

		this.toolfinderSelectionBlocSubscription = this.context.toolfinderSelectionBloc.listen(this.toolfinderSelectionBlocAction);
		this.toolfinderSelectionBlocAction(this.context.toolfinderSelectionBloc.state);
	}

	componentWillUnmount(): void {
		this.machinesBlocSubscription.unsubscribe();
		this.toolfinderSelectionBlocSubscription.unsubscribe();
	}

	machinesBlocAction = (state: MachinesState) => {
		if (state instanceof MachinesLoaded) {
			this.setState({
				rawMachinesList: state.machines,
				machines: this.sortAndFilterMachines(state.machines),
			});
		}
	};

	sortAndFilterMachines(_rawMachinesList: Array<Machine>): Array<Machine> {
		var _machines: Array<Machine> = _rawMachinesList;
		// Filters
		if (this.state.searchQuery && this.state.searchQuery !== "") {
			_machines = _machines.filter((machine: Machine) => {
				return (
					machine.code.toLowerCase().search(this.state.searchQuery.toLowerCase()) !== -1 ||
					machine.getShortDescription(this.props.i18n.language).toLowerCase().search(this.state.searchQuery.toLowerCase()) !== -1
				);
			});
		}

		return _machines;
	}

	toolfinderSelectionBlocAction = (state: ToolfinderSelectionsState) => {
		if (state instanceof ToolfinderSelectionsUpdated) {
			this.setState({
				toolfinderSelection: state.toolfinderSelection,
				selectedMachine: state.toolfinderSelection.machine ? state.toolfinderSelection.machine : this.state.selectedMachine,
			});
		}
	};

	renderActionButtons() {
		return (
			<div>
				<button
					className="btn btn-dark custom-btn text-white mx-2"
					onClick={(e) => {
						this.props.history.push("/dashboard");
					}}
				>
					<span className="icon icon-arrow-left2 icon-left"></span>
					{this.props.t("screen.step_machine.cancel_back_button")}
				</button>
				<button
					className="btn btn-primary text-white custom-btn mx-2"
					onClick={(e) => {
						if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated) {
							this.context.toolfinderSelectionBloc.add(
								new ToolfinderSetSelections(this.state.toolfinderSelection.copyWith({ machine: this.state.selectedMachine }))
							);
						}
						this.props.history.push("/dashboard");
					}}
				>
					<span className="icon icon-save icon-left"></span>
					{this.props.t("screen.step_machine.save_button")}
				</button>
			</div>
		);
	}

	setQueryString(query: string) {
		this.setState(
			{
				searchQuery: query,
				// machines: this.sortAndFilterMachines(this.state.rawMachinesList)
			},
			() => {
				this.setState({
					// searchQuery: event.currentTarget.value,
					machines: this.sortAndFilterMachines(this.state.rawMachinesList),
				});
			}
		);
	}

	renderSortsAndFilters() {
		return (
			<div className="container">
				<div className="sort-and-filter d-flex py-3">
					<div className="me-5">
						<div className="filterTitle">{this.props.t("general.search")}</div>
						<div className="d-flex">
							<div className="custom-search-field">
								<span className="icon icon-search"></span>
								<input
									type="text"
									placeholder={this.props.t("screen.step_machine.search")}
									value={this.state.searchQuery}
									onChange={(event) => this.setQueryString(event.currentTarget.value)}
								/>
							</div>
							<button
								onClick={(event) => this.setQueryString("")}
								type="button"
								className="btnResetSearch btn btn-primary text-white px-4"
							>
								{this.props.t("general.reset")}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<BaseScreen actionButtons={this.renderActionButtons()}>
				<div className="w-100 step-machine d-flex flex-column align-items-center">
					<StepsResume />
					<div className="border-bottom mb-4 mt-2 w-100 mx-auto">
						{/* <div className="container">
							<BackButton onClick={this.props.history.goBack} text={this.props.t("general.back_to_dashboard")} />
						</div> */}
						{this.renderSortsAndFilters()}
					</div>
					<div className="w-100 mx-auto">
						<div className="container align-items-center mx-auto">
							<div className="machines-found">
								{this.state.machines.length} {this.props.t("screen.step_machine.machines_found")}
							</div>
							<div className="d-flex flex-wrap">
								{this.state.machines.map((machine: Machine) => {
									return (
										<MachineItem
											key={machine.id}
											machine={machine}
											selected={this.state.selectedMachine && this.state.selectedMachine.id === machine.id}
											onClick={() => {
												this.setState({ selectedMachine: machine });
												if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated) {
													this.context.toolfinderSelectionBloc.add(
														new ToolfinderSetSelections(this.state.toolfinderSelection.copyWith({ machine: machine }))
													);
												}
												this.props.history.push("/dashboard");
											}}
										/>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</BaseScreen>
		);
	}
}

export default withTranslation("common")(withRouter(StepMachineScreen));
