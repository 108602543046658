import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Message from 'models/message';
import { WithTranslation, useTranslation } from 'react-i18next';
import GeneralHelper from 'helpers/general_helper';
import NewsModal from 'screens/news_modal/news_modal';
import clip from "text-clipper";

// Props pour le composant NewsCarousel
interface NewsCarouselProps /*extends WithTranslation*/ {
  news: Message[];
}

const NewsCarousel: React.FC<NewsCarouselProps> = ({ news }) => {

  const { i18n } = useTranslation("common");
  const [newsModaleOpened, setNewsModaleOpened] = React.useState(false);
  const [newsModalMessage, setNewsModalMessage] = React.useState<Message | null>(null);



  const newsItems = news.map((item, index) => {
    // const text = GeneralHelper.removeHtml(item.getText(i18n.language));
    const text = item.getText(i18n.language);
    const truncateLength = 200;
    // const truncatedText = isTruncated ? text.substring(0, truncateLength) + "..." : text;

    const truncatedText = clip(text, truncateLength, { html: true});
    const isTruncated = text.length > truncatedText.length;

    return (
      <div key={index} className="d-flex flex-column align-items-center">

        <div className="news_date">{item.displayFrom.format("DD.MM.YYYY")}</div>
        <div className="news_text" dangerouslySetInnerHTML={{__html: truncatedText }}></div>
        {/* <div className="news_text">
          {truncatedText}
        </div> */}
        {isTruncated && <a href="#" className="news_readmore mt-2" onClick={() => {
          setNewsModalMessage(item);
          setNewsModaleOpened(true);
        }}>Lire la suite</a>}

      </div>
    );
  });

  const settings = {
    dots: true, // Montre les pastilles en bas
    infinite: newsItems.length > 1 ? true : false,
    speed: 500,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  return (
    <>
      <Slider {...settings} >
        {newsItems}
      </Slider>

      <NewsModal modalOpened={newsModaleOpened} closeModal={() => { setNewsModaleOpened(false); setNewsModalMessage(null); }} message={newsModalMessage} />
    </>
  );
};

export default NewsCarousel;
