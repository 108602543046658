import { Bloc, Transition } from "@felangel/bloc";
import Article from "../../models/article";
import CartService from "../../services/cart";
import { CartEvent, CartInit, CartAddArticle, CartRemoveArticle, CartClear, CartCheckArticles, CartEditArticleQuantity, CartUpdateAll } from "./cart_event";
import { CartState, CartUpdated } from "./cart_state";
import Cart from "../../models/cart";
import CartArticle from "models/cart_article";
import AuthenticationBloc from "blocs/authentication_bloc/authentication_bloc";
import { AuthenticationInitial, AuthenticationAuthenticated, AuthenticationState, AuthenticationUnAuthenticated } from "blocs/authentication_bloc/authentication_state";
import { FavoritesToolsInit } from "blocs/favorites_tools_bloc/favorites_tools_event";
import ArticlesBloc from "blocs/articles_bloc/articles_bloc";
import { ArticlesInitial, ArticlesLoaded, ArticlesState } from "blocs/articles_bloc/articles_state";

const initialCart = new Cart({
  articles: [],
});

export default class CartBloc extends Bloc<CartEvent, CartState> {
  cart: Cart = initialCart;
  authenticationBloc: AuthenticationBloc;
  authenticationBlocState: AuthenticationState;

  articlesBloc: ArticlesBloc;
  articlesBlocState: ArticlesState;

  constructor(authenticationBloc: AuthenticationBloc, articlesBloc: ArticlesBloc) {
    super(new CartUpdated(initialCart));
    this.authenticationBloc = authenticationBloc;
    this.authenticationBlocState = authenticationBloc.state;
    this.articlesBloc = articlesBloc;
    this.articlesBlocState = articlesBloc.state;

    this.authenticationBloc.listen((state: AuthenticationState) => {
      if (state instanceof AuthenticationAuthenticated || state instanceof AuthenticationUnAuthenticated) {
        this.authenticationBlocState = state;
        this.add(new CartInit());
      }
    });

    this.articlesBloc.listen((state: ArticlesState) => {
      if (state instanceof ArticlesLoaded) {
        this.articlesBlocState = state;
        this.add(new CartInit());
      }
    });
  }

  async *mapEventToState(event: CartEvent) {
    if (event instanceof CartInit) {
      yield* this.mapCartInitToState(event);
    } else if (event instanceof CartAddArticle) {
      yield* this.mapCartAddArticleToState(event);
    } else if (event instanceof CartEditArticleQuantity) {
      yield* this.mapCartEditArticleQuantityToState(event);
    } else if (event instanceof CartRemoveArticle) {
      yield* this.mapCartRemoveArticletToState(event);
    } else if (event instanceof CartClear) {
      yield* this.mapCartClearToState(event);
    } else if (event instanceof CartCheckArticles) {
      yield* this.mapCartCheckArticlesToState(event);
    } else if (event instanceof CartUpdateAll) {
      yield* this.mapCartUpdateAllToState(event);
    }
  }

  async *mapCartInitToState(event: CartInit) {
    if (this.authenticationBlocState instanceof AuthenticationInitial || this.articlesBlocState instanceof ArticlesInitial) {
      return;
    }

    if (this.authenticationBlocState instanceof AuthenticationAuthenticated) {
      // Sync local favorites with skylight
      var skylightArticles: Array<CartArticle> = await this.getDataFromSkylight();
      var allArticles: Array<CartArticle> = skylightArticles;

      for (var i = 0; i < this.cart.articles.length; i++) {
        // this.cart.articles.forEach(async (cartArticle: CartArticle) => {
        var cartArticle: CartArticle = this.cart.articles[i];
        var presentSkylightCartArticle: CartArticle | undefined = skylightArticles.find((slArticle: CartArticle) => slArticle.article.id == cartArticle.article.id && slArticle.rev == cartArticle.rev);
        if (presentSkylightCartArticle == undefined) {
          await CartService.addProductToSkylightCart(this.authenticationBloc.authenticationData.accessToken, cartArticle.article.id, cartArticle.qty, cartArticle.rev);
          allArticles.push(cartArticle);
        }
        // });
      }

      // if (this.cart.articles.length == 0) {
      //   this.cart.articles = skylightArticles;
      // } else {
      //   this.cart.articles = await this.getDataFromSkylight();
      // }

      this.cart.articles = allArticles;

      await CartService.storageSaveCart(this.cart);
    } else if (this.authenticationBlocState instanceof AuthenticationUnAuthenticated) {
      // Get fav from storage and inject into this bloc
      this.cart = await CartService.storageGetLocalCart(true);
    }
    await CartService.storageSaveCart(this.cart);
    yield new CartUpdated(this.cart);
  }

  async *mapCartAddArticleToState(event: CartAddArticle) {
    this.cart = await CartService.storageGetLocalCart(true);

    if (!this.cart.articles.some((cartArticle: CartArticle) => event.article.id == cartArticle.article.id && cartArticle.rev == event.rev)) {
      this.cart.articles.push(
        new CartArticle({
          article: event.article,
          qty: event.qty,
          rev: event.rev,
        })
      );
      await CartService.storageSaveCart(this.cart);
    } else {
      this.cart.articles = this.cart.articles.map((cartArticle: CartArticle) => {
        if (cartArticle.article.id == event.article.id && cartArticle.rev == event.rev) {
          cartArticle.qty += 1;
        }
        return cartArticle;
      });
    }

    if (this.authenticationBlocState instanceof AuthenticationAuthenticated) {
      await CartService.addProductToSkylightCart(this.authenticationBloc.authenticationData.accessToken, event.article.id, event.qty, event.rev);
    }
    yield new CartUpdated(this.cart);
  }

  async *mapCartEditArticleQuantityToState(event: CartEditArticleQuantity) {
    this.cart.articles = this.cart.articles
      .map((cartArticle: CartArticle) => {
        if (cartArticle.qty == 0) {
          return undefined;
        }
        if (cartArticle.article.id == event.article.id && cartArticle.rev == event.rev) {
          cartArticle.qty = event.qty;
        }
        return cartArticle;
      })
      .filter((cartArticle: CartArticle | undefined) => cartArticle != undefined) as Array<CartArticle>;

    if (this.authenticationBlocState instanceof AuthenticationAuthenticated) {
      await CartService.removeProductToSkylightCart(this.authenticationBloc.authenticationData.accessToken, event.article.id, event.rev);
      // .then(() => {

      // });

      if (event.qty != 0) {
        await CartService.addProductToSkylightCart(this.authenticationBloc.authenticationData.accessToken, event.article.id, event.qty, event.rev);
      }
    }

    await CartService.storageSaveCart(this.cart);
    yield new CartUpdated(this.cart);
  }

  async *mapCartRemoveArticletToState(event: CartRemoveArticle) {
    this.cart = await CartService.storageGetLocalCart(true);
    this.cart.articles = this.cart.articles.filter((cartArticle: CartArticle) => {
      var isDeletedItem = cartArticle.article.id == event.article.id && cartArticle.rev == event.rev;
      return !isDeletedItem;
    });
    if (this.authenticationBlocState instanceof AuthenticationAuthenticated) {
      await CartService.removeProductToSkylightCart(this.authenticationBloc.authenticationData.accessToken, event.article.id, event.rev);
    }

    await CartService.storageSaveCart(this.cart);
    yield new CartUpdated(this.cart);
  }

  async *mapCartClearToState(event: CartClear) {
    this.cart = initialCart;
    CartService.storageSaveCart(this.cart);
    yield new CartUpdated(this.cart);
  }

  async *mapCartCheckArticlesToState(event: CartCheckArticles) {
    this.cart = await CartService.storageGetLocalCart(true);
    yield new CartUpdated(this.cart);
  }

  async *mapCartUpdateAllToState(event: CartUpdateAll) {
    var newCartArticles = event.articles;

    // this.cart.articles = this.cart.articles.filter(
    // 	(cartArticle: CartArticle) => cartArticle.article.id != event.article.id
    // );

    if (this.authenticationBlocState instanceof AuthenticationAuthenticated) {
      newCartArticles.forEach(async (cartArticle: CartArticle) => {
        var presentSkylightCartArticle: CartArticle | undefined = this.cart.articles.find(
          (slArticle: CartArticle) => slArticle.article.id == cartArticle.article.id && slArticle.rev == cartArticle.rev
        );
        // nouvel article
        if (presentSkylightCartArticle == undefined) {
          await CartService.addProductToSkylightCart(this.authenticationBloc.authenticationData.accessToken, cartArticle.article.id, cartArticle.qty, cartArticle.rev);
        }
        if (presentSkylightCartArticle != undefined && presentSkylightCartArticle.qty != cartArticle.qty) {
          await CartService.removeProductToSkylightCart(this.authenticationBloc.authenticationData.accessToken, cartArticle.article.id, cartArticle.rev);

          if (cartArticle.qty >= 1) {
            await CartService.addProductToSkylightCart(this.authenticationBloc.authenticationData.accessToken, cartArticle.article.id, cartArticle.qty, cartArticle.rev);
          }
        }

        // if(cartArticle.qty )
      });
    }

    this.cart.articles = newCartArticles.filter((cartArticle: CartArticle) => cartArticle.qty != 0);
    await CartService.storageSaveCart(this.cart);
    yield new CartUpdated(this.cart);
  }

  // Called whenever an `event` is added.
  onEvent(event: CartEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
  }

  // Called whenever a state change is about to occur.
  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
  }

  async getDataFromSkylight() {
    var articlesFromSyklightResponse = await CartService.getSkylightCart(this.authenticationBloc.authenticationData.accessToken);
    var articles: Array<CartArticle> = [];
    if (articlesFromSyklightResponse.status == 200) {
      articles = articlesFromSyklightResponse.data
        .filter((data: any) => data["goodid"] != null)
        .map((data: any) => {
          var article: Article | undefined = (this.articlesBlocState as ArticlesLoaded).articles.find((article: Article) => article.id == data["goodid"]);
          if (article != undefined) {
            return new CartArticle({ article: article, qty: data["tcpad_qte"], rev: data["rev"] });
          }
          return undefined;
        })
        .filter((value: any) => value != undefined);
    }
    return articles;
  }
}
