import React, { Fragment } from "react";
import ReactDOM from "react-dom";
// import reportWebVitals from './reportWebVitals';
import Router from "./router";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, RouteComponentProps } from "react-router-dom";

// CSS
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.scss";


import { BlocsContext } from "contexts/blocs_context";
// import { MaterialsBlocContext } from 'contexts/materials_bloc_context';

import MaterialsBloc from "blocs/materials_bloc/materials_bloc";
import MaterialRepository from "repositories/material_repository";
import { MaterialsLoad } from "blocs/materials_bloc/materials_event";

// import ToolsBloc from "blocs/tools_bloc/tools_bloc";
// import ToolRepository from "repositories/tool_repository";
// import { ToolsLoad } from "blocs/tools_bloc/tools_event";

import CoatingsBloc from "blocs/coatings_bloc/coatings_bloc";
import CoatingRepository from "repositories/coating_repository";
import { CoatingsLoad } from "blocs/coatings_bloc/coatings_event";

import MachinesBloc from "blocs/machines_bloc/machines_bloc";
import MachineRepository from "repositories/machine_repository";
import { MachinesLoad } from "blocs/machines_bloc/machines_event";

import ReferencesBloc from "blocs/references_bloc/references_bloc";
import ArticlesBloc from "blocs/articles_bloc/articles_bloc";
import { ArticlesLoad } from "blocs/articles_bloc/articles_event";

import ToolfinderSelectionBloc from "screens/toolfinder/blocs/toolfiinder_selections_bloc/toolfiinder_selections_bloc";
import CutParamsBloc from "screens/toolfinder/blocs/cut_params_bloc/cut_params_bloc";
// import { ToolfinderSelection } from 'blocs/machines_bloc/machines_event';

import FavoritesMaterialsBloc from "blocs/favorites_materials_bloc/favorites_materials_bloc";
import FavoritesToolsBloc from "blocs/favorites_tools_bloc/favorites_tools_bloc";
import { I18nextProvider, WithTranslationProps } from "react-i18next";
import i18next from "i18next";
import translations_fr from "./translations/fr.json";
import translations_en from "./translations/en.json";
import translations_de from "./translations/de.json";
import translations_it from "./translations/it.json";
import { ReferencesLoad } from "blocs/references_bloc/references_event";
import ReferenceRepository from "repositories/reference_repository";
import ArticleRepository from "repositories/article_repository";

import StarterLoadingScreen from "screens/starter_loading/starter_loading";
import AssRefMatsBloc from "blocs/ass_ref_mat_bloc/ass_ref_mat_bloc";
import AssRefUsisBloc from "blocs/ass_ref_usi_bloc/ass_ref_usi_bloc";
import AssRevMatsBloc from "blocs/ass_rev_mat_bloc/ass_rev_mat_bloc";
import MachiningsBloc from "blocs/machining_bloc/machining_bloc";
import MachiningGroupsBloc from "blocs/machining_group_bloc/machining_group_bloc";
import MachiningGroupRepository from "repositories/machining_grp_repository";
import MachiningRepository from "repositories/machining_repository";
import AssRevMatRepository from "repositories/ass_rev_mat_repository";
import AssRefUsiRepository from "repositories/ass_ref_usi_repository";
import AssRefMatRepository from "repositories/ass_ref_mat_repository";
import AuthenticationBloc from "blocs/authentication_bloc/authentication_bloc";
import CartBloc from "blocs/cart_bloc/cart_bloc";
import LanguageDetector from 'i18next-browser-languagedetector';
import ArticlesCoatingsSelectionRepository from "repositories/articles_coatings_selection_repository";
import ArticlesCoatingsSelectionBloc from "blocs/articles_coatings_selection_bloc/articles_coatings_selection_bloc";

import MessagesBloc from "blocs/messages_bloc/messages_bloc";
import MessageRepository from "repositories/message_repository";
import { MessagesLoad } from "blocs/messages_bloc/messages_event";

// import 'bootstrap/dist/js/bootstrap.min.js';

const materialsBloc: MaterialsBloc = new MaterialsBloc(new MaterialRepository());
// materialsBloc.add(new MaterialsLoad());

// const toolsBloc: ToolsBloc = new ToolsBloc(new ToolRepository());
// toolsBloc.add(new ToolsLoad());

const coatingsBloc: CoatingsBloc = new CoatingsBloc(new CoatingRepository());
// coatingsBloc.add(new CoatingsLoad());

const machinesBloc: MachinesBloc = new MachinesBloc(new MachineRepository());
// machinesBloc.add(new MachinesLoad());

const referencesBloc: ReferencesBloc = new ReferencesBloc(new ReferenceRepository());
// referencesBloc.add(new ReferencesLoad());

const articlesBloc: ArticlesBloc = new ArticlesBloc(new ArticleRepository());
// articlesBloc.add(new ArticlesLoad());

const messagesBloc: MessagesBloc = new MessagesBloc(new MessageRepository());


const assRefMatBloc: AssRefMatsBloc = new AssRefMatsBloc(new AssRefMatRepository());
const assRefUsiBloc: AssRefUsisBloc = new AssRefUsisBloc(new AssRefUsiRepository());
const assRevMatBloc: AssRevMatsBloc = new AssRevMatsBloc(new AssRevMatRepository());
const machiningBloc: MachiningsBloc = new MachiningsBloc(new MachiningRepository());
const machiningGroupBloc: MachiningGroupsBloc = new MachiningGroupsBloc(new MachiningGroupRepository());

const toolfinderSelectionBloc: ToolfinderSelectionBloc = new ToolfinderSelectionBloc({
  materialsBloc: materialsBloc,
  referencesBloc: referencesBloc,
  articlesBloc: articlesBloc,
  // toolsBloc: toolsBloc,
  coatingsBloc: coatingsBloc,
  assRefMatBloc: assRefMatBloc,
  assRefUsiBloc: assRefUsiBloc,
  assRevMatBloc: assRevMatBloc,
  machiningBloc: machiningBloc,
  machiningGroupBloc: machiningGroupBloc,
  machinesBloc: machinesBloc,
});

const cutParamsBloc: CutParamsBloc = new CutParamsBloc({
  assRefMatBloc: assRefMatBloc,
  assRefUsiBloc: assRefUsiBloc,
  assRevMatBloc: assRevMatBloc,
});

const favoritesMaterialsBloc: FavoritesMaterialsBloc = new FavoritesMaterialsBloc();
const authenticationBloc: AuthenticationBloc = new AuthenticationBloc();
const favoritesToolsBloc: FavoritesToolsBloc = new FavoritesToolsBloc(authenticationBloc);
const cartBloc: CartBloc = new CartBloc(authenticationBloc, articlesBloc);
const articlesCoatingsSelectionBloc: ArticlesCoatingsSelectionBloc = new ArticlesCoatingsSelectionBloc(new ArticlesCoatingsSelectionRepository());

// i18next.init({
//   interpolation: { escapeValue: false },
//   lng: 'fr',
//   resources: {
//     fr: translations_fr,
//     en: translations_en,
//     de: translations_de,
//   },
// });

i18next
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    // lng: "fr",
    fallbackLng: "fr",
    resources: {
      fr: {
        common: translations_fr,
      },
      en: {
        common: translations_en,
      },
      de: {
        common: translations_de,
      },
      it: {
        common: translations_it,
      },
    },
    detection: {
      order: ['querystring', 'cookie', 'header'],
      lookupCookie: 'lang',
      lookupQuerystring: 'lang',
      caches: ['cookie'],
    },
  });

class App extends React.Component {
  // componentDidMount() {
  // 	const script = document.createElement("script");
  // 	script.async = true;
  // 	script.src = "https://some-scripturl.js";
  // 	this.div.appendChild(script);
  // }
  render() {
    return (
      <React.StrictMode>
        <Toaster
          containerStyle={{
            top: 120,
            right: 30,
            zIndex: 999999,
          }}
        />
        <I18nextProvider i18n={i18next}>
          <BlocsContext.Provider
            value={{
              materialsBloc: materialsBloc,
              // toolsBloc: toolsBloc,
              referencesBloc: referencesBloc,
              articlesBloc: articlesBloc,
              coatingsBloc: coatingsBloc,
              machinesBloc: machinesBloc,
              assRefMatBloc: assRefMatBloc,
              assRefUsiBloc: assRefUsiBloc,
              assRevMatBloc: assRevMatBloc,
              machiningBloc: machiningBloc,
              machiningGroupBloc: machiningGroupBloc,
              toolfinderSelectionBloc: toolfinderSelectionBloc,
              cutParamsBloc: cutParamsBloc,
              favoritesMaterialsBloc: favoritesMaterialsBloc,
              favoritesToolsBloc: favoritesToolsBloc,
              authenticationBloc: authenticationBloc,
              cartBloc: cartBloc,
              articlesCoatingsSelectionBloc: articlesCoatingsSelectionBloc,
              messagesBloc: messagesBloc,
            }}
          >
            <StarterLoadingScreen>
              <BrowserRouter basename="/">
                <Route
                  // onEnter={this.handlePageChange}
                  render={(props) => <Router />}
                />
              </BrowserRouter>
            </StarterLoadingScreen>
          </BlocsContext.Provider>
        </I18nextProvider>
      </React.StrictMode>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
