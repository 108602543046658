import { CUT_PARAM_TYPE } from "constants/constants";
import ToolfinderCutParams from "models/toolfinder_cut_params";

export abstract class CutParamsState {}

export class CutParamsInitial extends CutParamsState {}

export class CutParamsUpdated extends CutParamsState {
	toolfinderCutParams: ToolfinderCutParams;
	currentEditing?: CUT_PARAM_TYPE;

	constructor(toolfinderCutParams: ToolfinderCutParams, currentEditing?: CUT_PARAM_TYPE) {
		super();
		this.toolfinderCutParams = toolfinderCutParams;
    this.currentEditing = currentEditing;
	}

	public toString = (): string => {
		return `CutParamsUpdated`;
	};
}

export class CutParamsError extends CutParamsState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `CutParamsError { errorMessage: ${this.errorMessage} }`;
	};
}
