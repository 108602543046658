import { Bloc, Transition } from "@felangel/bloc";
import MachiningGroup from "models/machining_group";
import MachiningGroupRepository from "repositories/machining_grp_repository";
import { MachiningGroupsEvent, MachiningGroupsLoad } from "./machining_group_event";
import { MachiningGroupsState, MachiningGroupsLoaded, MachiningGroupsLoading, MachiningGroupsInitial, MachiningGroupsFailure } from "./machining_group_state";


export default class MachiningGroupsBloc extends Bloc<MachiningGroupsEvent, MachiningGroupsState> {
  machiningGroupRepository: MachiningGroupRepository;
  machiningGroups: Array<MachiningGroup> = [];

  constructor(machiningGroupRepository: MachiningGroupRepository) {
    super(new MachiningGroupsInitial());
    this.machiningGroupRepository = machiningGroupRepository;
  }

  async *mapEventToState(event: MachiningGroupsEvent) {
    if (event instanceof MachiningGroupsLoad) {
      yield* this.mapLoadMachiningGroupsToState(event);
    }
  }

  async *mapLoadMachiningGroupsToState(event: MachiningGroupsLoad) {
    try {
      yield new MachiningGroupsLoading();
      var machiningGroupsList: Array<MachiningGroup> = await this.machiningGroupRepository.getData();
      this.machiningGroups = machiningGroupsList;

      yield new MachiningGroupsLoaded(this.machiningGroups);
    } catch (e) {
      if (typeof e === "string") {
        yield new MachiningGroupsFailure(e);
      } else {
        // yield new MachiningGroupsFailure(e.toString());
        yield new MachiningGroupsFailure("Unknow error");
      }
    }
  }

  // Called whenever an `event` is added.
  onEvent(event: MachiningGroupsEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
  }

  // Called whenever a state change is about to occur.
  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
  }
}
