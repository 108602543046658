import { CutParam } from "constants/constants";
import ToolfinderCutParams from "models/toolfinder_cut_params";
import ToolfinderSelection from "models/toolfinder_selection";

export abstract class ToolfinderSelectionsEvent {}

export class ToolfinderSetSelections extends ToolfinderSelectionsEvent {
	toolfinderSelection: ToolfinderSelection = null as any;
	constructor(toolfinderSelection: ToolfinderSelection) {
		super();
		this.toolfinderSelection = toolfinderSelection;
	}

	public toString = (): string => {
		return `ToolfinderSetSelections`;
	};
}

export class ToolfinderResetSelections extends ToolfinderSelectionsEvent {
	public toString = (): string => {
		return `ToolfinderResetSelections`;
	};
}


export class ToolfinderSetCutParams extends ToolfinderSelectionsEvent {
	cutParams: ToolfinderCutParams;
	constructor(cutParams: ToolfinderCutParams) {
		super();
		this.cutParams = cutParams;
	}

	public toString = (): string => {
		return `ToolfinderSetCutParam`;
	};
}