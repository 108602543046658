import Reference from "models/reference";

export abstract class ReferencesState {}

export class ReferencesInitial extends ReferencesState {}

export class ReferencesLoading extends ReferencesState {}

export class ReferencesLoaded extends ReferencesState {
	references: Array<Reference>;
	constructor(references: Array<Reference>) {
		super();
		this.references = references;
	}

	public toString = (): string => {
		return `ReferencesLoaded ${this.references.length} references`;
	};
}

export class ReferencesFailure extends ReferencesState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `ReferencesLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
