import config from "config/config";
import GeneralHelper from "helpers/general_helper";

// interface NamedParameters {
// 	id: number;
// 	code: string;
// 	type: string;
// 	constructorName: string;
// 	coef: number;
// 	createdAt: Date;
// 	longDescription: Record<string, string>;
// 	shortDescription: Record<string, string>;
// 	NMax: number;
// }

export default class Machining {
	// USI_ID
	id: number;
	// USI_GRP_ID
	usiGrpId: number;

	// USI_CODE
	code: string;
	// USI_IMAGE
	image: string;

	// USI_LIBRE_FLOAT_1
	float1: number;
	// USI_LIBRE_FLOAT_2
	float2: number;
	// USI_LIBRE_CHAR_1
	char1: number;
	// USI_LIBRE_CHAR_2
	char2: number;

	// DESC_USI_GRP_XX
	groupDescription: Record<string, string>;
	// LONG_DESC_XX
	longDescription: Record<string, string>;
	// SHORT_DESC_XX
	shortDescription: Record<string, string>;

	affinity: number;

	constructor({ id, usiGrpId, code, image, float1, float2, char1, char2, groupDescription, longDescription, shortDescription, affinity }: any) {
		this.id = id;
		this.usiGrpId = usiGrpId;
		this.code = code;
		this.image = image;
		this.float1 = float1;
		this.float2 = float2;
		this.char1 = char1;
		this.char2 = char2;
		this.groupDescription = groupDescription;
		this.longDescription = longDescription;
		this.shortDescription = shortDescription;
		this.affinity = affinity;
	}

	static areIdentical(a: Machining, b: Machining): boolean {
		if (!a && !b) return true;
		if (!a || !b) return false;
		return a.id === b.id;
	}

	static fromJson(map: Record<string, any>): Machining {
		return new Machining({
			id: GeneralHelper.parseIntOrNull(map["USI_ID"]),
			usiGrpId: map["USI_GRP_ID"],
			code: map["USI_CODE"],
			image: map["USI_IMAGE"],
			float1: map["USI_LIBRE_FLOAT_1"],
			float2: map["USI_LIBRE_FLOAT_2"],
			char1: map["USI_LIBRE_CHAR_1"],
			char2: map["USI_LIBRE_CHAR_2"],
			groupDescription: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["DESCR_CODE_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["DESCR_CODE_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["DESCR_CODE_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["DESCR_CODE_IT"]),
			},
			longDescription: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_IT"]),
			},
			shortDescription: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_IT"]),
			},
			// affinity: map[''],
		});
	}

	getLongDescription(lang: string): string {
		return this.longDescription[lang] != null && this.longDescription[lang] != ""
			? this.longDescription[lang]
			: this.longDescription[config["FALLBACK_LOCALE"]] ?? "";
	}

	getShortDescription(lang: string): string {
		return this.shortDescription[lang] != null && this.shortDescription[lang] != ""
			? this.shortDescription[lang]
			: this.shortDescription[config["FALLBACK_LOCALE"]] ?? "";
	}
}
