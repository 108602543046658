import Article from "models/article";
import Coating from "models/coating";
import Machining from "models/machining";

const radians2Degrees: number = 180.0 / Math.PI;

export default class CalculHelper {
  static getFraisageVc({
    indiceMaterial,
    indiceMachine,
    indiceMaterialCoating,
    indiceMaterialTool,
  }: {
    indiceMaterial: number;
    indiceMachine: number;
    indiceMaterialCoating: number;
    indiceMaterialTool: number;
  }): number {
    // var x1: number = 1 + indiceMaterial / 10;
    // var x2: number = 1 + indiceMachine / 10;
    var x1: number = (indiceMaterial + 0.9) / 3;
    var x2: number = indiceMachine;
    var x3: number = 1 + indiceMaterialCoating / 10;
    var x4: number = 1 + indiceMaterialTool / 10;
    var value = 100 * x1 * x2 * x3 * x4;
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getFraisagen({ Vc, d1 }: { Vc: number; d1: number }): number {
    var value = (Vc * 1000) / (Math.PI * d1);
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getFraisageVf({ Fz, Z, n }: { Fz: number; Z: number; n: number }): number {
    var value = Fz * Z * n;
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getFraisageQ({ ap, ae, Vf }: { ap: number; ae: number; Vf: number }): number {
    var value = (ap * ae * Vf) / 1000;
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getFraisagehm({ Fz, ae, d1 }: { Fz: number; ae: number; d1: number }): number {
    var value = Fz * Math.sqrt(ae / d1);
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getFraisageEmax({ ae, d1, Fz }: { ae: number; d1: number; Fz: number }): number {
    if (ae > d1 / 2) {
      return Fz;
    } else {
      var value = (2 * Fz * Math.sqrt(ae * d1 - Math.pow(ae, 2))) / d1;
      if (value == Infinity) return undefined as unknown as number;
      return value;
    }
  }

  static getFraisageLgCop({ d1, ae }: { d1: number; ae: number }): number {
    var value = ((Math.PI * (2 * this.degrees(Math.acos((d1 / 2 - ae) / (d1 / 2))))) / 180) * (d1 / 2 / 2);
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getFraisageVolCop({ LgCop, hm, ap }: { LgCop: number; hm: number; ap: number }): number {
    var value = LgCop * hm * ap;
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getFraisageVctf({ VolCop, Z }: { VolCop: number; Z: number }): number {
    var value = VolCop * Z;
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getFraisageP({ Rm, ap, ae, Vf, eta }: { Rm: number; ap: number; ae: number; Vf: number; eta: number }): number {
    var value = (Rm * ap * ae * Vf) / (60000000 * eta);
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static degrees(radians: number): number {
    return radians * radians2Degrees;
  }

  static getFraisageFz({ d1 }: { d1: number }) {
    var value = 0.005 * d1;
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getFraisageAe({ d1, l1, machining }: { d1: number; l1: number; machining: Machining }): number {
    var value = 0;
    switch (machining.id) {
      case 100000: // Surfaçage
        value = d1;
        break;
      case 100001: // Epaulement
        value = (0.25 * d1) / l1;
        break;

      case 100002: // Rainurage
        value = d1;
        break;

      case 100003: // POCHE
        value = d1;
        break;

      case 100004: // Interpolation hélicoîdale
        value = d1;
        break;
    }
    value = value;
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getFraisageAp({ d1, l1, machining }: { d1: number; l1: number; machining: Machining }): number {
    var value = 0;
    switch (machining.id) {
      case 100000: // Surfaçage
        value = 0.25 * d1;
        break;
      case 100001: // Epaulement
        value = l1;
        break;

      case 100002: // Rainurage
        value = 0.25 * d1;
        break;

      case 100003: // POCHE
        value = 0.25 * d1;
        break;

      case 100004: // Interpolation hélicoîdale
        value = 0.1 * d1;
        break;
    }
    value = value;
    if (value == Infinity) return undefined as unknown as number;

    return value;
  }

  static getFraisageVcFromN({ d1, n }: { d1: number; n: number }): number {
    var value = (Math.PI * d1 * n) / 1000;
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  /* ===================================== */
  /* =========== PARTIE PERCAGE ========== */
  /* ===================================== */

  static getPercageVf({ F, n }: { F: number; n: number }) {
    var value = F * n;
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getPercageVc({
    indiceMaterial,
    indiceMachine,
    indiceMaterialCoating,
    indiceMaterialTool,
    machining,
  }: {
    indiceMaterial: number;
    indiceMachine: number;
    indiceMaterialCoating: number;
    indiceMaterialTool: number;
    machining: Machining;
  }) {
    var value = CalculHelper.getFraisageVc({
      indiceMachine: indiceMachine,
      indiceMaterial: indiceMaterial,
      indiceMaterialCoating: indiceMaterialCoating,
      indiceMaterialTool: indiceMaterialTool,
    });
    switch (machining.id) {
      // 100000 Surfaçage
      // 100001 Epaulement
      // 100002 Rainurage
      // 100003 POCHE
      // 100004 Interpolation hélicoîdale
      // 100005 Centrage
      // 100006 Perçage sur face plate
      // 100007 Perçage sur face convexe
      // 100008 Perçage sur face concave
      // 100009 Alésage

      case 100005: // Centrage
        value = value / 3;
        break;
      case 100006: // Perçage sur face plate
        value = value / 2.5;
        break;

      case 100009: // Alésage
        value = value / 5;
        break;
    }
    value = value;

    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getPercageF({ d1 }: { d1: number }) {
    var value = d1 / 50;
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getPercageQ({ Vf, d1 }: { Vf: number; d1: number }) {
    // var value = ap * ae * Vf / 1000;
    var value = (Vf * Math.pow(d1, 2) * Math.PI) / 4000;
    if (value == Infinity) return undefined as unknown as number;
    return value;
  }

  static getCoatingsWithDefaultSort(coatings: Array<Coating>, selectedArticle: Article): Array<Coating> {
    var _coatings: Array<Coating> = Object.assign([], coatings);


    _coatings.sort((a: Coating, b: Coating) => {
      // sort by code (string)
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
    _coatings.sort((a: Coating, b: Coating) => {
      var aCompatible = CalculHelper.isCoatingCompatibleWithArticle(a, selectedArticle);
      var bCompatible = CalculHelper.isCoatingCompatibleWithArticle(b, selectedArticle);

      if (aCompatible && !bCompatible) {
        return -1;
      }

      if (!aCompatible && bCompatible) {
        return 1;
      }
      return b.affinity - a.affinity;
    });

    return _coatings;
  }

  static isCoatingCompatibleWithArticle(coating: Coating, article: Article): boolean {
    if (coating.dCibleMin === null || coating.dCibleMax === null) {
      return false;
    }

    if (article != undefined && coating.dCibleMin! <= article.dimD1 && article.dimD1 <= coating.dCibleMax!) {
      return true;
    }

    return false;
  }
}
