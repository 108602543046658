import Coating from "models/coating";

export abstract class CoatingsState {}

export class CoatingsInitial extends CoatingsState {}

export class CoatingsLoading extends CoatingsState {}

export class CoatingsLoaded extends CoatingsState {
	coatings: Array<Coating>;
	constructor(coatings: Array<Coating>) {
		super();
		this.coatings = coatings;
	}

	public toString = (): string => {
		return `CoatingsLoaded ${this.coatings.length} coatings`;
	};
}

export class CoatingsFailure extends CoatingsState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `CoatingsLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
