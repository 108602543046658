import { CutParam, CUT_PARAM_TYPE } from "constants/constants";
import ToolfinderSelection from "models/toolfinder_selection";

export abstract class ToolfinderSelectionsState {}

export class ToolfinderSelectionsInitial extends ToolfinderSelectionsState {}

export class ToolfinderSelectionsUpdated extends ToolfinderSelectionsState {
	toolfinderSelection: ToolfinderSelection;
	changedCutParam: CUT_PARAM_TYPE | undefined;
	constructor(toolfinderSelection: ToolfinderSelection, changedCutParam: CUT_PARAM_TYPE | undefined = undefined ) {
		super();
		this.toolfinderSelection = toolfinderSelection;
		this.changedCutParam = changedCutParam;
	}

	public toString = (): string => {
		return `ToolfinderSelectionsUpdated`;
	};
}

export class ToolfinderSelectionsError extends ToolfinderSelectionsState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `ToolfinderSelectionsError { errorMessage: ${this.errorMessage} }`;
	};
}
