import Cart from "../../models/cart";
import Article from "../../models/article";

export abstract class CartState {}

export class CartUpdated extends CartState {
	cart: Cart;

	constructor(cart: Cart) {
		super();
		this.cart = cart;
	}

	public toString = (): string =>
		`CartUpdated ` + this.cart.articles.length.toString();
}
