import Message from "models/message";
import Config from "config/config";
import axios from "axios";
import AuthenticationService from "services/authentication";

export default class MessageRepository {
	async getData(): Promise<Array<Message>> {
		var data: any = await axios.get(Config.SKYLIGHT_API + "/getMessageSky", {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "X-Requested-With",
			},
      params: {
        token: "eyJhbGciOiJIUzI1NiJ9.VG9vbEZpbmRlckFQSVRva2VuU2Vj.QCu1jWyoC3vH2xsbJuSUwrBdzk8Ay_Wu11cKFP3uboE",
      }
		});
		return await this.dataToMessage(data.data);
	}

	dataToMessage(data: any): Array<Message> {
		var materialGroups: Array<Message> = [];
		for (var i = 0; i < data.length; i++) {
			materialGroups.push(Message.fromJson(data[i]));
		}
		return materialGroups;
	}
}
