import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import React, { useRef } from "react";
import "./step_tool.scss";
import { Subscription } from "rxjs";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ReferenceItem from "./components/reference_item";
import BackButton from "components/back_button";
import { ToolfinderSetSelections } from "../blocs/toolfiinder_selections_bloc/toolfiinder_selections_event";
import ToolfinderSelection from "models/toolfinder_selection";
import { ToolfinderSelectionsState, ToolfinderSelectionsUpdated } from "../blocs/toolfiinder_selections_bloc/toolfiinder_selections_state";
// import { FavoritesToolsLoaded, FavoritesToolsState } from "blocs/favorites_references_bloc/favorites_references_state";
import { WithTranslation, withTranslation } from "react-i18next";
import StepsResume from "../components/steps_resume";
import FavoriteTool from "models/favorite_tool";
import { ReferencesLoaded, ReferencesState } from "blocs/references_bloc/references_state";
import Reference from "models/reference";
import Article from "models/article";
import { ArticlesLoaded, ArticlesState } from "blocs/articles_bloc/articles_state";
import EmptyImageSrc from "assets/images/empty.svg";
import { BlocBuilder } from "@felangel/react-bloc";
import { FavoritesToolsLoaded, FavoritesToolsState } from "blocs/favorites_tools_bloc/favorites_tools_state";
import { FavoritesToolsAdd, FavoritesToolsRemove } from "blocs/favorites_tools_bloc/favorites_tools_event";
import moment from "moment";
import GeneralHelper from "helpers/general_helper";
import { CartAddArticle, CartRemoveArticle } from "blocs/cart_bloc/cart_event";
import { CartState, CartUpdated } from "blocs/cart_bloc/cart_state";
import CartArticle from "models/cart_article";
import ShoppingCartIcon from "assets/icons/shopping-cart.svg";
import ToolItem from "./components/tool_item";
import Coating from "models/coating";
import { CoatingsLoaded } from "blocs/coatings_bloc/coatings_state";
import { ArticleCoating } from "constants/constants";
import { ArticlesCoatingsSelectionLoaded, ArticlesCoatingsSelectionState } from "blocs/articles_coatings_selection_bloc/articles_coatings_selection_state";
import { ArticlesCoatingsSelectionSave } from "blocs/articles_coatings_selection_bloc/articles_coatings_selection_event";

interface Props extends RouteComponentProps<any>, WithTranslation {}

enum SortBy {
  AFFINITY,
  REF,
}

type State = {
  rawToolsList: Array<Reference>;
  tools: Array<Reference>;
  rawArticlesList: Array<Article>;
  articles: Array<Article>;
  selectedReference: Reference;
  selectedArticle: Article;
  defaultCoating: Coating | undefined;
  articlesCoatingsSelection: Array<ArticleCoating> | undefined;
  selectedCoatings: ArticleCoating[];
  toolfinderSelection: ToolfinderSelection;
  sortby: SortBy;
  searchQuery: string;
  favoritesOnly: boolean;
  favoritesTools: Array<FavoriteTool>;
  showRefWarning: boolean;
};

class StepToolScreen extends React.Component<Props, State> {
  static contextType = BlocsContext;
  context!: React.ContextType<typeof BlocsContext>;
  articlesBoxRef: React.RefObject<HTMLDivElement>;

  updatingSelectedoatings: boolean = false;

  // history = useHistory();

  // static contextTypes = {
  //     contextType: BlocsContext,
  //     router: () => true, // replace with PropTypes.object if you use them
  //   }
  // declare context: React.ContextType<typeof BlocsContext>

  private referencesBlocSubscription: Subscription = Subscription.EMPTY;
  private articlesBlocSubscription: Subscription = Subscription.EMPTY;
  private toolfinderSelectionBlocSubscription: Subscription = Subscription.EMPTY;
  private favoritesToolsBlocSubscription: Subscription = Subscription.EMPTY;
  private articlesCoatingsSelectionBlocSubscription: Subscription = Subscription.EMPTY;

  rawArticlesList: Array<Article> = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      rawToolsList: [],
      tools: [],
      rawArticlesList: [],
      articles: [],
      favoritesTools: [],
      selectedReference: null as any,
      selectedArticle: null as any,
      defaultCoating: undefined,
      articlesCoatingsSelection: undefined,
      selectedCoatings: [],
      toolfinderSelection: null as any,
      sortby: SortBy.AFFINITY,
      searchQuery: "",
      favoritesOnly: false,
      showRefWarning: false,
    };

    this.articlesBoxRef = React.createRef();
  }

  componentDidMount(): void {
    this.referencesBlocSubscription = this.context.referencesBloc.listen(this.referencesBlocStateAction);
    this.referencesBlocStateAction(this.context.referencesBloc.state);

    this.articlesBlocSubscription = this.context.articlesBloc.listen(this.articlesBlocStateAction);
    this.articlesBlocStateAction(this.context.articlesBloc.state);

    this.toolfinderSelectionBlocSubscription = this.context.toolfinderSelectionBloc.listen(this.toolfinderSelectionBlocAction);
    this.toolfinderSelectionBlocAction(this.context.toolfinderSelectionBloc.state);

    this.favoritesToolsBlocSubscription = this.context.favoritesToolsBloc.listen(this.favoritesToolsBlocAction);
    this.favoritesToolsBlocAction(this.context.favoritesToolsBloc.state);

    this.articlesCoatingsSelectionBlocSubscription = this.context.articlesCoatingsSelectionBloc.listen(this.articlesCoatingsSelectionBlocAction);
    this.articlesCoatingsSelectionBlocAction(this.context.articlesCoatingsSelectionBloc.state);
  }

  articlesCoatingsSelectionBlocAction = (state: ArticlesCoatingsSelectionState) => {
    if (state instanceof ArticlesCoatingsSelectionLoaded) {
      this.setState(
        {
          articlesCoatingsSelection: state.articlesCoatingsSelection,
        },
        () => {
          this.updateSelectedCoatingsIfNecessary();
        }
      );
    }
  };

  updateSelectedCoatingsIfNecessary = () => {
    if (this.updatingSelectedoatings) return;
    this.updatingSelectedoatings = true;
    if (this.state.selectedCoatings.length != 0) return;
    if (this.state.articlesCoatingsSelection == undefined) return;
    if (this.state.rawArticlesList.length == 0) return;
    // if(this.state.defaultCoating == undefined) return;

    var newSelectedCoatings: Array<ArticleCoating> = [];

    if (this.state.articlesCoatingsSelection.length > 0) {
      newSelectedCoatings = this.state.articlesCoatingsSelection;
    }

    //     var copyRawArticlesList = this.state.rawArticlesList;
    //     var copyNewSelectedCoatings = newSelectedCoatings;
    // var iteration = 0;
    //     copyRawArticlesList.map((article: Article) => {

    //       copyNewSelectedCoatings.find((articleCoating: ArticleCoating) => {
    //         iteration++;
    //         return articleCoating.article.id == article.id;
    //       }) ??
    //         newSelectedCoatings.push({ article: article, coating: this.state.defaultCoating } as ArticleCoating);
    //     });

    // this.state.rawArticlesList.map((article: Article) => {
    //   newSelectedCoatings.find((articleCoating: ArticleCoating) => articleCoating.article.id == article.id) ??
    //     newSelectedCoatings.push({ article: article, coating: this.state.defaultCoating } as ArticleCoating);
    // });
    // console.log("============== SET STATE " + iteration);
    this.setState(
      {
        selectedCoatings: newSelectedCoatings,
      },
      () => {
        this.updatingSelectedoatings = false;
      }
    );
  };

  componentWillUnmount(): void {
    this.referencesBlocSubscription.unsubscribe();
    this.articlesBlocSubscription.unsubscribe();
    this.toolfinderSelectionBlocSubscription.unsubscribe();
    this.favoritesToolsBlocSubscription.unsubscribe();
    this.articlesCoatingsSelectionBlocSubscription.unsubscribe();
  }

  referencesBlocStateAction = (state: ReferencesState) => {
    if (state instanceof ReferencesLoaded) {
      var references: Array<Reference> = state.references.filter((ref: Reference) => ref.compatible == true);
      this.setState({
        rawToolsList: references,
        tools: this.sortAndFilterTools(references),
      });
    }
  };

  articlesBlocStateAction = (state: ArticlesState) => {
    if (state instanceof ArticlesLoaded) {
      this.rawArticlesList = state.articles;

      this.setState(
        {
          rawArticlesList: state.articles,
          articles: this.sortAndFilterArticles(state.articles, this.state.selectedReference),
        },
        () => {
          this.updateSelectedCoatingsIfNecessary();
        }
      );
    }
  };

  sortAndFilterArticles = (rawArticles: Array<Article>, selectedReference: Reference) => {
    // let articles = this.state.selectedReference != null ? this.state.rawArticlesList.filter((article: Article) => article.refId == this.state.selectedReference.id) : [];
    // articles = articles.sort((a: Article, b: Article) => a.dimD1 - b.dimD1);
    // this.setState({
    //   articles: articles,
    // });

    let reference = selectedReference ? selectedReference : this.state.selectedReference;

    if (reference == null) return [];

    let articles = rawArticles.filter((article: Article) => article.refId == reference.id);
    articles = articles.sort((a: Article, b: Article) => a.dimD1 - b.dimD1);

    if (this.state.favoritesOnly === true) {
      articles = articles.filter((article: Article) => {
        var isFavorite: boolean = false;
        this.state.favoritesTools.forEach((favorite: FavoriteTool) => {
          if (article.id === favorite.articleId) {
            isFavorite = true;
          }
        });
        return isFavorite;
      });
    }
    // this.setState({
    //   articles: articles,
    // });
    return articles;

    // articles: this.rawArticlesList != null && reference != null ? this.rawArticlesList.filter((article: Article) => article.refId == reference.id) : [],
    // articles: this.state.rawArticlesList.filter((article: Article) => article.refId == ref.id),
  };

  favoritesToolsBlocAction = (state: FavoritesToolsState) => {
    if (state instanceof FavoritesToolsLoaded) {
      this.setState(
        {
          favoritesTools: state.favorites,
        },
        () => {
          // var tools: Array<Tool> = this.state.tools.map((tool: Tool) => {
          //     reference.
          //     return tool;
          // })

          this.setState({
            tools: this.sortAndFilterTools(this.state.tools),
          });
        }
      );
    }
  };

  sortAndFilterTools(_rawToolsList: Array<Reference>): Array<Reference> {
    var _references: Array<Reference> = _rawToolsList;

    // Filters
    // if (this.state.searchQuery && this.state.searchQuery !== "") {
    // 	console.log(this.state.searchQuery);
    // }

    if (this.state.searchQuery != null && this.state.searchQuery != "") {
      _references = _references.filter((reference: Reference) => reference.code.toLowerCase().search(this.state.searchQuery.toLowerCase()) !== -1);

      this.setState({ showRefWarning: this.state.searchQuery.match(RegExp("[a-zA-Z]")) != undefined });
    } else {
      this.setState({ showRefWarning: false });
    }

    if (this.state.favoritesOnly === true) {
      _references = _references.filter((reference: Reference) => {
        var isFavorite: boolean = false;
        this.state.rawArticlesList.map((article: Article) => {
          if (reference.id == article.refId) {
            this.state.favoritesTools.forEach((favorite: FavoriteTool) => {
              if (article.id === favorite.articleId) {
                isFavorite = true;
              }
            });
          }
        });
        return isFavorite;
      });
      // List<ToolModel.Tool> favorites = BlocProvider.of<FavoriteToolsBloc>(context).favorites;
      // _references = _references.where((ToolModel.Tool tool) => favorites.contains(tool)).toList();
    }

    // Auto sort by ref by default
    _references.sort((a: Reference, b: Reference) => a.code.localeCompare(b.code));

    // Sort
    switch (this.state.sortby) {
      case SortBy.AFFINITY:
        // _references.sort((a, b) =>
        //     b.getAffinity(coating: _toolfinderSelection.coating, tool: _toolfinderSelection.tool).compareTo(a.getAffinity(coating: _toolfinderSelection.coating, tool: _toolfinderSelection.tool)));
        _references.sort((a: Reference, b: Reference) => b.affinity - a.affinity);

        break;
      // case SortBy.REF:
      // 	_references.sort((a: Tool, b: Tool) => a.ref.localeCompare(b.ref));
      // 	break;
    }

    return _references;
  }

  toolfinderSelectionBlocAction = (state: ToolfinderSelectionsState) => {
    if (state instanceof ToolfinderSelectionsUpdated) {
      var reference = state.toolfinderSelection.reference ? state.toolfinderSelection.reference : this.state.selectedReference;
      // console.log("state.toolfinderSelection.coating == undefined", state.toolfinderSelection.coating == undefined);
      // console.log("state.toolfinderSelection.coating", state.toolfinderSelection.coating);
      var newDefaultCoating =
        state.toolfinderSelection.coating != undefined ? state.toolfinderSelection.coating : (this.context.coatingsBloc.state as CoatingsLoaded).coatings.find((coating) => coating.code == "AA");
      // console.log("newDefaultCoating", newDefaultCoating);
      var articles = this.sortAndFilterArticles(this.rawArticlesList, reference);
      this.setState(
        {
          toolfinderSelection: state.toolfinderSelection,
          selectedReference: reference,

          selectedArticle: state.toolfinderSelection.article ? state.toolfinderSelection.article : this.state.selectedArticle,
          defaultCoating: newDefaultCoating,
          articles: articles,
        },
        () => {
          this.updateSelectedCoatingsIfNecessary();
        }
      );
    }
  };

  // getDefaultCoatings = () => {
  //   if(this.state.selectedCoatings != undefined && this.state.selectedCoatings.length > 0){
  //     return this.state.selectedCoatings;
  //   }
  //   var defaultCoating =
  // }

  renderActionButtons() {
    return (
      <div>
        <button
          className="btn btn-dark custom-btn text-white mx-2"
          onClick={(e) => {
            this.props.history.push("/dashboard");
          }}
        >
          <span className="icon icon-arrow-left2 icon-left"></span>
          {this.props.t("screen.step_tool.cancel_back_button")}
        </button>
        <button
          className="btn btn-primary text-white custom-btn mx-2"
          onClick={(e) => {
            if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated) {
              if (this.state.selectedReference != null && this.state.selectedArticle != null) {
                // console.log("coatings", this.state.selectedCoatings);
                // console.log("coating", this.state.selectedCoatings.find((articleCoating: ArticleCoating) => articleCoating.article.id == this.state.selectedArticle?.id));
                // console.log(this.state.selectedCoatings.find((articleCoating: ArticleCoating) => articleCoating.article.id == this.state.selectedArticle?.id)?.coating ?? this.state.defaultCoating);
                this.context.toolfinderSelectionBloc.add(
                  new ToolfinderSetSelections(
                    this.state.toolfinderSelection.copyWith({
                      reference: this.state.selectedReference,
                      article: this.state.selectedArticle,
                      coating: this.state.toolfinderSelection.coatingLock
                        ? this.state.toolfinderSelection.coating
                        : this.state.selectedCoatings.find((articleCoating: ArticleCoating) => articleCoating.article.id == this.state.selectedArticle?.id)?.coating ?? this.state.defaultCoating,
                      toolLock: true,
                    })
                  )
                );
              }
            }
            this.props.history.push("/dashboard");
          }}
        >
          <span className="icon icon-save icon-left"></span>
          {this.props.t("screen.step_tool.save_button")}
        </button>
      </div>
    );
  }

  setQueryString(query: string) {
    this.setState(
      {
        searchQuery: query,
        // tools: this.sortAndFilterTools(this.state.rawToolsList)
      },
      () => {
        this.setState({
          // searchQuery: event.currentTarget.value,
          tools: this.sortAndFilterTools(this.state.rawToolsList),
        });
      }
    );
  }

  setSortBy(sortBy: SortBy) {
    this.setState({ sortby: sortBy }, () => {
      this.setState({
        tools: this.sortAndFilterTools(this.state.rawToolsList),
      });
    });
  }

  setFavoritesOnly(favoritesOnly: boolean) {
    // console.log("set favorite only", favoritesOnly);
    this.setState({ favoritesOnly: favoritesOnly }, () => {
      this.setState({
        tools: this.sortAndFilterTools(this.state.rawToolsList),
        articles: this.sortAndFilterArticles(this.rawArticlesList, this.state.selectedReference),
      });
    });
  }

  renderSortsAndFilters() {
    return (
      <div className="container">
        <div className="sort-and-filter d-flex py-3">
          <div className="me-5">
            <div className="filterTitle">{this.props.t("general.search")}</div>
            <div className="d-flex">
              <div className="custom-search-field">
                <span className="icon icon-search"></span>
                <input type="text" placeholder={this.props.t("screen.step_tool.search")} value={this.state.searchQuery} onChange={(event) => this.setQueryString(event.currentTarget.value)} />
              </div>
              <button onClick={(event) => this.setQueryString("")} type="button" className="btnResetSearch btn btn-primary text-white px-4">
                {this.props.t("general.reset")}
              </button>
            </div>
          </div>
          <div className="vertical-separator"></div>
          <div className="mx-4">
            <div className="filterTitle">{this.props.t("general.sortby")}</div>
            <div className="d-flex">
              <button type="button" onClick={(event) => this.setSortBy(SortBy.AFFINITY)} className={"filter" + (this.state.sortby === SortBy.AFFINITY ? " active" : "")}>
                <span className="icon icon-d-cube"></span>
                {this.props.t("general.sortby_affinity")}
              </button>
              <button type="button" onClick={(event) => this.setSortBy(SortBy.REF)} className={"filter" + (this.state.sortby === SortBy.REF ? " active" : "")}>
                <span className="icon icon-sort"></span>
                {this.props.t("general.sortby_ref")}
              </button>
            </div>
          </div>
        </div>

        {this.state.showRefWarning && (
          <div className="alert alert-warning my-4 ref-warning" role="alert">
            <span className="icon icon-info icon-left"></span>
            {this.props.t("screen.step_tool.warningRefFoundMessage")}
          </div>
        )}
      </div>
    );
  }

  getClosestArticle(ref: Reference, article: Article): Article | undefined {
    if (article == undefined) return undefined;
    let nearestArticle: Article | undefined = undefined;
    let minDifference: number | undefined;

    this.state.rawArticlesList.map((rawArticle: Article) => {
      if (rawArticle.refId == ref.id) {
        if (nearestArticle == undefined) {
          nearestArticle = rawArticle;
          minDifference = Math.abs(article.dimD1 - rawArticle.dimD1);
        } else {
          const difference = Math.abs(article.dimD1 - rawArticle.dimD1);

          if (difference < minDifference!) {
            nearestArticle = rawArticle;
            minDifference = difference;
          }
        }
      }
    });
    return nearestArticle;
    // return this.state.rawArticlesList.find((article) => article.refId == ref.id);
  }

  render() {
    var maxAffinity: number = 0;

    maxAffinity = this.state.rawToolsList.reduce((value, element) => (value.affinity > element.affinity ? value : element), new Reference({ affinity: 0 })).affinity;
    return (
      <BaseScreen actionButtons={this.renderActionButtons()}>
        <div className="w-100 step-tool d-flex flex-column align-items-center">
          <StepsResume />
          <div className="border-bottom mb-4 mt-2 w-100 mx-auto">
            {/* <div className="container">
              <BackButton onClick={this.props.history.goBack} text={this.props.t("general.back_to_dashboard")} />
            </div> */}
            {this.renderSortsAndFilters()}
          </div>
          <div className="w-100 mx-auto">
            <div className="container align-items-center mx-auto stepWrapper">
              <div className="row">
                <div className="col-xl-4 reference-box">
                  <label className="cl-switch">
                    <input type="checkbox" checked={this.state.favoritesOnly} onChange={(event) => this.setFavoritesOnly(event.currentTarget.checked)} />
                    <span className="switcher"></span>
                    <span className="label"> {this.props.t("general.favorites_only")}</span>
                  </label>
                  <div className="my-3">
                    <h5>{this.props.t("screen.step_tool.referenceTitle")}</h5>
                    <span className="tools-found">
                      {this.state.tools.length} {this.props.t("screen.step_tool.tools_found")}
                    </span>
                  </div>
                  <div className="d-flex flex-wrap listingReferences">
                    {this.state.tools.map((ref: Reference) => {
                      return (
                        <ReferenceItem
                          key={ref.id}
                          reference={ref}
                          selected={this.state.selectedReference && this.state.selectedReference.code === ref.code}
                          bestReference={ref.affinity != undefined && ref.affinity != 0 && ref.affinity == maxAffinity}
                          onClick={() => {
                            this.setState(
                              {
                                selectedReference: ref,
                                // selectedArticle: this.getClosestArticle(ref, this.state.selectedArticle) ?? null as any,
                                selectedArticle: this.getClosestArticle(ref, this.state.selectedArticle) ?? this.state.selectedArticle,
                                articles: this.sortAndFilterArticles(this.rawArticlesList, ref),
                              }
                              // () => {
                              //   this.sortAndFilterArticles();
                              // }
                            );
                            // if (document.getElementById("articleChoosingSection") != null) {
                            //   document.getElementById("articleChoosingSection")!.scrollIntoView();
                            // }
                            setTimeout(() => {
                              document.querySelector(".tool-" + this.state.selectedArticle?.id)?.scrollIntoView({ behavior: "smooth", block: "center" });
                            }, 150);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>

                <div className="col-xl-8" id="articleChoosingSection">
                  <h5 className="articleChoosingTitle">{this.props.t("screen.step_tool.articlesTitle")}</h5>
                  <span className="tools-found">
                    {this.state.articles.length} {this.props.t("screen.step_tool.articleCount")}
                  </span>
                  {this.state.articles.length == 0 && (
                    <div className="d-flex justify-content-center my-5 empty-state">
                      <img src={EmptyImageSrc} className="emptyImage" alt="logo" />
                      <h5>{this.props.t("screen.step_tool.noArticleFoundTitle")}</h5>
                      <p>{this.props.t("screen.step_tool.noArticleFoundMessage")}</p>
                    </div>
                  )}
                  {this.state.articles.length != 0 && (
                    <div style={{ transform: "scale(1)" }}>
                      <div className="d-flex flex-wrap articles-box" ref={this.articlesBoxRef}>
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th></th>
                              <th>d₁ [mm]</th>
                              <th>l₁ [mm]</th>
                              <th>D [mm]</th>
                              <th>L [mm]</th>
                              <th style={{ width: "25%" }}>{this.props.t("screen.step_tool.coating")}</th>
                              <th style={{ width: "20%" }}>{this.props.t("screen.step_tool.actions")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.articles.map((article: Article) => {
                              return (
                                <ToolItem
                                  key={article.id}
                                  article={article}
                                  reference={this.state.selectedReference}
                                  selectedArticle={this.state.selectedArticle}
                                  onCheckClick={(event: any) => {
                                    this.setState({
                                      selectedArticle: event.currentTarget.checked == true ? article : (null as any),
                                    });
                                  }}
                                  selectedCoating={this.state.selectedCoatings.find((articleCoating: ArticleCoating) => articleCoating.article.id == article.id)?.coating ?? this.state.defaultCoating}
                                  setSelectedCoating={(coating: Coating) => {
                                    var found: boolean = false;
                                    var newSelectedCoatings: Array<ArticleCoating> = this.state.selectedCoatings.map((articleCoating: ArticleCoating) => {
                                      if (articleCoating.article.id == article.id) {
                                        return { article: article, coating: coating } as ArticleCoating;
                                        found = true;
                                      } else {
                                        return articleCoating;
                                      }
                                    });
                                    if (found == false) {
                                      newSelectedCoatings.push({ article: article, coating: coating } as ArticleCoating);
                                    }

                                    this.context.articlesCoatingsSelectionBloc.add(new ArticlesCoatingsSelectionSave(newSelectedCoatings));
                                    this.setState({
                                      selectedCoatings: newSelectedCoatings,
                                    });
                                    // this.setState({
                                    //   selectedCoatings: newSelectedCoatings,
                                    // });
                                  }}
                                />
                                // <ReferenceItem
                                // 	key={article.id}
                                // 	reference={article}
                                // 	selected={this.state.selectedReference && this.state.selectedReference.code === article.code}
                                // 	onClick={() => this.setState({ selectedReference: article })}
                                // />
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseScreen>
    );
  }
}

export default withTranslation("common")(withRouter(StepToolScreen));
