import { CutParam, CUT_PARAM_TYPE } from "constants/constants";
import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";

interface propsType {
	value: number;
	locked: boolean;
	onLockedChange: Function;
	valueData: CutParam;
	valueKey: CUT_PARAM_TYPE;
	onChange: Function;
	cutParamsUpdateIncrement: number;
}

const usePrevious = <T extends unknown>(value: T): T | undefined => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};
const CutParamsItem = ({ value, locked, onLockedChange, valueData, valueKey, onChange, cutParamsUpdateIncrement }: propsType) => {
	const props: propsType = {
		value,
		locked,
		onLockedChange,
		valueData,
		valueKey,
		onChange,
		cutParamsUpdateIncrement,
	};
	const prevProps = usePrevious(props);

	const [animationTimout, setAnimationTimout] = useState<NodeJS.Timeout | undefined>(undefined);
	const [isFocus, setIsFocus] = useState<boolean>(false);
	// var animationTimout : NodeJS.Timeout | undefined;

	useEffect(() => {
		if (
			props.cutParamsUpdateIncrement >= 2 &&
			prevProps?.cutParamsUpdateIncrement != props.cutParamsUpdateIncrement &&
			prevProps != undefined &&
			prevProps != null &&
			prevProps?.value != undefined &&
			prevProps?.value != null &&
			props.value != undefined &&
			props.value != null &&
			(prevProps?.value != 0 || props.value != 0) &&
			prevProps?.value != props.value
		) {
			$("#cut-params-item-" + valueData.title)
				.parent()
				.addClass("recently-changed");
			if (animationTimout != undefined) {
				clearTimeout(animationTimout);
			}
			setAnimationTimout(
				setTimeout(() => {
					$("#cut-params-item-" + valueData.title)
						.parent()
						.removeClass("recently-changed");
					setAnimationTimout(undefined);
				}, 1500)
			);

			$("#cut-params-item-" + valueData.title).animate(
				{
					bottom: "-25px",
				},
				150,
				() => {
					$("#cut-params-item-" + valueData.title).css({
						bottom: "20px",
					});

					$("#cut-params-item-" + valueData.title).animate(
						{
							bottom: "0px",
						},
						150
					);
				}
			);
		}
	});

	if (valueData.isEditable)
		return (
			<div className="d-flex justify-content-between dashboard-cut-param">
				<span className="value-title">{valueData.title}</span>
				<div className="d-flex align-items-center justify-content-center">
					<div className="inputContainer">
						<input
							type="text"
							id={"cut-params-item-" + valueData.title}
							value={value}
							onFocus={(event) => setIsFocus(true)}
							onBlur={(event) => setIsFocus(false)}
							onChange={isFocus == true ? (event) => onChange(valueKey, event) : (event) => {}}
							placeholder="-"
						/>
					</div>

					<span className="value-suffix">{valueData.suffix}</span>
					{/* <span className="icon icon-padlock"></span> */}
					<label className="cl-switch">
						<span className="label">
							{locked === true ? <span className="icon icon-padlock"></span> : <span className="icon icon-unlock"></span>}
						</span>
						<input type="checkbox" checked={locked === true} onChange={(event) => onLockedChange(event)} />
						<span className="switcher"></span>
					</label>
				</div>
			</div>
		);

	return (
		<div className="d-flex justify-content-between dashboard-cut-param">
			<span className="value-title">{valueData.title}</span>
			<div className="d-flex align-items-center justify-content-center">
				<div className="inputContainer-disabled">
					<input className="edit-disabled" type="text" value={value} readOnly={true} placeholder="-" />
				</div>
				<span className="value-suffix">{valueData.suffix}</span>
			</div>
		</div>
	);
};

export default CutParamsItem;
