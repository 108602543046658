import ToolfinderCutParams from "models/toolfinder_cut_params";
import ToolfinderSelection from "models/toolfinder_selection";
import { CUT_PARAM_TYPE } from "constants/constants";

export abstract class CutParamsEvent {}

export class CutParamsSetCutParams extends CutParamsEvent {
	toolfinderCutParams: ToolfinderCutParams;
	// currentEditing?: CUT_PARAM_TYPE;
	constructor(toolfinderCutParams: ToolfinderCutParams) {
		super();
		this.toolfinderCutParams = toolfinderCutParams;
	}

	public toString = (): string => {
		return `CutParamsSetCutParams`;
	};
}
export class CutParamsSetCutParam extends CutParamsEvent {
	cutParamType: CUT_PARAM_TYPE;
	value: any;

	constructor({ cutParamType, value}: any) {
		super();
		this.cutParamType = cutParamType;
		this.value = value;
	}

	public toString = (): string => {
		return `CutParamsSetCutParam`;
	};
}

export class CutParamsSetToolfinderSelection extends CutParamsEvent {
	toolfinderSelection: ToolfinderSelection;
	constructor(toolfinderSelection: ToolfinderSelection) {
		super();
		this.toolfinderSelection = toolfinderSelection;
	}

	public toString = (): string => {
		return `CutParamsSetToolfinderSelection`;
	};
}

export class CutParamsReset extends CutParamsEvent {
	public toString = (): string => {
		return `CutParamsReset`;
	};
}
