import { createContext } from 'react';

import MaterialsBloc from 'blocs/materials_bloc/materials_bloc';
// import ToolsBloc from 'blocs/tools_bloc/tools_bloc';
import ArticlessBloc from 'blocs/articles_bloc/articles_bloc';
import ReferencesBloc from 'blocs/references_bloc/references_bloc';
import CoatingsBloc from 'blocs/coatings_bloc/coatings_bloc';
import MachinesBloc from 'blocs/machines_bloc/machines_bloc';
import MessagesBloc from 'blocs/messages_bloc/messages_bloc';

import ToolfinderSelectionBloc from 'screens/toolfinder/blocs/toolfiinder_selections_bloc/toolfiinder_selections_bloc';
import CutParamsBloc from 'screens/toolfinder/blocs/cut_params_bloc/cut_params_bloc';

import FavoritesMaterialsBloc from 'blocs/favorites_materials_bloc/favorites_materials_bloc';
import FavoritesToolsBloc from 'blocs/favorites_tools_bloc/favorites_tools_bloc';
import AssRefMatsBloc from 'blocs/ass_ref_mat_bloc/ass_ref_mat_bloc';
import AssRefUsisBloc from 'blocs/ass_ref_usi_bloc/ass_ref_usi_bloc';
import AssRevMatsBloc from 'blocs/ass_rev_mat_bloc/ass_rev_mat_bloc';
import MachiningsBloc from 'blocs/machining_bloc/machining_bloc';
import MachiningGroupsBloc from 'blocs/machining_group_bloc/machining_group_bloc';
import AuthenticationBloc from 'blocs/authentication_bloc/authentication_bloc';
import CartBloc from 'blocs/cart_bloc/cart_bloc';
import ArticlesCoatingsSelectionBloc from 'blocs/articles_coatings_selection_bloc/articles_coatings_selection_bloc';

type blocsContextValueType = {
    materialsBloc: MaterialsBloc,
    articlesBloc: ArticlessBloc,
    referencesBloc: ReferencesBloc,
    coatingsBloc: CoatingsBloc,
    machinesBloc: MachinesBloc,
    assRefMatBloc: AssRefMatsBloc,
    assRefUsiBloc: AssRefUsisBloc,
    assRevMatBloc: AssRevMatsBloc,
    machiningBloc: MachiningsBloc,
    machiningGroupBloc: MachiningGroupsBloc,
    toolfinderSelectionBloc: ToolfinderSelectionBloc,
    cutParamsBloc: CutParamsBloc,
    favoritesMaterialsBloc: FavoritesMaterialsBloc,
    favoritesToolsBloc: FavoritesToolsBloc,
    authenticationBloc: AuthenticationBloc,
    cartBloc: CartBloc,
    articlesCoatingsSelectionBloc: ArticlesCoatingsSelectionBloc,
    messagesBloc: MessagesBloc,
}

const blocsContextValue: blocsContextValueType = {
    materialsBloc: null as any,
    articlesBloc: null as any,
    referencesBloc: null as any,
    coatingsBloc: null as any,
    machinesBloc: null as any,
    assRefMatBloc: null as any,
    assRefUsiBloc: null as any,
    assRevMatBloc: null as any,
    machiningBloc: null as any,
    machiningGroupBloc: null as any,
    toolfinderSelectionBloc: null as any,
    cutParamsBloc: null as any,
    favoritesMaterialsBloc: null as any,
    favoritesToolsBloc: null as any,
    authenticationBloc: null as any,
    cartBloc: null as any,
    articlesCoatingsSelectionBloc: null as any,
    messagesBloc: null as any,
}

export const BlocsContext = createContext(blocsContextValue);