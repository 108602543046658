import FavoriteTool from "models/favorite_tool";

export abstract class FavoritesToolsEvent {}


export class FavoritesToolsInit extends FavoritesToolsEvent {}
export class FavoritesToolsLoad extends FavoritesToolsEvent {}
export class FavoritesToolsClear extends FavoritesToolsEvent {}
export class FavoritesToolsAdd extends FavoritesToolsEvent {
	favorite: FavoriteTool;
	constructor(favorite: FavoriteTool) {
		super();
		this.favorite = favorite;
	}
}
export class FavoritesToolsRemove extends FavoritesToolsEvent {
	favorite: FavoriteTool;
	constructor(favorite: FavoriteTool) {
		super();
		this.favorite = favorite;
	}
}
