import BaseScreen from "layouts/base";
import React from "react";
import "./privacy_policy.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends RouteComponentProps<any>, WithTranslation {}

type State = {};

class PrivacyPolicyScreen extends React.Component<Props, State> {
	// static contextType = BlocsContext;
	// context!: React.ContextType<typeof BlocsContext>

	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	renderTitle() {
		return (
			<div className="container">
				<div className="sort-and-filter d-flex py-3">
					<div className="me-5">
						<h2>{this.props.t("screen.privacy_policy.title")}</h2>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<BaseScreen>
				<div className="w-100 privacy-policy d-flex flex-column align-items-center">
					<div className="border-bottom my-4 w-100 mx-auto">{this.renderTitle()}</div>
					<div className="w-100 mx-auto">
						<div className="container align-items-center mx-auto">{this.renderContentAs(this.props.i18n.language)}</div>
					</div>
				</div>
			</BaseScreen>
		);
	}

	renderContentAs(lang: String) {
		switch (lang) {
			case "fr":
				return (
					<div id="main-content" className="main">
						<h3>Définitions</h3>
						<p>
							L’Éditeur : La personne, physique ou morale, qui édite les services de communication au public en ligne.
							<br />
							L’Application : L’ensemble des pages et services en ligne proposés par l’Éditeur sur l’application pour Louis Bélet
							Toolfinder.
							<br />
							L’Utilisateur : La personne utilisant L’Application et les services.
						</p>
						<h3>Nature des données collectées</h3>
						<p>
							<strong>
								Dans le cadre de l’utilisation de l’Application, l’Éditeur est susceptible de collecter les catégories de données
								suivantes concernant ses Utilisateurs :
							</strong>
							<br />
							Données de connexion (adresses IP, agents utilisateurs, ID d'installation Firebase, journaux d’événements…)
							<br />
							<h3>Communication des données personnelles à des tiers</h3>
						</p>
						<p>
							<strong>Pas de communication à des tiers</strong>
							<br />
							Vos données ne font l’objet d’aucune communication à des tiers. Vous êtes toutefois informés qu’elles pourront être
							divulguées en application d’une loi, d’un règlement ou en vertu d’une décision d’une autorité réglementaire ou judiciaire
							compétente.
						</p>
						<h3>Collecte des données d’identité</h3>
						<p>
							<strong>Consultation libre</strong>
							<br />
							La consultation de L’Application ne nécessite pas d’inscription ni d’identification préalable. Elle peut s’effectuer sans
							que vous ne communiquiez de données nominatives vous concernant (nom, prénom, adresse, etc). Nous ne procédons à aucun
							enregistrement de données nominatives pour la simple consultation de L’Application.
						</p>
						<h3>Géolocalisation</h3>
						<p>
							<strong>Aucune collecte des données de géolocalisation</strong>
							<br />
							Nous ne collectons et ne conservons aucune donnée de géolocalisation.
						</p>
						<h3>Collecte des données du terminal</h3>
						<p>
							<strong>Aucune collecte des données techniques</strong>
							<br />
							Nous ne collectons et ne conservons aucune donnée technique de votre appareil (adresse IP, fournisseur d’accès à
							Internet…).
						</p>
						<h3>Conservation des données techniques</h3>
						<p>
							<strong>Durée de conservation des données techniques</strong>
							<br />
							Les données techniques sont conservées pour la durée strictement nécessaire à la réalisation des finalités visées
							ci-avant.
						</p>
						<h3>Indications en cas de faille de sécurité décelée par l’Editeur</h3>
						<p>
							<strong>Information de l’Utilisateur en cas de faille de sécurité</strong>
							<br />
							Nous nous engageons à mettre en oeuvre toutes les mesures techniques et organisationnelles appropriées afin de garantir un
							niveau de sécurité adapté au regard des risques d’accès accidentels, non autorisés ou illégaux, de divulgation,
							d’altération, de perte ou encore de destruction des données personnelles vous concernant. Dans l’éventualité où nous
							prendrions connaissance d’un accès illégal aux données personnelles vous concernant stockées sur nos serveurs ou ceux de
							nos prestataires, ou d’un accès non autorisé ayant pour conséquence la réalisation des risques identifiés ci-dessus, nous
							nous engageons à :
						</p>
						<ul>
							<li>Vous notifier l’incident dans les plus brefs délais ;</li>
							<li>Examiner les causes de l’incident et vous en informer ;</li>
							<li>
								Prendre les mesures nécessaires dans la limite du raisonnable afin d’amoindrir les effets négatifs et préjudices
								pouvant résulter dudit incident.
							</li>
						</ul>
						<p>
							<strong>Limitation de la responsabilité</strong>
							<br />
							En aucun cas les engagements définis au point ci-dessus relatifs à la notification en cas de faille de sécurité ne peuvent
							être assimilés à une quelconque reconnaissance de faute ou de responsabilité quant à la survenance de l’incident en
							question.
						</p>
						<h3>Modification de la politique de confidentialité</h3>
						<p>
							<strong>
								En cas de modification de la présente Politique de Confidentialité, nous nous engageons à ne pas baisser le niveau de
								confidentialité de manière substantielle sans l’information préalable des personnes concernées.
							</strong>
							<br />
							Nous nous engageons à vous informer en cas de modification substantielle de la présente Politique de Confidentialité, et à
							ne pas baisser le niveau de confidentialité de vos données de manière substantielle sans vous en informer et obtenir votre
							consentement.
						</p>
						<h3>Droit applicable et modalités de recours</h3>
						<p>
							<strong>Clause d’arbitrage</strong>
							<br />
							Vous acceptez expressément que tout litige susceptible de naître du fait de la présente Politique de Confidentialité,
							notamment de son interprétation ou de son exécution, relèvera d’une procédure d’arbitrage soumise au règlement de la
							plateforme d’arbitrage choisie d’un commun accord, auquel vous adhérerez sans réserve.
						</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<div className="page-links"></div>
					</div>
				);
			case "en":
				return (
					<div id="main-content" className="main">
						<h3>Definitions</h3>
						<p>
							The Publisher: The person, natural or legal, who publishes the online public communication services.
							<br />
							The Application: All of the online pages and services offered by the Publisher on the application for Louis Bélet
							Toolfinder.
							<br />
							The User: The person using the Application and the services.
						</p>
						<h3>Nature of the data collected</h3>
						<p>
							<strong>
								As part of the use of the Application, the Publisher may collect the following categories of data concerning its
								Users:
							</strong>
							<br />
							Connection data (IP addresses, user agents, Firebase installation IDs, event logs, etc.)
							<br />
							<h3>Communication of personal data to third parties</h3>
						</p>
						<p>
							<strong>No communication to third parties</strong>
							<br />
							Your data is not subject to any communication to third parties. However, you are informed that it may be disclosed
							pursuant to a law, regulation or pursuant to a decision of a competent regulatory or judicial authority.
						</p>
						<h3>Collection of identity data</h3>
						<p>
							<strong>Free consultation</strong>
							<br />
							Consultation Consultation of the Application does not require registration or prior identification. It can be done without
							you communicating personal data concerning you (surname, first name, address, etc.). We do not register any personal data
							for the simple consultation of the Application.
						</p>
						<h3>Geolocation</h3>
						<p>
							<strong>No collection of geolocation data</strong>
							<br />
							We do not collect or store any geolocation data.
						</p>
						<h3>Collection of terminal data</h3>
						<p>
							<strong>No collection of technical data</strong>
							<br />
							We do not collect or store any technical data from your device (IP address, Internet Service Provider). Internet
							provider...).
						</p>
						<h3>Technical data retention</h3>
						<p>
							<strong>Duration of technical data retention</strong>
							<br />
							The technical data are kept for the time strictly necessary to achieve the purposes mentioned above. above.
						</p>
						<h3>Indications in case of security breach detected by the Editor</h3>
						<p>
							<strong>Information to the User in case of a security breach</strong>
							<br />
							We undertake to implement all appropriate technical and organisational measures in order to guarantee an appropriate level
							of security with regard to the risks of accidental, unauthorised or illegal access, disclosure level of security
							appropriate to the risks of accidental, unauthorized or illegal access, disclosure alteration, loss or destruction of your
							personal data. In the event that we become aware of any unlawful access to your personal data stored on our servers or
							those of our service providers, or servers or those of our service providers, or of unauthorized access resulting in the
							risks identified above, we undertake to :
						</p>
						<ul>
							<li>Notify you of the incident as soon as possible;</li>
							<li>Examine the causes of the incident and inform you;</li>
							<li>
								Take reasonable steps to mitigate negative effects
								<br />
								and damages that may result from said incident.
							</li>
						</ul>
						<p>
							<strong>Limitation of liability</strong>
							<br />
							Under no circumstances may the commitments defined in the above point relating to notification in the event of a security
							breach be assimilated to any recognition of fault or responsibility for the occurrence of the incident in question.
							incident in question.
						</p>
						<h3>Changes to the privacy policy</h3>
						<p>
							<strong>If this Privacy Policy changes, we will not </strong>
							<strong>lowering the level of confidentiality substantially without prior information </strong>
							<strong>of the people involved.</strong>
							<br />
							We will inform you of any material changes to this Privacy Policy, and will not lower the level of confidentiality of your
							data substantially without informing you and obtaining your consent.
						</p>
						<h3>Applicable law and methods of appeal</h3>
						<p>
							<strong>Arbitration clause</strong>
							<br />
							You expressly agree that any dispute arising out of this Privacy Policy, including its interpretation or enforcement,
							shall be subject to arbitration under the rules of the mutually agreed upon arbitration platform, to which you shall
							adhere without reservation.
						</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<div className="page-links"></div>
					</div>
				);
			case "de":
				return (
					<div id="main-content" className="main">
						<h3>Definitionen</h3>
						<p>
							Der Herausgeber: Die natürliche oder juristische Person, die die öffentlichen Online-Kommunikationsdienste herausgibt.
							<br />
							Die Anwendung: Die Gesamtheit der Seiten und Online-Dienste, die der Herausgeber in der Anwendung für Louis Bélet
							anbietet. Toolfinder.
							<br />
							Der Nutzer: Die Person, die Die Anwendung und die Dienste nutzt.
						</p>
						<h3>Art der gesammelten Daten</h3>
						<p>
							<strong>
								Im Rahmen der Nutzung der Anwendung kann der Herausgeber folgende Kategorien von Daten sammeln über seine Nutzer:
							</strong>
							<br />
							Verbindungsdaten (IP-Adressen, Benutzeragenten, Firebase-Installations-IDs, Ereignisprotokolle...)
							<br />
							<h3>Communication des données personnelles à des tiers</h3>
						</p>
						<p>
							<strong>Keine Weitergabe an Dritte</strong>
							<br />
							Ihre Daten werden nicht an Dritte weitergegeben. Sie werden jedoch darüber informiert, dass Ihre Daten möglicherweise
							aufgrund eines Gesetzes, einer Verordnung oder einer Entscheidung einer Regulierungsbehörde oder eines Gerichts
							offengelegt werden können. zuständigen Behörde oder eines zuständigen Gerichts.
						</p>
						<h3>Erhebung von Identitätsdaten</h3>
						<p>
							<strong>Freie Beratung</strong>
							<br />
							Die Nutzung der Anwendung erfordert weder eine Registrierung noch eine vorherige Identifizierung. Sie kann erfolgen, ohne
							dass ohne dass Sie uns Ihre persönlichen Daten (Name, Vorname, Adresse usw.) mitteilen. Wir führen keine Wir speichern
							keine personenbezogenen Daten für die bloße Nutzung der Anwendung.
						</p>
						<h3>Geolokalisierung</h3>
						<p>
							<strong>Keine Erfassung von Geolokalisierungsdaten</strong>
							<br />
							Wir erheben und speichern keine Geolokalisierungsdaten.
						</p>
						<h3>Sammeln von Terminal-Daten</h3>
						<p>
							<strong>Keine Sammlung von technischen Daten</strong>
							<br />
							Wir sammeln und speichern keine technischen Daten von Ihrem Gerät (IP-Adresse, Internetanbieter, ...). Internet...).
						</p>
						<h3>Aufbewahrung von technischen Daten</h3>
						<p>
							<strong>Dauer der Speicherung von technischen Daten</strong>
							<br />
							Die technischen Daten werden nur so lange aufbewahrt, wie es für die Erreichung der genannten Zwecke unbedingt
							erforderlich ist. oben genannten Zwecke verwendet.
						</p>
						<h3>Hinweise im Falle einer vom Publisher entdeckten Sicherheitslücke</h3>
						<p>
							<strong>Information des Nutzers im Falle einer Sicherheitslücke</strong>
							<br />
							Wir verpflichten uns, alle geeigneten technischen und organisatorischen Maßnahmen zu ergreifen, um ein angemessenes
							Sicherheitsniveau zu gewährleisten, das dem Risiko eines zufälligen, nicht autorisierten oder illegalen Zugriffs, einer
							Offenlegung, Änderung, Verlust oder Zerstörung Ihrer personenbezogenen Daten zu verhindern. Für den Fall, dass wir
							Kenntnis von einem unrechtmäßigen Zugriff auf Ihre personenbezogenen Daten, die auf unseren Servern oder den Servern von
							unseren Dienstleistern gespeichert sind, oder von einem nicht autorisierten Zugriff, der zur Verwirklichung der oben
							genannten Risiken führt, werden wir verpflichten wir uns zu :
						</p>
						<ul>
							<li>Ihnen den Vorfall so schnell wie möglich mitteilen ;</li>
							<li>Die Ursachen des Vorfalls untersuchen und Sie darüber informieren ;</li>
							<li>
								alle angemessenen Maßnahmen ergreifen, um die negativen Auswirkungen und Schäden zu minimieren, die durch die Nutzung
								der Website entstehen. die sich aus dem Vorfall ergeben könnten.
							</li>
						</ul>
						<p>
							<strong>Beschränkung der Haftung</strong>
							<br />
							In keinem Fall dürfen die im obigen Abschnitt definierten Verpflichtungen zur Benachrichtigung im Falle von
							Sicherheitsverletzungen nicht als Anerkennung eines Fehlers oder einer Verantwortung für das Auftreten des Vorfalls
							angesehen werden. zu verstehen.
						</p>
						<h3>Änderung der Datenschutzrichtlinie</h3>
						<p>
							<strong>
								Im Falle einer Änderung dieser Datenschutzrichtlinie verpflichten wir uns, das Niveau des Datenschutzes nicht zu
								senken. Datenschutzniveau ohne vorherige Information der betroffenen Personen wesentlich zu senken.
							</strong>
							<br />
							Wir verpflichten uns, Sie im Falle einer wesentlichen Änderung dieser Datenschutzrichtlinie zu informieren und das
							Datenschutzniveau Ihrer Daten nicht wesentlich senken, ohne Sie darüber zu informieren und Ihre Zustimmung einzuholen.
							Zustimmung.
						</p>
						<h3>Geltendes Recht und Beschwerdemodalitäten</h3>
						<p>
							<strong>Schiedsgerichtsklausel</strong>
							<br />
							Sie erklären sich ausdrücklich damit einverstanden, dass alle Streitigkeiten, die sich aus dieser Datenschutzrichtlinie
							ergeben könnten, insbesondere aus ihrer Auslegung oder Ausführung, einem Schiedsverfahren unterzogen werden, das den
							Regeln der Plattform unterliegt, auf der Sie sich registriert haben. Schiedsgerichtsplattform unterliegt, die Sie
							gemeinsam gewählt haben und der Sie sich vorbehaltlos anschließen.
						</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<div className="page-links"></div>
					</div>
				);
			case "it":
				return (
					<div id="main-content" className="main">
						<h3>Definizioni</h3>
						<p>
							Editore: la persona, fisica o giuridica, che pubblica i servizi di comunicazione pubblica online.
							<br />
							L'applicazione: tutte le pagine e i servizi online offerti dall'editore sull'applicazione per Louis Bélet. Trova attrezzi.
							<br />
							L'Utente: la persona che utilizza l'Applicazione e i servizi.
						</p>
						<h3>Natura dei dati raccolti</h3>
						<p>
							<strong>
								Nel corso dell'utilizzo dell'Applicazione, l'Editore può raccogliere le seguenti categorie di dati sui suoi utenti:
							</strong>
							<br />
							Dati di connessione (indirizzi IP, user agent, ID di installazione di Firebase, log degli eventi...)
							<br />
							<h3>Divulgazione di dati personali a terzi</h3>
						</p>
						<p>
							<strong>Nessuna divulgazione a terzi</strong>
							<br />I vostri dati non saranno divulgati a terzi. Tuttavia, si informa che potrebbe essere divulgati in applicazione di
							una legge, di un regolamento o in virtù di una decisione di un'autorità di regolamentazione o giudiziaria competente.
							autorità.
						</p>
						<h3>Raccolta dei dati di identità</h3>
						<p>
							<strong>Consultazione gratuita</strong>
							<br />
							La consultazione dell'Applicazione non richiede alcuna registrazione o identificazione preventiva. Può essere effettuata
							senza non fornisca alcun dato personale (cognome, nome, indirizzo, ecc.). Non Non registriamo alcun dato personale per la
							semplice consultazione dell'Applicazione.
						</p>
						<h3>Geolocalizzazione</h3>
						<p>
							<strong>Nessuna raccolta di dati di geolocalizzazione</strong>
							<br />
							Non raccogliamo né memorizziamo alcun dato di geolocalizzazione.
						</p>
						<h3>Raccolta dei dati del terminale</h3>
						<p>
							<strong>Nessuna raccolta di dati tecnici</strong>
							<br />
							Non raccogliamo né memorizziamo alcun dato tecnico del vostro dispositivo (indirizzo IP, Internet Service Provider, ecc.).
							fornitore, ecc.)
						</p>
						<h3>Conservazione dei dati tecnici</h3>
						<p>
							<strong>Durata della conservazione dei dati tecnici</strong>
							<br />I dati tecnici sono conservati per il tempo strettamente necessario al raggiungimento delle finalità sopra indicate.
							sopra.
						</p>
						<h3>Indicazioni in caso di violazione della sicurezza rilevata dall'Editore</h3>
						<p>
							<strong>Informare l'Utente in caso di violazione della sicurezza.</strong>
							<br />
							Ci impegniamo a mettere in atto tutte le misure tecniche e organizzative adeguate per garantire un livello di sicurezza
							appropriato rispetto ai rischi di accesso, divulgazione, distruzione o distruzione accidentale, non autorizzata o illegale
							dei dati. livello di sicurezza adeguato ai rischi di accesso accidentale, non autorizzato o illegale, divulgazione
							alterazione, perdita o distruzione dei vostri dati personali. Nel caso in cui si venire a conoscenza di un accesso
							illegale ai vostri dati personali memorizzati sui nostri server o su quelli dei nostri fornitori di servizi, oppure server
							o quelli dei nostri fornitori di servizi, o di un accesso non autorizzato che comporti i rischi sopra identificati, noi si
							impegnano a :
						</p>
						<ul>
							<li>Vi informerà dell'incidente il più presto possibile;</li>
							<li>Esaminare le cause dell'incidente e informarvi;</li>
							<li>
								Adottare le misure necessarie, nei limiti del ragionevole, per mitigare gli effetti negativi.
								<br />e i danni che possono derivare da tale incidente.
							</li>
						</ul>
						<p>
							<strong>Limitazione di responsabilità</strong>
							<br />
							In nessun caso gli impegni di cui al punto precedente, relativi alla notifica in caso di violazione della sicurezza,
							possono essere assimilato a un riconoscimento di colpa o di responsabilità per il verificarsi dell'incidente in questione.
							incidente in questione.
						</p>
						<h3>Modifiche all'informativa sulla privacy</h3>
						<p>
							<strong>
								In caso di modifica della presente Informativa sulla privacy, non abbasseremo il livello di privacy in modo
								sostanziale senza previa notifica agli interessati.
							</strong>
							<br />
							Ci impegniamo a informare l'utente di eventuali modifiche sostanziali alla presente Informativa sulla privacy e a non
							ridurre in modo sostanziale il livello di riservatezza dei vostri dati senza informarvi e senza ottenere il vostro
							consenso. consenso.
						</p>
						<h3>Legge applicabile e rimedi</h3>
						<p>
							<strong>Clausola arbitrale</strong>
							<br />
							L'utente accetta espressamente che qualsiasi controversia derivante dalla presente Informativa sulla privacy, la sua
							interpretazione o esecuzione, sarà deferita a una procedura di arbitrato soggetta alle regole della piattaforma arbitrale
							scelta di comune accordo, alla quale il cliente dovrà aderire senza riserve.
						</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<div className="page-links"></div>
					</div>
				);
		}
	}
}

export default withTranslation("common")(withRouter(PrivacyPolicyScreen));
