import moment, { Moment } from "moment";
import Article from "./article";


interface NamedParameters {
	// article: Article;
	articleId: number;
	articleCode: string;
  revCode: string|undefined;
	createdAt: Moment;
}

export default class FavoriteTool {
	// article: Article;
	articleId: number;
	articleCode: string;
  revCode: string|undefined;
	createdAt: Moment;

	constructor({ articleId, articleCode, revCode, createdAt }: NamedParameters) {
    this.articleId = articleId;
		this.articleCode = articleCode;
    this.revCode = revCode;
		this.createdAt = createdAt;
	}

	static areIdentical(a: FavoriteTool, b: FavoriteTool): boolean {
		if (!a && !b) return true;
		if (!a || !b) return false;
		return a.articleId === b.articleId && a.revCode === b.revCode;
	}

  toJson(){
    return {
      articleId: this.articleId,
      articleCode: this.articleCode,
      revCode: this.revCode,
      createdAt: this.createdAt.toISOString(),
    };
  }

  static fromJson(json: any){
    return new FavoriteTool({
      articleId: json.articleId,
      articleCode: json.articleCode,
      revCode: json.revCode,
      createdAt: moment(json.createdAt),
    });
  }

  static favoritesToJson(favorites: Array<FavoriteTool>){
    return favorites.map((favorite: FavoriteTool) => favorite.toJson());
  }
  static favoritesFromJson(json: any){
    return json.map((favoriteJson: string) => FavoriteTool.fromJson(favoriteJson));
  }

}
