import React, { useContext, useState, useEffect } from "react";
import { BlocsContext } from "contexts/blocs_context";
import { BlocBuilder } from "@felangel/react-bloc";
import { WithTranslation, withTranslation } from "react-i18next";
import Article from "models/article";
import { FavoritesToolsLoaded, FavoritesToolsState } from "blocs/favorites_tools_bloc/favorites_tools_state";
import FavoriteTool from "models/favorite_tool";
import { FavoritesToolsAdd, FavoritesToolsRemove } from "blocs/favorites_tools_bloc/favorites_tools_event";
import moment from "moment";
import GeneralHelper from "helpers/general_helper";
import "./add_to_favorite_button.scss";


interface Props extends WithTranslation {
  article: Article;
  revCode: string | undefined;
}

const AddToFavoriteButton: React.FC<Props> = ({ t, article, revCode }) => {
  const blocsContext = useContext(BlocsContext);


  const [favorites, setFavorites] = useState<Array<FavoriteTool>>([]);

  useEffect(() => {
    const subscription = blocsContext.favoritesToolsBloc.listen((data) => {
      if (data instanceof FavoritesToolsLoaded) {
        const favoriteTools: Array<FavoriteTool> = data.favorites;
        setFavorites(favoriteTools);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [blocsContext.favoritesToolsBloc]);



  var favoriteReference: FavoriteTool = null as any;
  favorites.forEach(
    (cFavoriteTool: FavoriteTool) => {
      if (cFavoriteTool.articleCode === article.code && cFavoriteTool.revCode === revCode) {
        favoriteReference = cFavoriteTool;
      }
    }
  );

  return (
    <span className="add-to-favorite-btn-container">


      {favoriteReference && (
        <button
          type="button"
          onClick={(event) => {
            blocsContext.favoritesToolsBloc.add(
              new FavoritesToolsRemove(favoriteReference)
            );
            event.stopPropagation();
          }}
          className="add-to-favorite-btn"
        >
          <span className="icon icon-heart favorite"></span>
        </button>
      )}

      {!favoriteReference && (
        <button
          type="button"
          onClick={(event) => {
            blocsContext.favoritesToolsBloc.add(
              new FavoritesToolsAdd(
                new FavoriteTool({
                  articleId: article.id,
                  articleCode: article.code,
                  revCode: revCode,
                  createdAt: moment(),
                })
              )
            );
            GeneralHelper.successToast(
              t("screen.step_tool.addedToFavoriteMessage")
            );
            event.stopPropagation();
          }}
          className="add-to-favorite-btn"

        >
          <span className="icon icon-heart-o"></span>
        </button>
      )}

    </span>
  );
}


export default withTranslation("common")(AddToFavoriteButton);
