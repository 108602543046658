import config from "config/config";
import GeneralHelper from "helpers/general_helper";

interface NamedParameters {
	id: number;
	code: string;
	type: string;
	constructorName: string;
	coef: number;
	createdAt: Date;
	longDescription: Record<string, string>;
	shortDescription: Record<string, string>;
	NMax: number;
	image: string;
}

export default class Machine {
	// MACHINE_ID
	id: number;
	// MACHINE_CODE
	code: string;
	// MACHINE_TYPE
	type: string;

	// MACHINE_CONSTRUCTEUR
	constructorName: string;
	// MACHINE_COEF
	coef: number;
	// A_DATECREF
	createdAt: Date;

	// LONG_DESC_XX
	longDescription: Record<string, string>;
	// SHORT_DESC_XX
	shortDescription: Record<string, string>;

	// MACHINE_IMAGE
	image: string;

	NMax: number;

	constructor({ id, code, type, constructorName, coef, createdAt, longDescription, shortDescription, image, NMax = 0 }: NamedParameters) {
		this.id = id;
		this.code = code;
		this.type = type;
		this.constructorName = constructorName;
		this.coef = coef;
		this.createdAt = createdAt;
		this.longDescription = longDescription;
		this.shortDescription = shortDescription;
		this.NMax = NMax;
		this.image = image;
	}

	static areIdentical(a: Machine, b: Machine): boolean {
		if (!a && !b) return true;
		if (!a || !b) return false;
		return a.id === b.id;
	}

	static fromJson(map: Record<string, any>): Machine {
		return new Machine({
			id: GeneralHelper.parseIntOrNull(map["MACHINE_ID"]),
			code: map["MACHINE_CODE"],
			type: map["MACHINE_TYPE"],
			constructorName: map["MACHINE_CONSTRUCTEUR"],
			coef: GeneralHelper.parseDoubleOrNull(map["MACHINE_COEF"]),
			createdAt: new Date(map["A_DATECREF"]),
			image: GeneralHelper.avoidNullOrEmptyString(map["MACHINE_IMAGE"]),
			longDescription: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_IT"]),
			},
			shortDescription: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_IT"]),
			},
			NMax: 0,
		});
	}

	getLongDescription(lang: string): string {
		return this.longDescription[lang] != null && this.longDescription[lang] != ""
			? this.longDescription[lang]
			: this.longDescription[config["FALLBACK_LOCALE"]] ?? "";
	}

	getShortDescription(lang: string): string {
		return this.shortDescription[lang] != null && this.shortDescription[lang] != ""
			? this.shortDescription[lang]
			: this.shortDescription[config["FALLBACK_LOCALE"]] ?? "";
	}
}
