import React, { useState, useContext } from "react";
import "./reference_item.scss";
import Reference from "models/reference";
import { BlocsContext } from "contexts/blocs_context";
import { WithTranslation, withTranslation } from "react-i18next";
import Article from "models/article";
import AddToFavoriteButton from "components/add_to_favorite_button";
import AddToCartButton from "components/add_to_cart_button";
import CustomRevDropdown from "components/custom_rev_dropdown";
import Coating from "models/coating";
import { CoatingsLoaded } from "blocs/coatings_bloc/coatings_state";
import { ToolfinderSelectionsState, ToolfinderSelectionsUpdated } from "screens/toolfinder/blocs/toolfiinder_selections_bloc/toolfiinder_selections_state";

interface Props extends WithTranslation {
  article: Article;
  selectedArticle: Article | undefined;
  onCheckClick: Function;
  reference: Reference;

  selectedCoating: Coating | undefined;
  setSelectedCoating: Function;
}

const ToolItem: React.FC<Props> = ({ t, article, selectedArticle, onCheckClick, reference, selectedCoating, setSelectedCoating }) => {
  const blocsContext = useContext(BlocsContext);
  const toolfinderSelectionBloc = blocsContext.toolfinderSelectionBloc;
  // const [selectedCoating, setSelectedCoating] = useState<Coating | undefined>(
  //   toolfinderSelectionBloc.state instanceof ToolfinderSelectionsState && toolfinderSelectionBloc.toolfinderSelection.coating != undefined
  //     ? toolfinderSelectionBloc.toolfinderSelection.coating
  //     : (blocsContext.coatingsBloc.state as CoatingsLoaded).coatings.find((coating) => coating.code == "AA")
  // );

  return (
    <tr key={article.code} className={"tool-" + article.id}>
      <td>
        <input className="checkbox" checked={selectedArticle != null && selectedArticle.id == article.id} type="checkbox" onChange={(event) => onCheckClick(event, article)} />
      </td>
      <td>{article.dimD1}</td>
      <td>{article.dimL1}</td>
      <td>{article.dimD}</td>
      <td>{article.dimL}</td>
      <td>
        <CustomRevDropdown
          coatings={blocsContext.coatingsBloc.coatings}
          onSelectCoating={(coating: Coating) => {
            setSelectedCoating(coating);
          }}
          selectedCoating={selectedCoating}
          reference={reference}
          article={article}
        />
      </td>

      <td>
        <AddToCartButton article={article} revCode={selectedCoating?.code} />
        <AddToFavoriteButton article={article} revCode={selectedCoating != undefined ? selectedCoating.code : undefined} />
      </td>
    </tr>
  );
};

export default withTranslation("common")(ToolItem);
