import GeneralHelper from "helpers/general_helper";

export default class AssRevMat {
	// REV_MATUSI_SGRP_ID
	id: number;
	// MATUSI_SGRP_ID
	matId: number;
	// REV_CODE
	revCode: string;
	// REV_MATUSI_SGRP_COEF
	coef: number;
	// MATUSI_GRP_ID
	matGrpId: number;

	constructor({ id, matId, revCode, coef, matGrpId }: any /*NamedParameters*/) {
		this.id = id;
		this.matId = matId;
		this.revCode = revCode;
		this.coef = coef;
		this.matGrpId = matGrpId;
	}

	static areIdentical(a: AssRevMat, b: AssRevMat): boolean {
		if (!a && !b) return true;
		if (!a || !b) return false;
		return a.id === b.id;
	}

	// setAffinity({ material }: { material: Material }) {
	// 	if (material && this.indicesMaterial[material.id]) {
	// 		this.affinity = this.indicesMaterial[material.id];
	// 		return;
	// 	}

	// 	this.affinity = 0;
	// }

	static fromJson(map: Record<string, any>): AssRevMat {
		return new AssRevMat({
			id: GeneralHelper.parseIntOrNull(map["REV_MATUSI_SGRP_ID"]),
			matId: GeneralHelper.parseIntOrNull(map["MATUSI_SGRP_ID"]),
			revCode: map["REV_CODE"],
			coef: map["REV_MATUSI_SGRP_COEF"],
			matGrpId: GeneralHelper.parseIntOrNull(map["MATUSI_GRP_ID"]),
		});
	}
}
