import AuthenticationData from "../../models/authentication_data";

export abstract class AuthenticationState {}

export class AuthenticationInitial extends AuthenticationState {
    public toString = (): string => `AuthenticationInitial`;
}

export class AuthenticationUnAuthenticated extends AuthenticationState {
    public toString = (): string => `AuthenticationUnAuthenticated`;
}

export class AuthenticationAuthenticated extends AuthenticationState {
    authenticationData: AuthenticationData;
    fromRegistration: boolean;

    constructor(authenticationData: AuthenticationData, fromRegistration: boolean = false) {
        super();
        this.authenticationData = authenticationData;
        this.fromRegistration = fromRegistration;
    }

    public toString = (): string => `AuthenticationAuthenticated`;
}