import GeneralHelper from "helpers/general_helper";

// interface NamedParameters {
// 	id: number;
// 	code: string;
// 	refId: number;
// 	status: boolean;
// 	matGrpId: number;
// 	dimD: number;
// 	dimL: number;
// 	dimD1: number;
// 	dimL1: number;
// 	dimD2Collet1: number;
// 	dimL2Collet1: number;
// 	dimD3Collet2: number;
// 	dimL3Collet3: number;
// 	dimD2Ales: number;
// 	dimR: number;
// 	dimA: number;
// 	dimE: number;
// 	dimZ: number;
// 	senseCoupe: string;
// 	longDescription: Record<string, string>;
// 	shortDescription: Record<string, string>;
// }

export default class Article {
	// GCO_GOOD_ID
	id: number;
	// GOO_MAJOR_REFERENCE
	code: string;
	// GCO_GOOD_CATEGORY_ID
	refId: number;
	// C_GOOD_STATUS
	status: boolean;

	// AR_MAGRP_ID
	matGrpId: number;

	// AR_DIM_D
	dimD: number;
	// AR_DIM_L
	dimL: number;
	// AR_DIM_D1
	dimD1: number;
	// AR_DIM_L1
	dimL1: number;
	// AR_DIM_D2_Collet1
	dimD2Collet1: number;
	// AR_DIM_L2_Collet1
	dimL2Collet1: number;
	// AR_DIM_D3_Collet2
	dimD3Collet2: number;
	// AR_DIM_L3_Collet3
	dimL3Collet3: number;
	// AR_DIM_D2_Ales
	dimD2Ales: number;
	// AR_DIM_R
	dimR: number;
	// AR_DIM_A
	dimA: number;
	// AR_DIM_E
	dimE: number;
	// AR_DIM_Z
	dimZ: number;
	// AR_SENSCOUPE
	senseCoupe: string;

	// LONG_DESC_XX
	longDescription: Record<string, string>;
	// SHORT_DESC_XX
	shortDescription: Record<string, string>;

	constructor(
		{
			id,
			code,
			refId,
			status,
			matGrpId,
			dimD,
			dimL,
			dimD1,
			dimL1,
			dimD2Collet1,
			dimL2Collet1,
			dimD3Collet2,
			dimL3Collet3,
			dimD2Ales,
			dimR,
			dimA,
			dimE,
			dimZ,
			senseCoupe,
			longDescription,
			shortDescription,
		}: any /*NamedParameters*/
	) {
		this.id = id;
		this.code = code;
		this.refId = refId;
		this.status = status;
		this.matGrpId = matGrpId;
		this.dimD = dimD;
		this.dimL = dimL;
		this.dimD1 = dimD1;
		this.dimL1 = dimL1;
		this.dimD2Collet1 = dimD2Collet1;
		this.dimL2Collet1 = dimL2Collet1;
		this.dimD3Collet2 = dimD3Collet2;
		this.dimL3Collet3 = dimL3Collet3;
		this.dimD2Ales = dimD2Ales;
		this.dimR = dimR;
		this.dimA = dimA;
		this.dimE = dimE;
		this.dimZ = dimZ;
		this.senseCoupe = senseCoupe;
		this.longDescription = longDescription;
		this.shortDescription = shortDescription;
	}

	static areIdentical(a: Article, b: Article): boolean {
		if (!a && !b) return true;
		if (!a || !b) return false;
		return a.id === b.id;
	}

	// setAffinity({ material }: { material: Material }) {
	// 	if (material && this.indicesMaterial[material.id]) {
	// 		this.affinity = this.indicesMaterial[material.id];
	// 		return;
	// 	}

	// 	this.affinity = 0;
	// }

	static fromJson(map: Record<string, any>): Article {
		return new Article({
			id: GeneralHelper.parseIntOrNull(map["GCO_GOOD_ID"]),
			code: map["GOO_MAJOR_REFERENCE"],
			refId: GeneralHelper.parseIntOrNull(map["GCO_GOOD_CATEGORY_ID"]),
			status: map["C_GOOD_STATUS"] == "ACTIF" ? true : false,
			matGrpId: GeneralHelper.parseIntOrNull(map["AR_MAGRP_ID"]),
			dimD: GeneralHelper.parseDoubleOrNull(map["AR_DIM_D"]),
			dimL: GeneralHelper.parseDoubleOrNull(map["AR_DIM_L"]),
			dimD1: GeneralHelper.parseDoubleOrNull(map["AR_DIM_D1"]),
			dimL1: GeneralHelper.parseDoubleOrNull(map["AR_DIM_L1"]),
			dimD2Collet1: GeneralHelper.parseDoubleOrNull(map["AR_DIM_D2_Collet1"]),
			dimL2Collet1: GeneralHelper.parseDoubleOrNull(map["AR_DIM_L2_Collet1"]),
			dimD3Collet2: GeneralHelper.parseDoubleOrNull(map["AR_DIM_D3_Collet2"]),
			dimL3Collet3: GeneralHelper.parseDoubleOrNull(map["AR_DIM_L3_Collet3"]),
			dimD2Ales: GeneralHelper.parseDoubleOrNull(map["AR_DIM_D2_Ales"]),
			dimR: GeneralHelper.parseDoubleOrNull(map["AR_DIM_R"]),
			dimA: GeneralHelper.parseDoubleOrNull(map["AR_DIM_A"]),
			dimE: GeneralHelper.parseDoubleOrNull(map["AR_DIM_E"]),
			dimZ: GeneralHelper.parseDoubleOrNull(map["AR_DIM_Z"]),
			senseCoupe: map["AR_SENSCOUPE"],
			longDescription: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_IT"]),
			},
			shortDescription: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_IT"]),
			},
		});
	}

	toJson() {
		return {
			GCO_GOOD_ID: this.id,
			GOO_MAJOR_REFERENCE: this.code,
			GCO_GOOD_CATEGORY_ID: this.refId,
			C_GOOD_STATUS: this.status,
			AR_MAGRP_ID: this.matGrpId,
			AR_DIM_D: this.dimD,
			AR_DIM_L: this.dimL,
			AR_DIM_D1: this.dimD1,
			AR_DIM_L1: this.dimL1,
			AR_DIM_D2_Collet1: this.dimD2Collet1,
			AR_DIM_L2_Collet1: this.dimL2Collet1,
			AR_DIM_D3_Collet2: this.dimD3Collet2,
			AR_DIM_L3_Collet3: this.dimL3Collet3,
			AR_DIM_D2_Ales: this.dimD2Ales,
			AR_DIM_R: this.dimR,
			AR_DIM_A: this.dimA,
			AR_DIM_E: this.dimE,
			AR_DIM_Z: this.dimZ,
			AR_SENSCOUPE: this.senseCoupe,
			longDescription: {
				LONG_DESC_FR: this.longDescription.fr,
				LONG_DESC_DE: this.longDescription.de,
				LONG_DESC_EN: this.longDescription.en,
				LONG_DESC_IT: this.longDescription.it,
			},
			shortDescription: {
				LONG_DESC_FR: this.shortDescription.fr,
				LONG_DESC_DE: this.shortDescription.de,
				LONG_DESC_EN: this.shortDescription.en,
				LONG_DESC_IT: this.shortDescription.it,
			},
		};
	}

	public copyWith({
		id,
		code,
		refId,
		status,
		matGrpId,
		dimD,
		dimL,
		dimD1,
		dimL1,
		dimD2Collet1,
		dimL2Collet1,
		dimD3Collet2,
		dimL3Collet3,
		dimD2Ales,
		dimR,
		dimA,
		dimE,
		dimZ,
		senseCoupe,
		longDescription,
		shortDescription,
	}: any): Article {
		return new Article({
			id: id ?? this.id,
			code: code ?? this.code,
			refId: refId ?? this.refId,
			status: status ?? this.status,
			matGrpId: matGrpId ?? this.matGrpId,
			dimD: dimD ?? this.dimD,
			dimL: dimL ?? this.dimL,
			dimD1: dimD1 ?? this.dimD1,
			dimL1: dimL1 ?? this.dimL1,
			dimD2Collet1: dimD2Collet1 ?? this.dimD2Collet1,
			dimL2Collet1: dimL2Collet1 ?? this.dimL2Collet1,
			dimD3Collet2: dimD3Collet2 ?? this.dimD3Collet2,
			dimL3Collet3: dimL3Collet3 ?? this.dimL3Collet3,
			dimD2Ales: dimD2Ales ?? this.dimD2Ales,
			dimR: dimR ?? this.dimR,
			dimA: dimA ?? this.dimA,
			dimE: dimE ?? this.dimE,
			dimZ: dimZ ?? this.dimZ,
			senseCoupe: senseCoupe ?? this.senseCoupe,
			longDescription: longDescription ?? this.longDescription,
			shortDescription: shortDescription ?? this.shortDescription,
		});
	}
}
