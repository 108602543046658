import React, { useState, useContext, useEffect, useRef, MouseEventHandler } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import "./custom_rev_dropdown.scss";
import Coating from "models/coating";
import checkIcon from "assets/icons/check.svg";
import Article from "models/article";
import Reference from "models/reference";
import { BlocsContext } from "contexts/blocs_context";
import { ToolfinderSelectionsUpdated } from "screens/toolfinder/blocs/toolfiinder_selections_bloc/toolfiinder_selections_state";
import ShoppingCartIcon from "assets/icons/shopping-cart.svg";
import { BlocBuilder } from "@felangel/react-bloc";
import { CartState, CartUpdated } from "blocs/cart_bloc/cart_state";
import CartArticle from "models/cart_article";

interface Props extends WithTranslation {
  coatings: Array<Coating>;
  selectedCoating: Coating | undefined;
  onSelectCoating: Function;
  reference: Reference;
  article: Article;
}

const CustomRevDropdown: React.FC<Props> = ({ t, coatings, selectedCoating, onSelectCoating, reference, article }) => {
  const blocsContext = useContext(BlocsContext);

  const [open, setOpen] = useState(false);
  // const [selected, setSelected] = useState<Coating|undefined>(undefined);

  let btnRef = useRef<HTMLButtonElement>(null);
  let menuRef = useRef<HTMLDivElement>(null);

  const onMouseDownhandler = (e: any) => {
    if (!menuRef?.current?.contains(e.target) && !btnRef?.current?.contains(e.target)) {
      setOpen(false);
    }
  };
  const onWheelHandler = (e: any) => {
    if (!menuRef?.current?.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onMouseDownhandler);
    document.addEventListener("wheel", onWheelHandler);

    return () => {
      document.removeEventListener("mousedown", onMouseDownhandler);
      document.removeEventListener("wheel", onWheelHandler);
    };
  });

  useEffect(() => {
    if (open) {
      var btnRefPosition = btnRef.current?.getBoundingClientRect();
      var wrapperTop = document.getElementsByClassName("articles-box")[0].getBoundingClientRect().top;
      var btnHeight = btnRef.current?.clientHeight ?? 0;
      var popupPosition = (btnRefPosition?.top ?? 0) - wrapperTop + btnHeight;

      menuRef.current?.style.setProperty("top", `${popupPosition}px`);
      menuRef.current?.style.setProperty("display", `flex`);
      menuRef.current?.style.setProperty("width", `${btnRef.current?.clientWidth}px`);
    } else {
      menuRef.current?.style.setProperty("display", `none`);
    }
  }, [open]);

  const toggleList = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
  };

  const selectItem = (item: any) => {
    onSelectCoating(item);
    setOpen(false);
  };

  const getAffinity = (coating: Coating | undefined) => {
    const coatingAffinity = coating?.getAffinityWithRef(reference, blocsContext.toolfinderSelectionBloc.toolfinderSelection.material, blocsContext.assRevMatBloc);
    const referenceAffinity = reference.affinity;
    if (referenceAffinity == undefined) return "n/a";
    if (coatingAffinity == undefined) return referenceAffinity;
    var affinity: number = (coatingAffinity + referenceAffinity) / 2;
    return Number(affinity.toFixed(2));
  };

  const buildCoatingItem = (coating: Coating | undefined) => {
    return (
      <button type="button" className={["dd-list-item", selectedCoating?.code == coating?.code ? "selected" : ""].join(" ")} key={coating?.code} onClick={() => selectItem(coating)}>
        {getAffinity(coating)}
        {" - "}
        {coating?.name ?? t("components.custom_rev_dropdown.whitout_coating")}
        <BlocBuilder
          bloc={blocsContext.cartBloc}
          builder={(state: CartState) => {
            // return <img src={ShoppingCartIcon} className="cart-icon"/>;
            // return <ShoppingCartIcon />;
            if (state instanceof CartUpdated && state.cart.articles.find((cartArticle: CartArticle) => cartArticle.article.id == article.id && cartArticle.rev == coating?.code) != undefined) {
              return (
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 450.391 450.391"
                  enableBackground={"new 0 0 450.391 450.391"}
                  xmlSpace="preserve"
                  className="cart-icon"
                >
                  <g>
                    <g>
                      <g>
                        <path
                          d="M143.673,350.322c-25.969,0-47.02,21.052-47.02,47.02c0,25.969,21.052,47.02,47.02,47.02
          c25.969,0,47.02-21.052,47.02-47.02C190.694,371.374,169.642,350.322,143.673,350.322z M143.673,423.465
          c-14.427,0-26.122-11.695-26.122-26.122c0-14.427,11.695-26.122,26.122-26.122c14.427,0,26.122,11.695,26.122,26.122
          C169.796,411.77,158.1,423.465,143.673,423.465z"
                        />
                        <path
                          d="M342.204,350.322c-25.969,0-47.02,21.052-47.02,47.02c0,25.969,21.052,47.02,47.02,47.02s47.02-21.052,47.02-47.02
          C389.224,371.374,368.173,350.322,342.204,350.322z M342.204,423.465c-14.427,0-26.122-11.695-26.122-26.122
          c0-14.427,11.695-26.122,26.122-26.122s26.122,11.695,26.122,26.122C368.327,411.77,356.631,423.465,342.204,423.465z"
                        />
                        <path
                          d="M448.261,76.037c-2.176-2.377-5.153-3.865-8.359-4.18L99.788,67.155L90.384,38.42
          C83.759,19.211,65.771,6.243,45.453,6.028H10.449C4.678,6.028,0,10.706,0,16.477s4.678,10.449,10.449,10.449h35.004
          c11.361,0.251,21.365,7.546,25.078,18.286l66.351,200.098l-5.224,12.016c-5.827,15.026-4.077,31.938,4.702,45.453
          c8.695,13.274,23.323,21.466,39.184,21.943h203.233c5.771,0,10.449-4.678,10.449-10.449c0-5.771-4.678-10.449-10.449-10.449
          H175.543c-8.957-0.224-17.202-4.936-21.943-12.539c-4.688-7.51-5.651-16.762-2.612-25.078l4.18-9.404l219.951-22.988
          c24.16-2.661,44.034-20.233,49.633-43.886l25.078-105.012C450.96,81.893,450.36,78.492,448.261,76.037z M404.376,185.228
          c-3.392,15.226-16.319,26.457-31.869,27.69l-217.339,22.465L106.58,88.053l320.261,4.702L404.376,185.228z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              );
            }
            return <></>;
          }}
        />
        {/* {selectedCoating?.code == item.code && <img src={ShoppingCartIcon} />} */}
      </button>
    );
  };

  return (
    <div className="custom-dropdown dd-wrapper">
      <button type="button" className="dd-header" onClick={toggleList} ref={btnRef}>
        <div className={["dd-header-title", selectedCoating == undefined ? "unselected" : ""].join(" ")}>{selectedCoating?.name ?? t("components.custom_rev_dropdown.whitout_coating")}</div>
        {/* {open
          ? <FontAwesome name="angle-up" size="2x" />
          : <FontAwesome name="angle-down" size="2x" />} */}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="open-menu-icon">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
      </button>
      <div role="list" className="dd-list" ref={menuRef}>
        {open && (
          <>
            {/* <button type="button" className={["dd-list-item", selectedCoating?.code == undefined ? "selected" : ""].join(" ")} onClick={() => selectItem(undefined)}>
            {t("components.custom_rev_dropdown.no_coating")}
          </button> */}

            {/* {selectedCoating != undefined && buildCoatingItem(undefined)} */}
            <div className="dd-section-title">{t("components.custom_rev_dropdown.choosen_coating")}</div>
            {buildCoatingItem(coatings.find((coating: Coating) => coating.code == selectedCoating?.code) ?? undefined)}

            <div className="dd-section-title">{t("components.custom_rev_dropdown.availible_coating_by_affinity")}</div>
            {coatings
              .filter((coating: Coating) => coating.code != selectedCoating?.code)
              .sort((a: Coating, b: Coating) => b.affinity - a.affinity)
              .map((coating: Coating) => buildCoatingItem(coating))}
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation("common")(CustomRevDropdown);
