import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import config from "../config/config";
import Cart from "../models/cart";

export default class CartService {

	static storageSaveCart(cart: Cart) {
		localStorage.setItem("cart", JSON.stringify(cart.toJson()));
	}

	static storageGetLocalCart(withCheck: boolean = false): Cart {
		const cartJson: string = localStorage.getItem("cart") as string;
		if (cartJson == null || cartJson == undefined) {
			return new Cart({ articles: [] });
		}
		var cart: Cart = Cart.fromJson(JSON.parse(cartJson));

		// if (withCheck == true) cart.checkValidity();

		return cart;
		// return ;
	}


	static async getSkylightCart(accessToken: string) {
    var cartResponse: AxiosResponse = await axios.get(
      config.SKYLIGHT_API + "/getBasket", 
      {
        headers: {
          Accept: "application/json",
        },
        params: { 
          token: accessToken 
        }
      } as AxiosRequestConfig
    );
    return cartResponse;
	}

  static async addProductToSkylightCart(accessToken: string, goodid: number, qty: number, rev: string|undefined) {
    var cartResponse: AxiosResponse = await axios.post(
      config.SKYLIGHT_API + "/insertBasket", 
      {
        goodid: goodid,
        qte: qty,
        rev: rev ?? "",
        prep: "",
        fini: "",
      },
      {
        headers: {
          Accept: "application/json",
        },
        params: { 
          token: accessToken 
        },
      } as AxiosRequestConfig
    );
    return cartResponse;
	}


  static async removeProductToSkylightCart(accessToken: string, goodid: number, rev: string|undefined) {
    var cartResponse: AxiosResponse = await axios.delete(
      config.SKYLIGHT_API + "/deleteBasket", 
      {
        headers: {
          Accept: "application/json",
        },
        params: { 
          token: accessToken 
        },
        data: {
          goodid: goodid,
          rev: rev,
          prep: "",
          fini: "",
        }
      } as AxiosRequestConfig
    );
    return cartResponse;
	}




}
