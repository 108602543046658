import { Bloc, Transition } from "@felangel/bloc";
import Material from "models/material";
import MaterialRepository from "repositories/material_repository";
import { MaterialsEvent, MaterialsLoad } from "./materials_event";
import { MaterialsState, MaterialsLoaded, MaterialsLoading, MaterialsInitial, MaterialsFailure } from "./materials_state";


export default class MaterialsBloc extends Bloc<MaterialsEvent, MaterialsState> {
  materialRepository: MaterialRepository;
  materials: Array<Material> = [];

  constructor(materialRepository: MaterialRepository) {
    super(new MaterialsInitial());
    this.materialRepository = materialRepository;
  }

  async *mapEventToState(event: MaterialsEvent) {
    if (event instanceof MaterialsLoad) {
      yield* this.mapLoadMaterialsToState(event);
    }
  }

  async *mapLoadMaterialsToState(event: MaterialsLoad) {
    try {
      yield new MaterialsLoading();
      var materialsList: Array<Material> = await this.materialRepository.getData();
      this.materials = materialsList;

      yield new MaterialsLoaded(this.materials);
    } catch (e) {
      if (typeof e === "string") {
        yield new MaterialsFailure(e);
      } else {
        // yield new MaterialsFailure(e.toString());
        yield new MaterialsFailure("Unknow error");
      }
    }
  }

  // Called whenever an `event` is added.
  onEvent(event: MaterialsEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
  }

  // Called whenever a state change is about to occur.
  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
  }
}
