import config from "config/config";
import GeneralHelper from "helpers/general_helper";
import moment, { Moment } from "moment";

export default class Message {


  // tcsmc_id : 25,
  id: number;
  // tcsmc_enabled : true,
  enabled: boolean;
  // type : "IT",
  type: string;
  // tcsmc_titre : "test web",
  titre: string;
  // creation_date : "2024-02-15T09:09:53.767Z",
  creationDate: Moment;
  // tcsmc_text_fr : "text fr",
  // tcsmc_text_de : "text de",
  // tcsmc_text_it : "text it",
  // tcsmc_text_en : "text en",
  text: Record<string, string>;

  // tcsmc_internal_rem : "test",
  internalRem: string;
  // tcsmc_display_from : "2024-02-15T00:00:00.000Z",
  displayFrom: Moment;
  // tcsmc_display_to : "2024-02-16T00:00:00.000Z"
  displayTo: Moment;

  constructor({ id, enabled, type, titre, creationDate, text, internalRem, displayFrom, displayTo }: any) {
    this.id = id;
    this.enabled = enabled;
    this.type = type;
    this.titre = titre;
    this.creationDate = creationDate;
    this.text = text;
    this.internalRem = internalRem;
    this.displayFrom = displayFrom;
    this.displayTo = displayTo;
  }



  static areIdentical(a: Message, b: Message): boolean {
    if (!a && !b) return true;
    if (!a || !b) return false;
    return a.id === b.id;
  }

  static fromJson(map: Record<string, any>): Message {
    return new Message({
      id: GeneralHelper.parseIntOrNull(map["tcsmc_id"]),
      enabled: map["tcsmc_enabled"],
      type: map["type"],
      titre: map["tcsmc_titre"],
      creationDate: moment(map["creation_date"]),
      text: {
        fr: GeneralHelper.avoidNullOrEmptyString(map["tcsmc_text_fr"]),
        de: GeneralHelper.avoidNullOrEmptyString(map["tcsmc_text_de"]),
        en: GeneralHelper.avoidNullOrEmptyString(map["tcsmc_text_en"]),
        it: GeneralHelper.avoidNullOrEmptyString(map["tcsmc_text_it"]),
      },
      internalRem: GeneralHelper.avoidNullOrEmptyString(map["tcsmc_internal_rem"]),
      displayFrom: moment(map["tcsmc_display_from"]),
      displayTo: moment(map["tcsmc_display_to"]),
    });
  }

  toJson() {
    return {
      tcsmc_id: this.id,
      tcsmc_enabled: this.enabled,
      type: this.type,
      tcsmc_titre: this.titre,
      creation_date: this.creationDate,
      tcsmc_text_fr: this.text.fr,
      tcsmc_text_de: this.text.de,
      tcsmc_text_it: this.text.it,
      tcsmc_text_en: this.text.en,
      tcsmc_internal_rem: this.internalRem,
      tcsmc_display_from: this.displayFrom,
      tcsmc_display_to: this.displayTo,
    };
  }

  getText(lang: string): string {
    return this.text[lang] != null && this.text[lang] != "" ? this.text[lang] : (this.text[config['FALLBACK_LOCALE']] ?? "");
  }


}
