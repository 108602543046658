import React from "react";
import "./coating_item.scss";
import { BlocsContext } from "contexts/blocs_context";
import { WithTranslation, withTranslation } from "react-i18next";

import Coating from "models/coating";
import GeneralHelper from "helpers/general_helper";
import CalculHelper from "helpers/calcul_helper";
import { ToolfinderSelectionsError, ToolfinderSelectionsUpdated } from "screens/toolfinder/blocs/toolfiinder_selections_bloc/toolfiinder_selections_state";

interface Props extends WithTranslation {
  selected: boolean;
  coating: Coating;
  onClick: Function;
}

type State = {
  isFavorite: boolean;
};

class CoatingItem extends React.Component<Props, State> {
  static contextType = BlocsContext;
  context!: React.ContextType<typeof BlocsContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      isFavorite: false,
    };
  }

  render() {
    return (
      <button onClick={(e) => this.props.onClick()} className={"coating-item d-flex justify-content-between align-items-center " + (this.props.selected === true ? "active" : "")}>
        <div className="d-flex flex-column justify-content-start align-items-start">
          <div className="d-flex justify-content-start align-items-center">{this.props.coating.publicName[this.props.i18n.language]}</div>

          <div className="ref">{this.props.coating.code}</div>
          {this.props.coating.dCibleMin != undefined && this.props.coating.dCibleMax && (
            <div className="diameter">
              ø {this.props.coating.dCibleMin} - {this.props.coating.dCibleMax}
            </div>
          )}
          <div>              {CalculHelper.isCoatingCompatibleWithArticle(this.props.coating, (this.context.toolfinderSelectionBloc.state as ToolfinderSelectionsUpdated).toolfinderSelection.article) ? "Compatible" : "-" }
</div>
          {/* <span className="icon icon-heart-o favorite"></span> */}
        </div>

        <div className="d-flex flex-1 justify-content-cener align-items-end">
          <div className="d-flex flex-column justify-content-cener align-items-center">
            <div className="affinity">{this.props.coating.affinity != undefined ? Number(this.props.coating.affinity.toFixed(2)) : "n/a"}</div>
            <div className="affinity-text">{this.props.t("screen.step_coating.afinity_score")}</div>
          </div>
        </div>
      </button>
    );
  }
}

export default withTranslation("common")(CoatingItem);
