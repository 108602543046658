// import Coating from "models/coating";
// import Tool from "models/tool";

// interface NamedParameters {
// 	id: number;
// 	name: string;
// 	indice: number;
// 	RM: number;
// 	affinity?: number;
// }

// export default class Material implements NamedParameters {
// 	id: number;
// 	name: string;
// 	indice: number;
// 	RM: number;
// 	affinity: number;

// 	constructor({ id, name, indice, RM, affinity }: NamedParameters) {
// 		this.id = id;
// 		this.name = name;
// 		this.indice = indice;
// 		this.RM = RM;
// 		this.affinity = affinity ?? 0;
// 	}

// 	static areIdentical(a: Material, b: Material): boolean {
// 		if (!a && !b) return true;
// 		if (!a || !b) return false;
// 		return a.id === b.id;
// 	}

// 	setAffinity({ coating, tool }: { coating: Coating; tool: Tool }) {
// 		var affinities: Array<number> = [];

// 		if (coating && coating.indicesMaterial[this.id]) {
// 			// coatingAffinity = 1;

// 			affinities.push(coating.indicesMaterial[this.id]);
// 		}

// 		if (tool && tool.indicesMaterial[this.id]) {
// 			// coatingAffinity = 1;
// 			affinities.push(tool.indicesMaterial[this.id]);
// 		}
// 		if (affinities.length === 0) {
// 			this.affinity = 0;
// 			return;
// 		}
// 		this.affinity = affinities.reduce((a, b) => a + b) ?? 0 / affinities.length;
// 	}
// }

import config from "config/config";
import GeneralHelper from "helpers/general_helper";

// interface NamedParameters {
// 	id: number;
// 	code: string;
// 	type: string;
// 	constructorName: string;
// 	coef: number;
// 	createdAt: Date;
// 	longDescription: Record<string, string>;
// 	shortDescription: Record<string, string>;
// 	NMax: number;
// }

export default class Material {
	// MATUSI_SGRP_ID
	id: number;
	// MATUSI_GRP_ID
	groupId: number;

	// MATUSI_SGRP_CODE
	code: string;
	// MATUSI_SGRP_COEF
	coef: number;

	// MATUSI_SGRP_RM_MIN
	rmMin: number;
	// MATUSI_SGRP_RM_MAX
	rmMax: number;

	// DURETER_XX
	durete: Record<string, string>;
	// LONG_DESC_XX
	longDescription: Record<string, string>;
	// SHORT_DESC_XX
	shortDescription: Record<string, string>;

	affinity: number;

	constructor({ id, groupId, code, coef, rmMin, rmMax, durete, longDescription, shortDescription, affinity }: any) {
		this.id = id;
		this.groupId = groupId;
		this.code = code;
		this.coef = coef;
		this.rmMin = rmMin;
		this.rmMax = rmMax;
		this.durete = durete;
		this.longDescription = longDescription;
		this.shortDescription = shortDescription;
		this.affinity = affinity;
	}

	static areIdentical(a: Material, b: Material): boolean {
		if (!a && !b) return true;
		if (!a || !b) return false;
		return a.id === b.id;
	}

	setAffinity(affinity: number) {
		this.affinity = affinity;
	}

	static fromJson(map: Record<string, any>): Material {
		return new Material({
			id: GeneralHelper.parseIntOrNull(map["MATUSI_SGRP_ID"]),
			groupId: GeneralHelper.parseIntOrNull(map["MATUSI_GRP_ID"]),
			code: map["MATUSI_SGRP_CODE"],
			coef: GeneralHelper.parseDoubleOrNull(map["MATUSI_SGRP_COEF"]),
			rmMin: GeneralHelper.parseDoubleOrNull(map["MATUSI_SGRP_RM_MIN"]),
			rmMax: GeneralHelper.parseDoubleOrNull(map["MATUSI_SGRP_RM_MAX"]),
			durete: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["DURETER_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["DURETER_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["DURETER_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["DURETER_IT"]),
			},
			longDescription: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["LONG_DESC_IT"]),
			},
			shortDescription: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["SHORT_DESC_IT"]),
			},
			// affinity: map[''],
		});
	}

	toJson() {
		return {
			MATUSI_SGRP_ID: this.id,
			MATUSI_GRP_ID: this.groupId,
			MATUSI_SGRP_CODE: this.code,
			MATUSI_SGRP_COEF: this.coef,
			MATUSI_SGRP_RM_MIN: this.rmMin,
			MATUSI_SGRP_RM_MAX: this.rmMax,
			durete: {
				DURETER_FR: this.durete.fr,
				DURETER_DE: this.durete.de,
				DURETER_EN: this.durete.en,
				DURETER_IT: this.durete.it,
			},
			longDescription: {
				LONG_DESC_FR: this.durete.fr,
				LONG_DESC_DE: this.durete.de,
				LONG_DESC_EN: this.durete.en,
				LONG_DESC_IT: this.durete.it,
			},
			shortDescription: {
				SHORT_DESC_FR: this.durete.fr,
				SHORT_DESC_DE: this.durete.de,
				SHORT_DESC_EN: this.durete.en,
				SHORT_DESC_IT: this.durete.it,
			},
		};
	}

  getLongDescription(lang: string) : string {
    return this.longDescription[lang] != null && this.longDescription[lang] != "" ? this.longDescription[lang] : (this.longDescription[config['FALLBACK_LOCALE']] ?? "");
  }

  getShortDescription(lang: string) : string {
    return this.shortDescription[lang] != null && this.shortDescription[lang] != "" ? this.shortDescription[lang] : (this.shortDescription[config['FALLBACK_LOCALE']] ?? "");
  }
}
