import { Bloc, Transition } from "@felangel/bloc";
import Article from "models/article";
import ArticleRepository from "repositories/article_repository";
import { ArticlesEvent, ArticlesLoad } from "./articles_event";
import { ArticlesState, ArticlesLoaded, ArticlesLoading, ArticlesInitial, ArticlesFailure } from "./articles_state";


export default class ArticlesBloc extends Bloc<ArticlesEvent, ArticlesState> {
	articleRepository: ArticleRepository;
	articles: Array<Article> = [];

	constructor(articleRepository: ArticleRepository) {
		super(new ArticlesInitial());
		this.articleRepository = articleRepository;
	}

	async *mapEventToState(event: ArticlesEvent) {
		if (event instanceof ArticlesLoad) {
			yield* this.mapLoadArticlesToState(event);
		}
	}

	async *mapLoadArticlesToState(event: ArticlesLoad) {
		try {
			yield new ArticlesLoading();
			var articlesList: Array<Article> = await this.articleRepository.getData();
			this.articles = articlesList;

			yield new ArticlesLoaded(this.articles);
		} catch (e) {
			if (typeof e === "string") {
				yield new ArticlesFailure(e);
			} else {
				// yield new ArticlesFailure(e.toString());
				yield new ArticlesFailure("Unknow error");
			}
		}
	}

	// Called whenever an `event` is added.
	onEvent(event: ArticlesEvent): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
	}

	// Called whenever a state change is about to occur.
	onTransition(transition: Transition<any, any>): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
	}

	onError(error: any): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
	}
}
