import { Bloc, Transition } from "@felangel/bloc";
import AssRefMat from "models/ass_ref_mat";
import AssRefMatRepository from "repositories/ass_ref_mat_repository";
import { AssRefMatsEvent, AssRefMatsLoad } from "./ass_ref_mat_event";
import { AssRefMatsState, AssRefMatsLoaded, AssRefMatsLoading, AssRefMatsInitial, AssRefMatsFailure } from "./ass_ref_mat_state";


export default class AssRefMatsBloc extends Bloc<AssRefMatsEvent, AssRefMatsState> {
  assRefMatRepository: AssRefMatRepository;
  assRefMats: Array<AssRefMat> = [];

  constructor(assRefMatRepository: AssRefMatRepository) {
    super(new AssRefMatsInitial());
    this.assRefMatRepository = assRefMatRepository;
  }

  async *mapEventToState(event: AssRefMatsEvent) {
    if (event instanceof AssRefMatsLoad) {
      yield* this.mapLoadAssRefMatsToState(event);
    }
  }

  async *mapLoadAssRefMatsToState(event: AssRefMatsLoad) {
    try {
      yield new AssRefMatsLoading();
      var assRefMatsList: Array<AssRefMat> = await this.assRefMatRepository.getData();
      this.assRefMats = assRefMatsList;

      yield new AssRefMatsLoaded(this.assRefMats);
    } catch (e) {
      if (typeof e === "string") {
        yield new AssRefMatsFailure(e);
      } else {
        // yield new AssRefMatsFailure(e.toString());
        yield new AssRefMatsFailure("Unknow error");
      }
    }
  }

  // Called whenever an `event` is added.
  onEvent(event: AssRefMatsEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
  }

  // Called whenever a state change is about to occur.
  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
  }
}
