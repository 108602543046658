import Machining from "models/machining";

export abstract class MachiningsState {}

export class MachiningsInitial extends MachiningsState {}

export class MachiningsLoading extends MachiningsState {}

export class MachiningsLoaded extends MachiningsState {
	machinings: Array<Machining>;
	constructor(machinings: Array<Machining>) {
		super();
		this.machinings = machinings;
	}

	public toString = (): string => {
		return `MachiningsLoaded ${this.machinings.length} machinings`;
	};
}

export class MachiningsFailure extends MachiningsState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `MachiningsLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
