import moment, { Moment } from "moment";
import Material from "./material";

interface NamedParameters {
	material: Material;
	createdAt: Moment;
}

export default class FavoriteMaterial {
	material: Material;
	createdAt: Moment;

	constructor({ material, createdAt }: NamedParameters) {
		this.material = material;
		this.createdAt = createdAt;
	}

	static areIdentical(a: FavoriteMaterial, b: FavoriteMaterial): boolean {
		if (!a && !b) return true;
		if (!a || !b) return false;
		return a.material.id === b.material.id;
	}

  toJson(){
    return {
      material: this.material.toJson(),
      createdAt: this.createdAt.toISOString(),
    };
  }

  static fromJson(json: any){
    return new FavoriteMaterial({
      material: Material.fromJson(json.material),
      createdAt: moment(json.createdAt),
    });
  }

  static favoritesToJson(favorites: Array<FavoriteMaterial>){
    return favorites.map((favorite: FavoriteMaterial) => favorite.toJson());
  }
  static favoritesFromJson(json: any){
    if(json == undefined) return [];
    return json.map((favoriteJson: string) => FavoriteMaterial.fromJson(favoriteJson));
  }
}
