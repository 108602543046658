import GeneralHelper from "../helpers/general_helper";
import User from "./user";

interface NamedParameters {
    accessToken: string;
    user: User;
}

export default class AuthenticationData {
    accessToken: string;
    user: User;

    constructor(params: any) {
        this.accessToken = params.accessToken;
        this.user = params.user;
    }

    static fromJson(map: Record<string, any>): AuthenticationData {
        return new AuthenticationData({
            accessToken: map["access_token"],
            user: User.fromJson(map["user"]),
        });
    }
}
