import GeneralHelper from "helpers/general_helper";

export default class AssRefMat {
	// REF_MATUSI_SGRP_ID
	id: number;
	// GCO_GOOD_CATEGORY_ID
	refId: number;
	// MATUSI_SGRP_ID
	matId: number;
	// REF_MATUSI_COEF
	coef: number;
	// MATUSI_GRP_ID
	matGrpId: number;

	constructor({ id, refId, matId, coef, matGrpId }: any /*NamedParameters*/) {
		this.id = id;
		this.refId = refId;
		this.matId = matId;
		this.coef = coef;
		this.matGrpId = matGrpId;
	}

	static areIdentical(a: AssRefMat, b: AssRefMat): boolean {
		if (!a && !b) return true;
		if (!a || !b) return false;
		return a.id === b.id;
	}

	// setAffinity({ material }: { material: Material }) {
	// 	if (material && this.indicesMaterial[material.id]) {
	// 		this.affinity = this.indicesMaterial[material.id];
	// 		return;
	// 	}

	// 	this.affinity = 0;
	// }

	static fromJson(map: Record<string, any>): AssRefMat {
		return new AssRefMat({
			id: GeneralHelper.parseIntOrNull(map["REF_MATUSI_SGRP_ID"]),
			refId: GeneralHelper.parseIntOrNull(map["GCO_GOOD_CATEGORY_ID"]),
			matId: GeneralHelper.parseIntOrNull(map["MATUSI_SGRP_ID"]),
			coef: map["REF_MATUSI_COEF"],
			matGrpId: GeneralHelper.parseIntOrNull(map["MATUSI_GRP_ID"]),
		});
	}
}
