import { Bloc, Transition } from "@felangel/bloc";
import Reference from "models/reference";
import ReferenceRepository from "repositories/reference_repository";
import { ReferencesEvent, ReferencesLoad } from "./references_event";
import { ReferencesState, ReferencesLoaded, ReferencesLoading, ReferencesInitial, ReferencesFailure } from "./references_state";


export default class ReferencesBloc extends Bloc<ReferencesEvent, ReferencesState> {
  referenceRepository: ReferenceRepository;
  references: Array<Reference> = [];

  constructor(referenceRepository: ReferenceRepository) {
    super(new ReferencesInitial());
    this.referenceRepository = referenceRepository;
  }

  async *mapEventToState(event: ReferencesEvent) {
    if (event instanceof ReferencesLoad) {
      yield* this.mapLoadReferencesToState(event);
    }
  }

  async *mapLoadReferencesToState(event: ReferencesLoad) {
    try {
      yield new ReferencesLoading();
      var referencesList: Array<Reference> = await this.referenceRepository.getData();
      this.references = referencesList;

      yield new ReferencesLoaded(this.references);
    } catch (e) {
      if (typeof e === "string") {
        yield new ReferencesFailure(e);
      } else {
        // yield new ReferencesFailure(e.toString());
        // yield new ReferencesFailure(e.toString());
        yield new ReferencesFailure("Unknow error");
      }
    }
  }

  // Called whenever an `event` is added.
  onEvent(event: ReferencesEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
  }

  // Called whenever a state change is about to occur.
  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
  }
}
