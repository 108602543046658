import AssRefUsi from "models/ass_ref_usi";

export abstract class AssRefUsisState {}

export class AssRefUsisInitial extends AssRefUsisState {}

export class AssRefUsisLoading extends AssRefUsisState {}

export class AssRefUsisLoaded extends AssRefUsisState {
	assRefUsis: Array<AssRefUsi>;
	constructor(assRefUsis: Array<AssRefUsi>) {
		super();
		this.assRefUsis = assRefUsis;
	}

	public toString = (): string => {
		return `AssRefUsisLoaded ${this.assRefUsis.length} assRefUsis`;
	};
}

export class AssRefUsisFailure extends AssRefUsisState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `AssRefUsisLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
