import Article from "models/article";
import DataService from "services/data_service";
import Config from "config/config";
import axios from "axios";

export default class ArticleRepository {
	/// Constructor
	// ArticleRepository() {}

	// constructor() {
	// 	// this.dataService = new DataService();
	// }

	async getData(): Promise<Array<Article>> {
		var data: any = await axios.get(Config.base_data_url + "/getJson?file=IND_TOOLFIND_ART", {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "X-Requested-With",
			},
		});
		return this.dataToArticle(data.data);
	}

	dataToArticle(data: any): Array<Article> {
		var articles: Array<Article> = [];
		for (var i = 0; i < data.length; i++) {
			articles.push(Article.fromJson(data[i]));
		}
		return articles;
	}
}
