import Coating from "models/coating";
import Config from "config/config";
import axios from "axios";

export default class CoatingRepository {
	async getData(): Promise<Array<Coating>> {
		var data: any = await axios.get(Config.base_data_url + "/getJson?file=IND_TOOLFIND_REV", {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "X-Requested-With",
			},
		});
		return await this.dataToCoating(data.data);
	}

	dataToCoating(data: any): Array<Coating> {
		var coatings: Array<Coating> = [];
      for (var i = 0; i < data.length; i++) {
			coatings.push(Coating.fromJson(data[i]));
		}
		return coatings;
	}
}
