import AssRefMat from "models/ass_ref_mat";
import Config from "config/config";
import axios from "axios";

export default class AssRefMatRepository {
	async getData(): Promise<Array<AssRefMat>> {
		var data: any = await axios.get(Config.base_data_url + "/getJson?file=IND_TOOLFIND_REF_MATUSI_SGRP", {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "X-Requested-With",
			},
		});
		return await this.dataToAssRefMat(data.data);
	}

	dataToAssRefMat(data: any): Array<AssRefMat> {
		var assRefMas: Array<AssRefMat> = [];
		for (var i = 0; i < data.length; i++) {
			assRefMas.push(AssRefMat.fromJson(data[i]));
		}
		return assRefMas;
	}
}
