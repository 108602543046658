import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import React from "react";
import "./favorites_materials.scss";
import { Subscription } from "rxjs";
import Material from "models/material";
import { withRouter, RouteComponentProps } from "react-router-dom";
import MaterialItem from "./components/material_item";
import { FavoritesMaterialsLoaded, FavoritesMaterialsState } from "blocs/favorites_materials_bloc/favorites_materials_state";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FavoriteMaterial from "models/favorite_material";
import EmptyImageSrc from "assets/images/empty.svg";

interface Props extends RouteComponentProps<any>, WithTranslation {}

enum SortBy {
	DATE,
	NAME,
}

type State = {
	rawMaterialsList: Array<FavoriteMaterial>;
	materials: Array<FavoriteMaterial>;
	sortby: SortBy;
	searchQuery: string;
	favoritesMaterials: Array<Material>;
};

class FavoritesMaterialsScreen extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;
	// history = useHistory();

	// static contextTypes = {
	//     contextType: BlocsContext,
	//     router: () => true, // replace with PropTypes.object if you use them
	//   }
	// declare context: React.ContextType<typeof BlocsContext>

	private favoritesMaterialsBlocSubscription: Subscription = Subscription.EMPTY;
	private materialfinderSelectionBlocSubscription: Subscription = Subscription.EMPTY;

	constructor(props: Props) {
		super(props);
		this.state = {
			rawMaterialsList: [],
			materials: [],
			favoritesMaterials: [],
			sortby: SortBy.DATE,
			searchQuery: "",
		};
	}

	componentDidMount(): void {
		this.favoritesMaterialsBlocSubscription = this.context.favoritesMaterialsBloc.listen(this.favoritesMaterialsBlocAction);
		this.favoritesMaterialsBlocAction(this.context.favoritesMaterialsBloc.state);
	}

	componentWillUnmount(): void {
		this.favoritesMaterialsBlocSubscription.unsubscribe();
		this.materialfinderSelectionBlocSubscription.unsubscribe();
	}

	favoritesMaterialsBlocAction = (state: FavoritesMaterialsState) => {
		if (state instanceof FavoritesMaterialsLoaded) {
			this.setState({
				rawMaterialsList: state.favorites,
				materials: this.sortAndFilterMaterials(state.favorites),
			});
		}
	};

	sortAndFilterMaterials(_rawMaterialsList: Array<FavoriteMaterial>): Array<FavoriteMaterial> {
		var _materials: Array<FavoriteMaterial> = _rawMaterialsList;
		// Filters
		if (this.state.searchQuery && this.state.searchQuery !== "") {
			_materials = _materials.filter(
				(favorite: FavoriteMaterial) => favorite.material.code.toLowerCase().search(this.state.searchQuery.toLowerCase()) !== -1
			);
		}

		// Sort
		switch (this.state.sortby) {
			case SortBy.DATE:
				// _materials.sort((a, b) =>
				//     b.getAffinity(coating: _materialfinderSelection.coating, material: _materialfinderSelection.material).compareTo(a.getAffinity(coating: _materialfinderSelection.coating, material: _materialfinderSelection.material)));
				_materials.sort((a: FavoriteMaterial, b: FavoriteMaterial) => b.createdAt.diff(a.createdAt));

				break;
			case SortBy.NAME:
				_materials.sort((a: FavoriteMaterial, b: FavoriteMaterial) => a.material.code.localeCompare(b.material.code));

				break;
		}

		return _materials;
	}

	setQueryString(query: string) {
		this.setState(
			{
				searchQuery: query,
				// materials: this.sortAndFilterMaterials(this.state.rawMaterialsList)
			},
			() => {
				this.setState({
					// searchQuery: event.currentTarget.value,
					materials: this.sortAndFilterMaterials(this.state.rawMaterialsList),
				});
			}
		);
	}

	setSortBy(sortBy: SortBy) {
		this.setState({ sortby: sortBy }, () => {
			this.setState({
				materials: this.sortAndFilterMaterials(this.state.rawMaterialsList),
			});
		});
	}

	renderSortsAndFilters() {
		return (
			<div className="container">
				<div className="sort-and-filter d-flex py-3">
					<div className="me-5">
						<h2>{this.props.t("screen.favorites_materials.title")}</h2>
						<div className="materials-found">
							{this.state.materials.length} {this.props.t("screen.favorites_materials.favorite_found")}
						</div>
					</div>

					<div className="me-5">
						<div className="filterTitle">{this.props.t("general.search")}</div>
						<div className="d-flex">
							<div className="custom-search-field">
								<span className="icon icon-search"></span>
								<input
									type="text"
									placeholder={this.props.t("screen.favorites_materials.search")}
									value={this.state.searchQuery}
									onChange={(event) => this.setQueryString(event.currentTarget.value)}
								/>
							</div>
							<button onClick={(event) => this.setQueryString("")} type="button" className="btnResetSearch btn btn-primary text-white px-4">
								{this.props.t("general.reset")}
							</button>
						</div>
					</div>
					<div className="vertical-separator"></div>
					<div className="mx-4">
						<div className="filterTitle">{this.props.t("general.sortby")}</div>
						<div className="d-flex">
							<button
								type="button"
								onClick={(event) => this.setSortBy(SortBy.DATE)}
								className={"filter" + (this.state.sortby === SortBy.DATE ? " active" : "")}
							>
								<span className="icon icon-sort"></span>
								{this.props.t("general.sortby_date")}
							</button>
							<button
								type="button"
								onClick={(event) => this.setSortBy(SortBy.NAME)}
								className={"filter" + (this.state.sortby === SortBy.NAME ? " active" : "")}
							>
								<span className="icon icon-sort"></span>
								{this.props.t("general.sortby_name")}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<BaseScreen>
				<div className="w-100 step-material d-flex flex-column align-items-center">
					<div className="border-bottom my-4 w-100 mx-auto">{this.renderSortsAndFilters()}</div>
					<div className="w-100 mx-auto">
						<div className="container align-items-center mx-auto">
							{this.state.materials.length === 0 && (
								<div className="d-flex justify-content-center my-5 empty-state">
									<img src={EmptyImageSrc} className="emptyImage" alt="logo" />
									<h5>{this.props.t("screen.favorites_materials.no_favorite")}.</h5>
									<p>{this.props.t("screen.favorites_materials.no_favorite_description")}</p>
									<Link to="/select-material">
									<button
										type="button"
										className={"emptySeeAll"}
									>
                    {this.props.t("screen.favorites_materials.show_list")} →
										
									</button>
									</Link>
								</div>
							)}
							{this.state.materials.length !== 0 && (
								<div className="d-flex flex-wrap">
									{this.state.materials.map((favorite: FavoriteMaterial) => {
										return (
											<MaterialItem
												key={favorite.material.id}
												favorite={favorite}
												// onClick={() => this.setState({ selectedMaterial: material })}
												onClick={() => {}}
											/>
										);
									})}
								</div>
							)}
						</div>
					</div>
				</div>
			</BaseScreen>
		);
	}
}

export default withTranslation("common")(withRouter(FavoritesMaterialsScreen));
