import { Bloc, Transition } from "@felangel/bloc";
import AssRevMat from "models/ass_rev_mat";
import AssRevMatRepository from "repositories/ass_rev_mat_repository";
import { AssRevMatsEvent, AssRevMatsLoad } from "./ass_rev_mat_event";
import { AssRevMatsState, AssRevMatsLoaded, AssRevMatsLoading, AssRevMatsInitial, AssRevMatsFailure } from "./ass_rev_mat_state";


export default class AssRevMatsBloc extends Bloc<AssRevMatsEvent, AssRevMatsState> {
	assRevMatRepository: AssRevMatRepository;
	assRevMats: Array<AssRevMat> = [];

	constructor(assRevMatRepository: AssRevMatRepository) {
		super(new AssRevMatsInitial());
		this.assRevMatRepository = assRevMatRepository;
	}

	async *mapEventToState(event: AssRevMatsEvent) {
		if (event instanceof AssRevMatsLoad) {
			yield* this.mapLoadAssRevMatsToState(event);
		}
	}

	async *mapLoadAssRevMatsToState(event: AssRevMatsLoad) {
		try {
			yield new AssRevMatsLoading();
			var assRevMatsList: Array<AssRevMat> = await this.assRevMatRepository.getData();
			this.assRevMats = assRevMatsList;

			yield new AssRevMatsLoaded(this.assRevMats);
		} catch (e) {
			if (typeof e === "string") {
				yield new AssRevMatsFailure(e);
			} else {
				// yield new AssRevMatsFailure(e.toString());
				yield new AssRevMatsFailure("Unknow error");
        
			}
		}
	}

	// Called whenever an `event` is added.
	onEvent(event: AssRevMatsEvent): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
	}

	// Called whenever a state change is about to occur.
	onTransition(transition: Transition<any, any>): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
	}

	onError(error: any): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
	}
}
