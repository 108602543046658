import { Bloc, Transition } from "@felangel/bloc";
import CoatingsBloc from "blocs/coatings_bloc/coatings_bloc";
import { CoatingsLoaded } from "blocs/coatings_bloc/coatings_state";
import MaterialsBloc from "blocs/materials_bloc/materials_bloc";
import { MaterialsLoaded } from "blocs/materials_bloc/materials_state";
import Coating from "models/coating";
import Machine from "models/machine";
import Material from "models/material";
// import Tool from "models/tool";
import Reference from "models/reference";
import Article from "models/article";
import ToolfinderSelection from "models/toolfinder_selection";

import { ToolfinderResetSelections, ToolfinderSelectionsEvent, ToolfinderSetCutParams, ToolfinderSetSelections } from "./toolfiinder_selections_event";
import { ToolfinderSelectionsState, ToolfinderSelectionsUpdated } from "./toolfiinder_selections_state";
import AssRefMat from "models/ass_ref_mat";
import AssRevMat from "models/ass_rev_mat";
import AssRefUsi from "models/ass_ref_usi";
import AssRefMatRepository from "repositories/ass_ref_mat_repository";
import AssRevMatRepository from "repositories/ass_rev_mat_repository";
import AssRefUsiRepository from "repositories/ass_ref_usi_repository";
import Machining from "models/machining";
import ArticlesBloc from "blocs/articles_bloc/articles_bloc";
import ReferencesBloc from "blocs/references_bloc/references_bloc";
import { ReferencesLoaded } from "blocs/references_bloc/references_state";
import { ArticlesLoaded } from "blocs/articles_bloc/articles_state";
import AssRefMatsBloc from "blocs/ass_ref_mat_bloc/ass_ref_mat_bloc";
import AssRefUsisBloc from "blocs/ass_ref_usi_bloc/ass_ref_usi_bloc";
import AssRevMatsBloc from "blocs/ass_rev_mat_bloc/ass_rev_mat_bloc";
import MachiningsBloc from "blocs/machining_bloc/machining_bloc";
import MachiningGroupsBloc from "blocs/machining_group_bloc/machining_group_bloc";
import { AssRefMatsLoaded } from "blocs/ass_ref_mat_bloc/ass_ref_mat_state";
import { AssRevMatsLoaded } from "blocs/ass_rev_mat_bloc/ass_rev_mat_state";
import { AssRefUsisLoaded } from "blocs/ass_ref_usi_bloc/ass_ref_usi_state";
import ToolfinderCutParams from "models/toolfinder_cut_params";
import { CutParam, CUT_PARAM_TYPE } from "constants/constants";
import MachiningRepository from "repositories/machining_repository";
import MachinesBloc from "blocs/machines_bloc/machines_bloc";
import ArticlesCoatingsSelectionBloc from "blocs/articles_coatings_selection_bloc/articles_coatings_selection_bloc";
import CalculHelper from "helpers/calcul_helper";

const defaultToolfinderSelection = new ToolfinderSelection({
  material: undefined,
  materialLock: false,
  machining: undefined,
  machiningLock: false,
  tool: undefined,
  toolLock: false,
  coating: undefined,
  coatingLock: false,
  machine: undefined,
  machineLock: false,
});

const defaultToolfinderCutParams = new ToolfinderCutParams({
  // Vc: undefined,
  // n: undefined,
  // D: undefined,
  // Z: undefined,
  // Fz: undefined,
  // Vf: undefined,
  // ap: undefined,
  // ae: undefined,
  // Q: undefined,
  // Rm: undefined,
  // eta: undefined,
  // P: undefined,
  // hm: undefined,
  // Emax: undefined,
  // LgCop: undefined,
  // VolCop: undefined,
  // Vctf: undefined,
});

export default class ToolfinderSelectionsBloc extends Bloc<ToolfinderSelectionsEvent, ToolfinderSelectionsState> {
  toolfinderSelection: ToolfinderSelection = defaultToolfinderSelection.copyWith({});
  toolfinderCutParams: ToolfinderCutParams = defaultToolfinderCutParams.copyWith({});

  materialsBloc: MaterialsBloc;
  referencesBloc: ReferencesBloc;
  articlesBloc: ArticlesBloc;
  coatingsBloc: CoatingsBloc;
  machinesBloc: MachinesBloc;

  assRefMatBloc: AssRefMatsBloc;
  assRefUsiBloc: AssRefUsisBloc;
  assRevMatBloc: AssRevMatsBloc;
  machiningBloc: MachiningsBloc;
  machiningGroupBloc: MachiningGroupsBloc;

  assRefMats: Array<AssRefMat> = [];
  assRevMats: Array<AssRevMat> = [];
  assRefUsis: Array<AssRefUsi> = [];

  materialChanged: boolean = false;
  coatingChanged: boolean = false;
  machineChanged: boolean = false;
  machiningChanged: boolean = false;
  toolChanged: boolean = false;

  showLog = true;

  constructor({
    materialsBloc,
    referencesBloc,
    articlesBloc,
    coatingsBloc,
    assRefMatBloc,
    assRefUsiBloc,
    assRevMatBloc,
    machiningBloc,
    machiningGroupBloc,
    machinesBloc,
  }: {
    materialsBloc: MaterialsBloc;
    referencesBloc: ReferencesBloc;
    articlesBloc: ArticlesBloc;
    coatingsBloc: CoatingsBloc;
    assRefMatBloc: AssRefMatsBloc;
    assRefUsiBloc: AssRefUsisBloc;
    assRevMatBloc: AssRevMatsBloc;
    machiningBloc: MachiningsBloc;
    machiningGroupBloc: MachiningGroupsBloc;
    machinesBloc: MachinesBloc;
  }) {
    super(
      new ToolfinderSelectionsUpdated(
        new ToolfinderSelection({
          materialLock: false,
          machiningLock: false,
          toolLock: false,
          coatingLock: false,
          machineLock: false,
        })
      )
    );

    this.materialsBloc = materialsBloc;
    this.referencesBloc = referencesBloc;
    this.articlesBloc = articlesBloc;
    this.coatingsBloc = coatingsBloc;
    this.machinesBloc = machinesBloc;

    this.assRefMatBloc = assRefMatBloc;
    this.assRefUsiBloc = assRefUsiBloc;
    this.assRevMatBloc = assRevMatBloc;
    this.machiningBloc = machiningBloc;
    this.machiningGroupBloc = machiningGroupBloc;
  }

  async *mapEventToState(event: ToolfinderSelectionsEvent) {
    if (event instanceof ToolfinderSetSelections) {
      yield* this.mapToolfinderSetSelectionsToState(event as ToolfinderSetSelections);
    } else if (event instanceof ToolfinderResetSelections) {
      yield* this.mapToolfinderResetSelectionsToState(event as ToolfinderResetSelections);
    } else if (event instanceof ToolfinderSetCutParams) {
      yield* this.mapToolfinderSetCutParamToState(event as ToolfinderSetCutParams);
    }
  }

  async *mapToolfinderSetCutParamToState(event: ToolfinderSetCutParams) {
    // Fraisage
    if (
      this.toolfinderSelection.reference != undefined &&
      this.toolfinderSelection.toolLock == false &&
      event.cutParams.FRAISAGE_d1 != null &&
      this.toolfinderCutParams.FRAISAGE_d1 != event.cutParams.FRAISAGE_d1
    ) {
      this.toolfinderCutParams = event.cutParams.copyWith({});
      var articles: Array<Article> = (this.articlesBloc.state as ArticlesLoaded).articles;
      articles = articles.filter((article: Article) => article.refId == this.toolfinderSelection.reference.id);
      var article: Article =
        articles.find((article: Article) => article.dimD1 == event.cutParams.FRAISAGE_d1) ??
        (articles[0] ?? new Article({})).copyWith({
          id: -1,
          code: this.toolfinderSelection.reference.code + "SPd" + event.cutParams.FRAISAGE_d1.toFixed(2),
          dimD1: event.cutParams.FRAISAGE_d1,
        });
      this.toolfinderSelection = this.toolfinderSelection.copyWith({ article: article });

      yield new ToolfinderSelectionsUpdated(this.toolfinderSelection, CUT_PARAM_TYPE.FRAISAGE_d1);
    }
    // Percage
    if (
      this.toolfinderSelection.reference != undefined &&
      this.toolfinderSelection.toolLock == false &&
      event.cutParams.PERCAGE_d1 != null &&
      this.toolfinderCutParams.PERCAGE_d1 != event.cutParams.PERCAGE_d1
    ) {
      this.toolfinderCutParams = event.cutParams.copyWith({});
      var articles: Array<Article> = (this.articlesBloc.state as ArticlesLoaded).articles;
      articles = articles.filter((article: Article) => article.refId == this.toolfinderSelection.reference.id);
      var article: Article =
        articles.find((article: Article) => article.dimD1 == event.cutParams.PERCAGE_d1) ??
        (articles[0] ?? new Article({})).copyWith({
          id: -1,
          code: this.toolfinderSelection.reference.code + "SPd" + event.cutParams.PERCAGE_d1.toFixed(2),
          dimD1: event.cutParams.PERCAGE_d1,
        });
      this.toolfinderSelection = this.toolfinderSelection.copyWith({ article: article });
      yield new ToolfinderSelectionsUpdated(this.toolfinderSelection, CUT_PARAM_TYPE.PERCAGE_d1);
    }
  }

  async *mapToolfinderSetSelectionsToState(event: ToolfinderSetSelections) {
    this.materialChanged = !Material.areIdentical(this.toolfinderSelection.material, event.toolfinderSelection.material);
    this.coatingChanged = !Coating.areIdentical(this.toolfinderSelection.coating, event.toolfinderSelection.coating);
    this.machineChanged = !Machine.areIdentical(this.toolfinderSelection.machine, event.toolfinderSelection.machine);
    this.machiningChanged = !Machining.areIdentical(this.toolfinderSelection.machining, event.toolfinderSelection.machining);
    this.toolChanged =
      !Reference.areIdentical(this.toolfinderSelection.reference, event.toolfinderSelection.reference) || !Article.areIdentical(this.toolfinderSelection.article, event.toolfinderSelection.article);
    this.toolfinderSelection = event.toolfinderSelection;

    if (this.assRefMatBloc && this.assRefMatBloc.state instanceof AssRefMatsLoaded) {
      this.assRefMats = this.assRefMatBloc.assRefMats;
    }
    if (this.assRevMatBloc && this.assRevMatBloc.state instanceof AssRevMatsLoaded) {
      this.assRevMats = this.assRevMatBloc.assRevMats;
    }
    if (this.assRefUsiBloc && this.assRefUsiBloc.state instanceof AssRefUsisLoaded) {
      this.assRefUsis = this.assRefUsiBloc.assRefUsis;
    }

    // this.assRefMats = await new AssRefMatRepository().getAssRefMats();
    // this.assRevMats = await new AssRevMatRepository().getAssRevMats();
    // this.assRefUsis = await new AssRefUsiRepository().getAssRefUsis();

    if (this.materialChanged) this.onMaterialChanged();
    if (this.coatingChanged) this.onRevChanged();
    // if(machineChanged) machineChange();
    if (this.toolChanged) this.onRefChanged();
    if (this.machiningChanged) this.onUsiChanged();

    if (this.toolfinderSelection.machining == null && (this.toolChanged == true || this.materialChanged == true || this.machineChanged == true || this.machiningChanged == true)) {
      var machinings: Array<Machining> = this.machiningBloc.machinings;
      this.toolfinderSelection.machining = machinings.find((machining: Machining) => machining.id == 100000) as any;
      this.onUsiChanged();
    }

    yield new ToolfinderSelectionsUpdated(event.toolfinderSelection);
  }

  onMaterialChanged() {
    // console.log("onMaterialChanged");
    this.setRefAffinity();
    this.setRevAffinity();
  }

  onRefChanged() {
    // console.log("onRefChanged");
    this.setMaterialAffinity();
  }

  onRevChanged() {
    // console.log("onRevChanged");
    this.setMaterialAffinity();
  }

  onUsiChanged() {
    // console.log("onUsiChanged");
    this.setRefAffinity();
    this.setRefCompatibility();
  }

  setRefAffinity() {
    // console.log("setRefAffinity");

    if (!(this.referencesBloc && this.referencesBloc.state instanceof ReferencesLoaded) || !(this.articlesBloc && this.articlesBloc.state instanceof ArticlesLoaded)) return;

    // List<Tool> tools = (referencesBloc.state as ToolsLoaded).tools;
    var references: Array<Reference> = (this.referencesBloc.state as ReferencesLoaded).references;
    var articles: Array<Article> = (this.articlesBloc.state as ArticlesLoaded).articles;

    references.forEach((reference: Reference) => {
      var assRefMat: AssRefMat = null as any;
      if (this.toolfinderSelection.material != null) {
        assRefMat = this.assRefMats.find((assRefMat: AssRefMat) => {
          return assRefMat.refId == reference.id && assRefMat.matId == this.toolfinderSelection.material.id;
        }) as any;
      }

      var assRefUsi: AssRefUsi = null as any;
      if (this.toolfinderSelection.machining != null) {
        assRefUsi = this.assRefUsis.find((assRefUsi: AssRefUsi) => {
          return assRefUsi.refId == reference.id && assRefUsi.usiId == this.toolfinderSelection.machining.id;
        }) as any;
      }

      var affinity: number = ((assRefMat != null ? assRefMat.coef : 0) + (assRefUsi != null ? assRefUsi.coef : 0)) / 2;

      reference.setAffinity(affinity ?? 0);

      // reference.setAffinity(material: toolfinderSelection.material);
    });

    this.selectBestRef(references, articles);
  }

  setRefCompatibility() {
    console.log("call setRefCompatibility");
    // console.log("setRefCompatibility");
    if (!(this.referencesBloc && this.referencesBloc.state instanceof ReferencesLoaded) || !(this.articlesBloc && this.articlesBloc.state instanceof ArticlesLoaded)) return;

    var references: Array<Reference> = (this.referencesBloc.state as ReferencesLoaded).references;
    references.forEach((reference: Reference) => {
      var assRefUsi: AssRefUsi = null as any;

      if (this.toolfinderSelection.machining != null) {
        assRefUsi = this.assRefUsis.find((assRefUsi: AssRefUsi) => {
          return assRefUsi.refId == reference.id && assRefUsi.usiId == this.toolfinderSelection.machining.id;
        }) as any;

        var compatibility: boolean = assRefUsi != null && assRefUsi.coef != null && assRefUsi.coef > 0 ? true : false;
        // console.log(compatibility);
        reference.setCompatibility(compatibility);
      } else {
        reference.setCompatibility(true);

      }

      // reference.setAffinity(material: toolfinderSelection.material);
    });
  }

  selectBestRef(references: Array<Reference>, articles: Array<Article>) {
    // console.log("selectBestRef");
    if (this.toolChanged == true) return;
    if (this.toolfinderSelection.toolLock == true) return;
    // TODO : Check thaht is correct with lb
    var references: Array<Reference> = references.filter((ref: Reference) => articles.find((article: Article) => article.refId == ref.id) != null);

    var bestReference: Reference = null as any;
    if (references.length != 0) {
      bestReference = references.reduce((curr: Reference, next: Reference) => (curr.affinity >= next.affinity ? curr : next));
    }

    // console.log("bestReference =", bestReference);

    if (!Reference.areIdentical(this.toolfinderSelection.reference, bestReference)) {
      this.toolfinderSelection.reference = bestReference;
      this.toolfinderSelection.article = articles.find((article: Article) => article.refId == bestReference.id) as any;
      this.onRefChanged();
    }
  }

  setRevAffinity() {
    // console.log("setRevAffinity");
    if (!(this.coatingsBloc && this.coatingsBloc.state instanceof CoatingsLoaded)) return;

    // Coating affinity
    var coatings: Array<Coating> = (this.coatingsBloc.state as CoatingsLoaded).coatings;
    coatings.forEach((coating: Coating) => {
      var assRevMat: AssRevMat = undefined as any;
      if (this.toolfinderSelection.material != null) {
        assRevMat = this.assRevMats.find((assRevMat: AssRevMat) => assRevMat.revCode == coating.code && assRevMat.matId == this.toolfinderSelection.material.id) as any;
      }

      coating.setAffinity(assRevMat != undefined ? assRevMat.coef ?? 0 : 0);
      // coating.setAffinity(material: toolfinderSelection.material);
    });

    this.selectBestRev(coatings);
  }

  selectBestRev(coatings: Array<Coating>) {
    // console.log("selectBestRev");
    if (this.coatingChanged == true) return;
    if (this.toolfinderSelection.coatingLock == true) return;
    // var bestCoating: Coating = coatings.reduce((curr: Coating, next: Coating) => (curr.affinity >= next.affinity ? curr : next));
    var bestCoating: Coating =  CalculHelper.getCoatingsWithDefaultSort(coatings, this.toolfinderSelection.article!)[0];

    if (!Coating.areIdentical(this.toolfinderSelection.coating, bestCoating)) {
      this.toolfinderSelection.coating = bestCoating;
      this.onRevChanged();
    }
  }

  setMaterialAffinity() {
    // console.log("setMaterialAffinity");
    if (!(this.materialsBloc && this.materialsBloc.state instanceof MaterialsLoaded)) return;

    var materials: Array<Material> = (this.materialsBloc.state as MaterialsLoaded).materials;
    // Calcul materials affinity
    materials.forEach((material: Material) => {
      var assRefMat: AssRefMat = undefined as any;
      if (this.toolfinderSelection.reference != null) {
        assRefMat = this.assRefMats.find((assRefMat: AssRefMat) => {
          return assRefMat.matId == material.id && assRefMat.refId == this.toolfinderSelection.reference.id;
        }) as any;
      }

      var assRevMat: AssRevMat = undefined as any;
      if (this.toolfinderSelection.coating != null) {
        assRevMat = this.assRevMats.find((assRevMat: AssRevMat) => {
          return assRevMat.matId == material.id && assRevMat.revCode == this.toolfinderSelection.coating.code;
        }) as any;
      }
      var affinity: number = ((assRefMat != null ? assRefMat.coef ?? 0 : 0) + (assRevMat != null ? assRevMat.coef ?? 0 : 0)) / 2;

      material.setAffinity(affinity ?? 0);
    });

    this.selectBestMaterial(materials);
  }

  selectBestMaterial(materials: Array<Material>) {
    // console.log("selectBestMaterial");
    if (this.materialChanged == true) return;
    if (this.toolfinderSelection.materialLock == true) return;

    var bestMaterial: Material = materials.reduce((curr: Material, next: Material) => (curr.affinity >= next.affinity ? curr : next));

    if (!Material.areIdentical(this.toolfinderSelection.material, bestMaterial)) {
      this.toolfinderSelection.material = bestMaterial;
      this.onMaterialChanged();
    }
  }

  async *mapToolfinderResetSelectionsToState(event: ToolfinderResetSelections) {
    this.toolfinderSelection = new ToolfinderSelection({
      material: undefined,
      materialLock: false,
      machining: undefined,
      machiningLock: false,
      tool: undefined,
      toolLock: false,
      coating: undefined,
      coatingLock: false,
      machine: undefined,
      machineLock: false,
    });

    var references: Array<Reference> = (this.referencesBloc.state as ReferencesLoaded).references;
    // var articles: Array<Article> = (this.articlesBloc.state as ArticlesLoaded).articles;
    var coatings: Array<Coating> = (this.coatingsBloc.state as CoatingsLoaded).coatings;
    var materials: Array<Material> = (this.materialsBloc.state as MaterialsLoaded).materials;

    references.forEach((ref: Reference) => {
      ref.setAffinity(undefined as any);
    });
    coatings.forEach((coating: Coating) => {
      coating.setAffinity(undefined as any);
    });
    materials.forEach((material: Material) => {
      material.setAffinity(undefined as any);
    });
    this.setRefCompatibility();

    yield new ToolfinderSelectionsUpdated(this.toolfinderSelection);
  }

  // Called whenever an `event` is added.
  onEvent(event: ToolfinderSelectionsEvent): void {
    if ((!process.env.NODE_ENV || process.env.NODE_ENV === "development") && this.showLog == true) console.log("New event = ", event);
  }

  // Called whenever a state change is about to occur.
  onTransition(transition: Transition<any, any>): void {
    if ((!process.env.NODE_ENV || process.env.NODE_ENV === "development") && this.showLog == true) console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if ((!process.env.NODE_ENV || process.env.NODE_ENV === "development") && this.showLog == true) console.log("Error = ", error);
  }
}
