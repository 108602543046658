
export default class User {
	email: string;
	firstname: string;
	lastname: string;
	
	constructor(params: any) {
		this.email = params.email;
		this.firstname = params.firstname;
		this.lastname = params.lastname;
	}

	static fromJson(map: Record<string, any>): User {
		return new User({
			email: map["email"],
			firstname: map["firstname"],
			lastname: map["lastname"],
		});
	}
}
