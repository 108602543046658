import React from "react";
import "./material_item.scss";
import Material from "models/material";
import { BlocsContext } from "contexts/blocs_context";
import { BlocBuilder } from "@felangel/react-bloc";
import { FavoritesMaterialsLoaded, FavoritesMaterialsState } from "blocs/favorites_materials_bloc/favorites_materials_state";
import { FavoritesMaterialsAdd, FavoritesMaterialsRemove } from "blocs/favorites_materials_bloc/favorites_materials_event";
import FavoriteMaterial from "models/favorite_material";
import moment from "moment";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation {
	selected: boolean;
	material: Material;
	onClick: Function;
}

type State = {
	isFavorite: boolean;
};

class MaterialItem extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;

	constructor(props: Props) {
		super(props);
		this.state = {
			isFavorite: false,
		};
	}

	render() {
		return (
			// <button

			// >

			<div role="button" onClick={(e) => this.props.onClick()} className={"relative material-item d-flex flex-column justify-content-center align-items-center " + (this.props.selected === true ? "active" : "")}>
				<span className="icon icon-d-cube"></span>
				{this.props.material.getLongDescription(this.props.i18n.language)}
				<div className="affinityScore d-flex">
					<div className="affinityCircle">{this.props.material.affinity != undefined ? Number(this.props.material.affinity.toFixed(2)) : "n/a"}</div>
					<div className="affinityText">{this.props.t("screen.step_material.affinity_score")}</div>
				</div>
				<BlocBuilder
					bloc={this.context.favoritesMaterialsBloc}
					builder={(state: FavoritesMaterialsState) => {
						var favoriteMaterial: FavoriteMaterial = null as any;
						(state as FavoritesMaterialsLoaded).favorites.forEach((favorite: FavoriteMaterial) => {
							if (favorite.material.id === this.props.material.id) {
								favoriteMaterial = favorite;
							}
						});
						if (favoriteMaterial) {
							return (
								<button
									type="button"
									onClick={(event) => {
										this.context.favoritesMaterialsBloc.add(new FavoritesMaterialsRemove(favoriteMaterial));
										event.stopPropagation();
									}}
									className="favorite-btn"
								>
									<span className="icon icon-heart favorite"></span>
								</button>
							);
						} else {
							return (
								<button
									type="button"
									onClick={(event) => {
										this.context.favoritesMaterialsBloc.add(new FavoritesMaterialsAdd(new FavoriteMaterial({ material: this.props.material, createdAt: moment() })));
										event.stopPropagation();
									}}
									className="favorite-btn"
								>
									<span className="icon icon-heart-o favorite"></span>
								</button>
							);
						}
					}}
				/>

				{/* <span className="icon icon-heart-o favorite"></span> */}
			</div>
		);
	}
}

export default withTranslation("common")(MaterialItem);
