import { Bloc, Transition } from "@felangel/bloc";
import AssRefMatsBloc from "blocs/ass_ref_mat_bloc/ass_ref_mat_bloc";
import { AssRefMatsLoaded } from "blocs/ass_ref_mat_bloc/ass_ref_mat_state";
import AssRefUsisBloc from "blocs/ass_ref_usi_bloc/ass_ref_usi_bloc";
import AssRevMatsBloc from "blocs/ass_rev_mat_bloc/ass_rev_mat_bloc";
import { AssRevMatsLoaded } from "blocs/ass_rev_mat_bloc/ass_rev_mat_state";
import { CUT_PARAM_TYPE } from "constants/constants";
import CalculHelper from "helpers/calcul_helper";
import AssRefMat from "models/ass_ref_mat";
import AssRevMat from "models/ass_rev_mat";
import Reference from "models/reference";
import ToolfinderCutParams from "models/toolfinder_cut_params";
import ToolfinderSelection from "models/toolfinder_selection";
import AssRefMatRepository from "repositories/ass_ref_mat_repository";
import AssRevMatRepository from "repositories/ass_rev_mat_repository";
import { CutParamsEvent, CutParamsReset, CutParamsSetCutParam, CutParamsSetCutParams, CutParamsSetToolfinderSelection } from "./cut_params_event";
import { CutParamsState, CutParamsInitial, CutParamsUpdated } from "./cut_params_state";

const defaultToolfinderSelection = new ToolfinderSelection({
	// material: undefined,
	// materialLock: false,
	// machining: undefined,
	// machiningLock: false,
	// reference: undefined,
	// article: undefined,
	// toolLock: false,
	// coating: undefined,
	// coatingLock: false,
	// machine: undefined,
	// machineLock: false,
});

const defaultToolfinderCutParams = new ToolfinderCutParams({
	// Vc: undefined,
	// n: undefined,
	// D: undefined,
	// Z: undefined,
	// Fz: undefined,
	// Vf: undefined,
	// ap: undefined,
	// ae: undefined,
	// Q: undefined,
	// Rm: undefined,
	// eta: undefined,
	// P: undefined,
	// hm: undefined,
	// Emax: undefined,
	// LgCop: undefined,
	// VolCop: undefined,
	// Vctf: undefined,
});


export default class CutParamsBloc extends Bloc<CutParamsEvent, CutParamsState> {
	// toolRepository: ToolRepository;
	// tools: Array<Tool> = [];

	toolfinderSelection: ToolfinderSelection = defaultToolfinderSelection.copyWith({});
	toolfinderCutParams: ToolfinderCutParams = defaultToolfinderCutParams.copyWith({});

	assRefMatBloc: AssRefMatsBloc;
	assRefUsiBloc: AssRefUsisBloc;
	assRevMatBloc: AssRevMatsBloc;

	showLog = true;

	constructor({
		assRefMatBloc,
		assRefUsiBloc,
		assRevMatBloc,
	}: {
		assRefMatBloc: AssRefMatsBloc;
		assRefUsiBloc: AssRefUsisBloc;
		assRevMatBloc: AssRevMatsBloc;
	}) {
		super(new CutParamsInitial());

		this.assRefMatBloc = assRefMatBloc;
		this.assRefUsiBloc = assRefUsiBloc;
		this.assRevMatBloc = assRevMatBloc;
		// this.toolRepository = toolRepository
	}

	async *mapEventToState(event: CutParamsEvent) {
		if (event instanceof CutParamsSetCutParams) {
			yield* this.mapCutParamsSetCutParamsToState(event as CutParamsSetCutParams);
		} else if (event instanceof CutParamsSetCutParam) {
			yield* this.mapCutParamsSetCutParamToState(event as CutParamsSetCutParam);
		} else if (event instanceof CutParamsSetToolfinderSelection) {
			yield* this.mapCutParamsSetToolfinderSelectionToState(event as CutParamsSetToolfinderSelection);
		} else if (event instanceof CutParamsReset) {
			yield* this.mapCutParamsResetToState(event as CutParamsReset);
		}
	}

	async *mapCutParamsSetCutParamsToState(event: CutParamsSetCutParams) {
		// try {
		//     yield new CutParamsLoading();
		//     var toolsList: Array<Tool> = await this.toolRepository.getFraisageCutParams();
		//     this.tools = toolsList;
		//     yield new CutParamsLoaded(this.tools);
		// } catch (e) {
		//     if (typeof e === "string") {
		//         yield new CutParamsFailure(e);
		//     } else {
		//         yield new CutParamsFailure(e.toString());
		//     }
		// }

		this.toolfinderCutParams = event.toolfinderCutParams;
		yield new CutParamsUpdated(this.toolfinderCutParams);
	}

	async *mapCutParamsResetToState(event: CutParamsReset) {
		this.toolfinderSelection = defaultToolfinderSelection.copyWith({});
		this.toolfinderCutParams = defaultToolfinderCutParams.copyWith({});
		yield new CutParamsUpdated(this.toolfinderCutParams);
	}

	// toDouble(value: any): number {
	//   switch (value.runtimeType) {
	//     case String:
	//       try {
	//         return double.parse(value as String);
	//       } catch (e) {
	//         return 0;
	//       }
	//       break;
	//   }
	// }

	async *mapCutParamsSetCutParamToState(event: CutParamsSetCutParam) {
		switch (event.cutParamType) {
			case CUT_PARAM_TYPE.FRAISAGE_Vc:
				this.toolfinderCutParams.FRAISAGE_Vc = event.value;
				this.fraisageVcChanged();
				break;
			case CUT_PARAM_TYPE.FRAISAGE_n:
				this.toolfinderCutParams.FRAISAGE_n = event.value;
				this.fraisageNChanged();
				break;
			case CUT_PARAM_TYPE.FRAISAGE_d1:
				this.toolfinderCutParams.FRAISAGE_d1 = event.value;
				this.fraisageD1Changed();
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Z:
				this.toolfinderCutParams.FRAISAGE_Z = event.value;
				this.fraisageZChanged();
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Fz:
				this.toolfinderCutParams.FRAISAGE_Fz = event.value;
				this.fraisageFzChanged();
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Vf:
				this.toolfinderCutParams.FRAISAGE_Vf = event.value;
				this.fraisageVfChanged();
				break;
			case CUT_PARAM_TYPE.FRAISAGE_ap:
				this.toolfinderCutParams.FRAISAGE_ap = event.value;
				this.fraisageApChanged();
				break;
			case CUT_PARAM_TYPE.FRAISAGE_ae:
				this.toolfinderCutParams.FRAISAGE_ae = event.value;
				this.fraisageAeChanged();
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Q:
				this.toolfinderCutParams.FRAISAGE_Q = event.value;
				this.fraisageQChanged();
				break;
			case CUT_PARAM_TYPE.FRAISAGE_hm:
				this.toolfinderCutParams.FRAISAGE_hm = event.value;
				this.fraisageHmChanged();
				break;
			case CUT_PARAM_TYPE.FRAISAGE_Emax:
				this.toolfinderCutParams.FRAISAGE_Emax = event.value;
				this.fraisageEmaxChanged();
				break;
			case CUT_PARAM_TYPE.PERCAGE_Vc:
				this.toolfinderCutParams.PERCAGE_Vc = event.value;
				this.percageVcChanged();
				break;
			case CUT_PARAM_TYPE.PERCAGE_n:
				this.toolfinderCutParams.PERCAGE_n = event.value;
				this.percageNChanged();
				break;
			case CUT_PARAM_TYPE.PERCAGE_d1:
				this.toolfinderCutParams.PERCAGE_d1 = event.value;
				this.percageD1Changed();
				break;
			case CUT_PARAM_TYPE.PERCAGE_Z:
				this.toolfinderCutParams.PERCAGE_Z = event.value;
				this.percageZChanged();
				break;
			case CUT_PARAM_TYPE.PERCAGE_Q:
				this.toolfinderCutParams.PERCAGE_Q = event.value;
				this.percageQChanged();
				break;
			case CUT_PARAM_TYPE.PERCAGE_F:
				this.toolfinderCutParams.PERCAGE_F = event.value;
				this.percageFChanged();
				break;
			case CUT_PARAM_TYPE.PERCAGE_Vf:
				this.toolfinderCutParams.PERCAGE_Vf = event.value;
				this.percageVfChanged();
				break;
		}
		yield new CutParamsUpdated(this.toolfinderCutParams, event.cutParamType);
	}

	async *mapCutParamsSetToolfinderSelectionToState(event: CutParamsSetToolfinderSelection) {
		var toolChanged: boolean = false;
		var materialChanged: boolean = false;
		var machineChanged: boolean = false;
		var machiningChanged: boolean = false;

		if (
			this.toolfinderSelection.reference != event.toolfinderSelection.reference ||
			this.toolfinderSelection.article != event.toolfinderSelection.article
		) {
			toolChanged = true;
		}
		if (this.toolfinderSelection.material !== event.toolfinderSelection.material) {
			materialChanged = true;
		}
		if (this.toolfinderSelection.machine !== event.toolfinderSelection.machine) {
			machineChanged = true;
		}
		if (this.toolfinderSelection.machining != event.toolfinderSelection.machining) {
			machiningChanged = true;
		}

		this.toolfinderSelection = event.toolfinderSelection;

		if (toolChanged) {
			//calcul z
			this.calculateFraisageZ();
			//calcul D
			this.calculateFraisageD1();
			this.calculatePercageZ();
			this.calculatePercageD1();
			this.calculatePercageF();
			this.calculatePercageQ();
		}
		if (materialChanged) {
			// Calcul RM
			// this.calculateFraisageRM();
		}

		if (machineChanged) {
			// Calcul Eta
			// this.calculateFraisageEta();
		}

		//calcul ap
		this.calculateFraisageAp();
		//calcul ae
		this.calculateFraisageAe();

		// Calcul Vc
		await this.calculateFraisageVc();
		await this.calculatePercageVc();
		// Calcul Fz
		this.calculateFraisageFz();

		yield new CutParamsUpdated(this.toolfinderCutParams);
	}

	/* ===================================== */
	/* ========== PARTIE Fraisage ========== */
	/* ===================================== */

	async calculateFraisageVc() {
		if (this.toolfinderCutParams.FRAISAGE_Vc_Locked) return;

		if (
			this.toolfinderSelection.material /*&&this.toolfinderSelection.machine*/ &&
			this.toolfinderSelection.coating &&
			this.toolfinderSelection.reference
		) {
			var assRevMat: Array<AssRevMat> = [];
			var assRefMats: Array<AssRefMat> = [];

			if (this.assRefMatBloc && this.assRefMatBloc.state instanceof AssRefMatsLoaded) {
				assRefMats = this.assRefMatBloc.assRefMats;
			}
			if (this.assRevMatBloc && this.assRevMatBloc.state instanceof AssRevMatsLoaded) {
				assRevMat = this.assRevMatBloc.assRevMats;
			}
			// if (this.assRefUsiBloc && this.assRefUsiBloc.state.constructor.name === "AssRefUsiLoaded") {
			//   this.assRefUsis = this.assRefUsiBloc.assRefUsis;
			// }

			// var assRevMat: Array<AssRevMat> = await new AssRevMatRepository().getFraisageAssRevMats();
			// var assRefMats: Array<AssRefMat> = await new AssRefMatRepository().getFraisageAssRefMats();

			var materialCoating: AssRevMat =
				// assRevMat.firstWhere((AssRevMat assRevMat) => assRevMat.matId == toolfinderSelection.material.id && assRevMat.revCode == toolfinderSelection.coating.code, orElse: () => null);
				assRevMat.filter(
					(assRevMat: AssRevMat) =>
						assRevMat.matId == this.toolfinderSelection.material.id && assRevMat.revCode == this.toolfinderSelection.coating.code
				)[0];

			var materialTool: AssRefMat =
				// assRefMats.firstWhere((AssRefMat assRefMat) => assRefMat.matId == toolfinderSelection.material.id && assRefMat.refId == toolfinderSelection.reference.id, orElse: () => null);
				assRefMats.filter(
					(assRefMat: AssRefMat) =>
						assRefMat.matId == this.toolfinderSelection.material.id && assRefMat.refId == this.toolfinderSelection.reference.id
				)[0];

			this.toolfinderCutParams.FRAISAGE_Vc = CalculHelper.getFraisageVc({
				indiceMaterial: this.toolfinderSelection.material.coef ?? 0,
				indiceMachine: this.toolfinderSelection.machine != null ? this.toolfinderSelection.machine.coef ?? 1 : 1,
				indiceMaterialCoating: materialCoating != null ? materialCoating.coef ?? 0 : 0,
				indiceMaterialTool: materialTool != null ? materialTool.coef ?? 0 : 0,
			});

			this.fraisageVcChanged();
		}
	}

	fraisageVcChanged() {
		this.calculateFraisageN();
	}

	// calculateFraisageEta() {
	// 	if (this.toolfinderCutParams.FRAISAGE_n_Locked == true) return;

	// 	if (this.toolfinderSelection.machine) {
	// 		this.toolfinderCutParams.FRAISAGE_n = this.toolfinderSelection.machine.NMax;
	// 		this.EtaChanged();
	// 	}
	// }
	// EtaChanged() {
	// 	this.calculateFraisageP();
	// }

	// calculateFraisageRM() {
	// 	if (this.toolfinderCutParams.rm) return;

	// 	if (this.toolfinderSelection.material) {
	// 		if (this.toolfinderSelection.material.rmMin != null && this.toolfinderSelection.material.rmMax != null) {
	// 			this.toolfinderCutParams.Rm = (this.toolfinderSelection.material.rmMin + this.toolfinderSelection.material.rmMax) / 2;
	// 			this.RMChanged();
	// 		}
	// 	}
	// }
	// RMChanged() {
	// 	this.calculateFraisageP();
	// }

	calculateFraisageAp() {
		if (this.toolfinderCutParams.FRAISAGE_ap_Locked) return;

		if (this.toolfinderSelection.reference && this.toolfinderCutParams.FRAISAGE_d1 && this.toolfinderSelection.machining) {
			this.toolfinderCutParams.FRAISAGE_ap = this.toolfinderSelection.reference.ap;
			this.toolfinderCutParams.FRAISAGE_ap = CalculHelper.getFraisageAp({
				d1: this.toolfinderCutParams.FRAISAGE_d1 ?? 0,
				l1: this.toolfinderSelection.article.dimL1 ?? 0,
				machining: this.toolfinderSelection.machining,
			});

			this.fraisageApChanged();
		}
	}
	fraisageApChanged() {
		// this.calculateFraisageP();
		// this.calculateVolCop();
		this.calculateFraisageQ();
	}

	calculateFraisageAe() {
		if (this.toolfinderCutParams.FRAISAGE_ae_Locked) return;

		if (this.toolfinderSelection.reference && this.toolfinderCutParams.FRAISAGE_d1 && this.toolfinderSelection.machining) {
			// toolfinderCutParams.FRAISAGE_ae = toolfinderSelection.reference.ae;

			this.toolfinderCutParams.FRAISAGE_ae = CalculHelper.getFraisageAe({
				d1: this.toolfinderCutParams.FRAISAGE_d1 ?? 0,
				l1: this.toolfinderSelection.article.dimL1 ?? 0,
				machining: this.toolfinderSelection.machining,
			});

			this.fraisageAeChanged();
		}
	}
	fraisageAeChanged() {
		// this.calculateFraisageP();
		this.calculateFraisageHm();
		this.calculateFraisageQ();
		this.calculateFraisageEmax();
		this.calculateFraisageFz();
		// this.calculateFraisageLgCop();
	}

	calculateFraisageZ() {
		if (this.toolfinderCutParams.FRAISAGE_Z_Locked) return;

		// Todo -> dents en int
		if (this.toolfinderSelection.article) {
			this.toolfinderCutParams.FRAISAGE_Z = this.toolfinderSelection.article.dimZ ?? 0;
			this.fraisageZChanged();
		}
	}
	fraisageZChanged() {
		// this.calculateFraisageVctf();
		this.calculateFraisageVf();
	}

	calculateFraisageD1() {
		if (this.toolfinderCutParams.FRAISAGE_d1_Locked) return;

		if (this.toolfinderSelection.article) {
			this.toolfinderCutParams.FRAISAGE_d1 = this.toolfinderSelection.article.dimD1 ?? 0;
			this.fraisageD1Changed();
		}
	}

	fraisageD1Changed() {
		// this.autoSelectRefOutil();
		// this.calculateFraisageLgCop();
		this.calculateFraisageHm();
		this.calculateFraisageEmax();
		this.calculateFraisageN();
		this.calculateFraisageFz();
		this.calculateFraisageAp();
		this.calculateFraisageAe();
	}

	autoSelectRefOutil() {
		// this.toolfinderSelection.reference = new Reference({});
	}

	// calculateFraisageP() {
	// 	if (this.toolfinderCutParams.PLocked) return;

	// 	if (
	// 		this.toolfinderCutParams.Rm &&
	// 		this.toolfinderCutParams.ap &&
	// 		this.toolfinderCutParams.ae &&
	// 		this.toolfinderCutParams.Vf &&
	// 		this.toolfinderCutParams.eta
	// 	) {
	// 		this.toolfinderCutParams.P = CalculHelper.getFraisageP({
	// 			Rm: this.toolfinderCutParams.Rm,
	// 			ap: this.toolfinderCutParams.ap,
	// 			ae: this.toolfinderCutParams.ae,
	// 			Vf: this.toolfinderCutParams.Vf,
	// 			eta: this.toolfinderCutParams.eta,
	// 		});
	// 	}
	// }

	calculateFraisageVf() {
		if (this.toolfinderCutParams.FRAISAGE_Vf_Locked) return;

		if (this.toolfinderCutParams.FRAISAGE_Fz && this.toolfinderCutParams.FRAISAGE_Z && this.toolfinderCutParams.FRAISAGE_n) {
			this.toolfinderCutParams.FRAISAGE_Vf = CalculHelper.getFraisageVf({
				Fz: this.toolfinderCutParams.FRAISAGE_Fz,
				Z: this.toolfinderCutParams.FRAISAGE_Z,
				n: this.toolfinderCutParams.FRAISAGE_n,
			});
			this.fraisageVfChanged();
		}
	}
	fraisageVfChanged() {
		// this.calculateFraisageP();
		this.calculateFraisageQ();
	}

	// calculateVolCop() {
	// 	if (this.toolfinderCutParams.VolCopLocked) return;

	// 	if (this.toolfinderCutParams.LgCop && this.toolfinderCutParams.hm && this.toolfinderCutParams.ap) {
	// 		this.toolfinderCutParams.VolCop = CalculHelper.getFraisageVolCop({
	// 			LgCop: this.toolfinderCutParams.LgCop,
	// 			hm: this.toolfinderCutParams.hm,
	// 			ap: this.toolfinderCutParams.ap,
	// 		});
	// 		this.VolCopChanged();
	// 	}
	// }
	// VolCopChanged() {
	// 	this.calculateFraisageVctf();
	// }

	calculateFraisageQ() {
		if (this.toolfinderCutParams.FRAISAGE_Q_Locked) return;

		if (this.toolfinderCutParams.FRAISAGE_ap && this.toolfinderCutParams.FRAISAGE_ae && this.toolfinderCutParams.FRAISAGE_Vf) {
			this.toolfinderCutParams.FRAISAGE_Q = CalculHelper.getFraisageQ({
				ap: this.toolfinderCutParams.FRAISAGE_ap,
				ae: this.toolfinderCutParams.FRAISAGE_ae,
				Vf: this.toolfinderCutParams.FRAISAGE_Vf,
			});
			this.fraisageQChanged();
		}
	}

	fraisageQChanged() {}

	// calculateFraisageLgCop() {
	// 	if (this.toolfinderCutParams.LgCopLocked) return;

	// 	if (this.toolfinderCutParams.D && this.toolfinderCutParams.ae) {
	// 		this.toolfinderCutParams.LgCop = CalculHelper.getFraisageLgCop({
	// 			D: this.toolfinderCutParams.D,
	// 			ae: this.toolfinderCutParams.ae,
	// 		});
	// 		this.LgCopChanged();
	// 	}
	// }
	// LgCopChanged() {
	// 	this.calculateVolCop();
	// }

	calculateFraisageHm() {
		if (this.toolfinderCutParams.FRAISAGE_hm_Locked) return;

		if (this.toolfinderCutParams.FRAISAGE_Fz && this.toolfinderCutParams.FRAISAGE_ae && this.toolfinderCutParams.FRAISAGE_d1) {
			this.toolfinderCutParams.FRAISAGE_hm = CalculHelper.getFraisagehm({
				Fz: this.toolfinderCutParams.FRAISAGE_Fz,
				ae: this.toolfinderCutParams.FRAISAGE_ae,
				d1: this.toolfinderCutParams.FRAISAGE_d1,
			});
			this.fraisageHmChanged();
		}
	}
	fraisageHmChanged() {}

	calculateFraisageEmax() {
		if (this.toolfinderCutParams.FRAISAGE_Emax_Locked) return;

		if (this.toolfinderCutParams.FRAISAGE_ae && this.toolfinderCutParams.FRAISAGE_d1 && this.toolfinderCutParams.FRAISAGE_Fz) {
			this.toolfinderCutParams.FRAISAGE_Emax = CalculHelper.getFraisageEmax({
				ae: this.toolfinderCutParams.FRAISAGE_ae,
				d1: this.toolfinderCutParams.FRAISAGE_d1,
				Fz: this.toolfinderCutParams.FRAISAGE_Fz,
			});
			this.fraisageEmaxChanged();
		}
	}
	fraisageEmaxChanged() {}

	// calculateFraisageVctf() {
	// 	if (this.toolfinderCutParams.VctfLocked) return;

	// 	if (this.toolfinderCutParams.VolCop && this.toolfinderCutParams.Z) {
	// 		this.toolfinderCutParams.Vctf = CalculHelper.getFraisageVctf({
	// 			VolCop: this.toolfinderCutParams.VolCop,
	// 			Z: this.toolfinderCutParams.Z,
	// 		});
	// 	}
	// }

	calculateFraisageFz() {
		if (this.toolfinderCutParams.FRAISAGE_Fz_Locked) return;
		if (this.toolfinderCutParams.FRAISAGE_d1 != undefined) {
			this.toolfinderCutParams.FRAISAGE_Fz = CalculHelper.getFraisageFz({ d1: this.toolfinderCutParams.FRAISAGE_d1 ?? 0 });
			this.fraisageFzChanged();
		}
	}
	fraisageFzChanged() {
		this.calculateFraisageHm();
		this.calculateFraisageEmax();
		this.calculateFraisageVf();
	}

	calculateFraisageN() {
		if (this.toolfinderCutParams.FRAISAGE_n_Locked) return;

		if (this.toolfinderCutParams.FRAISAGE_Vc && this.toolfinderCutParams.FRAISAGE_d1) {
			this.toolfinderCutParams.FRAISAGE_n = CalculHelper.getFraisagen({
				Vc: this.toolfinderCutParams.FRAISAGE_Vc,
				d1: this.toolfinderCutParams.FRAISAGE_d1,
			});
			this.fraisageNChanged();
		}
	}
	fraisageNChanged() {
		this.calculateFraisageVf();
		this.calculateFraisageVcFromN();
	}

	calculateFraisageVcFromN() {
		if (this.toolfinderCutParams.FRAISAGE_Vc_Locked == true) return;

		if (this.toolfinderCutParams.FRAISAGE_n != null && this.toolfinderCutParams.FRAISAGE_d1 != null) {
			this.toolfinderCutParams.FRAISAGE_Vc = CalculHelper.getFraisageVcFromN({
				n: this.toolfinderCutParams.FRAISAGE_n,
				d1: this.toolfinderCutParams.FRAISAGE_d1,
			});
			// fraisageNChanged();
		}
	}

	/* ===================================== */
	/* =========== PARTIE PERCAGE ========== */
	/* ===================================== */

	async calculatePercageVc() {
		if (this.toolfinderCutParams.PERCAGE_Vc_Locked) return;

		if (
			this.toolfinderSelection.material /*&&this.toolfinderSelection.machine*/ &&
			this.toolfinderSelection.coating &&
			this.toolfinderSelection.reference &&
			this.toolfinderSelection.machining != null
		) {
			var assRevMat: Array<AssRevMat> = [];
			var assRefMats: Array<AssRefMat> = [];

			if (this.assRefMatBloc && this.assRefMatBloc.state instanceof AssRefMatsLoaded) {
				assRefMats = this.assRefMatBloc.assRefMats;
			}
			if (this.assRevMatBloc && this.assRevMatBloc.state instanceof AssRevMatsLoaded) {
				assRevMat = this.assRevMatBloc.assRevMats;
			}
			// if (this.assRefUsiBloc && this.assRefUsiBloc.state.constructor.name === "AssRefUsiLoaded") {
			//   this.assRefUsis = this.assRefUsiBloc.assRefUsis;
			// }

			// var assRevMat: Array<AssRevMat> = await new AssRevMatRepository().getFraisageAssRevMats();
			// var assRefMats: Array<AssRefMat> = await new AssRefMatRepository().getFraisageAssRefMats();

			var materialCoating: AssRevMat =
				// assRevMat.firstWhere((AssRevMat assRevMat) => assRevMat.matId == toolfinderSelection.material.id && assRevMat.revCode == toolfinderSelection.coating.code, orElse: () => null);
				assRevMat.filter(
					(assRevMat: AssRevMat) =>
						assRevMat.matId == this.toolfinderSelection.material.id && assRevMat.revCode == this.toolfinderSelection.coating.code
				)[0];

			var materialTool: AssRefMat =
				// assRefMats.firstWhere((AssRefMat assRefMat) => assRefMat.matId == toolfinderSelection.material.id && assRefMat.refId == toolfinderSelection.reference.id, orElse: () => null);
				assRefMats.filter(
					(assRefMat: AssRefMat) =>
						assRefMat.matId == this.toolfinderSelection.material.id && assRefMat.refId == this.toolfinderSelection.reference.id
				)[0];

			this.toolfinderCutParams.PERCAGE_Vc = CalculHelper.getPercageVc({
				indiceMaterial: this.toolfinderSelection.material.coef ?? 0,
				indiceMachine: this.toolfinderSelection.machine != null ? this.toolfinderSelection.machine.coef ?? 1 : 1,
				indiceMaterialCoating: materialCoating != null ? materialCoating.coef ?? 0 : 0,
				indiceMaterialTool: materialTool != null ? materialTool.coef ?? 0 : 0,
				machining: this.toolfinderSelection.machining,
			});
      
			this.percageVcChanged();
		}
	}

	percageVcChanged() {
		this.calculatePercageN();
	}

	calculatePercageZ() {
		if (this.toolfinderCutParams.PERCAGE_Z_Locked == true) return;

		// TODO: dents en int
		if (this.toolfinderSelection.article) {
			this.toolfinderCutParams.PERCAGE_Z = this.toolfinderSelection.article.dimZ ?? 0;
			this.percageZChanged();
		}
	}

	percageZChanged() {
		// calculatePercageVctf();
		// calculatePercageVf();
	}

	calculatePercageD1() {
		if (this.toolfinderCutParams.PERCAGE_d1_Locked == true) return;

		if (this.toolfinderSelection.article) {
			this.toolfinderCutParams.PERCAGE_d1 = this.toolfinderSelection.article.dimD1 ?? 0;
			this.percageD1Changed();
		}
	}

	percageD1Changed() {
		// calculatePercageLgCop();
		// calculatePercageHm();
		// calculatePercageEmax();
		this.calculatePercageN();
		this.calculatePercageF();
		this.calculatePercageQ();
	}

	calculatePercageVf() {
		if (this.toolfinderCutParams.PERCAGE_Vf_Locked == true) return;

		if (this.toolfinderCutParams.PERCAGE_F != null && this.toolfinderCutParams.PERCAGE_n != null) {
			this.toolfinderCutParams.PERCAGE_Vf = CalculHelper.getPercageVf({
				F: this.toolfinderCutParams.PERCAGE_F,
				n: this.toolfinderCutParams.PERCAGE_n,
			});
			// toolfinderCutParams.Vf = CalculHelper.getFraisageVf(F: toolfinderCutParams.Fz, Z: toolfinderCutParams.Z, n: toolfinderCutParams.n);
			this.percageVfChanged();
		}
	}

	percageVfChanged() {
		// calculatePercageP();
		this.calculatePercageQ();
	}

	calculatePercageQ() {
		if (this.toolfinderCutParams.PERCAGE_Q_Locked == true) return;

		if (this.toolfinderCutParams.PERCAGE_Vf != null && this.toolfinderCutParams.PERCAGE_d1 != null) {
			this.toolfinderCutParams.PERCAGE_Q = CalculHelper.getPercageQ({
				Vf: this.toolfinderCutParams.PERCAGE_Vf,
				d1: this.toolfinderCutParams.PERCAGE_d1,
			});
			this.percageQChanged();
		}
	}

	percageQChanged() {}

	calculatePercageF() {
		if (this.toolfinderCutParams.PERCAGE_F_Locked == true) return;

		if (this.toolfinderSelection.article != null && this.toolfinderCutParams.PERCAGE_d1 != null) {
			this.toolfinderCutParams.PERCAGE_F = (this.toolfinderCutParams.PERCAGE_d1 ?? 0) / 50;
			this.percageFChanged();
		}
	}

	percageFChanged() {
		// calculatePercageHm();
		// calculatePercageEmax();
		this.calculatePercageVf();
	}

	calculatePercageN() {
		if (this.toolfinderCutParams.PERCAGE_n_Locked == true) return;

		if (this.toolfinderCutParams.PERCAGE_Vc != null && this.toolfinderCutParams.PERCAGE_d1 != null) {
			// print("Vc = ${toolfinderCutParams.Vc}");
			// print("D = ${toolfinderCutParams.D}");
			// print("Result = ${CalculHelper.getFraisagen(Vc: toolfinderCutParams.Vc, D: toolfinderCutParams.D)}");

			this.toolfinderCutParams.PERCAGE_n = CalculHelper.getFraisagen({
				Vc: this.toolfinderCutParams.PERCAGE_Vc,
				d1: this.toolfinderCutParams.PERCAGE_d1,
			});
			this.percageNChanged();
		}
	}

	percageNChanged() {
		this.calculatePercageVf();
		this.calculatePercageVcFromN();
	}

	calculatePercageVcFromN() {
		if (this.toolfinderCutParams.PERCAGE_Vc_Locked == true) return;

		if (this.toolfinderCutParams.PERCAGE_n != null && this.toolfinderCutParams.PERCAGE_d1 != null) {
			this.toolfinderCutParams.PERCAGE_Vc = CalculHelper.getFraisageVcFromN({
				n: this.toolfinderCutParams.PERCAGE_n,
				d1: this.toolfinderCutParams.PERCAGE_d1,
			});
			// fraisageNChanged();
		}
	}

	// Called whenever an `event` is added.
	onEvent(event: CutParamsEvent) {
		if ((!process.env.NODE_ENV || process.env.NODE_ENV === "development") && this.showLog == true) console.log("New event = ", event);
	}

	// Called whenever a state change is about to occur.
	onTransition(transition: Transition<any, any>) {
		if ((!process.env.NODE_ENV || process.env.NODE_ENV === "development") && this.showLog == true) console.log("Transition = ", transition);
	}

	onError(error: any) {
		if ((!process.env.NODE_ENV || process.env.NODE_ENV === "development") && this.showLog == true) console.log("Error = ", error);
	}
}
