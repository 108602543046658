import AssRefMat from "models/ass_ref_mat";

export abstract class AssRefMatsState {}

export class AssRefMatsInitial extends AssRefMatsState {}

export class AssRefMatsLoading extends AssRefMatsState {}

export class AssRefMatsLoaded extends AssRefMatsState {
	assRefMats: Array<AssRefMat>;
	constructor(assRefMats: Array<AssRefMat>) {
		super();
		this.assRefMats = assRefMats;
	}

	public toString = (): string => {
		return `AssRefMatsLoaded ${this.assRefMats.length} assRefMats`;
	};
}

export class AssRefMatsFailure extends AssRefMatsState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `AssRefMatsLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
