import config from "config/config";
import GeneralHelper from "helpers/general_helper";

// interface NamedParameters {
// 	id: number;
// 	code: string;
// 	type: string;
// 	constructorName: string;
// 	coef: number;
// 	createdAt: Date;
// 	longDescription: Record<string, string>;
// 	shortDescription: Record<string, string>;
// 	NMax: number;
// }

export default class Reference {
	// GCO_GOOD_CATEGORY_ID
	id: number;
	// GCO_GOOD_CATEGORY_WORDING
	code: string;

	// REF_ACTIF
	status: boolean;
	// MATUSI_SGRP_COEF
	active: boolean;

	// REF_TOOLFINDER
	revReferencefinder: boolean;

	// REF_TOOLFINDER_AP
	ap: number;
	// REF_TOOLFINDER_AE
	ae: number;

	// REF_IMAGE
	image: string;

	// FREE_DESC_XX
	description: Record<string, string>;

	// URL_PDF_XX
	pdfUrl: Record<string, string>;

	affinity: number;
	compatible: boolean;

	constructor({ id, code, status, active, revReferencefinder, ap, ae, description, affinity, compatible, image, pdfUrl }: any) {
		this.id = id;
		this.code = code;
		this.status = status;
		this.active = active;
		this.revReferencefinder = revReferencefinder;
		this.ap = ap;
		this.ae = ae;
		this.description = description;
		this.affinity = affinity;
		this.image = image;
		this.pdfUrl = pdfUrl;
		this.compatible = compatible ?? true;
	}

	static areIdentical(a: Reference, b: Reference): boolean {
		if (!a && !b) return true;
		if (!a || !b) return false;
		return a.id === b.id;
	}

	setAffinity(affinity: number) {
		this.affinity = affinity;
	}

	setCompatibility(compatibility: boolean) {
		this.compatible = compatibility;
	}

	static fromJson(map: Record<string, any>): Reference {
		return new Reference({
			id: GeneralHelper.parseIntOrNull(map["GCO_GOOD_CATEGORY_ID"]),
			code: map["GCO_GOOD_CATEGORY_WORDING"],
			status: map["REF_STATUS"] == "ACTIF" ? true : false,
			active: map["REF_ACTIF"],
			image: GeneralHelper.avoidNullOrEmptyString(map["REF_IMAGE"]),
			revReferencefinder: map["REF_TOOLFINDER"],
			ap: GeneralHelper.parseDoubleOrNull(map["REF_TOOLFINDER_AP"]),
			ae: GeneralHelper.parseDoubleOrNull(map["REF_TOOLFINDER_AE"]),
			description: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["FREE_DESC_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["FREE_DESC_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["FREE_DESC_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["FREE_DESC_IT"]),
			},
			pdfUrl: {
				fr: GeneralHelper.avoidNullOrEmptyString(map["URL_PDF_FR"]),
				de: GeneralHelper.avoidNullOrEmptyString(map["URL_PDF_DE"]),
				en: GeneralHelper.avoidNullOrEmptyString(map["URL_PDF_EN"]),
				it: GeneralHelper.avoidNullOrEmptyString(map["URL_PDF_IT"]),
			},
			// affinity: map[''],
		});
	}

	getDescription(lang: string): string {
		return this.description[lang] != null && this.description[lang] != ""
			? this.description[lang]
			: this.description[config["FALLBACK_LOCALE"]] ?? "";
	}

	getPdfUrl(lang: string): string {
		return this.pdfUrl[lang] != null && this.pdfUrl[lang] != "" ? this.pdfUrl[lang] : this.pdfUrl[config["FALLBACK_LOCALE"]] ?? "";
	}
}
