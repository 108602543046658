import React from "react";
import Footer from "./footer";
import Header from "./header";
import './base.scss';

type Props = {
    children: JSX.Element[] | JSX.Element,
    center: boolean,
    actionButtons: JSX.Element[] | JSX.Element,
}

export default class BaseScreen extends React.Component<Props, {}> {

    public static defaultProps = {
        center: false,
        actionButtons: null as any,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            //state properties here
        };
    }
    render() {
        return (
            <div className="App d-flex">

                <Header />
                <main className={"content w-100 d-flex flex-shrink-0 flex-1" + (this.props.center === true ? " align-items-center" : "")}>

                    {this.props.children}
                </main>
                <Footer actionButtons={this.props.actionButtons} />
            </div>
        );
    }
}

