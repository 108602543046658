import MachiningGroup from "models/machining_group";

export abstract class MachiningGroupsState {}

export class MachiningGroupsInitial extends MachiningGroupsState {}

export class MachiningGroupsLoading extends MachiningGroupsState {}

export class MachiningGroupsLoaded extends MachiningGroupsState {
	machiningGroups: Array<MachiningGroup>;
	constructor(machiningGroups: Array<MachiningGroup>) {
		super();
		this.machiningGroups = machiningGroups;
	}

	public toString = (): string => {
		return `MachiningGroupsLoaded ${this.machiningGroups.length} machiningGroups`;
	};
}

export class MachiningGroupsFailure extends MachiningGroupsState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `MachiningGroupsLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
