import React from "react";
import "./material_item.scss";
import { BlocsContext } from "contexts/blocs_context";
import { FavoritesMaterialsRemove } from "blocs/favorites_materials_bloc/favorites_materials_event";
import FavoriteMaterial from "models/favorite_material";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {
	favorite: FavoriteMaterial;
	onClick: Function;
}

type State = {
	isFavorite: boolean;
};

class MaterialItem extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;

	constructor(props: Props) {
		super(props);
		this.state = {
			isFavorite: false,
		};
	}

	render() {
		return (
			<div className={"favorite-material-item d-flex flex-column justify-content-center align-items-center"}>
				<span className="icon icon-d-cube"></span>
				{this.props.favorite.material.code}
				<div className="d-flex justify-content-center text-center added-at py-2">
					{/* <span className="icon icon-heart-o favorite"></span> */}
					{this.props.t("screen.favorites_materials.added_at")} {this.props.favorite.createdAt.format("DD.MM.YYYY")}
				</div>

				<button
					className="remove-favorite"
					onClick={(event) => {
						this.context.favoritesMaterialsBloc.add(new FavoritesMaterialsRemove(this.props.favorite));
						event.stopPropagation();
					}}
				>
					<span className="icon icon-bin"></span>
				</button>

				{/* <span className="icon icon-heart-o favorite"></span> */}
			</div>
		);
	}
}

export default withTranslation("common")(MaterialItem);
