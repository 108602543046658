// import Material from "./material";

// export default class Coating {
// 	id: number;
// 	publicName: string;
// 	comercialName: string;
// 	code: string;
// 	indice: number;
// 	indicesMaterial: Record<number, number>;
// 	affinity: number;

// 	constructor(
// 		{
// 			id,
// 			publicName,
// 			comercialName,
// 			code,
// 			indice = null as any,
// 			indicesMaterial = {
// 				1: 3,
// 				2: 3,
// 				3: 4,
// 				4: 3,
// 				5: 3,
// 				6: 2,
// 				7: 3,
// 				8: 2,
// 				9: 3,
// 				10: 2,
// 				11: 2,
// 				12: 2,
// 				13: 3,
// 				14: 2,
// 				15: 3,
// 				16: 1,
// 				17: 1,
// 				18: 3,
// 				19: 4,
// 				20: 3,
// 				21: 2,
// 				22: 2,
// 				23: 1,
// 				24: 3,
// 				25: 2,
// 				26: 1,
// 				27: 1,
// 				28: 1,
// 				29: 1,
// 				30: 1,
// 				31: 3,
// 			},
// 			affinity = 0,
// 		}: any /*NamedParameters*/
// 	) {
// 		this.id = id;
// 		this.publicName = publicName;
// 		this.comercialName = comercialName;
// 		this.code = code;
// 		this.indice = indice;
// 		this.indicesMaterial = indicesMaterial;
// 		this.affinity = affinity;
// 	}

// 	static areIdentical(a: Coating, b: Coating): boolean {
// 		if (!a && !b) return true;
// 		if (!a || !b) return false;
// 		return a.id === b.id;
// 	}

// 	setAffinity({ material }: { material: Material }) {
// 		if (material && this.indicesMaterial[material.id]) {
// 			this.affinity = this.indicesMaterial[material.id];
// 			return;
// 		}

// 		this.affinity = 0;
// 	}
// }

import config from "config/config";
import GeneralHelper from "helpers/general_helper";
import Reference from "./reference";
import AssRefMatsBloc from "blocs/ass_ref_mat_bloc/ass_ref_mat_bloc";
import AssRefUsisBloc from "blocs/ass_ref_usi_bloc/ass_ref_usi_bloc";
import AssRefMat from "./ass_ref_mat";
import AssRefUsi from "./ass_ref_usi";
import Material from "./material";
import Machining from "./machining";
import { AssRefUsisLoaded } from "blocs/ass_ref_usi_bloc/ass_ref_usi_state";
import { AssRefMatsLoaded } from "blocs/ass_ref_mat_bloc/ass_ref_mat_state";
import AssRevMat from "./ass_rev_mat";
import AssRevMatsBloc from "blocs/ass_rev_mat_bloc/ass_rev_mat_bloc";
import { AssRevMatsLoaded } from "blocs/ass_rev_mat_bloc/ass_rev_mat_state";

export default class Coating {
  // REV_CODE
  code: string;
  // REV_NOM
  name: string;
  // CODE_XX
  publicCode: Record<string, string>;
  // PUBLIC_NAME_XX
  publicName: Record<string, string>;
  // STATUT
  status: boolean;

  // ORDRE
  order: number;
  // REV_TOOLFINDER
  revToolfinder: boolean;
  // STATUT
  affinity: number;

  // REV_D_CIBLE_MIN
  dCibleMin: number | undefined;

  // REV_D_CIBLE_MAX
  dCibleMax: number | undefined;

  constructor({ code, name, publicCode, publicName, status, order, revToolfinder, affinity, dCibleMin, dCibleMax }: any /*NamedParameters*/) {
    // console.log({ code, name, publicCode, publicName, status, order, revToolfinder, affinity, dCibleMin, dCibleMax });
    this.code = code;
    this.name = name;
    this.publicCode = publicCode;
    this.publicName = publicName;
    this.status = status;
    this.order = order;
    this.revToolfinder = revToolfinder;
    this.affinity = affinity;
    this.dCibleMin = dCibleMin;
    this.dCibleMax = dCibleMax;
  }

  static areIdentical(a: Coating, b: Coating): boolean {
    if (!a && !b) return true;
    if (!a || !b) return false;
    return a.code === b.code;
  }

  setAffinity(affinity: number) {
    this.affinity = affinity;
  }

  // setAffinity({ material }: { material: Material }) {
  // 	if (material && this.indicesMaterial[material.id]) {
  // 		this.affinity = this.indicesMaterial[material.id];
  // 		return;
  // 	}

  // 	this.affinity = 0;
  // }

  static fromJson(map: Record<string, any>): Coating {


    const newCoating =  new Coating({
      id: GeneralHelper.parseIntOrNull(map["REV_MATUSI_SGRP_ID"]),
      usiId: GeneralHelper.parseIntOrNull(map["MATUSI_SGRP_ID"]),
      refId: GeneralHelper.parseIntOrNull(map["REV_CODE"]),
      coef: map["REV_MATUSI_SGRP_COEF"],
      usiGrpId: GeneralHelper.parseIntOrNull(map["MATUSI_GRP_ID"]),

      code: map["REV_CODE"],
      name: map["REV_NOM"],
      publicCode: {
        fr: GeneralHelper.avoidNullOrEmptyString(map["CODE_FR"]),
        de: GeneralHelper.avoidNullOrEmptyString(map["CODE_DE"]),
        en: GeneralHelper.avoidNullOrEmptyString(map["CODE_EN"]),
        it: GeneralHelper.avoidNullOrEmptyString(map["CODE_IT"]),
      },
      publicName: {
        fr: GeneralHelper.avoidNullOrEmptyString(map["PUBLIC_NAME_FR"]),
        de: GeneralHelper.avoidNullOrEmptyString(map["PUBLIC_NAME_DE"]),
        en: GeneralHelper.avoidNullOrEmptyString(map["PUBLIC_NAME_EN"]),
        it: GeneralHelper.avoidNullOrEmptyString(map["PUBLIC_NAME_IT"]),
      },
      status: map["STATUT"] == "ACTIF" ? true : false,
      // order: map['ORDRE'],
      revToolfinder: map["REV_TOOLFINDER"],
      dCibleMin: map["REV_D_CIBLE_MIN"],
      dCibleMax: map["REV_D_CIBLE_MAX"],
      // affinity: map[''],
    });
    return newCoating;
  }

  toJson() {
    return {
      code: this.code,
      name: this.name,
      publicCode: this.publicCode,
      publicName: this.publicName,
      status: this.status,
      order: this.order,
      revToolfinder: this.revToolfinder,
      affinity: this.affinity,
      dCibleMin: this.dCibleMin,
      dCibleMax: this.dCibleMax,
    };
  }

  getPublicName(lang: string): string {
    return this.publicName[lang] != null && this.publicName[lang] != "" ? this.publicName[lang] : this.publicName[config["FALLBACK_LOCALE"]] ?? "";
  }

  getAffinityWithRef(reference: Reference, material: Material | undefined, assRevMatBloc: AssRevMatsBloc): number {
    // // assRefMat
    // // assRefUsi

    // let assRefMats: Array<AssRefMat> = [];
    let assRevMats: Array<AssRevMat> = [];
    // let assRefUsis: Array<AssRefUsi> = [];

    // if (assRefMatsBloc && assRefMatsBloc.state instanceof AssRefMatsLoaded) {
    // 	assRefMats = assRefMatsBloc.assRefMats;
    // }
    if (assRevMatBloc && assRevMatBloc.state instanceof AssRevMatsLoaded) {
      assRevMats = assRevMatBloc.assRevMats;
    }
    // if (assRefUsisBloc && assRefUsisBloc.state instanceof AssRefUsisLoaded) {
    // 	assRefUsis = assRefUsisBloc.assRefUsis;
    // }

    // var assRefMat: AssRefMat = null as any;
    // if (material != null) {
    //   assRefMat = assRefMats.find((assRefMat: AssRefMat) => {
    //     return assRefMat.refId == reference.id && assRefMat.matId == material.id;
    //   }) as any;
    // }

    // var assRefUsi: AssRefUsi = null as any;
    // if (machining != null) {
    //   assRefUsi = assRefUsis.find((assRefUsi: AssRefUsi) => {
    //     return assRefUsi.refId == reference.id && assRefUsi.usiId == machining.id;
    //   }) as any;
    // }

    // var affinity: number = ((assRefMat != null ? assRefMat.coef : 0) + (assRefUsi != null ? assRefUsi.coef : 0)) / 2;

    var assRevMat: AssRevMat = undefined as any;
    if (material != null) {
      assRevMat = assRevMats.find((assRevMat: AssRevMat) => assRevMat.revCode == this.code && assRevMat.matId == material.id) as any;
    }

    var affinity: number = assRevMat != undefined ? assRevMat.coef ?? 0 : 0;

    return affinity;
  }
}
