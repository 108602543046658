import React from "react";
import "./tool_item.scss";
import { BlocsContext } from "contexts/blocs_context";
import { FavoritesToolsRemove } from "blocs/favorites_tools_bloc/favorites_tools_event";
import { WithTranslation, withTranslation } from "react-i18next";
import FavoriteTool from "models/favorite_tool";

interface Props extends WithTranslation {
	favorite: FavoriteTool;
	onClick: Function;
}

type State = {
	isFavorite: boolean;
};

class ToolItem extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;

	constructor(props: Props) {
		super(props);
		this.state = {
			isFavorite: false,
		};
	}

	render() {
		return (
			<div className={"favorite-tool-item align-items-center justify-content-between"}>
				<div className="d-flex justify-content-start align-items-center left-part">
					<div className="d-flex flex-column justify-content-start align-items-start text-start">
						<div>
							{this.props.t("screen.favorites_tools.tool_ref")} : <span className="ref">{this.props.favorite.articleCode} {this.props.favorite.revCode ?? "" /*this.props.t('screen.favorites_tools.without_coating')*/}</span>
						</div>
            {/* <div>
							{this.props.t("screen.favorites_tools.tool_coating")} : <span className="ref">{this.props.favorite.revCode}</span>
						</div> */}
						<div className="d-flex justify-content-center text-start added-at">
							<span className="icon icon-heart-o favorite"></span>
							{this.props.t("screen.favorites_tools.added_at")} {this.props.favorite.createdAt.format("DD.MM.YYYY")}
						</div>
					</div>
				</div>
				<div className="right-part">
					<button
						className="remove-favorite"
						onClick={(event) => {
							this.context.favoritesToolsBloc.add(new FavoritesToolsRemove(this.props.favorite));
							event.stopPropagation();
						}}
					>
						<span className="icon icon-bin"></span>
					</button>
				</div>
			</div>
		);
	}
}

export default withTranslation("common")(ToolItem);
