import { BlocsContext } from "contexts/blocs_context";
import BaseScreen from "layouts/base";
import React from "react";
import "./step_machining.scss";
import { Subscription } from "rxjs";
import Machining from "models/machining";
import { withRouter, RouteComponentProps } from "react-router-dom";
import BackButton from "components/back_button";
import { ToolfinderSetSelections } from "../blocs/toolfiinder_selections_bloc/toolfiinder_selections_event";
import ToolfinderSelection from "models/toolfinder_selection";
import { ToolfinderSelectionsState, ToolfinderSelectionsUpdated } from "../blocs/toolfiinder_selections_bloc/toolfiinder_selections_state";
import { MACHININGS } from "constants/constants";
import { WithTranslation, withTranslation } from "react-i18next";
import StepsResume from "../components/steps_resume";
import MachiningGroup from "models/machining_group";
import MachiningRepository from "repositories/machining_repository";
import MachiningGroupRepository from "repositories/machining_grp_repository";
import { USINAGES_IMAGES } from "constants/constants";
import defaultMachiningImage from "assets/data_images/default_usinage_picture.png";
import config from "config/config";

interface Props extends RouteComponentProps<any>, WithTranslation {}

enum SortBy {
	AFFINITY,
	NAME,
}

type State = {
	machiningsParents: Array<MachiningGroup>;
	machiningsChildren: Array<Machining>;
	// rawMachiningsList:
	// machinings: Array<Machining>;
	selectedParentMachining: MachiningGroup;
	selectedMachining: Machining;
	toolfinderSelection: ToolfinderSelection;
	sortby: SortBy;
	searchQuery: string;
};

class StepMachiningScreen extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;
	// history = useHistory();

	// static contextTypes = {
	//     contextType: BlocsContext,
	//     router: () => true, // replace with PropTypes.object if you use them
	//   }
	// declare context: React.ContextType<typeof BlocsContext>

	private machiningsBlocSubscription: Subscription = Subscription.EMPTY;
	private toolfinderSelectionBlocSubscription: Subscription = Subscription.EMPTY;

	constructor(props: Props) {
		super(props);
		this.state = {
			machiningsParents: [],
			machiningsChildren: [],
			selectedMachining: null as any,
			selectedParentMachining: null as any,
			toolfinderSelection: null as any,
			sortby: SortBy.AFFINITY,
			searchQuery: "",
		};
	}

	componentDidMount(): void {
		this.toolfinderSelectionBlocSubscription = this.context.toolfinderSelectionBloc.listen(this.toolfinderSelectionBlocAction);
		this.toolfinderSelectionBlocAction(this.context.toolfinderSelectionBloc.state);

		this.initData();
	}

	async initData() {
		var machinings: Array<Machining> = await new MachiningRepository().getData();
		var machiningsGroups: Array<MachiningGroup> = await new MachiningGroupRepository().getData();
		this.setState({
			machiningsChildren: machinings,
			machiningsParents: machiningsGroups,
			selectedParentMachining: this.state.selectedParentMachining == null ? machiningsGroups[0] : this.state.selectedParentMachining,
		});
	}

	componentWillUnmount(): void {
		this.machiningsBlocSubscription.unsubscribe();
		this.toolfinderSelectionBlocSubscription.unsubscribe();
	}

	toolfinderSelectionBlocAction = (state: ToolfinderSelectionsState) => {
		if (state instanceof ToolfinderSelectionsUpdated) {
			this.setState({
				toolfinderSelection: state.toolfinderSelection,
				selectedMachining: state.toolfinderSelection.machining ? state.toolfinderSelection.machining : this.state.selectedMachining,
			});
		}
	};

	renderActionButtons() {
		return (
			<div>
				<button
					className="btn btn-dark custom-btn text-white mx-2"
					onClick={(e) => {
						this.props.history.push("/dashboard");
					}}
				>
					<span className="icon icon-arrow-left2 icon-left"></span>
					{this.props.t("screen.step_machining.cancel_back_button")}
				</button>
				<button
					className="btn btn-primary text-white custom-btn mx-2"
					onClick={(e) => {
						if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated) {
							this.context.toolfinderSelectionBloc.add(
								new ToolfinderSetSelections(this.state.toolfinderSelection.copyWith({ machining: this.state.selectedMachining }))
							);
						}
						this.props.history.push("/dashboard");
					}}
				>
					<span className="icon icon-save icon-left"></span>
					{this.props.t("screen.step_machining.save_button")}
				</button>
			</div>
		);
	}

	render() {
		return (
			<BaseScreen actionButtons={this.renderActionButtons()}>
				<div className="w-100 step-machining d-flex flex-column align-items-center">
					<StepsResume />
					{/* <div className="border-bottom mb-4 mt-2 w-100 mx-auto">
						<div className="container mb-4">
							<BackButton onClick={this.props.history.goBack} text={this.props.t("general.back_to_dashboard")} />
						</div>
					</div> */}
					<div className="w-100 mx-auto mt-5">
						<div className="container align-items-center mx-auto">
							<div className="row">
								<div className="col-xl-3 col-lg-4 col-md-12">
									<h3>{this.props.t("screen.step_machining.machining_type_title")}</h3>
									<div className="parents-machining-container">
										{this.state.machiningsParents.map((machiningGroup: MachiningGroup) => {
											return (
												<button
                          key={machiningGroup.id}
													className={
														"parent-machining-item " +
														(this.state.selectedParentMachining != null && this.state.selectedParentMachining.id === machiningGroup.id
															? "selected"
															: "")
													}
													onClick={() => this.setState({ selectedParentMachining: machiningGroup })}
												>
													{machiningGroup.longDescription[this.props.i18n.language]}
												</button>
											);
										})}
									</div>
								</div>
								<div className="col-xl-8 col-lg-7 col-md-12 offset-lg-1">
									<h3>{this.props.t("screen.step_machining.application_choice_title")}</h3>
									<div className="alert alert-primary my-4" role="alert">
										{this.props.t("screen.step_machining.warning_text")}
									</div>
									<div className="d-flex flex-wrap">
										{this.state.machiningsChildren
											.filter((machining: Machining) => machining.usiGrpId == this.state.selectedParentMachining.id)
											.map((machining: Machining) => {
												return (
													<button
                            key={machining.id}
														className={
															"machining-item " +
															(this.state.selectedMachining != null && this.state.selectedMachining.id === machining.id ? "selected" : "")
														}
														onClick={() => {
															this.setState({ selectedMachining: machining });
															if (this.context.toolfinderSelectionBloc.state instanceof ToolfinderSelectionsUpdated) {
																this.context.toolfinderSelectionBloc.add(
																	new ToolfinderSetSelections(this.state.toolfinderSelection.copyWith({ machining: machining }))
																);
															}
															this.props.history.push("/dashboard");
														}}
													>
														{/* {USINAGES_IMAGES[machining.id] != undefined && (
															<div className="image-wrapper">
																<img src={USINAGES_IMAGES[machining.id].default} width="50px" />
															</div>
														)} */}

														<div className="image-wrapper">
															<img
																src={config["lb_assets_base"] + machining.image}
																onError={(e) => {
																	// e.currentTarget.sr
																	e.currentTarget.onerror = null;
																	e.currentTarget.src = defaultMachiningImage;
																}}
																width="50px"
															/>
														</div>

														{machining.longDescription[this.props.i18n.language]}
													</button>
												);
											})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</BaseScreen>
		);
	}
}

export default withTranslation("common")(withRouter(StepMachiningScreen));
