import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import Modal from "react-modal";
import "./news_modal.scss";
import { BlocsContext } from "contexts/blocs_context";

import moment from "moment";
import Message from "models/message";
import GeneralHelper from "helpers/general_helper";

interface Props extends RouteComponentProps<any>, WithTranslation {
	modalOpened: boolean;
	closeModal: Function;
	message: Message|null;
}

type State = {
	modalOpened: boolean;
};


class NewsModal extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;

	componentRef: any;

	constructor(props: Props) {
		super(props);
		this.state = {
			modalOpened: this.props.modalOpened,
		};

		this.componentRef = React.createRef();
	}

	componentWillReceiveProps(nextProps: any) {
		// You don't have to do this check first, but it can help prevent an unneeded render
		if (nextProps.modalOpened !== this.props.modalOpened) {
			this.setState({ modalOpened: nextProps.modalOpened });
		}
	}

	openModal = () => {
		this.setState({
			modalOpened: true,
		});
	};

	render() {
    let newsText = "";
    if(this.props.message){
      // newsText = GeneralHelper.removeHtml(this.props.message.getText(this.props.i18n.language));
      newsText = this.props.message.getText(this.props.i18n.language);

    }

		return (
			<Modal
				id="news_modal"
				isOpen={this.state.modalOpened}
				// onAfterOpen={afterOpenModal}
				onRequestClose={() => this.props.closeModal()}
				style={{
					content: {
						top: "50%",
						left: "50%",
						right: "auto",
						bottom: "auto",
						marginRight: "-50%",
						transform: "translate(-50%, -50%)",
						width: 400,
						padding: 35,
						borderRadius: 10,
					},
					overlay: {
						backgroundColor: "rgba(0,0,0,0.7)",
					},
				}}
				contentLabel={this.props.t("screen.news_modal.title")}
			>
				{/* {this.renderImageExport()} */}
				<div className="d-flex flex-row mb-3 justify-content-between">
					<span className="d-inline title">{this.props.t("screen.news_modal.title")}</span>
          <span className="d-inline date text-primary">{this.props.message?.displayFrom.format("DD.MM.YYYY")}</span>
				</div>

				{/* <div className="">{newsText}</div> */}
        <div className="fs-15i" dangerouslySetInnerHTML={{__html: newsText }}></div>


		<div className="d-flex flex-row justify-content-end">

				<button className="btn btn-primary text-white mt-4 px-5 py-2" onClick={(event) => this.props.closeModal()}>
					
					{this.props.t("screen.news_modal.close")}
				</button>
    </div>
			</Modal>
		);
	}
}

export default withTranslation("common")(withRouter(NewsModal));
