import React from "react";
import "./reference_item.scss";
import Reference from "models/reference";
import { BlocsContext } from "contexts/blocs_context";
import { BlocBuilder } from "@felangel/react-bloc";
// import { FavoritesReferencesLoaded, FavoritesReferencesState } from "blocs/favorites_references_bloc/favorites_references_state";
// import { FavoritesReferencesAdd, FavoritesReferencesRemove } from "blocs/favorites_references_bloc/favorites_references_event";
import { WithTranslation, withTranslation } from "react-i18next";
// import FavoriteT from "models/favorite_reference";
import defaultImage from "assets/data_images/default_tool_picture.png";
import config from "config/config";

interface Props extends WithTranslation {
	selected: boolean;
	reference: Reference;
	bestReference: boolean;
	onClick: Function;
}

type State = {
	isFavorite: boolean;
};

class ReferenceItem extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;

	constructor(props: Props) {
		super(props);
		this.state = {
			isFavorite: false,
		};
	}

	render() {
		return (
			<button
				onClick={(e) => this.props.onClick()}
				className={"reference-item d-flex justify-content-between align-items-center " + (this.props.selected === true ? "active" : "")}
			>
				<div className="d-flex flex-column justify-content-start align-items-start">
					{this.props.bestReference == true && <div className="bestTool">Meilleur outil</div>}
					<div className="d-flex justify-content-start align-items-center referenceNameWrapper">{this.props.reference.code}</div>

					<div className="ref">{this.props.reference.description[this.props.i18n.language]}</div>
					{/* <span className="icon icon-heart-o favorite"></span> */}

					<div className="image-wrapper">
						<img
							src={config["lb_assets_base"] + this.props.reference.image}
							onError={(e) => {
								// e.currentTarget.sr
								e.currentTarget.onerror = null;
								e.currentTarget.src = defaultImage;
							}}
							className="ref-image"
						/>
					</div>

          {this.props.reference.getPdfUrl(this.props.i18n.language) != null && this.props.reference.getPdfUrl(this.props.i18n.language) != "" &&
					<a
						className="pdf-button"
						onClick={(event) => {
							event.stopPropagation();
              var url = this.props.reference.getPdfUrl(this.props.i18n.language); 
              const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
              if (newWindow) newWindow.opener = null;
              return null;
						}}
					>
          
						<span className="icon icon-pdf"></span>
						{this.props.t("screen.step_tool.pdfFile")} →
					</a>
          }
				</div>

				<div className="d-flex flex-1 justify-content-cener align-items-end">
					<div className="d-flex flex-column justify-content-cener align-items-center">
						<div className="affinity">{this.props.reference.affinity != undefined ? Number(this.props.reference.affinity).toFixed(2) : "n/a"}</div>
						<div className="affinity-text">{this.props.t("screen.step_tool.afinity_score")}</div>
					</div>
				</div>
			</button>
		);
	}
}

export default withTranslation("common")(ReferenceItem);
