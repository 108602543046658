import FavoriteMaterial from "models/favorite_material";

export abstract class FavoritesMaterialsState {}

export class FavoritesMaterialsInitial extends FavoritesMaterialsState {}

export class FavoritesMaterialsLoading extends FavoritesMaterialsState {}

export class FavoritesMaterialsLoaded extends FavoritesMaterialsState {
	favorites: Array<FavoriteMaterial>;
	constructor(favorites: Array<FavoriteMaterial>) {
		super();
		this.favorites = favorites;
	}

	public toString = (): string => {
		return `FavoritesMaterialsLoaded ${this.favorites.length} coatings`;
	};
}

export class FavoritesMaterialsFailure extends FavoritesMaterialsState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `FavoritesMaterialsLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
