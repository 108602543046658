import FavoriteMaterial from "models/favorite_material";

export abstract class FavoritesMaterialsEvent {}

export class FavoritesMaterialsInit extends FavoritesMaterialsEvent {}
export class FavoritesMaterialsLoad extends FavoritesMaterialsEvent {}
export class FavoritesMaterialsClear extends FavoritesMaterialsEvent {}
export class FavoritesMaterialsAdd extends FavoritesMaterialsEvent {
	favorite: FavoriteMaterial;
	constructor(favorite: FavoriteMaterial) {
		super();
		this.favorite = favorite;
	}
}
export class FavoritesMaterialsRemove extends FavoritesMaterialsEvent {
	favorite: FavoriteMaterial;
	constructor(favorite: FavoriteMaterial) {
		super();
		this.favorite = favorite;
	}
}
