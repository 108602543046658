import Machine from "models/machine";

export abstract class MachinesState {}

export class MachinesInitial extends MachinesState {}

export class MachinesLoading extends MachinesState {}

export class MachinesLoaded extends MachinesState {
	machines: Array<Machine>;
	constructor(machines: Array<Machine>) {
		super();
		this.machines = machines;
	}

	public toString = (): string => {
		return `MachinesLoaded ${this.machines.length} machines`;
	};
}

export class MachinesFailure extends MachinesState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `MachinesLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
