import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import BaseScreen from "layouts/base";
import { WithTranslation, withTranslation } from "react-i18next";
import { BlocsContext } from "contexts/blocs_context";
import { AuthenticationLoggedIn, AuthenticationLoggedOut } from "blocs/authentication_bloc/authentication_event";
import AuthenticationData from "models/authentication_data";
import AuthenticationService from "services/authentication";
import User from "models/user";
import { AuthenticationAuthenticated, AuthenticationState } from "blocs/authentication_bloc/authentication_state";
import { BlocBuilder } from "@felangel/react-bloc";
import { CartState, CartUpdated } from "blocs/cart_bloc/cart_state";
import "./cart.scss";
import Article from "models/article";
import CartArticle from "models/cart_article";
import { CartEditArticleQuantity, CartRemoveArticle, CartUpdateAll } from "blocs/cart_bloc/cart_event";
import { Link } from "react-router-dom";
import EmptyImageSrc from "assets/images/empty.svg";
import config from "config/config";
import Reference from "models/reference";
import { ReferencesLoaded } from "blocs/references_bloc/references_state";
import defaultImage from "assets/data_images/default_tool_picture.png";

interface Props extends WithTranslation { }

const CartScreen: React.FC<Props> = ({ t, i18n }) => {
  // const blocsContext = useContext(BlocsContext);
  const blocsContext = useContext(BlocsContext);

  const [initialArticles, setInitialArticles] = useState<Array<CartArticle>>([]);
  const [articles, setArticles] = useState<Array<CartArticle>>([]);

  useEffect(() => {
    const subscription = blocsContext.cartBloc.listen((data) => {
      if (data instanceof CartUpdated) {
        const cartArticles: Array<CartArticle> = data.cart.articles;
        setInitialArticles(cartArticles);
        setArticles(cartArticles);
      }
    });

    if (blocsContext.cartBloc.state instanceof CartUpdated) {
      const cartArticles: Array<CartArticle> = blocsContext.cartBloc.state.cart.articles;
      setInitialArticles(cartArticles);
      setArticles(cartArticles);
    }

    return () => {
      // Nettoyez l'abonnement lorsque le composant est démonté
      subscription.unsubscribe();
    };
  }, [blocsContext.cartBloc]);



  const updateStateCartArticle = (cartArticle: CartArticle, newQty: number) => {
    var newCartArticles = articles.map((stateCartArticle: CartArticle) => {
      if (stateCartArticle.article.id == cartArticle.article.id && stateCartArticle.rev == cartArticle.rev) {
        return new CartArticle({
          article: cartArticle.article,
          qty: newQty,
          rev: cartArticle.rev,
        });
      }
      return stateCartArticle;
    });
    setArticles(newCartArticles);
  }

  // Check if the local cart is up to date with the bloc cart
  const isLocalCartUpToDate = () => {
    return articles.every((cartArticle: CartArticle) => {
      var blocCartArticle = initialArticles.find(
        (blocCartArticle: CartArticle) => blocCartArticle.article.id == cartArticle.article.id && blocCartArticle.rev == cartArticle.rev
      );
      return blocCartArticle != undefined && blocCartArticle.qty == cartArticle.qty;
    });
  }


  const renderTableLine = (cartArticle: CartArticle) => {
    var reference: Reference | undefined = undefined;
    if (blocsContext.referencesBloc.state instanceof ReferencesLoaded) {
      reference = blocsContext.referencesBloc.state.references.find(
        (ref: Reference) => ref.id == cartArticle.article.refId
      );
    }

    return (
      <tr key={cartArticle.article.id + (cartArticle.rev ?? "")}>
        <td>
          <div className="d-flex">
            <div className="image-wrapper">
              <img
                src={config["lb_assets_base"] + reference?.image}
                onError={(e) => {
                  // e.currentTarget.sr
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = defaultImage;
                }}
                className="ref-image"
              />
            </div>

            <div className="d-flex justify-content-start align-items-center referenceNameWrapper">
              {cartArticle.article.code} {cartArticle.rev ?? t("screen.cart.without_rev")}
            </div>
          </div>
          {reference != undefined && (
            <>
              <div className="referenceDescription">{reference.description[i18n.language]}</div>
            </>
          )}
          <div className="d-flex properties">
            <span className="property">
              d₁
              <span className="value">{cartArticle.article.dimD1.toFixed(3)}</span>
            </span>
            <span className="property">
              l₁
              <span className="value">{cartArticle.article.dimL1.toFixed(3)}</span>
            </span>
            <span className="property">
              D<span className="value">{cartArticle.article.dimD.toFixed(3)}</span>
            </span>
            <span className="property">
              L<span className="value">{cartArticle.article.dimL.toFixed(3)}</span>
            </span>
            <span className="property">
              Z<span className="value">{cartArticle.article.dimZ.toFixed(3)}</span>
            </span>
          </div>
        </td>
        <td className="qtyContainer">
          <div className="qtyWrapper">
            {/* <button
              className="qtyBtn"
              onClick={() => {
                var newQty = cartArticle.qty - 1;
                // updateStateCartArticle(cartArticle, newQty);

                blocsContext.cartBloc.add(new CartEditArticleQuantity(cartArticle.article, newQty));
              }}
            >
              -
            </button> */}
            <input
              className="qtyInput"
              type="number"
              value={cartArticle.qty}
              onWheel={(e) => (e.target as HTMLElement).blur()}

              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                var newQty = parseInt(e.target.value);
                updateStateCartArticle(cartArticle, newQty);

                // blocsContext.cartBloc.add(new CartEditArticleQuantity(cartArticle.article, newQty));
              }}
              onBlur={() => {
                blocsContext.cartBloc.add(new CartEditArticleQuantity(cartArticle.article, cartArticle.qty, cartArticle.rev));
              }}
            />
            {/* <button
              className="qtyBtn"
              onClick={() => {
                var newQty = cartArticle.qty + 1;
                // updateStateCartArticle(cartArticle, newQty);

                blocsContext.cartBloc.add(new CartEditArticleQuantity(cartArticle.article, newQty));
              }}
            >
              +
            </button> */}
          </div>
        </td>
        <td>
          <button
            className="btn"
            onClick={(event) => {
              blocsContext.cartBloc.add(new CartRemoveArticle(cartArticle.article, cartArticle.rev));
              event.stopPropagation();
            }}
          >
            <span className="icon icon-bin"></span>
          </button>
        </td>
      </tr>
    );
  };


  const renderContent = () => {
    if (articles.length == 0) {
      return (
        <div className="d-flex justify-content-center my-5 empty-state">
          <img src={EmptyImageSrc} className="emptyImage" alt="logo" />
          <h5>{t("screen.cart.emptystate.title")}.</h5>
          <p>{t("screen.cart.emptystate.description")}</p>
          <Link to="/select-tool">
            <button type="button" className={"emptySeeAll"}>
              {t("screen.cart.emptystate.btnTitle")} →
            </button>
          </Link>
        </div>
      );
    }
    const getSkylightUrl = () => {
      var params = [];
      if (blocsContext.authenticationBloc.state instanceof AuthenticationAuthenticated) {
        params.push("token=" + blocsContext.authenticationBloc.authenticationData.accessToken);
      }
      params.push('link=1');
      return config.SKYLIGHT_WEBSITE + "?" + params.join("&");
    }

    return (
      <div className="w-100 mx-auto">
        <div className="container align-items-center mx-auto">
          <div className="row">
            <div className="col-8">
              <table>
                <thead>
                  <tr>
                    <td>{t("screen.cart.articlesTable.col1")}</td>
                    {/* <td>{t('screen.cart.articlesTable.col2')}</td> */}
                    <td style={{ textAlign: "center" as "center" }}>{t("screen.cart.articlesTable.col3")}</td>
                    {/* <td>{t('screen.cart.articlesTable.col4')}</td> */}
                    <td>{t("screen.cart.articlesTable.col5")}</td>
                  </tr>
                </thead>

                <tbody>{articles.map((cartArticle: CartArticle) => renderTableLine(cartArticle))}</tbody>
              </table>
            </div>

            <div className="col-3 offset-1">








              <BlocBuilder
                bloc={blocsContext.authenticationBloc}
                builder={(state: AuthenticationState) => {
                  if (state instanceof AuthenticationAuthenticated) {
                    return (
                      <div className="cart-total">
                        {/* <h3>{t('screen.cart.total')}</h3> */}

                        <a
                          href={getSkylightUrl()}
                          className="btn btn-primary text-white w-100 py-2 d-flex align-items-center justify-content-center"
                        >
                          {t("screen.cart.validate")}
                        </a>
                        <div className="mt-4 text-center">{t("screen.cart.validateMessage")}</div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="cart-total">
                        <h3 className="mt-4 text-center">{t('screen.cart.not_connected_title')}</h3>
                        <div className="mt-4 text-center">{t("screen.cart.not_connected")}</div>

                        <Link
                          to="/login"
                          className="btn btn-primary text-white w-100 mt-3 py-2 d-flex align-items-center justify-content-center"
                        >
                          {t("screen.cart.connect")}
                        </Link>
                      </div>
                    );
                  }
                }}
              />

            </div>
          </div>
        </div>
      </div>
    );
  }

  // const renderUpdateCartButton = () => {
  //   if (isLocalCartUpToDate() == false) {
  //     return (
  //       <div className="updateCartButton">
  //         <button
  //           className="btn"
  //           onClick={() => {
  //             blocsContext.cartBloc.add(new CartUpdateAll(articles));
  //           }}
  //         >
  //           Mettre à jour
  //           <span className="icon icon-refresh"></span>
  //         </button>
  //       </div>
  //     );
  //   }
  // }


  return (
    <BaseScreen>
      <div className="cart-screen w-100 d-flex flex-column align-items-center">
        <div className="my-4 w-100 mx-auto">
          <div className="container">
            <div className="sort-and-filter d-flex py-3">
              <div className="me-5">
                <h2>{t("screen.cart.title")}</h2>
              </div>
            </div>
          </div>
        </div>
        {/* {renderUpdateCartButton()} */}
        {renderContent()}
      </div>
    </BaseScreen>
  );
};
export default withTranslation("common")(CartScreen);
