export default class ToolfinderCutParams {
	// Vc: number;
	// n: number;
	// D: number;
	// Z: number;
	// Fz: number;
	// Vf: number;
	// ap: number;
	// ae: number;
	// Q: number;
	// Rm: number;
	// eta: number;
	// P: number;
	// hm: number;
	// Emax: number;
	// LgCop: number;
	// VolCop: number;
	// Vctf: number;

	// VcLocked: boolean;
	// nLocked: boolean;
	// DLocked: boolean;
	// ZLocked: boolean;
	// FzLocked: boolean;
	// VfLocked: boolean;
	// apLocked: boolean;
	// aeLocked: boolean;
	// QLocked: boolean;
	// RmLocked: boolean;
	// etaLocked: boolean;
	// PLocked: boolean;
	// hmLocked: boolean;
	// EmaxLocked: boolean;
	// LgCopLocked: boolean;
	// VolCopLocked: boolean;
	// VctfLocked: boolean;

	FRAISAGE_Vc: number;
	FRAISAGE_n: number;
	FRAISAGE_d1: number;
	FRAISAGE_Z: number;
	FRAISAGE_Fz: number;
	FRAISAGE_Vf: number;
	FRAISAGE_ap: number;
	FRAISAGE_ae: number;
	FRAISAGE_Q: number;
	FRAISAGE_hm: number;
	FRAISAGE_Emax: number;

	PERCAGE_Vc: number;
	PERCAGE_n: number;
	PERCAGE_d1: number;
	PERCAGE_Z: number;
	PERCAGE_Q: number;
	PERCAGE_F: number;
	PERCAGE_Vf: number;

	FRAISAGE_Vc_Locked: boolean;
	FRAISAGE_n_Locked: boolean;
	FRAISAGE_d1_Locked: boolean;
	FRAISAGE_Z_Locked: boolean;
	FRAISAGE_Fz_Locked: boolean;
	FRAISAGE_Vf_Locked: boolean;
	FRAISAGE_ap_Locked: boolean;
	FRAISAGE_ae_Locked: boolean;
	FRAISAGE_Q_Locked: boolean;
	FRAISAGE_hm_Locked: boolean;
	FRAISAGE_Emax_Locked: boolean;

	PERCAGE_Vc_Locked: boolean;
	PERCAGE_n_Locked: boolean;
	PERCAGE_d1_Locked: boolean;
	PERCAGE_Z_Locked: boolean;
	PERCAGE_Q_Locked: boolean;
	PERCAGE_F_Locked: boolean;
	PERCAGE_Vf_Locked: boolean;

	constructor(
		{
			// Vc,
			// n,
			// D,
			// Z,
			// Fz,
			// Vf,
			// ap,
			// ae,
			// Q,
			// Rm,
			// eta,
			// P,
			// hm,
			// Emax,
			// LgCop,
			// VolCop,
			// Vctf,

			// VcLocked = false,
			// nLocked = false,
			// DLocked = false,
			// ZLocked = false,
			// FzLocked = false,
			// VfLocked = false,
			// apLocked = false,
			// aeLocked = false,
			// QLocked = false,
			// RmLocked = false,
			// etaLocked = false,
			// PLocked = false,
			// hmLocked = false,
			// EmaxLocked = false,
			// LgCopLocked = false,
			// VolCopLocked = false,
			// VctfLocked = false,

			FRAISAGE_Vc,
			FRAISAGE_n,
			FRAISAGE_d1,
			FRAISAGE_Z,
			FRAISAGE_Fz,
			FRAISAGE_Vf,
			FRAISAGE_ap,
			FRAISAGE_ae,
			FRAISAGE_Q,
			FRAISAGE_hm,
			FRAISAGE_Emax,
			PERCAGE_Vc,
			PERCAGE_n,
			PERCAGE_d1,
			PERCAGE_Z,
			PERCAGE_Q,
			PERCAGE_F,
			PERCAGE_Vf,

			FRAISAGE_Vc_Locked = false,
			FRAISAGE_n_Locked = false,
			FRAISAGE_d1_Locked = false,
			FRAISAGE_Z_Locked = false,
			FRAISAGE_Fz_Locked = false,
			FRAISAGE_Vf_Locked = false,
			FRAISAGE_ap_Locked = false,
			FRAISAGE_ae_Locked = false,
			FRAISAGE_Q_Locked = false,
			FRAISAGE_hm_Locked = false,
			FRAISAGE_Emax_Locked = false,

			PERCAGE_Vc_Locked = false,
			PERCAGE_n_Locked = false,
			PERCAGE_d1_Locked = false,
			PERCAGE_Z_Locked = false,
			PERCAGE_Q_Locked = false,
			PERCAGE_F_Locked = false,
			PERCAGE_Vf_Locked = false,
		}: any /*NamedParameters*/
	) {
		this.FRAISAGE_Vc = FRAISAGE_Vc;
    this.FRAISAGE_n = FRAISAGE_n;
    this.FRAISAGE_d1 = FRAISAGE_d1;
    this.FRAISAGE_Z = FRAISAGE_Z;
    this.FRAISAGE_Fz = FRAISAGE_Fz;
    this.FRAISAGE_Vf = FRAISAGE_Vf;
    this.FRAISAGE_ap = FRAISAGE_ap;
    this.FRAISAGE_ae = FRAISAGE_ae;
    this.FRAISAGE_Q = FRAISAGE_Q;
    this.FRAISAGE_hm = FRAISAGE_hm;
    this.FRAISAGE_Emax = FRAISAGE_Emax;
    this.PERCAGE_Vc = PERCAGE_Vc;
    this.PERCAGE_n = PERCAGE_n;
    this.PERCAGE_d1 = PERCAGE_d1;
    this.PERCAGE_Z = PERCAGE_Z;
    this.PERCAGE_Q = PERCAGE_Q;
    this.PERCAGE_F = PERCAGE_F;
    this.PERCAGE_Vf = PERCAGE_Vf;
    this.FRAISAGE_Vc_Locked = FRAISAGE_Vc_Locked;
    this.FRAISAGE_n_Locked = FRAISAGE_n_Locked;
    this.FRAISAGE_d1_Locked = FRAISAGE_d1_Locked;
    this.FRAISAGE_Z_Locked = FRAISAGE_Z_Locked;
    this.FRAISAGE_Fz_Locked = FRAISAGE_Fz_Locked;
    this.FRAISAGE_Vf_Locked = FRAISAGE_Vf_Locked;
    this.FRAISAGE_ap_Locked = FRAISAGE_ap_Locked;
    this.FRAISAGE_ae_Locked = FRAISAGE_ae_Locked;
    this.FRAISAGE_Q_Locked = FRAISAGE_Q_Locked;
    this.FRAISAGE_hm_Locked = FRAISAGE_hm_Locked;
    this.FRAISAGE_Emax_Locked = FRAISAGE_Emax_Locked;
    this.PERCAGE_Vc_Locked = PERCAGE_Vc_Locked;
    this.PERCAGE_n_Locked = PERCAGE_n_Locked;
    this.PERCAGE_d1_Locked = PERCAGE_d1_Locked;
    this.PERCAGE_Z_Locked = PERCAGE_Z_Locked;
    this.PERCAGE_Q_Locked = PERCAGE_Q_Locked;
    this.PERCAGE_F_Locked = PERCAGE_F_Locked;
    this.PERCAGE_Vf_Locked = PERCAGE_Vf_Locked;
	}

	// name() {
	//     return "his firstname is: " + this.firstname;
	// }

	public copyWith({
		FRAISAGE_Vc,
		FRAISAGE_n,
		FRAISAGE_d1,
		FRAISAGE_Z,
		FRAISAGE_Fz,
		FRAISAGE_Vf,
		FRAISAGE_ap,
		FRAISAGE_ae,
		FRAISAGE_Q,
		FRAISAGE_hm,
		FRAISAGE_Emax,
		PERCAGE_Vc,
		PERCAGE_n,
		PERCAGE_d1,
		PERCAGE_Z,
		PERCAGE_Q,
		PERCAGE_F,
		PERCAGE_Vf,
		FRAISAGE_Vc_Locked,
		FRAISAGE_n_Locked,
		FRAISAGE_d1_Locked,
		FRAISAGE_Z_Locked,
		FRAISAGE_Fz_Locked,
		FRAISAGE_Vf_Locked,
		FRAISAGE_ap_Locked,
		FRAISAGE_ae_Locked,
		FRAISAGE_Q_Locked,
		FRAISAGE_hm_Locked,
		FRAISAGE_Emax_Locked,
		PERCAGE_Vc_Locked,
		PERCAGE_n_Locked,
		PERCAGE_d1_Locked,
		PERCAGE_Z_Locked,
		PERCAGE_Q_Locked,
		PERCAGE_F_Locked,
		PERCAGE_Vf_Locked,
	}: any): ToolfinderCutParams {
		return new ToolfinderCutParams({
			FRAISAGE_Vc: FRAISAGE_Vc ?? this.FRAISAGE_Vc,
			FRAISAGE_n: FRAISAGE_n ?? this.FRAISAGE_n,
			FRAISAGE_d1: FRAISAGE_d1 ?? this.FRAISAGE_d1,
			FRAISAGE_Z: FRAISAGE_Z ?? this.FRAISAGE_Z,
			FRAISAGE_Fz: FRAISAGE_Fz ?? this.FRAISAGE_Fz,
			FRAISAGE_Vf: FRAISAGE_Vf ?? this.FRAISAGE_Vf,
			FRAISAGE_ap: FRAISAGE_ap ?? this.FRAISAGE_ap,
			FRAISAGE_ae: FRAISAGE_ae ?? this.FRAISAGE_ae,
			FRAISAGE_Q: FRAISAGE_Q ?? this.FRAISAGE_Q,
			FRAISAGE_hm: FRAISAGE_hm ?? this.FRAISAGE_hm,
			FRAISAGE_Emax: FRAISAGE_Emax ?? this.FRAISAGE_Emax,
			PERCAGE_Vc: PERCAGE_Vc ?? this.PERCAGE_Vc,
			PERCAGE_n: PERCAGE_n ?? this.PERCAGE_n,
			PERCAGE_d1: PERCAGE_d1 ?? this.PERCAGE_d1,
			PERCAGE_Z: PERCAGE_Z ?? this.PERCAGE_Z,
			PERCAGE_Q: PERCAGE_Q ?? this.PERCAGE_Q,
			PERCAGE_F: PERCAGE_F ?? this.PERCAGE_F,
			PERCAGE_Vf: PERCAGE_Vf ?? this.PERCAGE_Vf,
			FRAISAGE_Vc_Locked: FRAISAGE_Vc_Locked ?? this.FRAISAGE_Vc_Locked,
			FRAISAGE_n_Locked: FRAISAGE_n_Locked ?? this.FRAISAGE_n_Locked,
			FRAISAGE_d1_Locked: FRAISAGE_d1_Locked ?? this.FRAISAGE_d1_Locked,
			FRAISAGE_Z_Locked: FRAISAGE_Z_Locked ?? this.FRAISAGE_Z_Locked,
			FRAISAGE_Fz_Locked: FRAISAGE_Fz_Locked ?? this.FRAISAGE_Fz_Locked,
			FRAISAGE_Vf_Locked: FRAISAGE_Vf_Locked ?? this.FRAISAGE_Vf_Locked,
			FRAISAGE_ap_Locked: FRAISAGE_ap_Locked ?? this.FRAISAGE_ap_Locked,
			FRAISAGE_ae_Locked: FRAISAGE_ae_Locked ?? this.FRAISAGE_ae_Locked,
			FRAISAGE_Q_Locked: FRAISAGE_Q_Locked ?? this.FRAISAGE_Q_Locked,
			FRAISAGE_hm_Locked: FRAISAGE_hm_Locked ?? this.FRAISAGE_hm_Locked,
			FRAISAGE_Emax_Locked: FRAISAGE_Emax_Locked ?? this.FRAISAGE_Emax_Locked,
			PERCAGE_Vc_Locked: PERCAGE_Vc_Locked ?? this.PERCAGE_Vc_Locked,
			PERCAGE_n_Locked: PERCAGE_n_Locked ?? this.PERCAGE_n_Locked,
			PERCAGE_d1_Locked: PERCAGE_d1_Locked ?? this.PERCAGE_d1_Locked,
			PERCAGE_Z_Locked: PERCAGE_Z_Locked ?? this.PERCAGE_Z_Locked,
			PERCAGE_Q_Locked: PERCAGE_Q_Locked ?? this.PERCAGE_Q_Locked,
			PERCAGE_F_Locked: PERCAGE_F_Locked ?? this.PERCAGE_F_Locked,
			PERCAGE_Vf_Locked: PERCAGE_Vf_Locked ?? this.PERCAGE_Vf_Locked,
		});
	}
}
