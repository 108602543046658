import Article from "models/article";
import DataService from "services/data_service";
import Config from "config/config";
import axios from "axios";
import { ArticleCoating } from "constants/constants";
import Coating from "models/coating";

export default class ArticlesCoatingsSelectionRepository {
  /// Constructor
  // ArticleRepository() {}

  // constructor() {
  // 	// this.dataService = new DataService();
  // }

  // async getData(): Promise<Array<ArticleCoating>> {
  //   return this.storageGet();
  // }

  storageSave(articlesCoatingsSelection: Array<ArticleCoating>) {
    localStorage.setItem(
      "articlesCoatingsSelection",
      JSON.stringify(
        articlesCoatingsSelection.map((articleCoating: ArticleCoating) => ({
          article: articleCoating.article.toJson(),
          coating: articleCoating.coating.toJson(),
        }))
      )
    );
  }

  storageGet(): Array<ArticleCoating> {
    const articlesCoatingsSelectionJson: string = localStorage.getItem("articlesCoatingsSelection") as string;
    if (articlesCoatingsSelectionJson == null || articlesCoatingsSelectionJson == undefined) {
      return [];
    }
    var articlesCoatingsSelection: Array<ArticleCoating> = JSON.parse(articlesCoatingsSelectionJson).map((articleCoatingJson: any) => {
      return { article: Article.fromJson(articleCoatingJson.article), coating: Coating.fromJson(articleCoatingJson.coating) } as ArticleCoating;
    });

    return articlesCoatingsSelection;
  }
}
