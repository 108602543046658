import { Bloc, Transition } from "@felangel/bloc";
import Article from "models/article";
import ArticleRepository from "repositories/article_repository";
import { ArticlesCoatingsSelectionEvent, ArticlesCoatingsSelectionLoad, ArticlesCoatingsSelectionSave } from "./articles_coatings_selection_event";
import { ArticlesCoatingsSelectionFailure, ArticlesCoatingsSelectionInitial, ArticlesCoatingsSelectionLoaded, ArticlesCoatingsSelectionLoading, ArticlesCoatingsSelectionState } from "./articles_coatings_selection_state";
import ArticlesCoatingsSelectionRepository from "repositories/articles_coatings_selection_repository";
import { ArticleCoating } from "constants/constants";


export default class ArticlesCoatingsSelectionBloc extends Bloc<ArticlesCoatingsSelectionEvent, ArticlesCoatingsSelectionState> {
	articlesCoatingsSelectionRepository: ArticlesCoatingsSelectionRepository;
	articlesCoatingsSelection: Array<ArticleCoating> = [];

	constructor(articlesCoatingsSelectionRepository: ArticlesCoatingsSelectionRepository) {
		super(new ArticlesCoatingsSelectionInitial());
		this.articlesCoatingsSelectionRepository = articlesCoatingsSelectionRepository;
	}

	async *mapEventToState(event: ArticlesCoatingsSelectionEvent) {
		if (event instanceof ArticlesCoatingsSelectionLoad) {
			yield* this.mapLoadArticlesToState(event);
		} else if( event instanceof ArticlesCoatingsSelectionSave) {
      yield* this.mapSaveArticlesToState(event);
    }
	}

	async *mapLoadArticlesToState(event: ArticlesCoatingsSelectionLoad) {
		try {
			yield new ArticlesCoatingsSelectionLoading();
			// var articlesList: Array<ArticleCoating> = await this.articlesCoatingsSelectionRepository.storageGet();
			// this.articlesCoatingsSelection = articlesList;

			yield new ArticlesCoatingsSelectionLoaded(this.articlesCoatingsSelection);
		} catch (e) {
			if (typeof e === "string") {
				yield new ArticlesCoatingsSelectionFailure(e);
			} else {
				// yield new ArticlesCoatingsSelectionFailure(e.toString());
				yield new ArticlesCoatingsSelectionFailure("Unknow error");
			}
		}
	}

  async *mapSaveArticlesToState(event: ArticlesCoatingsSelectionSave) {
    try {
      yield new ArticlesCoatingsSelectionLoading();
      this.articlesCoatingsSelection = event.articlesCoatingsSelection;
      // this.articlesCoatingsSelectionRepository.storageSave(this.articlesCoatingsSelection);

      yield new ArticlesCoatingsSelectionLoaded(this.articlesCoatingsSelection);
    } catch (e) {
      if (typeof e === "string") {
        yield new ArticlesCoatingsSelectionFailure(e);
      } else {
        // yield new ArticlesCoatingsSelectionFailure(e.toString());
        yield new ArticlesCoatingsSelectionFailure("Unknow error");
      }
    }
  }

	// Called whenever an `event` is added.
	onEvent(event: ArticlesCoatingsSelectionEvent): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
	}

	// Called whenever a state change is about to occur.
	onTransition(transition: Transition<any, any>): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
	}

	onError(error: any): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
	}
}
