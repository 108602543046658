import Article from "./article";

export default class CartArticle {
	article: Article;
  rev: string|undefined;
  qty: number;

	constructor(params: any) {
		this.article = params.article;
    this.rev = params.rev;
		this.qty = params.qty;
	}

	// static areIdentical(a: Cart, b: Cart): boolean {
	// 	if (!a && !b) return true;
	// 	if (!a || !b) return false;
	// 	return a.id === b.id;
	// }

	// static fromJson(map: Record<string, any>): Cart {
	static fromJson(map: any): CartArticle|undefined {
		try {
      if(map["article"] == undefined || map["qty"] == undefined){
        return undefined;
      }
			return new CartArticle({
				article: map["article"] != undefined ? Article.fromJson(map["article"]) : undefined,
				qty: map["qty"],
        rev: map["rev"],
			});
		} catch (e) {
			console.log(e);
			return undefined;
		}
	}

	toJson() {
		return {
      article: this.article != undefined ? this.article.toJson() : undefined,
      qty: this.qty,
      rev: this.rev,
		};
	}


	// getPrice(): number {
	// 	return this.articles.reduce(
	// 		(prevValue: number, article: Article) => prevValue + article.getPrice(),
	// 		0
	// 	);
	// }

}
