import AssRevMat from "models/ass_rev_mat";

export abstract class AssRevMatsState {}

export class AssRevMatsInitial extends AssRevMatsState {}

export class AssRevMatsLoading extends AssRevMatsState {}

export class AssRevMatsLoaded extends AssRevMatsState {
	assRevMats: Array<AssRevMat>;
	constructor(assRevMats: Array<AssRevMat>) {
		super();
		this.assRevMats = assRevMats;
	}

	public toString = (): string => {
		return `AssRevMatsLoaded ${this.assRevMats.length} assRevMats`;
	};
}

export class AssRevMatsFailure extends AssRevMatsState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `AssRevMatsLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
