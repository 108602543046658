import React, { useState, useContext, useEffect, useRef } from "react";
import { BlocsContext } from "contexts/blocs_context";
import { BlocBuilder } from "@felangel/react-bloc";
import { WithTranslation, withTranslation, useTranslation } from "react-i18next";
import ShoppingCartIcon from "assets/icons/shopping-cart.svg";
import Article from "models/article";

import { CartState, CartUpdated } from "blocs/cart_bloc/cart_state";
import CartArticle from "models/cart_article";
import { CartRemoveArticle } from "blocs/cart_bloc/cart_event";
import Popup from "./add_to_cart_popup";
import "./add_to_cart_button.scss";
import GeneralHelper from "helpers/general_helper";
import { CartAddArticle } from "blocs/cart_bloc/cart_event";

interface Props extends WithTranslation {
  article: Article;
  revCode: string | undefined;
}

const AddToCartButton: React.FC<Props> = ({ /*t, */ article, revCode }) => {
  const [showPopup, setShowPopup] = useState(false);
  const blocsContext = useContext(BlocsContext);

  const [qty, setQty] = useState(1);
  const { t } = useTranslation("common");
  let btnRef = useRef<HTMLButtonElement>(null);
  let menuRef = useRef<HTMLDivElement>(null);


  const onMouseDownhandler = (e: any) => {
    if (!menuRef?.current?.contains(e.target) /*&& !btnRef?.current?.contains(e.target)*/) {
      setShowPopup(false);
    }
  };
  const onWheelHandler = (e: any) => {
    if (!menuRef?.current?.contains(e.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", onMouseDownhandler);
    document.addEventListener("wheel", onWheelHandler);

    return () => {
      document.removeEventListener("mousedown", onMouseDownhandler);
      document.removeEventListener("wheel", onWheelHandler);
    };
  });

  
  useEffect(() => {
    if (showPopup) {
      var btnRefPosition = btnRef.current?.getBoundingClientRect();
      var articleBox = document.getElementsByClassName("articles-box");

      var wrapperTop = articleBox.length == 0 ? 0 : articleBox[0].getBoundingClientRect().top;
      var wrapperLeft = articleBox.length == 0 ? 0 : articleBox[0].getBoundingClientRect().left;
      var btnHeight = (btnRef.current?.clientHeight ?? 0) + 10;
      var menuWidth = 155;

      var popupPositionTop = (btnRefPosition?.top ?? 0) - wrapperTop + btnHeight;
      var popupPositionLeft = (btnRefPosition?.left ?? 0) - wrapperLeft - (menuWidth /2) + ((btnRefPosition?.width ?? 0) / 2);

      menuRef.current?.style.setProperty("top", `${popupPositionTop}px`);
      menuRef.current?.style.setProperty("left", `${popupPositionLeft}px`);
      menuRef.current?.style.setProperty("display", `flex`);
    } else {
      menuRef.current?.style.setProperty("display", `none`);
    }
  }, [showPopup]);

  return (
    <span className="add-to-cart-btn-container">
      <BlocBuilder
        bloc={blocsContext.cartBloc}
        builder={(state: CartState) => {
          var articleInCart: boolean = false;
          (state as CartUpdated).cart.articles.forEach((cartArticle: CartArticle) => {
            if (cartArticle.article.code === article.code) {
              articleInCart = true;
            }
          });

          return (
            <button
              type="button"
              onClick={(event) => {
                setShowPopup(true);
                event.stopPropagation();
              }}
              className="add-to-cart-btn"
              ref={btnRef}
            >
              <div className="shoppingcart-icon">
                <div className="shoppingcart-menu-container">
                  <img src={ShoppingCartIcon} />
                  <span className="cart-badge">+</span>
                </div>
              </div>
            </button>
          );
        }}
      />
      {/* {showPopup && (
        <Popup
          handleClose={handleClosePopup}
          handleAddToCart={handleAddToCart}
          article={article}
          revCode={revCode}
        />
      )} */}

      {/* <Popup open={showPopup} setOpen={setShowPopup} handleClose={handleClosePopup} handleAddToCart={handleAddToCart} article={article} revCode={revCode} /> */}
      <div style={{ position: "absolute",/* marginLeft: "-50px"*/ }} ref={menuRef}>
      {showPopup && (
        <div
          className="popup"
          style={{
            // position: "absolute",
            backgroundColor: "white",
            padding: "5px 15px 5px 15px",
            zIndex: 1001,
            // marginTop: '-0px',
            // transform: "translate(0, -50%)",
            display: "flex",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
            fontSize: "0.9em",
          }}
        >
          <div className="popup-inner d-flex flex-column">
            <div style={{ marginBottom: 1, fontWeight: 500 }}>{t("general.quantity")}</div>
            <div className="d-flex flex-row align-items-center">
              <input
                type="number"
                min="1"
                value={qty}
                defaultValue="1"
                onChange={(event) => {
                  setQty(GeneralHelper.parseIntOrNull(event.target.value) || 1);
                }}
                style={{ width: "80px" }}
              />
              <button
                className="btn"
                style={{ border: "solid #26a9b2 1px", padding: "1px 10px", marginLeft: 5, backgroundColor: "#009aa4" }}
                onClick={() => {
                  blocsContext.cartBloc.add(new CartAddArticle(article, qty, revCode));
                  GeneralHelper.successToast(t("screen.step_tool.addedToCartMessage"));
                  setShowPopup(false);
                }}
              >
                <svg fill="none" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
                  <clipPath id="clip0_17_4631">
                    <path d="m0 0h24v24h-24z" />
                  </clipPath>
                  <g clipPath="url(#clip0_17_4631)">
                    <path d="m9.00016 16.17-4.17-4.17-1.42 1.41 5.59 5.59 12.00004-12.00003-1.41-1.41z" fill="rgb(255,255,255)" />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    
    </span>
  );
};

export default withTranslation("common")(AddToCartButton);
