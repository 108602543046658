import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface propsType {
	identifier: string;
	toolfinderSelectionUpdateIncrement: number;
	hasLock: boolean;
	locked: boolean;
	icon: string;
	title: string;
	value: string;
	linkPath: string;
	onLockSwitch: Function;
}

const usePrevious = <T extends unknown>(value: T): T | undefined => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};
const SelectionItem = ({
	identifier,
	toolfinderSelectionUpdateIncrement,
	hasLock,
	locked,
	icon,
	title,
	value,
	linkPath,
	onLockSwitch,
}: propsType) => {
	const props: propsType = {
		identifier,
		toolfinderSelectionUpdateIncrement,
		hasLock,
		locked,
		icon,
		title,
		value,
		linkPath,
		onLockSwitch,
	};
	const prevProps = usePrevious(props);

	const [animationTimout, setAnimationTimout] = useState<NodeJS.Timeout | undefined>(undefined);
	// var animationTimout : NodeJS.Timeout | undefined;

	const { t, i18n } = useTranslation("common");

	useLayoutEffect(() => {
		if (
			props.value != undefined &&
			prevProps != undefined &&
			props.value != prevProps?.value &&
			toolfinderSelectionUpdateIncrement >= 2 &&
			prevProps.toolfinderSelectionUpdateIncrement != props.toolfinderSelectionUpdateIncrement
		) {
			var id = "#selection-item-" + identifier;
			var mainElement = $(id);
			var valueElement = $(id + " .cardSelection");

			// Add class "recently-changed" to main element
			mainElement.addClass("recently-changed");
			if (animationTimout != undefined) {
				clearTimeout(animationTimout);
			}
			setAnimationTimout(
				setTimeout(() => {
					mainElement.removeClass("recently-changed");
					setAnimationTimout(undefined);
				}, 1500)
			);

			// Animate element
			valueElement.animate({ bottom: "-25px" }, 150, () => {
				valueElement.css({
					bottom: "20px",
				});
				valueElement.animate({ bottom: "0px" }, 150);
			});
		}
	});

	return (
		<div id={"selection-item-" + identifier} className={"selection-card" + (locked === true ? " locked" : "") + (value ? " filled" : "")}>
			{/* <span className="icon icon-bracket"></span> */}
			<img src={icon} />

			<div className={"cardTitle"}>{title}</div>
			<div className={"cardSelectionContainer"}>
				<div className={value ? "cardSelection selected" : "cardSelection"}>{value ? value : t("screen.dashboard.unspecified")}</div>
			</div>
			<Link to={linkPath}>{t("screen.dashboard.edit")} →</Link>
			{hasLock && (
				<label className="cl-switch mt-3">
					<span className="label">
						{locked === true ? <span className="icon icon-padlock"></span> : <span className="icon icon-unlock"></span>}
					</span>
					<input type="checkbox" checked={locked === true} onChange={(event) => onLockSwitch(event)} />
					<span className="switcher"></span>
				</label>
			)}
		</div>
	);
};

export default SelectionItem;
