import Article from "models/article";

export abstract class ArticlesState {}

export class ArticlesInitial extends ArticlesState {}

export class ArticlesLoading extends ArticlesState {}

export class ArticlesLoaded extends ArticlesState {
	articles: Array<Article>;
	constructor(articles: Array<Article>) {
		super();
		this.articles = articles;
	}

	public toString = (): string => {
		return `ArticlesLoaded ${this.articles.length} articles`;
	};
}

export class ArticlesFailure extends ArticlesState {
	errorMessage: string;

	constructor(errorMessage: string) {
		super();
		this.errorMessage = errorMessage;
	}

	public toString = (): string => {
		return `ArticlesLoadingFailed { errorMessage: ${this.errorMessage} }`;
	};
}
