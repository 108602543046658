import React, { useState, useContext } from "react";
import axios from "axios";
import BaseScreen from "layouts/base";
import { WithTranslation, withTranslation } from "react-i18next";
import { BlocsContext } from "contexts/blocs_context";
import { AuthenticationLoggedIn, AuthenticationLoggedOut } from "blocs/authentication_bloc/authentication_event";
import AuthenticationData from "models/authentication_data";
import AuthenticationService from "services/authentication";
import User from "models/user";
import { AuthenticationAuthenticated } from "blocs/authentication_bloc/authentication_state";
import "./login.scss";

import UsernameIcon from "assets/icons/mail.svg";
import PasswordIcon from "assets/icons/padlock.svg";
import { useHistory } from "react-router-dom";

interface UserData {
  username: string;
  password: string;
}

interface Props extends WithTranslation { }

const LoginScreen: React.FC<Props> = ({ t }) => {
  // const blocsContext = useContext(BlocsContext);
  const history = useHistory();
  const blocsContext = useContext(BlocsContext);
  const [authenticationBlocState, setAuthenticationBlocState] = React.useState(blocsContext.authenticationBloc.state);

  React.useEffect(() => {
    if (authenticationBlocState instanceof AuthenticationAuthenticated) {
      history.push("/");
    }

    const subscription = blocsContext.authenticationBloc.listen(setAuthenticationBlocState);
    return () => {
      subscription.unsubscribe();
    };
  }, [blocsContext.authenticationBloc, authenticationBlocState]);

  const [userData, setUserData] = useState<UserData>({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // const response = await axios.post(
      //   "api/login",
      //   JSON.stringify(userData)
      // );
      setLoading(true);

      const response = await AuthenticationService.login(userData.username, userData.password);

      if (response.status == 200) {
        if (response.data == "ko") {
          setError(t("screen.login.errors.incorrectLogs"));
          setLoading(false);
          return;
        }
        const token = response.data;
        const response2 = await AuthenticationService.getMySelft(token);

        if (response2.status == 200 && response2.data[0]["Token"] != null) {
          blocsContext.authenticationBloc.add(
            new AuthenticationLoggedIn(
              new AuthenticationData({
                accessToken: response2.data[0]["Token"],
                user: new User({
                  email: response2.data[0]["Login"],
                  firstname: response2.data[0]["Name"],
                  lastname: response2.data[0]["FirstName"],
                }),
              })
            )
          );
          setLoading(false);
          return;
        }
      }

      setError(t("screen.login.errors.generalError"));
      setLoading(false);
    } catch (error: any) {
      if (error.response?.status == 429) {
        setError(t("screen.login.errors.tooManyRequests"));
        setLoading(false);
        return;
      }
      console.log("error", error);
      setError(t("screen.login.errors.generalError"));
      setLoading(false);
    }
  };

  const handleLogout = async (e: React.FormEvent) => {
    e.preventDefault();

    blocsContext.authenticationBloc.add(new AuthenticationLoggedOut());
  };

  return (
    <BaseScreen>
      <div className="w-100 help-info d-flex flex-column align-items-center">
        <div className="my-4 w-100 mx-auto">
          <div className="container">
            <div className="sort-and-filter d-flex py-3 justify-content-center">
              <div className="">
                <h2>{t("screen.login.title")}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="login-screen w-100 mx-auto">
          <div className="container d-flex flex-column align-items-center justify-content-center">
            <div className="form-container">
              <form onSubmit={handleLogin} className="d-flex flex-column justfiy-content-center">
                <h3>{t("screen.login.formTitle")}</h3>
                <div className="input-container">
                  <img src={UsernameIcon} className="input-icon" />
                  <input
                    className="w-100"
                    type="text"
                    name="username"
                    placeholder={t("screen.login.usernamePlaceholder")}
                    onChange={handleChange}
                    value={userData.username}
                    required
                  />
                </div>
                <div className="input-container">
                  <img src={PasswordIcon} className="input-icon" />
                  <input
                    className="w-100"
                    type="password"
                    name="password"
                    placeholder={t("screen.login.passwordPlaceholder")}
                    onChange={handleChange}
                    value={userData.password}
                    required
                  />
                </div>
                {error && <div className="error">{error}</div>}
                <button
                  type="submit"
                  className="btn btn-primary text-white w-100 mt-3 py-2 d-flex align-items-center justify-content-center"
                >
                  {loading ? (
                    <div className="spinner-border">
                      <span className="sr-only"></span>
                    </div>
                  ) : (
                    t("screen.login.loginButton")
                  )}
                </button>

                <div className="tips">{t("screen.login.loginTips")}</div>

                <h3 className="d-flex justify-content-center">{t("screen.login.loginIssueTitle")}</h3>

                <a
                  className="btn btn-outline-primary contact-btn d-flex justify-content-center"
                  target="_blank"
                  href="https://www.louisbelet.ch/fr/louis-belet/contact/"
                >
                  {t("menu.contact_us")}
                </a>
              </form>

              {/* <div>State</div>
              {authenticationBlocState.toString()}

              {authenticationBlocState instanceof AuthenticationAuthenticated && (
                <button type="submit" onClick={handleLogout}>
                  Se déconnecter
                </button>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </BaseScreen>
  );
};
export default withTranslation("common")(LoginScreen);
