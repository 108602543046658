// import Article from "./article";
import CartArticle from "./cart_article";

interface NamedParameters {
  articles: Array<CartArticle>;
}

export default class Cart {
  articles: Array<CartArticle>;

  constructor(params: any) {
    this.articles = params.articles;
  }

  // static areIdentical(a: Cart, b: Cart): boolean {
  // 	if (!a && !b) return true;
  // 	if (!a || !b) return false;
  // 	return a.id === b.id;
  // }

  // static fromJson(map: Record<string, any>): Cart {
  static fromJson(map: any): Cart {
    try {
      var cart = new Cart({
        articles:
          map["articles"] != null
            ? map["articles"]
                .map((cartArticleJson: Record<string, any>) => CartArticle.fromJson(cartArticleJson))
                .filter((cartArticle: CartArticle) => cartArticle != undefined)
            : undefined,
      });
      return cart;
    } catch (e) {
      console.log(e);
      return new Cart({ articles: [] });
    }
  }

  toJson() {
    return {
      articles: this.articles != null ? this.articles.map((article: CartArticle) => article.toJson()) : undefined,
    };
  }

  // getPrice(): number {
  // 	return this.articles.reduce(
  // 		(prevValue: number, article: Article) => prevValue + article.getPrice(),
  // 		0
  // 	);
  // }
}
