import { Bloc, Transition } from "@felangel/bloc";

import { FavoritesToolsEvent, FavoritesToolsInit, FavoritesToolsLoad } from "./favorites_tools_event";
import { FavoritesToolsState, FavoritesToolsLoaded, FavoritesToolsLoading } from "./favorites_tools_state";
import { FavoritesToolsAdd, FavoritesToolsClear, FavoritesToolsRemove } from "./favorites_tools_event";
import FavoriteTool from "models/favorite_tool";
import AuthenticationBloc from "blocs/authentication_bloc/authentication_bloc";
import {
  AuthenticationAuthenticated,
  AuthenticationState,
  AuthenticationUnAuthenticated,
} from "blocs/authentication_bloc/authentication_state";
import FavoriteService from "services/favorite";
import moment from "moment";


export default class FavoritesToolsBloc extends Bloc<FavoritesToolsEvent, FavoritesToolsState> {
  favorites: Array<FavoriteTool> = [];
  authenticationBloc: AuthenticationBloc;
  authenticationBlocState: AuthenticationState;

  constructor(authenticationBloc: AuthenticationBloc) {
    super(new FavoritesToolsLoaded([]));
    this.authenticationBloc = authenticationBloc;
    this.authenticationBlocState = authenticationBloc.state;

    this.authenticationBloc.listen((state: AuthenticationState) => {
      if (state instanceof AuthenticationAuthenticated || state instanceof AuthenticationUnAuthenticated) {
        this.authenticationBlocState = state;
        this.add(new FavoritesToolsInit());
      }
    });
  }

  async *mapEventToState(event: FavoritesToolsEvent) {
    if (event instanceof FavoritesToolsInit) {
      yield* this.mapInitFavoritesToolsToState(event as FavoritesToolsInit);
    } else if (event instanceof FavoritesToolsLoad) {
      yield* this.mapLoadFavoritesToolsToState(event as FavoritesToolsLoad);
    } else if (event instanceof FavoritesToolsAdd) {
      yield* this.mapFavoritesToolsAddToState(event as FavoritesToolsAdd);
    } else if (event instanceof FavoritesToolsRemove) {
      yield* this.mapFavoritesToolsRemoveToState(event as FavoritesToolsRemove);
    } else if (event instanceof FavoritesToolsClear) {
      yield* this.mapFavoritesToolsClearToState(event as FavoritesToolsClear);
    }
  }

  async *mapInitFavoritesToolsToState(event: FavoritesToolsInit) {
    if (this.authenticationBlocState instanceof AuthenticationAuthenticated) {
      // Sync local favorites with skylight
      var skylightFavorites: Array<FavoriteTool> = await this.getDataFromSkylight();

      this.favorites.forEach(async (favorite: FavoriteTool) =>{
        if(! skylightFavorites.some((slFavorite: FavoriteTool) => slFavorite.articleId == favorite.articleId)){
          await FavoriteService.addFavoriteToSkylight(
            this.authenticationBloc.authenticationData.accessToken,
            favorite.articleId,
            favorite.revCode
          );
        }
      });
      // If length == 0, no favorite was added to skylight so the previous list is up to date
      if(this.favorites.length == 0 ){
        this.favorites = skylightFavorites;
      } else {
        this.favorites = await this.getDataFromSkylight();
      }
      this.saveDataToLocalStorage();
    } else if (this.authenticationBlocState instanceof AuthenticationUnAuthenticated) {
      // Get fav from storage and inject into this bloc
      this.restoreDataFromLocalStorage();
    }

    yield new FavoritesToolsLoaded(this.favorites);
  }

  async *mapLoadFavoritesToolsToState(event: FavoritesToolsLoad) {
    yield new FavoritesToolsLoading();
  }

  async *mapFavoritesToolsAddToState(event: FavoritesToolsAdd) {
    var alreadyHasFavorite: boolean = false;

    this.favorites.forEach((favoriteTool: FavoriteTool) => {
      if (favoriteTool.articleCode === event.favorite.articleCode && favoriteTool.revCode === event.favorite.revCode) {
        alreadyHasFavorite = true;
      }
    });

    if (alreadyHasFavorite) {
      return;
    }

    this.favorites.push(event.favorite);
    if (this.authenticationBlocState instanceof AuthenticationAuthenticated) {
      FavoriteService.addFavoriteToSkylight(
        this.authenticationBloc.authenticationData.accessToken,
        event.favorite.articleId,
        event.favorite.revCode
      );
    }

    this.saveDataToLocalStorage();
    yield new FavoritesToolsLoaded(this.favorites);
  }

  async *mapFavoritesToolsRemoveToState(event: FavoritesToolsRemove) {
    this.favorites.splice(
      this.favorites.map((favorite: FavoriteTool) => favorite.articleCode).indexOf(event.favorite.articleCode),
      1
    );
    if (this.authenticationBlocState instanceof AuthenticationAuthenticated) {
      FavoriteService.removeFavoriteToSkylight(
        this.authenticationBloc.authenticationData.accessToken,
        event.favorite.articleId,
        event.favorite.revCode
      );
    }
    this.saveDataToLocalStorage();
    yield new FavoritesToolsLoaded(this.favorites);
  }

  async *mapFavoritesToolsClearToState(event: FavoritesToolsClear) {
    this.favorites = [];
    this.saveDataToLocalStorage();
    yield new FavoritesToolsLoaded(this.favorites);
  }

  // Called whenever an `event` is added.
  onEvent(event: FavoritesToolsEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
  }

  // Called whenever a state change is about to occur.
  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
  }

  restoreDataFromLocalStorage() {
    var favoritesToolsRaw: string = localStorage.getItem("favorites_tools") as string;

    var favoritesTools: Array<FavoriteTool> = FavoriteTool.favoritesFromJson(JSON.parse(favoritesToolsRaw));
    this.favorites = favoritesTools;
  }
  saveDataToLocalStorage() {
    localStorage.setItem("favorites_tools", JSON.stringify(FavoriteTool.favoritesToJson(this.favorites)));
  }

  async getDataFromSkylight() {
    var favoritesFromSyklightResponse = await FavoriteService.getSkylightFavorites(
      this.authenticationBloc.authenticationData.accessToken
    );
    var favorites: Array<FavoriteTool> = [];
    if (favoritesFromSyklightResponse.status == 200) {
      favorites = favoritesFromSyklightResponse.data.filter((data: any) => data['goodid'] != null).map(
        (data: any) => new FavoriteTool({ articleId: data['goodid'], articleCode: data["fad_article"], revCode: data['rev'], createdAt: moment(data['fad_iwhen']) })
      );
    }
    return favorites;
  }
}
