import MachiningGroup from "models/machining_group";
import Config from "config/config";
import axios from "axios";

export default class MachiningGroupRepository {
	async getData(): Promise<Array<MachiningGroup>> {
		var data: any = await axios.get(Config.base_data_url + "/getJson?file=IND_USINAGE_GRP", {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "X-Requested-With",
			},
		});
		return await this.dataToMachiningGroup(data.data);
	}
	dataToMachiningGroup(data: any): Array<MachiningGroup> {
		var machiningGroups: Array<MachiningGroup> = [];
		for (var i = 0; i < data.length; i++) {
			machiningGroups.push(MachiningGroup.fromJson(data[i]));
		}
		return machiningGroups;
	}
}
