import Material from "models/material";
import Config from "config/config";
import axios from "axios";

export default class MaterialRepository {
	async getData(): Promise<Array<Material>> {
		var data: any = await axios.get(Config.base_data_url + "/getJson?file=IND_MATUSI_SGRP", {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "X-Requested-With",
			},
		});
		return await this.dataToMaterial(data.data);
	}

	dataToMaterial(data: any): Array<Material> {
		var materialGroups: Array<Material> = [];
		for (var i = 0; i < data.length; i++) {
			materialGroups.push(Material.fromJson(data[i]));
		}
		return materialGroups;
	}
}
