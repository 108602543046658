import AssRevMat from "models/ass_rev_mat";
import Config from "config/config";
import axios from "axios";

export default class AssRevMatRepository {
	async getData(): Promise<Array<AssRevMat>> {
		var data: any = await axios.get(Config.base_data_url + "/getJson?file=IND_TOOLFIND_REV_MATUSI_SGRP", {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "X-Requested-With",
			},
		});
		return await this.dataToAssRevMat(data.data);
	}

	dataToAssRevMat(data: any): Array<AssRevMat> {
		var assRevMas: Array<AssRevMat> = [];
		for (var i = 0; i < data.length; i++) {
			assRevMas.push(AssRevMat.fromJson(data[i]));
		}
		return assRevMas;
	}
}
