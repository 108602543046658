import { Bloc, Transition } from "@felangel/bloc";
import Coating from "models/coating";
import CoatingRepository from "repositories/coating_repository";
import { CoatingsEvent, CoatingsLoad } from "./coatings_event";
import { CoatingsState, CoatingsLoaded, CoatingsLoading, CoatingsInitial, CoatingsFailure } from "./coatings_state";


export default class CoatingsBloc extends Bloc<CoatingsEvent, CoatingsState> {
	coatingRepository: CoatingRepository;
	coatings: Array<Coating> = [];

	constructor(coatingRepository: CoatingRepository) {
		super(new CoatingsInitial());
		this.coatingRepository = coatingRepository;
	}

	async *mapEventToState(event: CoatingsEvent) {
		if (event instanceof CoatingsLoad) {
			yield* this.mapLoadCoatingsToState(event);
		}
	}

	async *mapLoadCoatingsToState(event: CoatingsLoad) {
		try {
			yield new CoatingsLoading();
			var coatingsList: Array<Coating> = await this.coatingRepository.getData();
			this.coatings = coatingsList;

			yield new CoatingsLoaded(this.coatings);
		} catch (e) {
			if (typeof e === "string") {
				yield new CoatingsFailure(e);
			} else {
				// yield new CoatingsFailure(e.toString());
				yield new CoatingsFailure("Unknow error");
			}
		}
	}

	// Called whenever an `event` is added.
	onEvent(event: CoatingsEvent): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
	}

	// Called whenever a state change is about to occur.
	onTransition(transition: Transition<any, any>): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
	}

	onError(error: any): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
	}
}
