import Article from "models/article";
import Coating from "models/coating";
import Machining from "models/machining";

const MACHININGS: Array<Machining> = [
	new Machining({ id: 0, slug: "fraisage", name: "Fraisage" }),
	new Machining({ id: 1, slug: "surfacage", name: "Surfaçage", parent: 0 }),
	new Machining({ id: 2, slug: "contournage", name: "Contournage", parent: 0 }),
	new Machining({ id: 3, slug: "rainurage", name: "Rainurage", parent: 0 }),
	new Machining({ id: 4, slug: "poche", name: "Poche", parent: 0 }),
	new Machining({ id: 5, slug: "interpolation_heli", name: "Interpolation hélicoïdale", parent: 0 }),
	new Machining({ id: 6, slug: "percage", name: "Perçage" }),
	new Machining({ id: 7, slug: "percage_face_plate", name: "Perçage sur face plate", parent: 6 }),
	new Machining({ id: 8, slug: "percage_face_convexe", name: "Perçage sur face convexe", parent: 6 }),
	new Machining({ id: 9, slug: "percage_face_concave", name: "Perçage sur face concave", parent: 6 }),
];

// const MachiningNames: Record<string, string> = {
//     [MACHININGS[0].slug]: "Fraisage",

//     [MACHININGS[1].slug]: "Surfaçage",
//     [MACHININGS[2].slug]: "Contournage",
//     [MACHININGS[3].slug]: "Rainurage",
//     [MACHININGS[4].slug]: "Poche",
//     [MACHININGS[5].slug]: "Interpolation hélicoïdale",
//     // Machining_subtype.Centrage: "Centrage",

//     [MACHININGS[6].slug]: "Perçage",

//     [MACHININGS[7].slug]: "Perçage sur face plate",
//     [MACHININGS[8].slug]: "Perçage sur face convexe",
//     [MACHININGS[9].slug]: "Perçage sur face concave",
//     // Machining_subtype.Alesage: "Alésage",
// };

enum CUT_PARAM_TYPE {
	// Vc,
	// n,
	// D,
	// Z,
	// Fz,
	// Vf,
	// ap,
	// ae,
	// Q,
	// Rm,
	// eta,
	// P,
	// hm,
	// Emax,
	// LgCop,
	// VolCop,
	// Vctf,

	FRAISAGE_Vc,
	FRAISAGE_n,
	FRAISAGE_d1,
	FRAISAGE_Z,
	FRAISAGE_Fz,
	FRAISAGE_Vf,
	FRAISAGE_ap,
	FRAISAGE_ae,
	FRAISAGE_Q,
	FRAISAGE_hm,
	FRAISAGE_Emax,

	PERCAGE_Vc,
	PERCAGE_n,
	PERCAGE_d1,
	PERCAGE_Z,
	PERCAGE_F,
	PERCAGE_Vf,
	PERCAGE_Q,
}

class CutParam {
	key: string;
	title: string;
	suffix: string;
	isLockable: boolean;
	isEditable: boolean;

	decimalLength: number;

	constructor({ key, title, suffix, isLockable, isEditable, decimalLength }: any) {
		this.key = key;
		this.title = title;
		this.suffix = suffix;
		this.isLockable = isLockable;
		this.isEditable = isEditable;
		this.decimalLength = decimalLength;
	}
}

// const FRAISAGE_FIELDS = new Map<CUT_PARAM_TYPE, CutParam>([
// 	[CUT_PARAM_TYPE.Vc, new CutParam({ key: "Vc", title: "Vc", suffix: "m/min", isLockable: true, isEditable: true, decimalLength: 0 })],
// 	[CUT_PARAM_TYPE.n, new CutParam({ key: "n", title: "n", suffix: "tr/min", isLockable: true, isEditable: true, decimalLength: 0 })],
// 	[CUT_PARAM_TYPE.D, new CutParam({ key: "D", title: "d\u2081", suffix: "mm", isLockable: true, isEditable: true, decimalLength: 3 })],
// 	[CUT_PARAM_TYPE.Z, new CutParam({ key: "Z", title: "Z", suffix: "dents", isLockable: true, isEditable: true, decimalLength: 0 })],
// 	[CUT_PARAM_TYPE.Fz, new CutParam({ key: "Fz", title: "Fz", suffix: "mm/z", isLockable: true, isEditable: true, decimalLength: 4 })],
// 	[CUT_PARAM_TYPE.Vf, new CutParam({ key: "Vf", title: "Vf", suffix: "mm/min", isLockable: true, isEditable: true, decimalLength: 0 })],
// 	[CUT_PARAM_TYPE.ap, new CutParam({ key: "ap", title: "ap", suffix: "mm", isLockable: true, isEditable: true, decimalLength: 3 })],
// 	[CUT_PARAM_TYPE.ae, new CutParam({ key: "ae", title: "ae", suffix: "mm", isLockable: true, isEditable: true, decimalLength: 3 })],
// 	[CUT_PARAM_TYPE.Q, new CutParam({ key: "Q", title: "Q", suffix: "cm³/min", isLockable: false, isEditable: false, decimalLength: 4 })],
// 	[CUT_PARAM_TYPE.Rm, new CutParam({ key: "Rm", title: "Rm", suffix: "N/mm²", isLockable: false, isEditable: false, decimalLength: 0 })],
// 	// CUT_PARAM_TYPE.eta: CutParam({title: "ƞ", suffix: "", isLockable: false, isEditable: false, decimalLength: 3}),
// 	// CUT_PARAM_TYPE.P: CutParam({title: "P", suffix: "kW", isLockable: false, isEditable: false, decimalLength: 3}),
// 	[CUT_PARAM_TYPE.hm, new CutParam({ key: "hm", title: "hm", suffix: "mm", isLockable: false, isEditable: false, decimalLength: 4 })],
// 	[CUT_PARAM_TYPE.Emax, new CutParam({ key: "Emax", title: "Emax", suffix: "mm", isLockable: false, isEditable: false, decimalLength: 4 })],
// 	// CUT_PARAM_TYPE.LgCop: CutParam({title: "LgCop", suffix: "mm", isLockable: false, isEditable: false, decimalLength: 5}),
// 	// CUT_PARAM_TYPE.VolCop: CutParam({title: "VolCop", suffix: "mm³", isLockable: false, isEditable: false, decimalLength: 5}),
// 	// CUT_PARAM_TYPE.Vctf: CutParam({title: "Vctf", suffix: "mm³", isLockable: false, isEditable: false, decimalLength: 5}),
// ]);

const FRAISAGE_FIELDS = new Map<CUT_PARAM_TYPE, CutParam>([
	[CUT_PARAM_TYPE.FRAISAGE_Vc, new CutParam({key: "CUT_PARAM_TYPE.FRAISAGE_Vc", title: "Vc", suffix: "m/min", isLockable: true, isEditable: true, decimalLength: 0 })],
	[CUT_PARAM_TYPE.FRAISAGE_n, new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_n", title: "n", suffix: "tr/min", isLockable: true, isEditable: true, decimalLength: 0 })],
	[CUT_PARAM_TYPE.FRAISAGE_d1, new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_d1", title: "d\u2081", suffix: "mm", isLockable: true, isEditable: true, decimalLength: 3 })],
	[CUT_PARAM_TYPE.FRAISAGE_Z, new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_Z", title: "Z", suffix: "dents", isLockable: true, isEditable: true, decimalLength: 0 })],
	[CUT_PARAM_TYPE.FRAISAGE_Fz, new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_Fz", title: "Fz", suffix: "mm/z", isLockable: true, isEditable: true, decimalLength: 4 })],
	[CUT_PARAM_TYPE.FRAISAGE_Vf, new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_Vf", title: "Vf", suffix: "mm/min", isLockable: true, isEditable: true, decimalLength: 0 })],
	[CUT_PARAM_TYPE.FRAISAGE_ap, new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_ap", title: "ap", suffix: "mm", isLockable: true, isEditable: true, decimalLength: 3 })],
	[CUT_PARAM_TYPE.FRAISAGE_ae, new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_ae", title: "ae", suffix: "mm", isLockable: true, isEditable: true, decimalLength: 3 })],
	[CUT_PARAM_TYPE.FRAISAGE_Q, new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_Q", title: "Q", suffix: "cm³/min", isLockable: false, isEditable: false, decimalLength: 4 })],
	[CUT_PARAM_TYPE.FRAISAGE_hm, new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_hm", title: "hm", suffix: "mm", isLockable: false, isEditable: false, decimalLength: 4 })],
	[CUT_PARAM_TYPE.FRAISAGE_Emax, new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_Emax", title: "Emax", suffix: "mm", isLockable: false, isEditable: false, decimalLength: 4 })],
]);


// const FRAISAGE_FIELDS = {
// 	[CUT_PARAM_TYPE.FRAISAGE_Vc]: new CutParam({key: "CUT_PARAM_TYPE.FRAISAGE_Vc", title: "Vc", suffix: "m/min", isLockable: true, isEditable: true, decimalLength: 0 }),
// 	[CUT_PARAM_TYPE.FRAISAGE_n]: new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_n", title: "n", suffix: "tr/min", isLockable: true, isEditable: true, decimalLength: 0 }),
// 	[CUT_PARAM_TYPE.FRAISAGE_d1]: new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_d1", title: "d\u2081", suffix: "mm", isLockable: true, isEditable: true, decimalLength: 3 }),
// 	[CUT_PARAM_TYPE.FRAISAGE_Z]: new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_Z", title: "Z", suffix: "dents", isLockable: true, isEditable: true, decimalLength: 0 }),
// 	[CUT_PARAM_TYPE.FRAISAGE_Fz]: new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_Fz", title: "Fz", suffix: "mm/z", isLockable: true, isEditable: true, decimalLength: 4 }),
// 	[CUT_PARAM_TYPE.FRAISAGE_Vf]: new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_Vf", title: "Vf", suffix: "mm/min", isLockable: true, isEditable: true, decimalLength: 0 }),
// 	[CUT_PARAM_TYPE.FRAISAGE_ap]: new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_ap", title: "ap", suffix: "mm", isLockable: true, isEditable: true, decimalLength: 3 }),
// 	[CUT_PARAM_TYPE.FRAISAGE_ae]: new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_ae", title: "ae", suffix: "mm", isLockable: true, isEditable: true, decimalLength: 3 }),
// 	[CUT_PARAM_TYPE.FRAISAGE_Q]: new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_Q", title: "Q", suffix: "cm³/min", isLockable: false, isEditable: false, decimalLength: 4 }),
// 	[CUT_PARAM_TYPE.FRAISAGE_hm]: new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_hm", title: "hm", suffix: "mm", isLockable: false, isEditable: false, decimalLength: 4 }),
// 	[CUT_PARAM_TYPE.FRAISAGE_Emax]: new CutParam({ key: "CUT_PARAM_TYPE.FRAISAGE_Emax", title: "Emax", suffix: "mm", isLockable: false, isEditable: false, decimalLength: 4 }),
// };

const PERCAGE_FIELDS = new Map<CUT_PARAM_TYPE, CutParam>([
	[CUT_PARAM_TYPE.PERCAGE_Vc, new CutParam({ key: "CUT_PARAM_TYPE.PERCAGE_Vc", title: "Vc", suffix: "m/min", isLockable: true, isEditable: true, decimalLength: 0 })],
	[CUT_PARAM_TYPE.PERCAGE_n, new CutParam({ key: "CUT_PARAM_TYPE.PERCAGE_n", title: "n", suffix: "tr/min", isLockable: true, isEditable: true, decimalLength: 0 })],
	[CUT_PARAM_TYPE.PERCAGE_d1, new CutParam({ key: "CUT_PARAM_TYPE.PERCAGE_d1", title: "d\u2081", suffix: "mm", isLockable: true, isEditable: true, decimalLength: 3 })],
	[CUT_PARAM_TYPE.PERCAGE_Z, new CutParam({ key: "CUT_PARAM_TYPE.PERCAGE_Z", title: "Z", suffix: "dents", isLockable: true, isEditable: true, decimalLength: 0 })],
	[CUT_PARAM_TYPE.PERCAGE_F, new CutParam({ key: "CUT_PARAM_TYPE.PERCAGE_F", title: "F", suffix: "mm/tr", isLockable: true, isEditable: true, decimalLength: 4 })],
	[CUT_PARAM_TYPE.PERCAGE_Vf, new CutParam({ key: "CUT_PARAM_TYPE.PERCAGE_Vf", title: "Vf", suffix: "mm/min", isLockable: true, isEditable: true, decimalLength: 0 })],
	[CUT_PARAM_TYPE.PERCAGE_Q, new CutParam({ key: "CUT_PARAM_TYPE.PERCAGE_Q", title: "Q", suffix: "cm³/min", isLockable: false, isEditable: false, decimalLength: 4 })],
]);

const MACHINES_IMAGES: Record<string, any> = {
	MR430: require("assets/data_images/machines/MR430.png"),
	NP50: require("assets/data_images/machines/NP50.jpg"),
};

const USINAGES_IMAGES: Record<string, any> = {
	"100000": require("assets/data_images/usinages/100000.jpg"),
	"100001": require("assets/data_images/usinages/100001.jpg"),
	"100002": require("assets/data_images/usinages/100002.jpg"),
	"100003": require("assets/data_images/usinages/100003.jpg"),
	"100004": require("assets/data_images/usinages/100004.jpg"),
	"100005": require("assets/data_images/usinages/100005.jpg"),
	"100006": require("assets/data_images/usinages/100006.jpg"),
	"100007": require("assets/data_images/usinages/100007.jpg"),
	"100008": require("assets/data_images/usinages/100008.jpg"),
	"100009": require("assets/data_images/usinages/100009.jpg"),
};

export type ArticleCoating = {
  article: Article;
  coating: Coating;
};

export {
	MACHININGS,
	CUT_PARAM_TYPE,
	FRAISAGE_FIELDS,
  PERCAGE_FIELDS,
	MACHINES_IMAGES,
	USINAGES_IMAGES,
  CutParam,
  // ArticleCoating,
	// MachiningNames,
};

