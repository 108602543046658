import Machining from "models/machining";
import Config from "config/config";
import axios from "axios";

export default class MachiningRepository {
	async getData(): Promise<Array<Machining>> {
		var data: any = await axios.get(Config.base_data_url + "/getJson?file=IND_USINAGE", {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "X-Requested-With",
			},
		});
		return await this.dataToMachining(data.data);
	}

	dataToMachining(data: any): Array<Machining> {
		var machiningGroups: Array<Machining> = [];
		for (var i = 0; i < data.length; i++) {
			if (data[i]["USI_ID"] != "100007" && data[i]["USI_ID"] != "100008") {
				machiningGroups.push(Machining.fromJson(data[i]));
			}
		}
		return machiningGroups;
	}
}
