import { ArticleCoating } from "constants/constants";

export abstract class ArticlesCoatingsSelectionEvent {}

export class ArticlesCoatingsSelectionLoad extends ArticlesCoatingsSelectionEvent {}

export class ArticlesCoatingsSelectionSave extends ArticlesCoatingsSelectionEvent {
  articlesCoatingsSelection: Array<ArticleCoating>;
  constructor(articlesCoatingsSelection: Array<ArticleCoating>) {
    super();
    this.articlesCoatingsSelection = articlesCoatingsSelection;
  }
}
