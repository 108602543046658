import { Bloc, Transition } from "@felangel/bloc";
import Message from "models/message";
import MessageRepository from "repositories/message_repository";
import { MessagesEvent, MessagesLoad } from "./messages_event";
import { MessagesState, MessagesLoaded, MessagesLoading, MessagesInitial, MessagesFailure } from "./messages_state";


export default class MessagesBloc extends Bloc<MessagesEvent, MessagesState> {
	messageRepository: MessageRepository;
	messages: Array<Message> = [];

	constructor(messageRepository: MessageRepository) {
		super(new MessagesInitial());
		this.messageRepository = messageRepository;
	}

	async *mapEventToState(event: MessagesEvent) {
		if (event instanceof MessagesLoad) {
			yield* this.mapLoadMessagesToState(event);
		}
	}

	async *mapLoadMessagesToState(event: MessagesLoad) {
		try {
			yield new MessagesLoading();
			var messagesList: Array<Message> = await this.messageRepository.getData();
			this.messages = messagesList;

			yield new MessagesLoaded(this.messages);
		} catch (e) {
			if (typeof e === "string") {
				yield new MessagesFailure(e);
			} else {
				// yield new MessagesFailure(e.toString());
				yield new MessagesFailure("Unknow error");
			}
		}
	}

	// Called whenever an `event` is added.
	onEvent(event: MessagesEvent): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
	}

	// Called whenever a state change is about to occur.
	onTransition(transition: Transition<any, any>): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
	}

	onError(error: any): void {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
	}
}
