import { Bloc, Transition } from "@felangel/bloc";
import Machining from "models/machining";
import MachiningRepository from "repositories/machining_repository";
import { MachiningsEvent, MachiningsLoad } from "./machining_event";
import { MachiningsState, MachiningsLoaded, MachiningsLoading, MachiningsInitial, MachiningsFailure } from "./machining_state";


export default class MachiningsBloc extends Bloc<MachiningsEvent, MachiningsState> {
  machiningRepository: MachiningRepository;
  machinings: Array<Machining> = [];

  constructor(machiningRepository: MachiningRepository) {
    super(new MachiningsInitial());
    this.machiningRepository = machiningRepository;
  }

  async *mapEventToState(event: MachiningsEvent) {
    if (event instanceof MachiningsLoad) {
      yield* this.mapLoadMachiningsToState(event);
    }
  }

  async *mapLoadMachiningsToState(event: MachiningsLoad) {
    try {
      yield new MachiningsLoading();
      var machiningsList: Array<Machining> = await this.machiningRepository.getData();
      this.machinings = machiningsList;

      yield new MachiningsLoaded(this.machinings);
    } catch (e) {
      if (typeof e === "string") {
        yield new MachiningsFailure(e);
      } else {
        // yield new MachiningsFailure(e.toString());
        yield new MachiningsFailure("Unknow error");
      }
    }
  }

  // Called whenever an `event` is added.
  onEvent(event: MachiningsEvent): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("New event = ", event);
  }

  // Called whenever a state change is about to occur.
  onTransition(transition: Transition<any, any>): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Transition = ", transition);
  }

  onError(error: any): void {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") console.log("Error = ", error);
  }
}
