import GeneralHelper from "helpers/general_helper";

export default class AssRefUsi {
	// REF_USI_ID
	id: number;
	// USI_ID
	usiId: number;
	// GCO_GOOD_CATEGORY_ID
	refId: number;
	// REF_USI_COEF
	coef: number;
	// USI_GRP_ID
	usiGrpId: number;

	constructor({ id, usiId, refId, coef, usiGrpId }: any /*NamedParameters*/) {
		this.id = id;
		this.usiId = usiId;
		this.refId = refId;
		this.coef = coef;
		this.usiGrpId = usiGrpId;
	}

	static areIdentical(a: AssRefUsi, b: AssRefUsi): boolean {
		if (!a && !b) return true;
		if (!a || !b) return false;
		return a.id === b.id;
	}

	// setAffinity({ material }: { material: Material }) {
	// 	if (material && this.indicesMaterial[material.id]) {
	// 		this.affinity = this.indicesMaterial[material.id];
	// 		return;
	// 	}

	// 	this.affinity = 0;
	// }

	static fromJson(map: Record<string, any>): AssRefUsi {
		return new AssRefUsi({
			id: GeneralHelper.parseIntOrNull(map["REF_USI_ID"]),
			usiId: GeneralHelper.parseIntOrNull(map["USI_ID"]),
			refId: GeneralHelper.parseIntOrNull(map["GCO_GOOD_CATEGORY_ID"]),
			coef: map["REF_USI_COEF"],
			usiGrpId: GeneralHelper.parseIntOrNull(map["USI_GRP_ID"]),
		});
	}
}
