import React from "react";
import logo from "assets/images/logo-louis-belet.svg";
import "./header.scss";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { WithTranslation, withTranslation } from "react-i18next";
import { BlocsContext } from "contexts/blocs_context";
import { BlocBuilder } from "@felangel/react-bloc";
import { CartState, CartUpdated } from "blocs/cart_bloc/cart_state";
import { AuthenticationAuthenticated, AuthenticationState } from "blocs/authentication_bloc/authentication_state";
import { AuthenticationLoggedOut } from "blocs/authentication_bloc/authentication_event";

import ShoppingCartIcon from "assets/icons/shopping-cart.svg";
import ReactModal from "react-modal";
import skylightLogo from "assets/images/skylight_logo.png";
import config from "../config/config";
import { get } from "jquery";

// type Props = {
// title: string,
// paragraph: string
// }

type State = {
  isToggleOn: boolean;
  modalIsOpen: boolean;
};

const CustomToggle = React.forwardRef(({ children, onClick, customClassName }: any, ref: any) => (
  <button
    // href="#"
    className={customClassName + ""}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  // onMouseEnter={(e) => onClick(e)}
  // onMouseLeave={(e) => onClick(e)}
  >
    {children}
    <span className="icon icon-angle-down"></span>
  </button>
));

interface Props extends RouteComponentProps<any>, WithTranslation { }

// interface Props extends WithTranslation {

// }

class Header extends React.Component<Props, State> {
  static contextType = BlocsContext;
  context!: React.ContextType<typeof BlocsContext>;


  constructor(props: Props) {
    super(props);
    this.state = {
      isToggleOn: false,
      modalIsOpen: false,
    };
  }

  isRouteActive(route: string) {
    return !!matchPath(this.props.location.pathname, { path: route, exact: true, strict: false });
  }

  handleClick = () => {
    this.setState((state) => ({
      isToggleOn: !state.isToggleOn,
    }));
  };

  getSkylightUrl = () => {
    var params = [];
    if (this.context.authenticationBloc.state instanceof AuthenticationAuthenticated && this.context.authenticationBloc.authenticationData != undefined) {
      params.push("token=" + this.context.authenticationBloc.authenticationData.accessToken);
    }
    params.push('link=0');
    return config.SKYLIGHT_WEBSITE + "?" + params.join("&");
  }

  renderDesktopHeader() {
    return (
      <header className="main-navbar">
        <div className="px-3 borderBottom">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <a href="/" className="d-flex align-items-center my-2 my-lg-0 text-white text-decoration-none">
                {/* <svg className="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap"><use xlink:href="#bootstrap"></use></svg> */}
                <img src={logo} className="App-logo" alt="logo" />
              </a>

              <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                <li>
                  <Link to="/" className={"nav-link text-secondary " + (this.isRouteActive("/") ? "active" : "")}>
                    {this.props.t("menu.menu_toolfinder")}
                  </Link>
                </li>
                {/* <li>
                                    <a href="#" className="nav-link text-secondary">Favoris <span className="badge rounded-pill bg-primary">0</span></a>
                                </li> */}
                <li>
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-custom-components"
                      customClassName={
                        "btn-cancel-style nav-link text-secondary " +
                        (this.isRouteActive("/favorites-tools") || this.isRouteActive("/favorites-materials")
                          ? "active"
                          : "")
                      }
                    >
                      {this.props.t("menu.menu_favorites")}
                      {/* <span className="badge rounded-pill bg-primary">0</span> */}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Link className="dropdown-item" to="/favorites-tools">
                        {this.props.t("menu.menu_favorite_tools")}
                      </Link>
                      <Link className="dropdown-item" to="/favorites-materials">
                        {this.props.t("menu.menu_favorites_materials")}
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                {/* <li>
									<Link to="/help-info" className={"nav-link text-secondary " + (this.isRouteActive("/help-info") ? "active" : "")}>
										{this.props.t("menu.menu_help_info")}
									</Link>
								</li> */}
                {/* <li>
                    <Link to="/privacy-policy" className={"nav-link text-secondary " + (this.isRouteActive("/privacy-policy") ? "active" : "")}>
                      {this.props.t("menu.menu_privacy_policy")}
                    </Link>
                  </li> */}
                {/* <li>
									<Link to="/about" className={"nav-link text-secondary " + (this.isRouteActive("/about") ? "active" : "")}>
										{this.props.t("menu.menu_about")}
									</Link>
								</li> */}
                <li>
                  <a href="https://www.louisbelet.ch/" target="_blank" className="nav-link text-secondary">
                    <span className="icon icon-export"></span> Louis Bélet SA
                  </a>
                </li>

                {/* <li>
                    <Link
                      to="/cart"
                      className={"nav-link text-secondary " + (this.isRouteActive("/cart") ? "active" : "")}
                    >
                      <BlocBuilder
                        bloc={this.context.cartBloc}
                        builder={(state: CartState) => {
                          var articlsNbr = 0;
                          if (state instanceof CartUpdated) {
                            articlsNbr = state.cart.articles.length;
                          }
                          return (
                            <div className="shoppingcart-menu">
                              <div className="shoppingcart-menu-container">
                                <img src={ShoppingCartIcon} />
                                <span className="cart-badge">{articlsNbr}</span>
                              </div>
                            </div>
                          );
                        }}
                      />
                    </Link>
                  </li> */}
                <li>
                  <BlocBuilder
                    bloc={this.context.authenticationBloc}
                    builder={(state: AuthenticationState) => {
                      if (state instanceof AuthenticationAuthenticated) {
                        return (
                          <div
                            className="nav-link text-secondary"
                            onClick={() => {
                              this.setState({ modalIsOpen: true });
                            }}
                          >
                            {this.props.t('menu.menu_logout')}
                          </div>
                        );
                      } else {
                        return (
                          <Link
                            to="/login"
                            className={"nav-link text-secondary " + (this.isRouteActive("/login") ? "active" : "")}
                          >
                            <div>{this.props.t('menu.menu_login')}</div>
                          </Link>
                        );
                      }
                    }}
                  />
                </li>

                {/* <li className="skylight-menu">
                    <a
                      href={
                        "https://skylight.louisbelet.ch" +
                        (this.context.authenticationBloc.state instanceof AuthenticationAuthenticated
                          ? "?token=" + this.context.authenticationBloc.authenticationData.accessToken
                          : "")
                      }
                      target="_blank"
                      className="nav-link text-secondary"
                    >
                      <img src={skylightLogo} className="skylight-logo"/>
                    </a>
                  </li> */}


              </ul>

              <div className="d-flex align-items-center ml-5 gap-2">


                <BlocBuilder
                  bloc={this.context.authenticationBloc}
                  builder={(state: AuthenticationState) => {
                    if (state instanceof AuthenticationAuthenticated) {
                      return (
                        <div className="text-secondary">
                          {state.authenticationData.user.email}
                        </div>
                      );
                    } else {
                      return (
                        <></>
                      );
                    }
                  }}
                />


                <Link
                  to="/cart"
                  className={"nav-link text-secondary " + (this.isRouteActive("/cart") ? "active" : "")}
                >
                  <BlocBuilder
                    bloc={this.context.cartBloc}
                    builder={(state: CartState) => {
                      var articlsNbr = 0;
                      if (state instanceof CartUpdated) {
                        articlsNbr = state.cart.articles.length;
                      }
                      return (
                        <div className="shoppingcart-menu">
                          <div className="shoppingcart-menu-container">
                            <img src={ShoppingCartIcon} />
                            <span className="cart-badge">{articlsNbr}</span>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Link>


                <Dropdown className="language-dropdown">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {this.props.i18n.language.toUpperCase()}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      active={this.props.i18n.language === "fr"}
                      onClick={() => this.props.i18n.changeLanguage("fr")}
                      size="12px"
                    >
                      FR
                    </Dropdown.Item>
                    <Dropdown.Item
                      active={this.props.i18n.language === "en"}
                      onClick={() => this.props.i18n.changeLanguage("en")}
                      size="12px"
                    >
                      EN
                    </Dropdown.Item>
                    <Dropdown.Item
                      active={this.props.i18n.language === "de"}
                      onClick={() => this.props.i18n.changeLanguage("de")}
                      size="12px"
                    >
                      DE
                    </Dropdown.Item>
                    <Dropdown.Item
                      active={this.props.i18n.language === "it"}
                      onClick={() => this.props.i18n.changeLanguage("it")}
                      size="12px"
                    >
                      IT
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {/* <a
                    className="btn btn-outline-primary contact-btn"
                    target="_blank"
                    href="https://www.louisbelet.ch/fr/louis-belet/contact/"
                  >
                    {this.props.t("menu.contact_us")}
                  </a> */}


                <a
                  href={this.getSkylightUrl()}
                  target="_blank"
                  className="btn skylight-btn"
                >
                  <img src={skylightLogo} className="skylight-logo" />
                </a>

              </div>
            </div>
          </div>
        </div>
        {/* <div className="px-3 py-2 border-bottom mb-3">
					<div className="container d-flex flex-wrap justify-content-center">
						<div className="text-end">
						<button type="button" className="btn btn-light text-dark me-2">Login</button>
						<button type="button" className="btn btn-primary">Sign-up</button>
						</div>
					</div>
					</div> */}
      </header>
    );
  }

  renderMobileHeader() {

    return (
      <>

        <header className="main-navbar mobile">
          <div className="container">
            <a href="/" className="d-flex align-items-center my-2 my-lg-0 text-white text-decoration-none">
              {/* <svg className="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap"><use xlink:href="#bootstrap"></use></svg> */}
              <img src={logo} className="App-logo" alt="logo" />
            </a>

            <label htmlFor="check">
              <input type="checkbox" id="check" onClick={this.handleClick} />
              <span></span>
              <span></span>
              <span></span>
            </label>
          </div>
        </header>
        <div className={"SideBarNavigation " + (this.state.isToggleOn ? "enabled" : "disabled")} id="LBSideNav">
          <div className="topSection">
            <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
              <div className="d-flex align-items-center my-2 my-lg-0 text-white text-decoration-none">
                {/* <svg className="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap"><use xlink:href="#bootstrap"></use></svg> */}
                <img src={logo} className="App-logo" alt="logo" />
              </div>
              <li>
                <Link to="/" className={"nav-link text-secondary " + (this.isRouteActive("/") ? "active" : "")}>
                  {this.props.t("menu.menu_toolfinder")}
                </Link>
              </li>
              <li>
                <Link
                  to="/favorites-tools"
                  className={"nav-link text-secondary " + (this.isRouteActive("/") ? "active" : "")}
                >
                  {this.props.t("menu.menu_favorite_tools")}
                </Link>
              </li>
              <li>
                <Link
                  to="/favorites-materials"
                  className={"nav-link text-secondary " + (this.isRouteActive("/") ? "active" : "")}
                >
                  {this.props.t("menu.menu_favorites_materials")}
                </Link>
              </li>
              <li>
                <Link
                  to="/help-info"
                  className={"nav-link text-secondary " + (this.isRouteActive("/help-info") ? "active" : "")}
                >
                  {this.props.t("menu.menu_help_info")}
                </Link>
              </li>
              {/* <li>
					<Link to="/about" className={"nav-link text-secondary " + (this.isRouteActive("/about") ? "active" : "")}>
						{this.props.t("menu.menu_about")}
					</Link>
				</li> */}
              <li>
                <a href="https://www.louisbelet.ch/" target="_blank" className="nav-link text-secondary">
                  <span className="icon icon-export"></span> Louis Bélet SA
                </a>
              </li>

              <li>
                <Link
                  to="/cart"
                  className={"nav-link text-secondary " + (this.isRouteActive("/cart") ? "active" : "")}
                >
                  <BlocBuilder
                    bloc={this.context.cartBloc}
                    condition={(previousState: CartState, currentState: CartState) => true}
                    builder={(state: CartState) => {
                      var articlsNbr = 0;
                      if (state instanceof CartUpdated) {
                        articlsNbr = state.cart.articles.length;
                      }
                      return (
                        <div className="">
                          <div className="shoppingcart-menu">
                            <div className="shoppingcart-menu-container d-inline">
                              <span className="my-cart-mobile">{this.props.t("menu.menu_my_cart")}</span>
                              <span className="cart-badge">{articlsNbr}</span>
                            </div>
                          </div>
                          {/* <img src={ShoppingCartIcon} /> */}
                        </div>
                      );
                    }}
                  />
                </Link>
              </li>

              <li>
                <BlocBuilder
                  bloc={this.context.authenticationBloc}
                  builder={(state: AuthenticationState) => {
                    if (state instanceof AuthenticationAuthenticated) {
                      return (
                        <div
                          className="nav-link text-secondary"
                          onClick={() => {
                            this.setState({ modalIsOpen: true });
                          }}
                        >
                          {this.props.t('menu.menu_logout')}
                        </div>
                      );
                    } else {
                      return (
                        <Link
                          to="/login"
                          className={"nav-link text-secondary " + (this.isRouteActive("/login") ? "active" : "")}
                        >
                          <div>{this.props.t('menu.menu_login')}</div>
                        </Link>
                      );
                    }
                  }}
                />
              </li>

            </ul>



            <div className="langageChooser d-flex align-items-center ml-5">

              <Dropdown className="language-dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {this.props.i18n.language.toUpperCase()}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    active={this.props.i18n.language === "fr"}
                    onClick={() => this.props.i18n.changeLanguage("fr")}
                    size="12px"
                  >
                    FR
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={this.props.i18n.language === "en"}
                    onClick={() => this.props.i18n.changeLanguage("en")}
                    size="12px"
                  >
                    EN
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={this.props.i18n.language === "de"}
                    onClick={() => this.props.i18n.changeLanguage("de")}
                    size="12px"
                  >
                    DE
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={this.props.i18n.language === "it"}
                    onClick={() => this.props.i18n.changeLanguage("it")}
                    size="12px"
                  >
                    IT
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {/* <a
                className="btn btn-outline-primary contact-btn"
                target="_blank"
                href="https://www.louisbelet.ch/fr/louis-belet/contact/"
              >
                {this.props.t("menu.contact_us")}
              </a> */}

              <a
                href={this.getSkylightUrl()}
                target="_blank"
                className="btn skylight-btn"
              >
                <img src={skylightLogo} className="skylight-logo" />
              </a>
            </div>
          </div>



          <div className="bottomSection">

            <div className="bottomLinks">
              <span className="text-muted">
                {/* <a href="https://www.novadev.ch/" target="_blank">Politique de confidentialité</a> */}
                <Link to="/privacy-policy">
                  {this.props.t("menu.menu_privacy_policy")}
                </Link>
              </span>
            </div>
          </div>

        </div>
      </>
    );
  }
  render() {
    return (
      <div className="header">
        {this.renderDesktopHeader()}
        {this.renderMobileHeader()}


        <ReactModal
          isOpen={this.state.modalIsOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => this.setState({ modalIsOpen: false })}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              padding: 35,
              borderRadius: 5,
            },
            overlay: {
              backgroundColor: "rgba(0,0,0,0.7)",
            },
          }}
          contentLabel="Example Modal"
        >
          <h3>Voulez-vous vraiment vous déconnecter ?</h3>
          <div className="d-flex justify-content-end">
            <button className="btn btn-danger text-white mx-2" onClick={() => this.setState({ modalIsOpen: false })}>
              Non
            </button>
            <button
              className="btn btn-primary text-white"
              onClick={() => {
                this.context.authenticationBloc.add(new AuthenticationLoggedOut());
                this.setState({ modalIsOpen: false });
              }}
            >
              Oui
            </button>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default withTranslation("common")(withRouter(Header));
