import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import config from "../config/config";

export default class FavoriteService {
  static async getSkylightFavorites(accessToken: string) {
    var cartResponse: AxiosResponse = await axios.get(config.SKYLIGHT_API + "/getFav", {
      headers: {
        Accept: "application/json",
      },
      params: {
        token: accessToken,
      },
    } as AxiosRequestConfig);
    return cartResponse;
  }

  static async addFavoriteToSkylight(accessToken: string, goodid: number, rev: string|undefined) {
    var cartResponse: AxiosResponse = await axios.post(
      config.SKYLIGHT_API + "/insertFav",
      {
        goodid: goodid,
        rev: rev ?? "",
        prep: "",
        fini: "",
      },
      {
        headers: {
          Accept: "application/json",
        },
        params: {
          token: accessToken,
        },
      } as AxiosRequestConfig
    );
    return cartResponse;
  }

  static async removeFavoriteToSkylight(accessToken: string, goodid: number, rev: string|undefined) {
    var cartResponse: AxiosResponse = await axios.delete(config.SKYLIGHT_API + "/deleteFav", {
      headers: {
        Accept: "application/json",
      },
      params: {
        token: accessToken,
      },
      data: {
        goodid: goodid,
        rev: rev ?? "",
        prep: "",
        fini: "",
      },
    } as AxiosRequestConfig);
    return cartResponse;
  }
}
