import Reference from "models/reference";
import Config from "config/config";
import axios from "axios";

export default class ReferenceRepository {
	async getData(): Promise<Array<Reference>> {
		var data: any = await axios.get(Config.base_data_url + "/getJson?file=IND_TOOLFIND_REF", {
			headers: {
				"Access-Control-Allow-Origin": "*",
				// "Access-Control-Allow-Headers": "X-Requested-With",
				"Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
				"Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
			},
		});
		return await this.dataToReference(data.data);
	}

	dataToReference(data: any): Array<Reference> {
		var referenceGroups: Array<Reference> = [];
		for (var i = 0; i < data.length; i++) {
			referenceGroups.push(Reference.fromJson(data[i]));
		}
		return referenceGroups;
	}
}
