import AssRefUsi from "models/ass_ref_usi";
import Config from "config/config";
import axios from "axios";

export default class AssRefUsiRepository {
	async getData(): Promise<Array<AssRefUsi>> {
		var data: any = await axios.get(Config.base_data_url + "/getJson?file=IND_TOOLFIND_REF_USINAGE", {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "X-Requested-With",
			},
		});
		return await this.dataToAssRefUsi(data.data);
	}

	dataToAssRefUsi(data: any): Array<AssRefUsi> {
		var assRefUsis: Array<AssRefUsi> = [];
		for (var i = 0; i < data.length; i++) {
			assRefUsis.push(AssRefUsi.fromJson(data[i]));
		}
		return assRefUsis;
	}
}
