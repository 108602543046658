import React from "react";
import "./machine_item.scss";
import Machine from "models/machine";
import { BlocsContext } from "contexts/blocs_context";
import { MACHINES_IMAGES } from "constants/constants";
import defaultMachineImage from "assets/data_images/default_machine_picture.png";
import config from "config/config";
import { RouteComponentProps, withRouter } from "react-router";
import { withTranslation, WithTranslation } from "react-i18next";

type State = {
	isFavorite: boolean;
};

interface Props extends RouteComponentProps<any>, WithTranslation {
	selected: boolean;
	machine: Machine;
	onClick: Function;
}

class MachineItem extends React.Component<Props, State> {
	static contextType = BlocsContext;
	context!: React.ContextType<typeof BlocsContext>;

	constructor(props: Props) {
		super(props);
		this.state = {
			isFavorite: false,
		};
	}

	render() {
		return (
			<button
				onClick={(e) => this.props.onClick()}
				className={
					"relative machine-item d-flex flex-column justify-content-center align-items-center " + (this.props.selected === true ? "active" : "")
				}
			>
				<div className="image-wrapper">
					<img
						src={config["lb_assets_base"] + this.props.machine.image}
						onError={(e) => {
							// e.currentTarget.sr
							e.currentTarget.onerror = null;
							e.currentTarget.src = defaultMachineImage;
						}}
						className="machine-image"
					/>
				</div>

				{this.props.machine.getShortDescription(this.props.i18n.language)}

				{/* <span className="icon icon-heart-o favorite"></span> */}
			</button>
		);
	}
}

export default withTranslation("common")(withRouter(MachineItem));
